/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalDetailsReviewGoalStatus_goal = {
    readonly type: GoalType;
    readonly " $refType": "GoalDetailsReviewGoalStatus_goal";
};
export type GoalDetailsReviewGoalStatus_goal$data = GoalDetailsReviewGoalStatus_goal;
export type GoalDetailsReviewGoalStatus_goal$key = {
    readonly " $data"?: GoalDetailsReviewGoalStatus_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsReviewGoalStatus_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsReviewGoalStatus_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = 'a6a32757d5634151fe1adba4498a885f';
export default node;
