import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Box } from "@mui/system";
import Logo from "common/Logo";
import { useNavigate } from "react-router";

const Container = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
`

const MainNavLogo = () => {
  const navigate = useNavigate();
  return <Toolbar
    sx={{
      height: useTheme().app.toolbarHeight
    }}
  >
    <Container onClick={() => navigate("/")} data-cy="mainLogo">
        <Logo height="2.1rem" fontSize="1.9rem" />
    </Container>
  </Toolbar>
}

export default MainNavLogo;