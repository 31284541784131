/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalsListListGoalsQueryVariables = {
    first?: number | null;
    after?: string | null;
    last?: number | null;
    before?: string | null;
    sortBefore?: string | null;
    archived?: boolean | null;
};
export type GoalsListListGoalsQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"GoalsList_goalConnection">;
};
export type GoalsListListGoalsQuery = {
    readonly response: GoalsListListGoalsQueryResponse;
    readonly variables: GoalsListListGoalsQueryVariables;
};



/*
query GoalsListListGoalsQuery(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $sortBefore: String
  $archived: Boolean
) {
  ...GoalsList_goalConnection
}

fragment ArchiveGoalMenuItem_goal on Goal {
  id
  readableId
  archived
  nextReviewAt
}

fragment DeleteGoalMenuItem_goal on Goal {
  id
  readableId
}

fragment GoalCardMenuButton_goal on Goal {
  ...GoalCardMenu_goal
}

fragment GoalCardMenu_goal on Goal {
  id
  readableId
  ...DeleteGoalMenuItem_goal
  ...ArchiveGoalMenuItem_goal
}

fragment GoalCardTitle_goal on Goal {
  readableId
  title
  description
  ...GoalStatusField_goal
}

fragment GoalCard_goal on Goal {
  id
  type
  readableId
  title
  nextReviewAt
  currentReviewAt
  dueDate
  completion
  ...GoalCardTitle_goal
  ...GoalStatusField_goal
  ...GoalCardMenuButton_goal
}

fragment GoalStatusField_goal on Goal {
  type
  status
  completion
  currentReviewAt
}

fragment GoalsList_goalConnection on Query {
  listGoals(first: $first, after: $after, last: $last, before: $before, sortBefore: $sortBefore, archived: $archived) {
    edges {
      node {
        ...GoalCard_goal
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBefore"
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "archived",
    "variableName": "archived"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "sortBefore",
    "variableName": "sortBefore"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GoalsListListGoalsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "GoalsList_goalConnection"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "GoalsListListGoalsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "GoalConnection",
        "kind": "LinkedField",
        "name": "listGoals",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GoalEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readableId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nextReviewAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentReviewAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dueDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "filters": [
          "sortBefore",
          "archived"
        ],
        "handle": "connection",
        "key": "GoalsList_listGoals",
        "kind": "LinkedHandle",
        "name": "listGoals"
      }
    ]
  },
  "params": {
    "cacheID": "16140a3c026209ce30023808095785dc",
    "id": null,
    "metadata": {},
    "name": "GoalsListListGoalsQuery",
    "operationKind": "query",
    "text": "query GoalsListListGoalsQuery(\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $sortBefore: String\n  $archived: Boolean\n) {\n  ...GoalsList_goalConnection\n}\n\nfragment ArchiveGoalMenuItem_goal on Goal {\n  id\n  readableId\n  archived\n  nextReviewAt\n}\n\nfragment DeleteGoalMenuItem_goal on Goal {\n  id\n  readableId\n}\n\nfragment GoalCardMenuButton_goal on Goal {\n  ...GoalCardMenu_goal\n}\n\nfragment GoalCardMenu_goal on Goal {\n  id\n  readableId\n  ...DeleteGoalMenuItem_goal\n  ...ArchiveGoalMenuItem_goal\n}\n\nfragment GoalCardTitle_goal on Goal {\n  readableId\n  title\n  description\n  ...GoalStatusField_goal\n}\n\nfragment GoalCard_goal on Goal {\n  id\n  type\n  readableId\n  title\n  nextReviewAt\n  currentReviewAt\n  dueDate\n  completion\n  ...GoalCardTitle_goal\n  ...GoalStatusField_goal\n  ...GoalCardMenuButton_goal\n}\n\nfragment GoalStatusField_goal on Goal {\n  type\n  status\n  completion\n  currentReviewAt\n}\n\nfragment GoalsList_goalConnection on Query {\n  listGoals(first: $first, after: $after, last: $last, before: $before, sortBefore: $sortBefore, archived: $archived) {\n    edges {\n      node {\n        ...GoalCard_goal\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cf306715efd9987ef32aa36c1959bb4b';
export default node;
