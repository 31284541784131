import { Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import AppTextField from "forms/AppTextField";
import { ControlFormFieldReturn } from "forms/useFormHandlers";

export const GoalDescriptionEditorCommonProps = {
    inputProps: {
        sx: {
            fontSize: "0.875rem",
            pt: "0.75rem",
            pb: "1rem"
        }
    },
    multiline: true,
    sx: {
        width: "100%"
    }
}

const GoalDescriptionEditor = ({
    handleChange,
    value,
    sx
}: ControlFormFieldReturn<string> & {sx?: SxProps}) => {

    return <Stack sx={sx}>
        <AppTextField
            onBlur={(e) => {
                handleChange(e.target.value);
            }}
            minRows={1}
            label="Beschreibung oder Begründung"
            {...GoalDescriptionEditorCommonProps}
        />
    </Stack>
}
export default GoalDescriptionEditor;