import { Divider, Stack, styled } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const TermsLink = styled(Link, {
    shouldForwardProp: prop => prop !== 'linkActive'
})((props: {linkActive: boolean}) => ({
    opacity: props.linkActive ? 0.8 : 0.5,
    cursor: "pointer",
    transition: "0.2s",
    textDecoration: "none",
    color: "rgba(255,255,255,1)",
    ":hover": { opacity: 0.8 }
}))

const ImprintAndTermsLink = ({ children, href }: { children: React.ReactNode, href: string }) => {
    const location = useLocation();
    const linkActive = new RegExp(href.substring(1)).test(location.pathname);
    return <TermsLink to={href} {...{linkActive}}>{children}</TermsLink>
}

const ImprintAndTerms = () => <Stack direction="row" spacing={"0.5rem"} divider={<Divider orientation="vertical" flexItem sx={{ borderColor: "rgba(255,255,255,0.4)" }} />} sx={{ fontSize: "0.7rem", padding: "0.75rem 1rem" }}>
    <ImprintAndTermsLink href="/imprint">Impressum</ImprintAndTermsLink>
    <ImprintAndTermsLink href="/terms">AGB</ImprintAndTermsLink>
    <ImprintAndTermsLink href="/privacy">Datenschutz</ImprintAndTermsLink>
</Stack>;
export default ImprintAndTerms;