/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalCardMenuButton_goal = {
    readonly " $fragmentRefs": FragmentRefs<"GoalCardMenu_goal">;
    readonly " $refType": "GoalCardMenuButton_goal";
};
export type GoalCardMenuButton_goal$data = GoalCardMenuButton_goal;
export type GoalCardMenuButton_goal$key = {
    readonly " $data"?: GoalCardMenuButton_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalCardMenuButton_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalCardMenuButton_goal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalCardMenu_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '68c2ae6c05b4eb1122b7f64e2e72898e';
export default node;
