import { Button, styled } from "@mui/material";
import { AccountSettingsDownloadDataQuery } from "main/settings/account/AccountSettingsDownloadData";
import { AccountSettingsDownloadDataGenerator_goalConnection$key } from "main/settings/account/__generated__/AccountSettingsDownloadDataGenerator_goalConnection.graphql";
import { AccountSettingsDownloadDataQuery as AccountSettingsDownloadDataQueryType } from "main/settings/account/__generated__/AccountSettingsDownloadDataQuery.graphql";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const AnchorElement = styled('a')({
    textDecoration: "none"
})

const AccountSettingsDownloadDataButton = ({ loadedQuery }: { loadedQuery: AccountSettingsDownloadDataGenerator_goalConnection$key }) => {
    const readableBytes = (bytes: number) => {
        if(bytes < 1000) return `1 KB`
        if(bytes < 1000000) return `${Math.round(bytes / 1000)} KB`
        return `${Math.round(bytes / 1000000)} MB`
    }
    
    const data = useFragment(graphql`
    fragment AccountSettingsDownloadDataGenerator_goalConnection on GoalConnection {
        edges {
            node {
                readableId
                title
                description
                createdAt
                updatedAt
                type
                nextReviewAt
                dueDate
                reviewMode {
                    cycleBegin {
                        days
                        months
                    }
                    cycleGoal {
                        days
                        months
                    }
                    spacedRepetition
                }
                spacedRepetitionLevel
                status
                reviews {
                    edges {
                        node {
                            createdAt
                            updatedAt
                            comment
                            completion
                            status
                        }
                    }
                }
            }
        }
    }
    `, loadedQuery)
    const jsonObject = !data.edges ? { status: "keine Daten vorhanden" } : {
        goals: data.edges
            .flatMap(edge => edge ? [edge] : [])
            .flatMap(edge => edge.node ? [edge.node] : [])
            .map(goal => (Object.assign({}, goal, {
                readableId: undefined,
                id: goal.readableId,
                reviews: goal.reviews ? goal.reviews.edges ? goal.reviews.edges
                    .flatMap(edge => edge ? [edge] : [])
                    .flatMap(edge => edge.node ? [edge.node] : [])
                    .map(review => (Object.assign({}, review, {

                    })))
                    : [] : []
            })))
    }
    const blob = new Blob([JSON.stringify(jsonObject, null, 2)], { type: "application/json" });
    const blobURL = URL.createObjectURL(blob);
    return <AnchorElement href={blobURL} download="valign_goals_data.json">
        <Button variant="contained" size="small" color="success" sx={{ color: "rgb(255,255,255)" }}>Download ({readableBytes(blob.size)})</Button>
    </AnchorElement>
}

const AccountSettingsDownloadDataGenerator = ({ goals }: { goals: PreloadedQuery<AccountSettingsDownloadDataQueryType> }) => {
    const loadedQuery = usePreloadedQuery<AccountSettingsDownloadDataQueryType>(AccountSettingsDownloadDataQuery, goals)
    return loadedQuery.listGoals ? <AccountSettingsDownloadDataButton loadedQuery={loadedQuery.listGoals} /> : <></>
}
export default AccountSettingsDownloadDataGenerator;