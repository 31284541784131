/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalDetailsSettingsNextReview_goal = {
    readonly archived: boolean | null;
    readonly type: GoalType;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsSettingsReviewCycleNextReview_goal">;
    readonly " $refType": "GoalDetailsSettingsNextReview_goal";
};
export type GoalDetailsSettingsNextReview_goal$data = GoalDetailsSettingsNextReview_goal;
export type GoalDetailsSettingsNextReview_goal$key = {
    readonly " $data"?: GoalDetailsSettingsNextReview_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsSettingsNextReview_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsSettingsNextReview_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsSettingsReviewCycleNextReview_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = 'a4f718f49e9512269e443be530b60577';
export default node;
