import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { ThemeProvider } from '@mui/material/styles';
import MainNavLogo from "navigation/MainNavLogo";
import { darkTheme } from "themes";
import NavContent from "./NavContent";

const drawerWidth = 256;

const MainNav = () => {
    return (
        <ThemeProvider theme={darkTheme}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box'
              }
            }}
            variant='permanent'
            anchor='left'
          >
            <MainNavLogo/>
            <Divider />
            <NavContent/>
          </Drawer>
        </ThemeProvider>
    )
}
export default MainNav;