/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalDetailsSettingsDueDateSelect_goal = {
    readonly id: string;
    readonly type: GoalType;
    readonly dueDate: string | null;
    readonly " $refType": "GoalDetailsSettingsDueDateSelect_goal";
};
export type GoalDetailsSettingsDueDateSelect_goal$data = GoalDetailsSettingsDueDateSelect_goal;
export type GoalDetailsSettingsDueDateSelect_goal$key = {
    readonly " $data"?: GoalDetailsSettingsDueDateSelect_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsSettingsDueDateSelect_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsSettingsDueDateSelect_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '8614abe01f74acfda02f104740e7c622';
export default node;
