import { useUpdateGoalMutation } from "main/goals/__hooks/__generated__/useUpdateGoalMutation.graphql";
import { useMutation } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const useUpdateGoal = () => {
    return useMutation<useUpdateGoalMutation>(graphql`
        mutation useUpdateGoalMutation(
            $input: UpdateGoalInput!, $first: Int, $after: String, $last: Int, $before: String
          ) {
            updateGoal(input: $input) {
                ...GoalDetailsOverview_goal
                ...GoalDetailsReview_goal
                ...GoalDetailsSettingsReviewMode_goal
            }
        }
    `)
}
export default useUpdateGoal;