import React from "react";
import { Box, SxProps } from "@mui/system";
import LoginBackgroundSvg from "common/_assets/canvas_background.svg";

const BackgroundFrame = ({ children, sx = {} }: { children: React.ReactNode, sx?: SxProps }) => {
    return <Box sx={Object.assign({
        width: "100vw",
        minHeight: "100vh",
        boxSizing: "border-box",
        padding: "6rem 2.5rem 5rem 2.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${LoginBackgroundSvg})`,
        backgroundSize: "100vw auto",
        backgroundRepeat: "no-repeat"
    }, sx)}>{children}</Box>
}
export default BackgroundFrame;