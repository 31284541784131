import { FormFieldSavingCustomEventPayload, FormFieldSavingEventType, FormFieldSavingStatus } from "forms/types";
import React from "react";

const useFormFieldSavingEvents = (eventRef: React.RefObject<EventTarget>) => {
    return {
        dispatchFormFieldSavingEvent: () => {
            if(eventRef.current) {
                eventRef.current.dispatchEvent(new CustomEvent<FormFieldSavingCustomEventPayload>(FormFieldSavingEventType, {
                    detail: {
                        status: FormFieldSavingStatus.SAVING
                    }
                }))
            }
        },
        dispatchFormFieldSavingSuccessEvent: () => {
            if(eventRef.current) {
                eventRef.current.dispatchEvent(new CustomEvent<FormFieldSavingCustomEventPayload>(FormFieldSavingEventType, {
                    detail: {
                        status: FormFieldSavingStatus.SUCCESS
                    }
                }))
            }
        },
        dispatchFormFieldSavingErrorEvent: () => {
            if(eventRef.current) {
                eventRef.current.dispatchEvent(new CustomEvent<FormFieldSavingCustomEventPayload>(FormFieldSavingEventType, {
                    detail: {
                        status: FormFieldSavingStatus.ERROR
                    }
                }))
            }
        }

    }
}
export default useFormFieldSavingEvents;