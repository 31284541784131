import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import useAccount from "authentication/_hooks/useAccount";
import BackgroundFrame from "common/BackgroundFrame";
import Logo from "common/Logo";
import StartFooter from "common/StartFooter";
import useMount from "common/useMount";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Introduction from "start/Introduction";

const Start = () => {
    const navigate = useNavigate();
    const account = useAccount();
    const { unmount: unmountAnyModal } = useMount();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (mounted) return;
        setMounted(true);
        unmountAnyModal();
    }, [mounted, setMounted, unmountAnyModal])

    const handleAccountOption = () => {
        if (account.authenticatedAccount) {
            navigate("/dashboard");
            return;
        }
        if (account.accountExists) {
            navigate("/login");
            return;
        }
        navigate("/login/signup");
    }

    const handleGuestOption = async () => {
        if (account.authenticatedAccount) {
            try {
                await account.logout();
                window.location.assign("/dashboard");
            } catch(e) {}
            return;
        }
        if (account.accountExists) {
            navigate("/dashboard");
            return;
        }
        navigate("/dashboard");
    }

    return <BackgroundFrame sx={{ padding: "3rem 2.5rem 5rem 2.5rem", }}>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Box sx={{ margin: "0 0 3rem 0" }}>
                <Logo height="6rem" fontSize="4rem" textColor="rgba(0,80,140)" fontWeight="900" />
            </Box>
            <Stack direction="row" spacing="1rem" sx={{ margin: "0rem 0 3rem 0" }}>
                <Button data-cy="accountOption" onClick={handleAccountOption} variant="contained" color="primary" disableElevation={true} sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    padding: "0.7rem 1.5rem"
                }}>
                    {(() => {
                        if (account.authenticatedAccount) return "Zur Anwendung";
                        if (account.accountExists) return "Anmelden";
                        return "Kostenlos registrieren";
                    })()}
                </Button>
                <Button data-cy="guestOption" onClick={handleGuestOption} variant="contained" disableElevation={true} sx={{
                    color: theme => theme.palette.primary.main,
                    background: "rgba(255,255,255,1)",
                    textTransform: "none",
                    fontSize: "1rem",
                    padding: "0.7rem 1.5rem",
                    "&:hover": {
                        background: "rgba(255,255,255)"
                    }
                }}>
                    {(() => {
                        if (account.authenticatedAccount) return "Abmelden und als Gast nutzen";
                        if (account.accountExists) return "Als Gast nutzen";
                        return "ohne Registrierung nutzen";
                    })()}
                </Button>
            </Stack>
            <Box sx={{ maxWidth: "70rem", margin: "0rem 0 0 0" }}>
                <Introduction imgSize="8rem" disableAppLinks />
            </Box>
            <Box sx={{ margin: "1rem 0 0 0" }}>
                <StartFooter />
            </Box>
        </Box>
    </BackgroundFrame>
}
export default Start;