import '@fontsource/roboto/latin-300.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-400-italic.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';
import '@fontsource/roboto/latin-700-italic.css';
import '@fontsource/dancing-script/latin-400.css';
import '@fontsource/open-sans/latin-400.css';
import '@fontsource/open-sans/latin-400-italic.css';
import '@fontsource/open-sans/latin-700.css';
import '@fontsource/open-sans/latin-700-italic.css';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from "themes";
import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterLuxon";
import SnackbarProvider from "common/SnackbarProvider";
import { BrowserRouter } from "react-router-dom";
import AppMain from "AppMain";
import { Provider } from "react-redux";
import store from "app/store";
import MountProvider from "common/MountProvider";
import GlobalsQueryProvider from "common/GlobalsQueryProvider";
// import Authenticator from "authentication/Authenticator";
// import { LOCAL_ENVIRONMENT } from "common/common";
import AffirmTerms from "AffirmTerms";
import Amplify from "aws-amplify";
import awsconfig from "./awsconfig";
import LoginRoute from "authentication/LoginRoute";
import AccountAuthentication from "authentication/AccountAuthentication";
import GuestNote from "GuestNote";
import AppRelayEnvironmentProvider from "AppRelayEnvironmentProvider";
import StartRoute from "start/StartRoute";

Amplify.configure(awsconfig);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppRelayEnvironmentProvider>
          <ThemeProvider theme={defaultTheme}>
            <LocalizationProvider dateAdapter={DateAdapter} locale="de-DE">
              {/* <Authenticator deactivated={LOCAL_ENVIRONMENT}> */}
                <AccountAuthentication>
                  <SnackbarProvider>
                    <MountProvider>
                      <StartRoute>
                        <LoginRoute>
                          <GlobalsQueryProvider>
                            <AffirmTerms>
                              <GuestNote>
                                <AppMain />
                              </GuestNote>
                            </AffirmTerms>
                          </GlobalsQueryProvider>
                        </LoginRoute>
                      </StartRoute>
                    </MountProvider>
                  </SnackbarProvider>
                </AccountAuthentication>
              {/* </Authenticator> */}
            </LocalizationProvider>
          </ThemeProvider>
        </AppRelayEnvironmentProvider>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
