import EditIcon from "@mui/icons-material/EditRounded";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import ReviewIcon from "common/_icons/ReviewIcon";
import ArchiveGoalMenuItem from "main/goals/goal_card/menu/ArchiveGoalMenuItem";
import DeleteGoalMenuItem from "main/goals/goal_card/menu/DeleteGoalMenuItem";
import { GoalCardMenu_goal$key } from "main/goals/goal_card/menu/__generated__/GoalCardMenu_goal.graphql";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router";
const graphql = require("babel-plugin-relay/macro");

const GoalCardMenu = ({ goal, connectionId }: {
    goal: GoalCardMenu_goal$key,
    connectionId?: string
}) => {
    const data = useFragment(graphql`
        fragment GoalCardMenu_goal on Goal {
            id
            readableId
            ...DeleteGoalMenuItem_goal
            ...ArchiveGoalMenuItem_goal
        }
    `, goal)

    const navigate = useNavigate();

    return <>
        <MenuItem onClick={() => navigate(`/goal/${data.readableId}/review`)}>
            <ListItemIcon><ReviewIcon /></ListItemIcon>
            <ListItemText sx={{ mr: "2rem" }}>Review durchführen</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(`/goal/${data.readableId}`)}>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText sx={{ mr: "2rem" }}>Bearbeiten</ListItemText>
        </MenuItem>
        <Divider />
        <ArchiveGoalMenuItem goal={data} />
        <DeleteGoalMenuItem goal={data} connectionId={connectionId}/>
    </>
}
export default GoalCardMenu;