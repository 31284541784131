import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import AppEmphBolder from "common/AppEmphBolder";
import SpacedRepetitionImgSrc from "main/goals/_assets/spaced_repetition.png";

const Img = styled("img")({
    width: "100%"
})

const ExplanationParagraph = styled("p")({
    margin: "0.5rem 0"
})

const GoalReviewCycleModeTooltipConst = () => <>
    <Stack direction="row" spacing={"2rem"} sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box sx={{ minWidth: "12rem", width: "12rem" }}>
            <Img src={SpacedRepetitionImgSrc} />
        </Box>
        <Stack direction="column" spacing={"1rem"}>

            <Box sx={{ fontSize: "0.875rem", lineHeight: "1.2rem", maxWidth: "25rem" }}>
                <Box sx={{ fontSize: "0.95rem", fontWeight: 500, margin: "0 0 0.5rem 0" }}>Gleichbleibender Abstand</Box>
                <Box sx={{width: "20rem"}}>
                    vAlign erinnert euch an die Überprüfung des Ziels in gleichbleibenden Abständen.
                    <ExplanationParagraph><AppEmphBolder>Dies ist der empfohlene Modus für fertigstellbare Ziele.</AppEmphBolder></ExplanationParagraph>
                </Box>
            </Box>
        </Stack>
    </Stack>
</>
export default GoalReviewCycleModeTooltipConst;