/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewCardMenu_review = {
    readonly " $fragmentRefs": FragmentRefs<"ReviewCardMenuDeleteItem_review">;
    readonly " $refType": "ReviewCardMenu_review";
};
export type ReviewCardMenu_review$data = ReviewCardMenu_review;
export type ReviewCardMenu_review$key = {
    readonly " $data"?: ReviewCardMenu_review$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewCardMenu_review">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCardMenu_review",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewCardMenuDeleteItem_review"
    }
  ],
  "type": "Review",
  "abstractKey": null
};
(node as any).hash = '51b5e28e181e4033f89d427669fc67e6';
export default node;
