import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { GlobalsQueryProviderQuery } from "common/GlobalsQueryProvider";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import useGlobalsQueryLoader from "_hooks/useGlobalsQueryLoader";
import { GlobalsQueryProviderQuery as GlobalsQueryProviderQueryType } from "common/__generated__/GlobalsQueryProviderQuery.graphql";
import { ReviewButton_getGlobalsReturn$key } from "toolbar/__generated__/ReviewButton_getGlobalsReturn.graphql";
import { useNavigate } from "react-router";
import AppButton from "common/AppButton";
const graphql = require("babel-plugin-relay/macro");

const ReviewButtonFallback = () => <Button variant="contained" color="primary" disabled>Review</Button>;

const ReviewButton = () => {
    const [queryRef] = useGlobalsQueryLoader();
    return queryRef ? <React.Suspense fallback={<ReviewButtonFallback/>}><ReviewButtonMain queryRef={queryRef} /></React.Suspense> : <ReviewButtonFallback/>
}

const ReviewButtonMain = ({ queryRef }: { queryRef: PreloadedQuery<GlobalsQueryProviderQueryType> }) => {

    const query = usePreloadedQuery(GlobalsQueryProviderQuery, queryRef);
    const data = useFragment<ReviewButton_getGlobalsReturn$key>(graphql`
        fragment ReviewButton_getGlobalsReturn on GetGlobalsReturn {
            dueForReviewCount
        }
    `, query.getGlobals);

    const navigate = useNavigate();

    return <Badge badgeContent={data !== null ? data.dueForReviewCount : 0} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
            '& .MuiBadge-badge': { top: 4 }
        }}
    >
        <Tooltip title={
            data && data.dueForReviewCount > 0 ? "Führe jetzt einen Review durch, um zu überprüfen, ob ihr eure Ziele erreicht habt" :
                "Aktuell gibt es keine Ziele, die fällig für einen Review sind"
        } arrow>
            <span>
                <AppButton onClick={() => navigate("/review")} disabled={!data || data.dueForReviewCount === 0}>
                    Review
                </AppButton>
            </span>
        </Tooltip>
    </Badge>
}
export default ReviewButton;