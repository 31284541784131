import { Stack } from "@mui/material";
import GoalField from "main/goals/common/field/GoalField";
import GoalCardDivider from "main/goals/goal_card/GoalCardDivider";
import React from "react";

const GoalCardFieldContainer = ({ children, width = "10rem" }: { children: React.ReactNode, width?: string }) => {
    return <Stack direction="row" sx={{ width: width }}>
        <GoalCardDivider />
        <GoalField width="100%">
            {children}
        </GoalField>
    </Stack>
}
export default GoalCardFieldContainer;