import { MenuItem } from "@mui/material";
import AppSelect from "forms/AppSelect";
import { FormHandlersReturn } from "forms/useFormHandlers";
import { ReviewCycleInput } from "graphql/API";
import { GoalFormData } from "main/goals/goal_edit/GoalEditForm";
import { beginToGoalCycleOptionsMap, cycleGoalSelection, CycleOption, cycleOptionsLabelMap, cycleOptionsMap, mapReviewCycleInputToCycleOption } from "main/goals/common/cycleOptions";
import { useEffect, useRef, useState } from "react";

const GoalReviewCycleGoalSelect = ({ formHandlers: { controlFormField, formData, validateForm } }: { formHandlers: FormHandlersReturn<GoalFormData> }) => {

    const [selectValue, setSelectValue] = useState<CycleOption>(CycleOption.HALFYEARLY);

    const { handleChange, errorsExist } = controlFormField({ fieldName: "reviewCycleGoal" });

    const handledCycleBegin = useRef<ReviewCycleInput | null>(null)
    const handledSpacedRepetition = useRef<boolean | null>(null)
    useEffect(() => {
        if (handledSpacedRepetition.current === formData.spacedRepetition && handledCycleBegin.current === formData.reviewCycleBegin) return;
        handledCycleBegin.current = formData.reviewCycleBegin;
        handledSpacedRepetition.current = formData.spacedRepetition;
        if(formData.spacedRepetition) return;
        if(!formData.reviewCycleBegin) return;
        const reviewCycleGoal = [formData.reviewCycleBegin]
            .map(reviewCycleBegin => mapReviewCycleInputToCycleOption(reviewCycleBegin))
            .map(cycleOptionBegin => cycleOptionBegin && beginToGoalCycleOptionsMap.get(cycleOptionBegin))
            .map(cycleOptionGoal => cycleOptionGoal && cycleOptionsMap.get(cycleOptionGoal))
            .pop()
        if(!reviewCycleGoal) return;
        handleChange(reviewCycleGoal);
        const reviewCycleGoalCycleOption = mapReviewCycleInputToCycleOption(reviewCycleGoal);
        if(reviewCycleGoalCycleOption) setSelectValue(reviewCycleGoalCycleOption)
    }, [formData.reviewCycleBegin, formData.spacedRepetition, handleChange])
    
    const createMenuItems = (cycleSelection: CycleOption[]) => cycleSelection.map((cycleOption, i) => <MenuItem value={cycleOption} key={i}>{cycleOptionsLabelMap.get(cycleOption)}</MenuItem>)
    return <>
        {formData.spacedRepetition &&
            <AppSelect
                labelText={"Ziel-Intervall"}
                labelId="reviewCycleGoalLabelId"
                label={"Ziel-Intervall"}
                value={selectValue}
                error={errorsExist()}
                onChange={e => {
                    const cycleOption = e.target.value as CycleOption;
                    let reviewCycle: ReviewCycleInput | null = cycleOptionsMap.get(cycleOption) ?? null;
                    if (!reviewCycle) return;
                    setSelectValue(cycleOption);
                    handleChange(reviewCycle)
                    validateForm();
                }}
                required
                boldValue
                sx={{
                    width: "12em"
                }}
            >
                {createMenuItems(cycleGoalSelection)}
            </AppSelect>
        }
    </>
}
export default GoalReviewCycleGoalSelect;