import { Box } from "@mui/system";
import React from "react";

const TextFeature = ({children}: {children: React.ReactNode}) => {
    return <Box component="span" sx={{
        fontStyle: "italic",
        fontFamily: "Open Sans",
        fontWeight: "700"
    }}>{children}</Box>
}
export default TextFeature;