/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalDetailsSettingsReviewCycleNextReview_goal = {
    readonly id: string;
    readonly nextReviewAt: string;
    readonly " $refType": "GoalDetailsSettingsReviewCycleNextReview_goal";
};
export type GoalDetailsSettingsReviewCycleNextReview_goal$data = GoalDetailsSettingsReviewCycleNextReview_goal;
export type GoalDetailsSettingsReviewCycleNextReview_goal$key = {
    readonly " $data"?: GoalDetailsSettingsReviewCycleNextReview_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsSettingsReviewCycleNextReview_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsSettingsReviewCycleNextReview_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nextReviewAt",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = 'a72a0c8761a4e4bf276a3f7479bf74df';
export default node;
