import { Box, SxProps } from "@mui/system";
import React from "react";



const GoalFieldText = ({ children, sx }: { children: React.ReactNode, sx?: SxProps }) => {
    return <Box sx={Object.assign({
        fontSize: "0.8rem",
        lineHeight: "1.3rem",
        fontWeight: "300"
    }, sx ?? {})}>
        {children}
    </Box>
}
export default GoalFieldText;