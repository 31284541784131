import ProgressIcon from "@mui/icons-material/Circle";
import EmptyProgressIcon from "@mui/icons-material/CircleOutlined";
import { Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

export const valueLabelMap: { [value: number]: string } = {
    0: "noch nicht erfüllt",
    1: "sehr selten erfüllt",
    2: "selten erfüllt",
    3: "manchmal erfüllt",
    4: "ganz OK",
    5: "OK",
    6: "fast gut",
    7: "gut",
    8: "fast sehr gut",
    9: "sehr gut",
    10: "perfekt"
}

const GoalCompletionContinuousSelect = ({ value, handleChange }: {
    value: number,
    handleChange: (v: number) => void
}) => {
    const [localValue, setLocalValue] = useState(-1);
    return <>
        <Box sx={{position: "relative", display: "flex", top: "0.1rem"}}>
            <Rating
                value={value * 10}
                defaultValue={5}
                max={10}
                icon={<ProgressIcon fontSize="small" color="primary" />}
                emptyIcon={<EmptyProgressIcon fontSize="small" />}
                onChange={(e, v) => { handleChange(v ? +v / 10 : 0) }}
                onChangeActive={(e, v) => setLocalValue(+v)}
            />
            <Typography variant="body2" sx={{ fontWeight: "500", fontSize: "1rem", position: "relative", top: "-0.05rem", width: "max-content", padding: "0 0 0 1rem" }}>{valueLabelMap[localValue !== -1 ? localValue : Math.round(value * 10)]}</Typography>
        </Box>
    </>
}
export default GoalCompletionContinuousSelect;
