import ProgressIcon from "@mui/icons-material/Circle";
import EmptyProgressIcon from "@mui/icons-material/CircleOutlined";
import { Rating, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useStatusCalculations from "common/useStatusCalculations";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import { useState } from "react";

export const valueLabelMap: { [value: number]: string } = {
    0: "noch nicht erfüllt",
    1: "sehr selten erfüllt",
    2: "selten erfüllt",
    3: "manchmal erfüllt",
    4: "ganz OK",
    5: "OK",
    6: "fast gut",
    7: "gut",
    8: "fast sehr gut",
    9: "sehr gut",
    10: "perfekt"
}

const GoalStatusSelect = ({
    value, handleChange
}: ControlFormFieldReturn<number>) => {
    const [localValue, setLocalValue] = useState(-1);
    const {getStatusColor} = useStatusCalculations();
    const calculatedStatusColor = getStatusColor(localValue !== -1 ? localValue : value);
    return <Box sx={{ padding: "0 1rem", width: "100%", boxSizing: "border-box" }} >
        <Stack direction="row" spacing={4} sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ position: "relative", display: "flex", top: "0.1rem" }}>
                <Rating
                    value={value * 10}
                    defaultValue={5}
                    max={10}
                    icon={<ProgressIcon fontSize="small" sx={{color: calculatedStatusColor}} />}
                    emptyIcon={<EmptyProgressIcon fontSize="small" />}
                    onChange={(e, v) => { handleChange(v ? v / 10 : 0) }}
                    onChangeActive={(e, v) => setLocalValue(v !== -1 ? v / 10 : v)}
                />
                <Typography variant="body2" sx={{ fontWeight: "500", fontSize: "1rem", position: "relative", top: "-0.05rem", width: "max-content", padding: "0 0 0 1rem" }}>
                    {Math.round((localValue !== -1 ? localValue : value) * 100)} %
                </Typography>
            </Box>
        </Stack>
    </Box>
}
export default GoalStatusSelect;