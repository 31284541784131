import MainTitle from "main/MainTitle";
import Introduction from "start/Introduction";
import Box from "@mui/material/Box";
// import MainSubtitle from "main/MainSubtitle";
import MainContent from "main/MainContent";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import { Route, Routes } from "react-router";
import BackgroundSvg from "common/_assets/canvas_background.svg";

const DashboardComponent = () => {
    return <>
        <MainBar>
            <MainTitle>
                Willkommen bei vAlign<Box component="span" sx={{ fontFamily: "Dancing Script", fontSize: "1.2em", lineHeight: "1.875rem", margin: "0 0 0 0.4rem" }}>Goals</Box>
            </MainTitle>
        </MainBar>
        {/* <MainSubtitle>
            vAlign Goals hilft euch, eure Working Agreements und Ziele zu erreichen! <b>Der Start ist ganz einfach:</b>
        </MainSubtitle> */}
        <MainContent sx={{
            backgroundImage: `
                linear-gradient(rgba(235,237,240,0.3), rgba(235,237,240,1)),
                url(${BackgroundSvg})
            `,
            backgroundSize: "100vw auto",
            backgroundRepeat: "no-repeat",
            borderTop: "1px solid rgba(0,0,0,0.05)"
        }}>
            <MainContainer>
                <Introduction embedded />
            </MainContainer>
        </MainContent>
    </>
}

const Dashboard = () => {
    return <Routes>
        <Route path="*" element={<></>} />
        <Route path="/" element={<DashboardComponent />} />
        <Route path="/dashboard" element={<DashboardComponent />} />
    </Routes>
}
export default Dashboard;