import useAccountTerm from "common/useAccountTerm";
import MainTabs from "main/common/MainTabs";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainTitle from "main/MainTitle";
import AccountSettingsAccount from "main/settings/account/AccountSettingsAccount";
import { Route, Routes } from "react-router-dom";

const AppSettings = () => {
    const accountTerm = useAccountTerm();
    return <>
        <MainBar>
            <MainTitle>Einstellungen</MainTitle>
        </MainBar>
        <MainTabs
            tabs={[
                { label: accountTerm, value: "/account" }
            ]}
        >
            <MainContent>
                <MainContainer>
                    <Routes>
                        <Route path="*" element={<AccountSettingsAccount />} />
                    </Routes>
                </MainContainer>
            </MainContent>
        </MainTabs>
    </>
}
export default AppSettings;