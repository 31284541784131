import { FormControl, FormHelperText, Grid, Stack, StackProps } from "@mui/material";
import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperExpandable from "common/StandardPaperExpandable";
import StandardPaperTitle from "common/StandardPaperTitle";
import StandardPaperVMargin from "common/StandardPaperVMargin";
import useDateCalculations from "common/useDateCalculations";
import { FormHandlersReturn } from "forms/useFormHandlers";
import { GoalType, ReviewCycleInput } from "graphql/API";
import { DateTime } from "luxon";
import GoalDateSelect from "main/goals/goal_edit/GoalDateSelect";
import GoalDescriptionEditor from "main/goals/goal_edit/GoalDescriptionEditor";
import GoalReviewCycleBeginSelect from "main/goals/goal_edit/GoalReviewCycleBeginSelect";
import GoalReviewCycleGoalSelect from "main/goals/goal_edit/GoalReviewCycleGoalSelect";
import GoalReviewCycleStartDateSelect from "main/goals/goal_edit/GoalReviewCycleStartDateSelect";
import GoalReviewModeSelect from "main/goals/goal_edit/GoalReviewModeSelect";
import GoalReviewModeSummary from "main/goals/goal_edit/GoalReviewModeSummary";
import GoalTitleEditor from "main/goals/goal_edit/GoalTitleEditor";
import GoalStatusCompletionSelect from "main/goals/goal_edit/goal_completion_select/GoalStatusCompletionSelect";
import GoalTypeSelect from "main/goals/goal_edit/goal_type_select/GoalTypeSelect";

export type GoalFormData = {
    title: string;
    description?: string;
    goalType: GoalType;
    dueDate: DateTime | null;
    reviewCycleBegin: ReviewCycleInput;
    reviewCycleGoal: ReviewCycleInput;
    reviewCycleStartDate: DateTime;
    spacedRepetition: boolean;
    status: number;
    completion: number;
}

export const FormFieldsStack = (props: StackProps) => <Stack direction="row" spacing={2} {...props}>{props.children}</Stack>

const GoalEditForm = (
    {
        formHandlers
    }: {
        formHandlers: FormHandlersReturn<GoalFormData>
    }
) => {

    const { excludeFromValidation, includeForValidation, controlFormField, formData, errors } = formHandlers;

    const { dateIsNotInThePast } = useDateCalculations();

    const updateGoalTypeDependentValidations = (v: GoalType | undefined) => {
        if (v === GoalType.continuous) {
            excludeFromValidation("dueDate");
            excludeFromValidation("completion");
        }
        if (v === GoalType.completable) {
            includeForValidation("dueDate");
            includeForValidation("completion");
        }
    }

    const updateReviewModeDependentValidations = (spacedRepetition: boolean) => {
        if (!spacedRepetition) excludeFromValidation("reviewCycleGoal");
        if (spacedRepetition) includeForValidation("reviewCycleGoal");
    }

    return <>
        <Grid container spacing={"0.75rem"}>
            <Grid item xs={12}>
                <StandardPaper boxShadow>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <GoalTitleEditor
                                {...controlFormField<string>({
                                    fieldName: "title", validations: {
                                        "required": () => (s: string) => !!s
                                    }
                                })}
                                sx={{
                                    margin: "0 0 0.5em 0",
                                    width: "100%"
                                }}
                            />
                            <GoalDescriptionEditor
                                {...controlFormField<string>({ fieldName: "description" })}
                                sx={{
                                    margin: "0 0 0 0"
                                }}
                            />
                        </Grid>
                    </Grid>
                </StandardPaper>
            </Grid>
            <Grid item xs={12}>
                <StandardPaper>
                    <StandardPaperTitle>Art des Ziels</StandardPaperTitle>
                    <StandardPaperVMargin>
                        <GoalTypeSelect
                            {...controlFormField<GoalType>({ fieldName: "goalType", onChange: v => updateGoalTypeDependentValidations(v) })}
                        />
                    </StandardPaperVMargin>
                </StandardPaper>
            </Grid>
            <Grid item xs={12} sx={{ display: formData?.goalType === GoalType.completable ? undefined : "none" }}>
                <StandardPaper display={formData?.goalType === GoalType.completable}>
                    <StandardPaperTitle>Zieldatum</StandardPaperTitle>
                    <StandardPaperVMargin>
                        <GoalDateSelect
                            {...controlFormField<DateTime | null>({
                                fieldName: "dueDate", validations: {
                                    'required': () => d => !!d,
                                    'valid': () => d => !!d && d.isValid,
                                    'future': () => d => {
                                        if (!d || !d.isValid) return false;
                                        return dateIsNotInThePast(d, false)
                                    }
                                }
                            })}
                            visible={true}
                        />
                    </StandardPaperVMargin>
                </StandardPaper>
            </Grid>
            <Grid item xs={12}>
                <GoalStatusCompletionSelect formHandlers={formHandlers} />
            </Grid>
            <Grid item xs={12}>
                <StandardPaperExpandable
                    title={"Review Modus"}
                    summary={<GoalReviewModeSummary spacedRepetition={formData.spacedRepetition} reviewCycleBegin={formData.reviewCycleBegin} reviewCycleGoal={formData.reviewCycleGoal} reviewCycleStartDate={formData.reviewCycleStartDate} />}
                    expandIconLabel={"Einstellungen ausklappen"}
                >
                    <StandardPaperVMargin>
                        <Grid container spacing={2}>
                            <GoalReviewModeSelect
                                {...controlFormField<boolean>({ fieldName: "spacedRepetition", onChange: b => updateReviewModeDependentValidations(b) })}
                                formData={formData as GoalFormData}
                            />
                            <Grid item md={12} xs={12}>
                                <Stack direction="row" spacing={"1rem"}>
                                    <Stack direction="column">
                                        <Box>
                                            <Stack direction="row" spacing={"1rem"}>
                                                <GoalReviewCycleBeginSelect formHandlers={formHandlers} />
                                                <GoalReviewCycleGoalSelect formHandlers={formHandlers} />
                                            </Stack>
                                        </Box>
                                        <FormControl error={true} sx={{ maxWidth: "26em" }}>
                                            {errors.reviewCycleBegin?.lessThanCycleGoal && <FormHelperText>Bitte gib ein Intervall an, das kleiner als das Ziel-Intervall ist.</FormHelperText>}
                                        </FormControl>
                                    </Stack>
                                    <GoalReviewCycleStartDateSelect formHandlers={formHandlers} formData={formData} />
                                </Stack>
                            </Grid>

                        </Grid>
                    </StandardPaperVMargin>
                </StandardPaperExpandable>
            </Grid>
        </Grid>
    </>
}
export default GoalEditForm;