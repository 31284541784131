import { Stack } from "@mui/material";
import useDateCalculations from "common/useDateCalculations";
import { DateTime } from "luxon";
import GoalFieldRow from "main/goals/common/field/GoalFieldRow";
import GoalFieldText from "main/goals/common/field/GoalFieldText";
import GoalFieldTextMain, { GoalFieldTextMainStyle } from "main/goals/common/field/GoalFieldTextMain";
import GoalFieldTitle from "main/goals/common/field/GoalFieldTitle";

const GoalDueDateField = ({ dueDate, fieldStyle = "default", showTitle = true }: {
    dueDate: DateTime, fieldStyle?: GoalFieldTextMainStyle, showTitle?: boolean
}) => {
    const { getLocaleDateString, getReadableDuration, flatDue } = useDateCalculations();
    const due = flatDue(dueDate);
    return <>
        <Stack direction="column">
            {showTitle && <GoalFieldTitle>Zieldatum:</GoalFieldTitle>}
            <GoalFieldRow>
                <GoalFieldTextMain fieldStyle={fieldStyle} sx={{
                    color: due ? "secondary.main" : undefined,
                    fontStyle: due ? "italic" : undefined,
                    fontWeight: due ? "700" : "400"
                }}>
                    {getReadableDuration(dueDate, { precision: "day" })}
                </GoalFieldTextMain>
            </GoalFieldRow>
            <GoalFieldRow>
                <GoalFieldText>{getLocaleDateString(dueDate)}</GoalFieldText>
            </GoalFieldRow>
        </Stack>
    </>
}
export default GoalDueDateField;