/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewButton_getGlobalsReturn = {
    readonly dueForReviewCount: number;
    readonly " $refType": "ReviewButton_getGlobalsReturn";
};
export type ReviewButton_getGlobalsReturn$data = ReviewButton_getGlobalsReturn;
export type ReviewButton_getGlobalsReturn$key = {
    readonly " $data"?: ReviewButton_getGlobalsReturn$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewButton_getGlobalsReturn">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewButton_getGlobalsReturn",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueForReviewCount",
      "storageKey": null
    }
  ],
  "type": "GetGlobalsReturn",
  "abstractKey": null
};
(node as any).hash = 'fda467ab81a41432902b70a6b651d693';
export default node;
