/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalDetailsDueDate_goal = {
    readonly id: string;
    readonly dueDate: string | null;
    readonly " $refType": "GoalDetailsDueDate_goal";
};
export type GoalDetailsDueDate_goal$data = GoalDetailsDueDate_goal;
export type GoalDetailsDueDate_goal$key = {
    readonly " $data"?: GoalDetailsDueDate_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsDueDate_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsDueDate_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '6e4609091052e77cb7449265a32c3337';
export default node;
