/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalsCountBadge_getGlobalsReturn = {
    readonly goalsCount: number;
    readonly " $refType": "GoalsCountBadge_getGlobalsReturn";
};
export type GoalsCountBadge_getGlobalsReturn$data = GoalsCountBadge_getGlobalsReturn;
export type GoalsCountBadge_getGlobalsReturn$key = {
    readonly " $data"?: GoalsCountBadge_getGlobalsReturn$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalsCountBadge_getGlobalsReturn">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalsCountBadge_getGlobalsReturn",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalsCount",
      "storageKey": null
    }
  ],
  "type": "GetGlobalsReturn",
  "abstractKey": null
};
(node as any).hash = 'eff2a55678cbaa2cea0d7ddee1a6039d';
export default node;
