import React from "react";
import { Stack } from "@mui/material";

const MainContentList = ({
    children
}: {
    children: React.ReactNode
}) => {
    return <Stack direction="column" spacing={"1rem"}>
        { children }
    </Stack>
}
export default MainContentList;