import { DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const DialogTitleWithIcon = ({ children, icon }: { children: React.ReactNode, icon: React.ReactNode }) => {
    return <DialogTitle sx={{ alignItems: "center", display: "flex" }}>
        <Box sx={{ margin: "0 0.5rem 0 0", display: "flex", alignItems: "center" }}>
            {icon}
        </Box>
        {children}
    </DialogTitle>
}
export default DialogTitleWithIcon;