import AppSettings from "main/settings/AppSettings";
import { Route, Routes } from "react-router";

const AppSettingsRoute = () => {

    return <Routes>
        <Route path="*" element={<></>} />
        <Route path="/settings/*" element={<AppSettings/>} />
    </Routes>
}
export default AppSettingsRoute;