import { useCreateGoalMutation } from "main/goals/__hooks/__generated__/useCreateGoalMutation.graphql";
import { useMutation } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const useCreateGoal = () => {
    return useMutation<useCreateGoalMutation>(graphql`
        mutation useCreateGoalMutation(
            $input: CreateGoalInput!
          ) {
            createGoal(input: $input) {
              goal {
                id
                readableId
                type
                title
                description
                dueDate
                completed
                reviewMode {
                  spacedRepetition
                  cycleBegin {
                    days
                    months
                  }
                  cycleGoal {
                    days
                    months
                  }
                }
                nextReviewAt
                currentReviewAt
                createdAt
                updatedAt
              }
            }
        }
    `)
}
export default useCreateGoal;