import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import useAccount from "authentication/_hooks/useAccount";
import DialogContentApp from "common/DialogContentApp";
import DialogContentParagraph from "common/DialogContentParagraph";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AppTimeoutModal = () => {
    const account = useAccount();
    const location = useLocation();
    const [mounted, setMounted] = useState(false);
    const logout = () => {
        window.location.assign(`/login${location.pathname === "/" ? `` : `?redirect=${window.encodeURIComponent(location.pathname.substring(1))}`}`);
    }
    useEffect(() => {
        if(mounted) return;
        setMounted(true);

        account.logout();
    }, [mounted, setMounted, account])
    return <Dialog open>
        <DialogTitle>Browsersitzung abgelaufen</DialogTitle>
        <DialogContentApp>
            Deine Browsersitzung ist abgelaufen.<br />Bitte melde dich erneut an, um weiter zu arbeiten.
            <DialogContentParagraph>
                Du wirst jetzt zur Loginseite weitergeleitet.
            </DialogContentParagraph>
        </DialogContentApp>
        <DialogActions>
            <Button onClick={logout}>
                OK, verstanden
            </Button>
        </DialogActions>
    </Dialog>
}
export default AppTimeoutModal;