/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type useDeleteAccountMutationVariables = {};
export type useDeleteAccountMutationResponse = {
    readonly deleteAccount: {
        readonly deletedAccount: string;
    };
};
export type useDeleteAccountMutation = {
    readonly response: useDeleteAccountMutationResponse;
    readonly variables: useDeleteAccountMutationVariables;
};



/*
mutation useDeleteAccountMutation {
  deleteAccount {
    deletedAccount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DeleteAccountResponse",
    "kind": "LinkedField",
    "name": "deleteAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAccount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteAccountMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useDeleteAccountMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5fb0b4142a3d99d959f73883b1a194d2",
    "id": null,
    "metadata": {},
    "name": "useDeleteAccountMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteAccountMutation {\n  deleteAccount {\n    deletedAccount\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd8d538de403687bb5d2034cec0c13a97';
export default node;
