import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import useEventBus, { EventTypes } from "app/useEventBus";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import GoalsList, { GoalsListListGoalsQuery } from "main/goals/GoalsList";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainTitle from "main/MainTitle";
import { Suspense, useCallback, useEffect, useState } from "react";
import { fetchQuery, PreloadedQuery, usePreloadedQuery, useQueryLoader, useRelayEnvironment } from "react-relay";
import { useLocation, useNavigate } from "react-router";
import { Route, Routes } from "react-router-dom";
import { GoalsListListGoalsQuery as GoalsListListGoalsQueryType } from "./__generated__/GoalsListListGoalsQuery.graphql";

const Goals = ({ archive = false, pathname }: {
  archive?: boolean,
  pathname: string
}) => {

  const { addEventListener, removeEventListener } = useEventBus();

  const [listGoalsQueryRef, loadListGoalsQuery] = useQueryLoader<GoalsListListGoalsQueryType>(GoalsListListGoalsQuery);
  const fetchListGoalsQuery = useCallback(({ refresh = false }: { refresh?: boolean } = {}) => {
    loadListGoalsQuery({
      archived: archive
    }, { fetchPolicy: refresh ? "network-only" : undefined });
  }, [loadListGoalsQuery, archive])
  const environment = useRelayEnvironment();
  const refetchListGoalsQuery = useCallback(() => {
    fetchQuery<GoalsListListGoalsQueryType>(environment, GoalsListListGoalsQuery, {
      archived: archive
    }, { fetchPolicy: "network-only" }).subscribe({
      complete: () => fetchListGoalsQuery()
    })
  }, [environment, fetchListGoalsQuery, archive])

  useEffect(() => {
    const listener = () => refetchListGoalsQuery();
    addEventListener(EventTypes.GOAL_CREATED, listener);
    addEventListener(EventTypes.GOAL_DELETED, listener);
    addEventListener(EventTypes.GUEST_ID_CHANGED, listener);
    addEventListener(EventTypes.GOAL_ARCHIVED, listener);
    addEventListener(EventTypes.GOAL_REACTIVATED, listener);
    return () => {
      removeEventListener(EventTypes.GOAL_CREATED, listener)
      removeEventListener(EventTypes.GOAL_DELETED, listener)
      removeEventListener(EventTypes.GUEST_ID_CHANGED, listener)
      removeEventListener(EventTypes.GOAL_ARCHIVED, listener)
      removeEventListener(EventTypes.GOAL_REACTIVATED, listener)
    }
  })

  const [listGoalsQueryInititallyFetched, setListGoalsQueryInititallyFetched] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (!listGoalsQueryInititallyFetched && location.pathname.startsWith(pathname)) {
      fetchListGoalsQuery();
      setListGoalsQueryInititallyFetched(true);
    }
  }, [fetchListGoalsQuery, setListGoalsQueryInititallyFetched, listGoalsQueryInititallyFetched, location.pathname, pathname])

  return <Routes>
    <Route path="*" element={<></>} />
    <Route path={pathname} element={<>
      <MainBar>
        <MainTitle>{archive ? "Archiv:" : "Aktuelle"} Working Agreements & Ziele</MainTitle>
      </MainBar>
      <MainContent>
        <MainContainer>

          <Suspense fallback={
            <CircularProgress color="primary" />
          }>
            {listGoalsQueryRef && <GoalsListContainer queryRef={listGoalsQueryRef} />}
          </Suspense>

        </MainContainer>
      </MainContent>
    </>} />
  </Routes>
}
export default Goals;

const GoalsListContainer = ({ queryRef }: { queryRef: PreloadedQuery<GoalsListListGoalsQueryType> }) => {
  const navigate = useNavigate();
  const queryData = usePreloadedQuery(GoalsListListGoalsQuery, queryRef);
  return queryData ? <GoalsList
    queryData={queryData}
    emptyPlaceholder={<StandardPaper>
      <StandardPaperTitle>Noch keine Ziele</StandardPaperTitle>
      Du hast noch keine Ziele erstellt.
      <Box sx={{ margin: "2rem 0 0 0" }}>
        <Button variant="contained" onClick={() => navigate("/goals/new")} disableElevation>Neues Ziel erstellen</Button>
      </Box>
    </StandardPaper>}
  /> : <></>
}