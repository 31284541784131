import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { ReviewListGoalsQuery as ReviewListGoalsQueryType } from "main/review/__generated__/ReviewListGoalsQuery.graphql";
import { ReviewListGoalsQuery } from "main/review/Review";
import GoalsList from "main/goals/GoalsList";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import AppEmph from "common/AppEmph";
import StandardPaperText from "common/StandardPaperText";
import StandardPaperVMargin from "common/StandardPaperVMargin";

const ReviewGoalsList = ({ queryRef }: { queryRef: PreloadedQuery<ReviewListGoalsQueryType> }) => {
    const queryData = usePreloadedQuery(ReviewListGoalsQuery, queryRef);
    return queryData ? <GoalsList
        queryData={queryData}
        emptyPlaceholder={<StandardPaper>
            <StandardPaperTitle>Super, alles erledigt!</StandardPaperTitle>
            <StandardPaperVMargin>
                <StandardPaperText>
                    <AppEmph>Heute ist kein weiterer Review mehr fällig.</AppEmph>
                    <p>
                        Sobald ein Review für ein Working Agreement oder ein Ziel fällig ist, erscheint es hier auf dieser Seite.
                    </p>
                </StandardPaperText>
            </StandardPaperVMargin>
        </StandardPaper>}
        showReviewButton={true}
    /> : <></>
}
export default ReviewGoalsList;