/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalDetailsSettings_goal = {
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsSettingsReviewMode_goal" | "GoalDetailsSettingsNextReview_goal" | "GoalDetailsSettingsDueDate_goal">;
    readonly " $refType": "GoalDetailsSettings_goal";
};
export type GoalDetailsSettings_goal$data = GoalDetailsSettings_goal;
export type GoalDetailsSettings_goal$key = {
    readonly " $data"?: GoalDetailsSettings_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsSettings_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsSettings_goal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsSettingsReviewMode_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsSettingsNextReview_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsSettingsDueDate_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '094b6d74c16d34623b0ad106481b31ea';
export default node;
