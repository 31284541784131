import { GoalType } from "common/types";

const GoalTypeLabel = ({ type, casus = "nominative", fullName = false, uppercase = true }: {
    type: GoalType,
    casus?: "nominative" | "accusative",
    fullName?: boolean,
    uppercase?: boolean
}) => type === "continuous" ? <>
    Working Agreement
</> : <>
        {fullName && <>{uppercase ? "F" : "f"}ertigstellbare{(() => {
            switch (casus) {
                case "nominative":
                    return "s";
                case "accusative":
                    return "n";
            }
        })()} </>}Ziel
    </>;
export default GoalTypeLabel;