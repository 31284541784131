import GuestAccountIcon from '@mui/icons-material/PersonOutlineRounded';
import { Button, Dialog, DialogActions, FormControl, FormHelperText, Link, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import useAccount from "authentication/_hooks/useAccount";
import useEventBus, { EventTypes } from "app/useEventBus";
import DialogContentApp from "common/DialogContentApp";
import DialogTitleWithIcon from "common/DialogTitleWithIcon";
import useMount from "common/useMount";
import WarningWithIcon, { WarningWithIconEmph } from "common/WarningWithIcon";
import AppTextField from "forms/AppTextField";
import useFormHandlers, { FormHandlersReturn } from "forms/useFormHandlers";
import { useState } from "react";
import { useNavigate } from "react-router";

export const uuidRegexpString = "[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}";

const SetIdModal = ({ formHandlers }: { formHandlers: FormHandlersReturn<{ guestAccountId: string }> }) => {
    const [showId, setShowId] = useState(false);
    const guestAccountId = useAccount().getGuestAccountId();
    const { controlFormField } = formHandlers;
    const { value, handleChange, errors, setVisited, visited, errorsExist, validate } = controlFormField<string>({
        fieldName: "guestAccountId", validations: {
            validUUID: () => v => (new RegExp("^" + uuidRegexpString + "$")).test(v)
        }
    });
    const showErrors = value !== "" && visited
    return <>
        Setze eine Gast-ID, um dauerhaft mit dieser neuen Gast-ID zu arbeiten.
        {/* <p>
            Das kann nützlich sein, um zum Beispiel mit einer anderen Person auf einem Gast-Workspace zusammen zu arbeiten.
        </p> */}
        <Box component="p" sx={{ fontWeight: 500 }}>
            Diese Aktion überschreibt deine aktuelle Gast-ID!<br />
            <Box component="span" sx={{ fontSize: "0.875rem", fontWeight: 300 }}>
                Deine aktuelle Gast-ID lautet: <Box component="span" sx={{ fontWeight: 500 }}>
                    {!showId && <Link sx={{ fontWeight: 400, cursor: "pointer" }} onClick={() => setShowId(true)} role="button">Aktuelle Gast-ID einblenden</Link>}
                    {showId && guestAccountId}
                </Box>
            </Box>
        </Box>
        <WarningWithIcon>
            Fährst du fort, ohne deine aktuelle Gast-ID gespeichert zu haben, <WarningWithIconEmph>verlierst du alle damit verbundenen Daten unwiederbringlich!</WarningWithIconEmph>
        </WarningWithIcon>
        <p>
            Bitte gib hier deine neue Gast-ID an:
        </p>
        <Box sx={{ margin: "1rem 0 0 0" }}>
            <FormControl error={showErrors && errorsExist()} sx={{ width: "100%" }}>
                <AppTextField
                    label="Neue Gast-ID"
                    autoFocus
                    onChange={(e) => {
                        handleChange(e.target.value);
                    }}
                    onBlur={e => {
                        setVisited(true);
                        validate();
                    }}
                    error={showErrors && errorsExist()}
                />
                {
                    showErrors && errors?.validUUID && <FormHelperText data-testid="error-message">Bitte gib eine gültige Gast-ID an</FormHelperText>
                }
            </FormControl>
        </Box>
    </>
}

export const GuestToolsSetIdDialogs = ({ onClose }: { onClose: () => void }) => {

    const formHandlers = useFormHandlers({
        initialFormData: { guestAccountId: "" },
        initialValidState: { guestAccountId: false }
    });

    const { formData, isValidForm, resetFormData } = formHandlers;
    const account = useAccount();
    const eventBus = useEventBus();
    const navigate = useNavigate();

    const setNewGuestAccountId = () => {
        if (!isValidForm()) {
            console.log("invalid form data");
            return;
        }
        account.setGuestAccountId(formData.guestAccountId);
        eventBus.dispatchEvent(new CustomEvent(EventTypes.GUEST_ID_CHANGED));
        navigate("/goals");
        setShowConfirmation(true);
    }

    const close = () => {
        setShowDialog(false);
    }

    const onExited = () => {
        setShowDialog(true);
        setShowConfirmation(false);
        resetFormData();
        onClose();
    }

    const [showDialog, setShowDialog] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);

    return <Dialog open={showDialog} onClose={close} TransitionProps={{ onExited: onExited }}>
        {!showConfirmation && <>
            <DialogTitleWithIcon icon={<GuestAccountIcon />}>
                Gast-ID setzen
            </DialogTitleWithIcon>
            <DialogContentApp>
                <SetIdModal formHandlers={formHandlers} />
            </DialogContentApp>
            <DialogActions>
                <Button onClick={close}>Abbrechen</Button>
                <Button onClick={() => setNewGuestAccountId()} disabled={!isValidForm()}>Neue ID setzen</Button>
            </DialogActions>
        </>}
        {showConfirmation && <>
            <DialogTitleWithIcon icon={<GuestAccountIcon />}>
                Gast-ID erfolgreich gesetzt!
            </DialogTitleWithIcon>
            <DialogContentApp>
                Deine aktuelle Gast-ID lautet:
                <Box sx={{ margin: "1rem 0 0 0" }}>
                    <Box component="span" sx={{ fontWeight: 500 }}>{formData.guestAccountId}</Box>
                </Box>
            </DialogContentApp>
            <DialogActions>
                <Button onClick={close}>OK, verstanden</Button>
            </DialogActions>
        </>}

    </Dialog>
}

const GuestToolsSetId = () => {

    const { mountComponent, unmount } = useMount();

    return <>
        <MenuItem onClick={() => mountComponent(<GuestToolsSetIdDialogs onClose={unmount} />)}>
            <ListItemIcon><GuestAccountIcon /></ListItemIcon>
            <ListItemText>Gast-ID setzen</ListItemText>
        </MenuItem>
    </>
}
export default GuestToolsSetId;