import { Paper } from "@mui/material"
import { SxProps } from "@mui/system";
import React from "react";

const StandardPaper = React.forwardRef(({children, boxShadow, display = true, sx}: {
    children: React.ReactNode,
    boxShadow?: boolean,
    display?: boolean,
    sx?: SxProps
}, ref: React.ForwardedRef<HTMLDivElement>) => {
    return <Paper
        ref={ref}
        elevation={0}
        sx={Object.assign({
            boxSizing: "border-box",
            borderRadius: "0.75rem",
            width: "100%",
            padding: "1em 1em",
            boxShadow: boxShadow ? "0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.07), 0px 1px 3px 0px rgba(0,0,0,0.06)" : "none",
            display: display ? "block" : "none"
        }, sx )}
    >
        {children}
    </Paper>
})
export default StandardPaper;