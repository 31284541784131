import useEventBus, { CustomEventTimeSetPayload, EventTypes } from "app/useEventBus";
import useTimeNow from "app/useTimeNow";
import useDateCalculations from "common/useDateCalculations";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useRef } from "react";
import { useQueryLoader, fetchQuery, useRelayEnvironment } from "react-relay";
import { useQueryLoaderHookType } from "react-relay/relay-hooks/useQueryLoader";
import { GlobalsQueryProviderQuery as GlobalsQueryProviderQueryType } from "common/__generated__/GlobalsQueryProviderQuery.graphql";
const graphql = require("babel-plugin-relay/macro");

export const GlobalsQueryProviderQuery = graphql`
    query GlobalsQueryProviderQuery($reviewDueBefore: String!) {
        getGlobals(reviewDueBefore: $reviewDueBefore) {
            ...ReviewNavItemBadge_getGlobalsReturn
            ...ReviewButton_getGlobalsReturn
            ...ReviewMainTitleMain_getGlobalsReturn
            ...GoalsCountBadge_getGlobalsReturn
            ...ArchiveCountBadge_getGlobalsReturn
        }
    }
`
export const GlobalsQueryContext = React.createContext<useQueryLoaderHookType<GlobalsQueryProviderQueryType> | []>([]);

const GlobalsQueryProvider = ({ children }: { children: React.ReactNode }) => {

    const now = useTimeNow();
    const queryLoaderResponse = useQueryLoader<GlobalsQueryProviderQueryType>(GlobalsQueryProviderQuery);
    const [, loadQuery] = queryLoaderResponse;
    const environment = useRelayEnvironment();
    const { getFlatISORequestString } = useDateCalculations();
    const refreshQuery = useCallback((newNow?: DateTime | undefined) => {
        const reviewDueBefore = getFlatISORequestString(newNow ?? now);
        fetchQuery(environment, GlobalsQueryProviderQuery, { reviewDueBefore }).subscribe({
            complete: () => loadQuery({ reviewDueBefore })
        })
    }, [environment, loadQuery, now, getFlatISORequestString])

    const alreadyFetched = useRef(false);
    useEffect(() => {
        if (alreadyFetched.current) return;
        alreadyFetched.current = true;
        refreshQuery();
    }, [refreshQuery, alreadyFetched])

    const { addEventListener, removeEventListener } = useEventBus();
    useEffect(() => {
        const defaultListener = () => refreshQuery();
        const timeSetListener = (event: CustomEvent<CustomEventTimeSetPayload>) => refreshQuery(event.detail.now);
        addEventListener(EventTypes.TIME_SET, timeSetListener);
        addEventListener(EventTypes.GOAL_CREATED, defaultListener);
        addEventListener(EventTypes.GOAL_DELETED, defaultListener);
        addEventListener(EventTypes.GOAL_UPDATED_NEXT_REVIEW, defaultListener);
        addEventListener(EventTypes.GOAL_ARCHIVED, defaultListener);
        addEventListener(EventTypes.GOAL_REACTIVATED, defaultListener);
        return () => {
            removeEventListener(EventTypes.TIME_SET, timeSetListener)
            removeEventListener(EventTypes.GOAL_CREATED, defaultListener)
            removeEventListener(EventTypes.GOAL_DELETED, defaultListener)
            removeEventListener(EventTypes.GOAL_UPDATED_NEXT_REVIEW, defaultListener)
            removeEventListener(EventTypes.GOAL_ARCHIVED, defaultListener)
            removeEventListener(EventTypes.GOAL_REACTIVATED, defaultListener)
        }
    })

    return <GlobalsQueryContext.Provider value={queryLoaderResponse}>{children}</GlobalsQueryContext.Provider>
}
export default GlobalsQueryProvider;