import styled from '@emotion/styled';
import { Stack } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import logo from './_assets/logo.png';

const LogoImg = styled.img`
  height: 100%;
`

const Logo = ({ height, fontSize, textColor = "rgb(255,255,255)", fontWeight = "400", sx = {} }: {
  height: string,
  fontSize?: string,
  textColor?: string,
  fontWeight?: string,
  sx?: SxProps
}) => {
  return <Stack direction="row" spacing={"0.3em"} sx={Object.assign({ height, fontSize, display: "flex", alignItems: "center" }, sx)}>
    <LogoImg src={logo} />
    {fontSize && <Box sx={{ fontFamily: "Dancing Script", marginLeft: "0.3em", fontSize: "1em", fontWeight, color: textColor }}>Goals</Box>}
  </Stack>
}
export default Logo;