import { CircularProgress } from "@mui/material";
import useEventBus, { CustomEventTimeSetPayload, EventTypes } from "app/useEventBus";
import useTimeNow from "app/useTimeNow";
import useDateCalculations from "common/useDateCalculations";
import { DateTime } from "luxon";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import ReviewGoalsList from "main/review/ReviewGoalsList";
import ReviewMainTitle from "main/review/ReviewMainTitle";
import { ReviewListGoalsQuery as ReviewListGoalsQueryType } from "main/review/__generated__/ReviewListGoalsQuery.graphql";
import React, { useCallback, useEffect, useRef } from "react";
import { fetchQuery, useQueryLoader, useRelayEnvironment } from "react-relay";
import { Route, Routes, useLocation } from "react-router";
const graphql = require("babel-plugin-relay/macro");

export const ReviewListGoalsQuery = graphql`
    query ReviewListGoalsQuery($first: Int, $after: String, $last: Int, $before: String, $sortBefore: String, $archived: Boolean) {
        ...GoalsList_goalConnection
    }
`

const Review = () => {

    const { addEventListener, removeEventListener } = useEventBus();
    const { getFlatISORequestString } = useDateCalculations();
    const now = useTimeNow();

    const [listGoalsQueryRef, loadListGoalsQuery] = useQueryLoader<ReviewListGoalsQueryType>(ReviewListGoalsQuery);
    const fetchListGoalsQuery = useCallback(({ refresh = false, newNow }: { refresh?: boolean, newNow?: DateTime | undefined } = {}) => {
        const sortBefore = getFlatISORequestString(newNow ?? now);
        loadListGoalsQuery({
            sortBefore
        }, {
            fetchPolicy: refresh ? "network-only" : undefined
        });
    }, [loadListGoalsQuery, getFlatISORequestString, now])
    const environment = useRelayEnvironment();
    const refetchListGoalsQuery = useCallback(({ refresh = false, newNow }: { refresh?: boolean, newNow?: DateTime | undefined } = {}) => {
        const sortBefore = getFlatISORequestString(newNow ?? now);
        fetchQuery<ReviewListGoalsQueryType>(environment, ReviewListGoalsQuery, { sortBefore }).subscribe({
            complete: () => fetchListGoalsQuery({ newNow })
        })
    }, [fetchListGoalsQuery, getFlatISORequestString, now, environment])

    useEffect(() => {
        const timeSetListener = (event: CustomEvent<CustomEventTimeSetPayload>) => refetchListGoalsQuery({ newNow: event.detail.now });
        const listener = () => refetchListGoalsQuery({ refresh: true });
        addEventListener(EventTypes.TIME_SET, timeSetListener);
        addEventListener(EventTypes.GOAL_UPDATED_NEXT_REVIEW, listener);
        addEventListener(EventTypes.GOAL_DELETED, listener);
        addEventListener(EventTypes.GUEST_ID_CHANGED, listener);
        addEventListener(EventTypes.GOAL_ARCHIVED, listener);
        addEventListener(EventTypes.GOAL_REACTIVATED, listener);
        return () => {
            removeEventListener(EventTypes.TIME_SET, timeSetListener)
            removeEventListener(EventTypes.GOAL_UPDATED_NEXT_REVIEW, listener)
            removeEventListener(EventTypes.GOAL_DELETED, listener)
            removeEventListener(EventTypes.GUEST_ID_CHANGED, listener)
            removeEventListener(EventTypes.GOAL_ARCHIVED, listener)
            removeEventListener(EventTypes.GOAL_REACTIVATED, listener)
        }
    })

    const listGoalsQueryInititallyFetched = useRef(false);
    const location = useLocation();
    useEffect(() => {
        if (!listGoalsQueryInititallyFetched.current && location.pathname.startsWith("/review")) {
            fetchListGoalsQuery();
            listGoalsQueryInititallyFetched.current = true;
        }
    })

    return <Routes>
        <Route path="*" element={<></>} />
        <Route path="/review/goal" element={<></>} />
        <Route path="/review" element={<>
            <MainBar>
                <ReviewMainTitle />
            </MainBar>
            <MainContent>
                <MainContainer>
                    <React.Suspense fallback={<CircularProgress color="primary" />}>
                        {listGoalsQueryRef && <ReviewGoalsList queryRef={listGoalsQueryRef} />}
                    </React.Suspense>
                </MainContainer>
            </MainContent>
        </>} />
    </Routes>
}
export default Review;