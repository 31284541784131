import { Slider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import { useState } from "react";

const GoalCompletionSelect = ({
    value, handleChange
}: ControlFormFieldReturn<number>) => {
    const [localValue, setLocalValue] = useState(Math.round(value * 100));
    return <Box sx={{ padding: "0 1rem", width: "100%", boxSizing: "border-box" }} >
        <Stack direction="row" spacing={4} sx={{ display: "flex", alignItems: "center" }}>
            <Slider
                sx={{ position: "relative", top: "0.05rem" }}
                value={localValue}
                onChange={(e, v) => setLocalValue(+v)}
                onChangeCommitted={(e, v) => handleChange(+v / 100)}
            />
            <Typography variant="body2" sx={{ fontWeight: "500", minWidth: "2rem", display: "flex", justifyContent: "center" }}>{localValue}%</Typography>
        </Stack>
    </Box>
}
export default GoalCompletionSelect;