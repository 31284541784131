import React from "react";
import { Box } from "@mui/system"

const MainBarField = ({children}: {children: React.ReactNode}) => {
    return <Box sx={{
        height: "2.5rem",
        // border: "1px solid rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box"
    }}>
        {children}
    </Box>
}
export default MainBarField;