import AppMenu from "common/AppMenu";
import ReviewCardMenuDeleteItem from "main/review/review_card/menu/ReviewCardMenuDeleteItem";
import { ReviewCardMenu_goal$key } from "main/review/review_card/menu/__generated__/ReviewCardMenu_goal.graphql";
import { ReviewCardMenu_review$key } from "main/review/review_card/menu/__generated__/ReviewCardMenu_review.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const ReviewCardMenu = ({ review, goal }: {
    review: ReviewCardMenu_review$key,
    goal: ReviewCardMenu_goal$key,
}) => {
    const data = useFragment(graphql`
        fragment ReviewCardMenu_review on Review {
            ...ReviewCardMenuDeleteItem_review
        }
    `, review)
    const goalData = useFragment(graphql`
        fragment ReviewCardMenu_goal on Goal {
            ...ReviewCardMenuDeleteItem_goal
        }
    `, goal)

    return <AppMenu menuIconProps={{ fontSize: "small" }}>
        <ReviewCardMenuDeleteItem review={data} goal={goalData} />
    </AppMenu>
}
export default ReviewCardMenu;