import { ReviewCycleInput } from "graphql/API";
import { getRepetitionLevelEquivalent, getRepetitionLevels, mapReviewCycleInputToCycleOption } from "main/goals/common/cycleOptions";

const useCalculateRepetitionLevel = () => (oldCycleBegin: ReviewCycleInput, oldCycleGoal: ReviewCycleInput, newCycleBegin: ReviewCycleInput, newCycleGoal: ReviewCycleInput, formerLevel: number): number => {
    const e = new Error("error calculating repetition level");
    const oldRepetitionLevels = getRepetitionLevels(mapReviewCycleInputToCycleOption(oldCycleBegin), mapReviewCycleInputToCycleOption(oldCycleGoal));
    if(!oldRepetitionLevels) throw e;
    const newRepetitionLevels = getRepetitionLevels(mapReviewCycleInputToCycleOption(newCycleBegin), mapReviewCycleInputToCycleOption(newCycleGoal));
    if(!newRepetitionLevels) throw e;
    const formerCycleOption = oldRepetitionLevels[formerLevel];
    const newLevel = getRepetitionLevelEquivalent(formerCycleOption, newRepetitionLevels);
    if(newLevel === null) throw e;
    return newLevel;
}
export default useCalculateRepetitionLevel;