import { FormControl } from "@mui/material";
import AppTextField from "forms/AppTextField";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import { GoalDetailsTitle_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsTitle_goal.graphql";
import { GoalTitleEditorCommonProps } from "main/goals/goal_edit/GoalTitleEditor";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useEventBus, { EventTypes } from "app/useEventBus";
import { useRef, useState } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsTitle = ({ goal }: { goal: GoalDetailsTitle_goal$key }) => {

    const data = useFragment(graphql`
        fragment GoalDetailsTitle_goal on Goal {
            id
            title
        }
    `, goal)

    const [value, setValue] = useState<string>(data.title);
    const [saving, setSaving] = useState(false);
    const ref: React.RefObject<EventTarget> = useRef(new EventTarget());

    const [commit] = useUpdateGoal();
    const { dispatchFormFieldSavingEvent, dispatchFormFieldSavingSuccessEvent, dispatchFormFieldSavingErrorEvent } = useFormFieldSavingEvents(ref);

    const eventBus = useEventBus();
    const update = () => {
        if (value === data.title) return;
        if (value === "") {
            setValue(data.title);
            return;
        }
        setSaving(true);
        dispatchFormFieldSavingEvent();
        commit({
            variables: {
                input: {
                    id: data.id,
                    title: value
                }
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                dispatchFormFieldSavingSuccessEvent();
                setSaving(false);
            },
            onError: e => {
                console.error(e);
                dispatchFormFieldSavingErrorEvent();
                setSaving(false);
            }
        })
    }

    return <FormControl sx={{ width: "100%" }} component="div">
        <AppTextField
            readonlyStyle
            readonly={saving}
            label="Überschrift"
            eventSource={ref.current}
            value={value}
            onChange={e => setValue(e.target.value)}
            onBlur={update}
            {...GoalTitleEditorCommonProps}
        />
    </FormControl>
}
export default GoalDetailsTitle;