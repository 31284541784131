import { FormControl, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import GoalTypeLabel from "common/GoalTypeLabel";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import FormFieldSavingIndicator from "forms/FormFieldSavingIndicator";
import GoalDetailsSettingsInactiveText from "main/goals/goal_details/goal_details_settings/GoalDetailsSettingsInactiveText";
import GoalDetailsSettingsReviewCycleNextReview from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/GoalDetailsSettingsReviewCycleNextReview";
import { GoalDetailsSettingsNextReview_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/__generated__/GoalDetailsSettingsNextReview_goal.graphql";
import { useRef } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsNextReview = ({ goal }: { goal: GoalDetailsSettingsNextReview_goal$key }) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsNextReview_goal on Goal {
        archived
        type
        ...GoalDetailsSettingsReviewCycleNextReview_goal
    }
    `, goal)

    const eventSource = useRef(new EventTarget());

    return <Grid item xs={12}>
        <Stack direction="column" spacing={"0.25rem"}>
            <StandardPaper>
                <StandardPaperTitle noOpacityReduction>
                    <Box sx={{ opacity: 0.8 }}>Nächster Review</Box>
                    <Box sx={{ margin: "0 0 0 0.5rem" }}>
                        <FormFieldSavingIndicator eventSource={eventSource.current} />
                    </Box>
                </StandardPaperTitle>
                {data.archived ? <>
                    <GoalDetailsSettingsInactiveText>
                        Das nächste Reviewdatum kann bei einem archivierten <GoalTypeLabel type={data.type} casus="accusative" uppercase={false} /> nicht bearbeitet werden.
                    </GoalDetailsSettingsInactiveText>
                </> : <>
                    <FormControl error={true} sx={{ width: "100%" }}>
                        <GoalDetailsSettingsReviewCycleNextReview goal={data} eventSource={eventSource} />
                    </FormControl>
                </>}
            </StandardPaper>
        </Stack>
    </Grid>
}
export default GoalDetailsSettingsNextReview;