import { FormControl, FormHelperText, Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import AppTextField from "forms/AppTextField";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import { ChangeEvent } from "react";

export const GoalTitleEditorCommonProps = {
    inputProps: {
        sx: {
            fontSize: "1.2rem",
            fontWeight: "500",
            lineHeight: "1.7rem",
            pt: "0.75rem",
            pb: "1rem"
        }
    },
    sx: {
        width: "100%"
    },
    multiline: true
}

export const GoalTitleEditorFormHelperTextCommonProps = {
    sx: { marginTop: "4px", position: "absolute", right: "0", fontWeight: "500" } as SxProps
}

const GoalTitleEditor = ({
    errorsExist,
    visited,
    setVisited,
    handleChange,
    errors,
    sx
}: ControlFormFieldReturn<string> & { sx?: SxProps }) => {

    return <Stack sx={sx}>
        <FormControl error={visited && errorsExist()}>
            <AppTextField
                label="Überschrift"
                required
                autoFocus
                error={visited && errorsExist()}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    if (e.target.value.length > 1) return;
                    handleChange(e.target.value);
                }}
                onBlur={(e) => {
                    setVisited(true);
                    handleChange(e.target.value);
                }}
                {...GoalTitleEditorCommonProps}
            />
            {
                visited && errors?.required && <FormHelperText {...GoalTitleEditorFormHelperTextCommonProps}>Bitte gib eine Überschrift für das Ziel oder Working Agreement an</FormHelperText>
            }
        </FormControl>

    </Stack>
}
export default GoalTitleEditor;