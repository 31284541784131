import React from "react";
import { Route, Routes } from "react-router-dom";
import Start from "start/Start";

const StartRoute = ({ children }: { children: React.ReactNode }) => {

    return <Routes>
        <Route path="/" element={<Start/>} />
        <Route path="*" element={<>{children}</>} />
    </Routes>
}
export default StartRoute;