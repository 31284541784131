import { Divider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const StandardPaperTitle = ({ children, noOpacityReduction, compact, embedded, subtitle }: {
    children: React.ReactNode,
    noOpacityReduction?: boolean,
    compact?: boolean,
    embedded?: boolean,
    subtitle?: boolean
}) => {
    return <Stack direction="column" sx={{width: "100%"}}>
        <Box sx={{
            fontSize: embedded || subtitle ? "0.95rem" : "1.1rem",
            fontWeight: "300",
            opacity: noOpacityReduction ? 1 : 0.8,
            display: "flex",
            alignItems: "center"
        }}>{children}</Box>
        {!compact && <Divider sx={{ margin: "0.5rem 0 1rem 0", opacity: 0.8 }} />}
    </Stack>
}
export default StandardPaperTitle;