import { Grid, Stack } from "@mui/material";
import { GoalType } from "graphql/API";
import GoalDetailsCompletion from "main/goals/goal_details/goal_details_overview/GoalDetailsCompletion";
import GoalDetailsCurrentReview from "main/goals/goal_details/goal_details_overview/GoalDetailsCurrentReview";
import GoalDetailsDueDate from "main/goals/goal_details/goal_details_overview/GoalDetailsDueDate";
import GoalDetailsNextReview from "main/goals/goal_details/goal_details_overview/GoalDetailsNextReview";
import GoalDetailsReviews from "main/goals/goal_details/goal_details_overview/GoalDetailsReviews";
import GoalDetailsTitleDescription from "main/goals/goal_details/goal_details_overview/GoalDetailsTitleDescription";
import { GoalDetailsOverview_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsOverview_goal.graphql";
import MainGrid from "main/MainGrid";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsOverview = ({ goal }: { goal: GoalDetailsOverview_goal$key }) => {

    const data = useFragment(graphql`
            fragment GoalDetailsOverview_goal on Goal {
                type
                archived
                ...GoalDetailsNextReview_goal
                ...GoalDetailsCurrentReview_goal
                ...GoalDetailsCompletion_goal
                ...GoalDetailsReviews_goal
                ...GoalDetailsTitleDescription_goal
                ...GoalDetailsDueDate_goal
            }
        `, goal)

    return <>
        <MainGrid>
            <Grid item xs={6}>
                <Stack direction="column" spacing={"1rem"}>
                    <GoalDetailsTitleDescription goal={data} />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" spacing={"1rem"}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                        <Stack direction="column" spacing={"1rem"} sx={{height: "100%"}}>
                            <GoalDetailsCompletion goal={data} />
                            {data.type === GoalType.completable &&
                                <GoalDetailsDueDate goal={data} />
                            }
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            {!data.archived && <GoalDetailsNextReview goal={data} />}
                            {data.archived && <GoalDetailsCurrentReview goal={data} />}
                        </Grid>
                        <Grid item xs={12}>
                            <GoalDetailsReviews goal={data} />
                        </Grid>
                    </Grid>

                </Stack>
            </Grid>
        </MainGrid>

    </>
}
export default GoalDetailsOverview;