import { Badge } from "@mui/material";
import useDateCalculations from "common/useDateCalculations";
import { DateTime } from "luxon";
import { GoalDetailsReviewTab_goal$key } from "main/goals/goal_details/__generated__/GoalDetailsReviewTab_goal.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsReviewTab = ({ goal }: { goal: GoalDetailsReviewTab_goal$key }) => {

    const data = useFragment<GoalDetailsReviewTab_goal$key>(graphql`
            fragment GoalDetailsReviewTab_goal on Goal {
                archived
                nextReviewAt
            }
        `, goal)

    const nextReviewAt = DateTime.fromISO(data.nextReviewAt);
    const { flatDue } = useDateCalculations();

    return data.archived ? <>Review</> : <Badge invisible={!flatDue(nextReviewAt)} color="secondary" variant="dot" sx={{
        '& .MuiBadge-badge': {
            right: "-0.4rem"
        }
    }}>
        Review
    </Badge>
}
export default GoalDetailsReviewTab;