/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalCard_goal = {
    readonly id: string;
    readonly type: GoalType;
    readonly readableId: number;
    readonly title: string;
    readonly nextReviewAt: string;
    readonly currentReviewAt: string;
    readonly dueDate: string | null;
    readonly completion: number | null;
    readonly " $fragmentRefs": FragmentRefs<"GoalCardTitle_goal" | "GoalStatusField_goal" | "GoalCardMenuButton_goal">;
    readonly " $refType": "GoalCard_goal";
};
export type GoalCard_goal$data = GoalCard_goal;
export type GoalCard_goal$key = {
    readonly " $data"?: GoalCard_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalCard_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalCard_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readableId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nextReviewAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentReviewAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completion",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalCardTitle_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalStatusField_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalCardMenuButton_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '307241191df40356b3c424fd6a8ff679';
export default node;
