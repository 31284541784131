import { Box, SxProps } from "@mui/system";
import React from "react";

const StandardPaperText = ({ children, sx = {} }: { children: React.ReactNode, sx?: SxProps }) => <Box sx={Object.assign({
    fontSize: "0.875rem",
    lineHeight: "1.4em"
}, sx)}>
    {children}
</Box>
export default StandardPaperText;

export const StandardPaperTextParagraph = ({ children, sx = {} }: { children: React.ReactNode, sx?: SxProps }) => <StandardPaperText sx={Object.assign({
    margin: "0.5rem 0 0 0"
}, sx)}>
    {children}
</StandardPaperText>