import { Badge, CircularProgress } from "@mui/material";
import { GlobalsQueryProviderQuery } from "common/GlobalsQueryProvider";
import React, { useEffect, useReducer, useRef } from "react";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import useGlobalsQueryLoader from "_hooks/useGlobalsQueryLoader";
import { GlobalsQueryProviderQuery as GlobalsQueryProviderQueryType } from "common/__generated__/GlobalsQueryProviderQuery.graphql";
import { GoalsCountBadge_getGlobalsReturn$key } from "navigation/__generated__/GoalsCountBadge_getGlobalsReturn.graphql";
const graphql = require("babel-plugin-relay/macro");

const GoalsCountBadge = () => {
    const [queryRef] = useGlobalsQueryLoader();
    return queryRef ? <React.Suspense fallback={<CircularProgress size="1rem" />}><GoalsCountBadgeMain queryRef={queryRef} /></React.Suspense> : <></>
}

const GoalsCountBadgeMain = ({ queryRef }: { queryRef: PreloadedQuery<GlobalsQueryProviderQueryType> }) => {

    const query = usePreloadedQuery(GlobalsQueryProviderQuery, queryRef);
    const data = useFragment<GoalsCountBadge_getGlobalsReturn$key>(graphql`
        fragment GoalsCountBadge_getGlobalsReturn on GetGlobalsReturn {
            goalsCount
        }
    `, query.getGlobals);

    const [visible, setVisible] = useReducer(() => true, false);
    const alreadyVisible = useRef(false);
    useEffect(() => {
        if (alreadyVisible.current) return;
        alreadyVisible.current = true;
        const timeout = setTimeout(() => setVisible(), 500);
        return () => clearTimeout(timeout);
    }, [setVisible, alreadyVisible])

    return <Badge max={500} invisible={!visible} badgeContent={data?.goalsCount} color="secondary" anchorOrigin={{ horizontal: "left", vertical: "top" }} sx={{
        transform: "translate(-1rem, 0)",
        "& .MuiBadge-badge": {
            fontWeight: 400,
            background: "rgb(15,50,70)"
        }
    }} />
}
export default GoalsCountBadge;