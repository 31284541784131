import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/LogoutRounded";
import useAccount from "authentication/_hooks/useAccount";

const UserToolsLogout = () => {

    const { logout } = useAccount();

    return <>
        <MenuItem onClick={async () => {
            try {
                await logout();
                window.location.assign("/logout");
            } catch(e) {}
        }}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText>Abmelden</ListItemText>
        </MenuItem>
    </>
}
export default UserToolsLogout;