import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "app/store";

type User = {
    email: string;
}

interface UserState {
    authenticatedUser: User | null;
    loggingIn: boolean;
}

const initialState: UserState = {
    authenticatedUser: null,
    loggingIn: false
}

export const AccountSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthenticatedUser: (state, action: PayloadAction<User | null>) => {
            state.authenticatedUser = action.payload;
        },
        unsetAuthenticatedUser: (state) => {
            state.authenticatedUser = null;
        },
        setLoggingIn: (state, action: PayloadAction<boolean>) => {
            state.loggingIn = action.payload;
        }
    }
})

export const {
    setAuthenticatedUser,
    unsetAuthenticatedUser,
    setLoggingIn
} = AccountSlice.actions;

export const selectAuthenticatedUser = (state: RootState) => state.account.authenticatedUser;
export const selectLoggingIn = (state: RootState) => state.account.loggingIn;

export default AccountSlice.reducer;