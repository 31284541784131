import Terms from "main/terms/Terms";
import { Route, Routes } from "react-router";

const TermsRoute = () => {

    return <Routes>
        <Route path="*" element={<></>} />
        <Route path="/terms" element={<Terms/>} />
    </Routes>
}
export default TermsRoute;