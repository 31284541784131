/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type GoalType = "completable" | "continuous" | "%future added value";
export type CreateGoalInput = {
    completed?: boolean | null;
    completion?: number | null;
    description?: string | null;
    dueDate?: string | null;
    nextReviewAt: string;
    reviewMode: ReviewModeInput;
    status: number;
    title: string;
    type: GoalType;
};
export type ReviewModeInput = {
    cycleBegin: ReviewCycleInput;
    cycleGoal?: ReviewCycleInput | null;
    spacedRepetition: boolean;
};
export type ReviewCycleInput = {
    days?: number | null;
    months?: number | null;
};
export type useCreateGoalMutationVariables = {
    input: CreateGoalInput;
};
export type useCreateGoalMutationResponse = {
    readonly createGoal: {
        readonly goal: {
            readonly id: string;
            readonly readableId: number;
            readonly type: GoalType;
            readonly title: string;
            readonly description: string | null;
            readonly dueDate: string | null;
            readonly completed: boolean | null;
            readonly reviewMode: {
                readonly spacedRepetition: boolean;
                readonly cycleBegin: {
                    readonly days: number | null;
                    readonly months: number | null;
                };
                readonly cycleGoal: {
                    readonly days: number | null;
                    readonly months: number | null;
                } | null;
            };
            readonly nextReviewAt: string;
            readonly currentReviewAt: string;
            readonly createdAt: string;
            readonly updatedAt: string;
        };
    };
};
export type useCreateGoalMutation = {
    readonly response: useCreateGoalMutationResponse;
    readonly variables: useCreateGoalMutationVariables;
};



/*
mutation useCreateGoalMutation(
  $input: CreateGoalInput!
) {
  createGoal(input: $input) {
    goal {
      id
      readableId
      type
      title
      description
      dueDate
      completed
      reviewMode {
        spacedRepetition
        cycleBegin {
          days
          months
        }
        cycleGoal {
          days
          months
        }
      }
      nextReviewAt
      currentReviewAt
      createdAt
      updatedAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "days",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "months",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateGoalResponse",
    "kind": "LinkedField",
    "name": "createGoal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Goal",
        "kind": "LinkedField",
        "name": "goal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readableId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dueDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewMode",
            "kind": "LinkedField",
            "name": "reviewMode",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spacedRepetition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewCycle",
                "kind": "LinkedField",
                "name": "cycleBegin",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReviewCycle",
                "kind": "LinkedField",
                "name": "cycleGoal",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nextReviewAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentReviewAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateGoalMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateGoalMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "87b766ca72f39b9ccd242716df2a2c5d",
    "id": null,
    "metadata": {},
    "name": "useCreateGoalMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateGoalMutation(\n  $input: CreateGoalInput!\n) {\n  createGoal(input: $input) {\n    goal {\n      id\n      readableId\n      type\n      title\n      description\n      dueDate\n      completed\n      reviewMode {\n        spacedRepetition\n        cycleBegin {\n          days\n          months\n        }\n        cycleGoal {\n          days\n          months\n        }\n      }\n      nextReviewAt\n      currentReviewAt\n      createdAt\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e8774803652b2ea54ce29d52be84db12';
export default node;
