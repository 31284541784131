import NavigateBeforeIcon from "@mui/icons-material/ArrowBackRounded";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import AppEmph from "common/AppEmph";
import ExplanationParagraph from "common/ExplanationParagraph";
import GoalTypeLabel from "common/GoalTypeLabel";
import StandardPaper from "common/StandardPaper";
import StandardPaperContainer from "common/StandardPaperContainer";
import useDateCalculations from "common/useDateCalculations";
import ReviewIcon from "common/_icons/ReviewIcon";
import { DateTime } from "luxon";
import GoalReviewField from "main/goals/common/fields/GoalReviewField";
import { ReviewDateCalculationResult, ReviewingStatus } from "main/goals/goal_details/goal_details_review/GoalDetailsReview";
import GoalDetailsReviewCalculationOptions from "main/goals/goal_details/goal_details_review/GoalDetailsReviewCalculationOptions";
import { GoalDetailsReviewStatus_goal$key } from "main/goals/goal_details/goal_details_review/__generated__/GoalDetailsReviewStatus_goal.graphql";
import GoalDetailsSettingsInactiveText from "main/goals/goal_details/goal_details_settings/GoalDetailsSettingsInactiveText";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsReviewStatus = ({ status = ReviewingStatus.DEFAULT, goal, reviewDateCalculationResult }: {
    status: ReviewingStatus,
    goal: GoalDetailsReviewStatus_goal$key,
    reviewDateCalculationResult: ReviewDateCalculationResult
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsReviewStatus_goal on Goal {
        nextReviewAt
        type
        archived
        ...GoalDetailsReviewCalculationOptions_goal
    }
    `, goal)

    const nextReviewAt = DateTime.fromISO(data.nextReviewAt);

    const { flatDue } = useDateCalculations();
    const due = flatDue(nextReviewAt);

    const navigate = useNavigate();

    return (() => {
        switch (status) {
            case ReviewingStatus.REVIEWING:
                return <>
                    <StandardPaper sx={{ margin: "0 0 1rem 0" }}>
                        <Box>
                            <Box sx={{ fontSize: "2rem", fontWeight: "300" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <IconButton sx={{ margin: "0 0.5rem 0 0" }} onClick={() => navigate("")}>
                                        <NavigateBeforeIcon />
                                    </IconButton>
                                    Review durchführen
                                </Box>
                            </Box>
                        </Box>
                    </StandardPaper>
                </>
            case ReviewingStatus.SUCCESS:
            case ReviewingStatus.SUCCESS_LOADING:
                return <>
                    <StandardPaper sx={{ margin: "0 0 1rem 0" }}>
                        <Box>
                            <Box sx={{ fontSize: "2rem", fontWeight: "300", margin: "0 0 1rem 0" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {reviewDateCalculationResult.completed ? "Ziel erreicht!" : "Review erfolgreich!"}
                                    <ReviewIcon color={"success"} sx={{ margin: "0 0 0 0.5rem" }} />
                                    {status === ReviewingStatus.SUCCESS_LOADING && <CircularProgress size="1.2rem" sx={{ margin: "0 0 0 0.5rem" }} />}
                                </Box>
                            </Box>
                            <Box sx={{ fontSize: "0.875rem", lineHeight: "1.5", letterSpacing: "0.00938em", fontWeight: 400 }}>
                                {reviewDateCalculationResult.completed ? <>
                                    <StandardPaperContainer>
                                        Glückwunsch, ihr scheint das Ziel zu 100% erreicht zu haben!
                                        <ExplanationParagraph>
                                            <AppEmph>Daher wurde das Ziel archiviert.</AppEmph>
                                        </ExplanationParagraph>
                                        <ExplanationParagraph>
                                            Bei archivierten Zielen finden in vAlign keine weiteren Reviews statt. Du kannst das Ziel jederzeit wieder reaktivieren.
                                        </ExplanationParagraph>
                                    </StandardPaperContainer>
                                </> : <>
                                    <StandardPaperContainer>
                                        <Box sx={{ fontSize: "0.875rem", fontWeight: "400", margin: "1.25rem 0 1.5rem 0" }}>
                                            <Box component="div" sx={{ fontSize: "1rem" }}>
                                                <GoalReviewField label="Nächster Review fällig" reviewAt={DateTime.fromISO(data.nextReviewAt)} fieldStyle="emphasized" />
                                            </Box>
                                        </Box>
                                        <GoalDetailsReviewCalculationOptions goal={data} reviewDateCalculationResult={reviewDateCalculationResult} />
                                    </StandardPaperContainer>
                                </>}
                            </Box>
                        </Box>
                    </StandardPaper>
                </>
            case ReviewingStatus.DEFAULT:
            default:
                return data.archived ? <>
                    <StandardPaper sx={{ margin: "0 0 1rem 0" }}>
                        <Box>
                            <Box sx={{ fontSize: "2rem", fontWeight: "300" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}><GoalTypeLabel type={data.type} /> archiviert</Box>
                                <StandardPaperContainer>
                                    <Box sx={{ fontSize: "0.875rem", fontWeight: "400", margin: "1.25rem 0 0.5rem 0" }}>
                                        <GoalDetailsSettingsInactiveText>
                                            Bei einem archivierten <GoalTypeLabel type={data.type} /> kann kein Review durchgeführt werden.
                                        </GoalDetailsSettingsInactiveText>
                                    </Box>
                                </StandardPaperContainer>
                            </Box>
                        </Box>
                    </StandardPaper>
                </> : <>
                    <StandardPaper sx={{ margin: "0 0 1rem 0" }}>
                        <Box>
                            <Box sx={{ fontSize: "2rem", fontWeight: "300" }}>
                                <Box sx={{ display: "flex", alignItems: "center" }}>{due ? <>It's Review Time!</> : <>Review noch nicht fällig</>}</Box>
                                <StandardPaperContainer>
                                    <Box sx={{ fontSize: "0.875rem", fontWeight: "400", margin: "1.25rem 0 0 0" }}>
                                        <Box component="div" sx={{ fontSize: "1rem" }}>
                                            <GoalReviewField label="Nächster Review fällig" reviewAt={DateTime.fromISO(data.nextReviewAt)} fieldStyle="emphasized" />
                                        </Box>
                                        {!due && <p>Ihr könnt trotzdem jederzeit einen vorzeitigen Review des <GoalTypeLabel type={data.type} />s durchführen.</p>}
                                    </Box>
                                    <Button variant="contained" color={due ? "secondary" : "primary"} onClick={() => navigate("new")} sx={{ margin: "1rem 0 0 0" }}>
                                        {due ? <>Jetzt Review durchführen</> : <>Vorzeitigen Review durchführen</>}
                                    </Button>
                                </StandardPaperContainer>
                            </Box>
                        </Box>
                    </StandardPaper>
                </>
        }
    })()
}
export default GoalDetailsReviewStatus;