import { Badge, CircularProgress } from "@mui/material";
import { GlobalsQueryProviderQuery } from "common/GlobalsQueryProvider";
import React, { useEffect, useReducer, useRef } from "react";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import useGlobalsQueryLoader from "_hooks/useGlobalsQueryLoader";
import { GlobalsQueryProviderQuery as GlobalsQueryProviderQueryType } from "common/__generated__/GlobalsQueryProviderQuery.graphql";
import { ArchiveCountBadge_getGlobalsReturn$key } from "navigation/__generated__/ArchiveCountBadge_getGlobalsReturn.graphql";
const graphql = require("babel-plugin-relay/macro");

const ArchiveCountBadge = () => {
    const [queryRef] = useGlobalsQueryLoader();
    return queryRef ? <React.Suspense fallback={<CircularProgress size="1rem" />}><ArchiveCountBadgeMain queryRef={queryRef} /></React.Suspense> : <></>
}

const ArchiveCountBadgeMain = ({ queryRef }: { queryRef: PreloadedQuery<GlobalsQueryProviderQueryType> }) => {

    const query = usePreloadedQuery(GlobalsQueryProviderQuery, queryRef);
    const data = useFragment<ArchiveCountBadge_getGlobalsReturn$key>(graphql`
        fragment ArchiveCountBadge_getGlobalsReturn on GetGlobalsReturn {
            archiveCount
        }
    `, query.getGlobals);

    const [visible, setVisible] = useReducer(() => true, false);
    const alreadyVisible = useRef(false);
    useEffect(() => {
        if (alreadyVisible.current) return;
        alreadyVisible.current = true;
        const timeout = setTimeout(() => setVisible(), 500);
        return () => clearTimeout(timeout);
    }, [setVisible, alreadyVisible])

    return <Badge max={500} invisible={!visible} badgeContent={data?.archiveCount} color="secondary" anchorOrigin={{ horizontal: "left", vertical: "top" }} sx={{
        transform: "translate(-1rem, 0)",
        "& .MuiBadge-badge": {
            fontWeight: 400,
            color: "rgba(255,255,255,0.7)",
            background: "rgb(15,50,70)"
        }
    }} />
}
export default ArchiveCountBadge;