import { configureStore } from '@reduxjs/toolkit';
import TimeReducer from "app/TimeSlice";
import GoalReducer from "main/goals/GoalSlice";
import AccountReducer from "authentication/_store/AccountSlice";

export const store = configureStore({
    reducer: {
        goal: GoalReducer,
        time: TimeReducer,
        account: AccountReducer
    }
})
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;