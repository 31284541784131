import { useCreateReviewMutation } from "main/review/__hooks/__generated__/useCreateReviewMutation.graphql";
import { useMutation } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const useCreateReview = () => {
    return useMutation<useCreateReviewMutation>(graphql`
        mutation useCreateReviewMutation(
            $input: CreateReviewInput!, $first: Int, $after: String, $last: Int, $before: String
          ) {
            createReview(input: $input) {
                review {
                    id
                    automatedReview
                    completion
                    comment
                    createdAt
                    updatedAt
                }
                goal {
                    id
                    completion
                    spacedRepetitionLevel
                    nextReviewAt
                    updatedAt
                    ...GoalDetailsOverview_goal
                }
            }
        }
    `)
}
export default useCreateReview;