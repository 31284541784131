import { GraphQLResponse } from "relay-runtime";
import { API } from "aws-amplify";
import { GraphQLOptions } from "@aws-amplify/api-graphql";
import AppConsole from "app/AppConsole";

const appConsole = AppConsole("fetchGraphQL");

export interface FetchGraphQLFunction {({ query, variables, authMode }: {
    query: string,
    variables: GraphQLOptions["variables"],
    authMode: GraphQLOptions["authMode"]
}, h: { [key: string]: string }): Promise<GraphQLResponse>}

const fetchGraphQL: FetchGraphQLFunction = ({ query, variables, authMode }, h): Promise<GraphQLResponse> => {
    appConsole.log("fetching graphQL from server...", 0);
    return API.graphql({ query, variables, authMode }, h) as Promise<GraphQLResponse>
}
export default fetchGraphQL;