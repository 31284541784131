import { useDeleteGoalMutation } from "main/goals/__hooks/__generated__/useDeleteGoalMutation.graphql";
import { useMutation } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const useDeleteGoal = () => {
    return useMutation<useDeleteGoalMutation>(graphql`
        mutation useDeleteGoalMutation(
            $input: DeleteGoalInput!
            $connections: [ID!]!
          ) {
            deleteGoal(input: $input) {
              deletedId @deleteEdge(connections: $connections)
            }
        }
    `)
}
export default useDeleteGoal;