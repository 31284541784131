const config = {
    'aws_appsync_graphqlEndpoint': process.env.REACT_APP_API_ENDPOINT,
    'aws_appsync_region': 'eu-central-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
    'aws_appsync_apiKey': process.env.REACT_APP_API_KEY,
    Auth: {
        region: "eu-central-1",
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID
    }
}
export default config;