import { Box, FormControl, FormHelperText } from "@mui/material";
import AppDatePicker from "forms/AppDatePicker";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import { DateTime } from "luxon";

const GoalDateSelect = ({
    value, handleChange, errorsExist, setVisited, visited, errors, visible, validate, updateErrors, validateForm
}: ControlFormFieldReturn<DateTime | null> & {
    visible: boolean
}) => {

    return <Box sx={{ justifyContent: "flex-start", visibility: visible ? "visible" : "hidden" }}>
        <FormControl error={visited && errorsExist()}>
            <AppDatePicker
                inputFormat="dd.LL.yyyy"
                label="Zieldatum"
                value={value}
                onChange={d => {
                    handleChange(d as DateTime);
                    d && (d as DateTime).isValid && validateForm();
                }}
                renderInput={() => <></>}
                customRenderInput={{
                    onBlur: () => {
                        setVisited(true);
                        updateErrors();
                        validateForm();
                    },
                    required: true,
                    sx: { width: "12em" },
                }}
                error={visited && errorsExist()}
                boldValue
                minDate={DateTime.now().plus({days: 1})}
            />
            { 
               visited && (errors?.required ? <FormHelperText sx={{display: visible ? "inline-flex" : "none"}}>Bitte gib ein Zieldatum an.</FormHelperText> :
                    errors?.valid ? <FormHelperText sx={{display: visible ? "inline-flex" : "none"}}>Bitte gib ein gültiges Datum im Format dd.mm.yyyy an.</FormHelperText> :
                        errors?.future ? <FormHelperText sx={{display: visible ? "inline-flex" : "none"}}>Das Zieldatum muss in der Zukunft liegen.</FormHelperText> : <></>)
            }
        </FormControl>
    </Box>
}
export default GoalDateSelect;