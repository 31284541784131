import { Divider } from "@mui/material";
import { selectAuthenticatedUser } from "authentication/_store/AccountSlice";
import { useSelector } from "react-redux";
import GuestToolsSetId from "toolbar/guest_tools/GuestToolsSetId";
import GuestToolsShareURL from "toolbar/guest_tools/GuestToolsShareURL";
import GuestToolsShowId from "toolbar/guest_tools/GuestToolsShowId";

const GuestTools = () => {

    const authenticatedUser = useSelector(selectAuthenticatedUser);

    return authenticatedUser ? <></> : <>
        <GuestToolsShowId />
        <GuestToolsSetId />
        <Divider />
        <GuestToolsShareURL />
    </>
}
export default GuestTools;