import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import LoginForm from "authentication/LoginForm";
import RecoverForm from "authentication/RecoverForm";
import SignupForm from "authentication/SignupForm";
import VerifyForm from "authentication/VerifyForm";
import useAccount from "authentication/_hooks/useAccount";
import { selectLoggingIn } from "authentication/_store/AccountSlice";
import BackgroundFrame from "common/BackgroundFrame";
import Logo from "common/Logo";
import StandardPaper from "common/StandardPaper";
import StartFooter from "common/StartFooter";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Login = () => {

    const account = useAccount();
    const loggingIn = useSelector(selectLoggingIn);
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");

    const navigate = useNavigate();
    const location = useLocation();
    const signupPath = location.pathname.match(/signup$/);

    useEffect(() => {
        if (account.authenticatedAccount && !loggingIn) navigate(redirectPath ? "/" + redirectPath : "/dashboard", { replace: true })
    })

    return <>
        <BackgroundFrame sx={{ alignItems: "flex-start" }}>
            <Box sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Box sx={{ margin: "0 0 2rem 0" }}>
                    <div onClick={() => navigate("/")} style={{cursor: "pointer"}}>
                        <Logo height="5rem" fontSize="3rem" textColor="rgba(0,80,140)" fontWeight="900" />
                    </div>
                </Box>
                <StandardPaper sx={{ maxWidth: "27.5rem", width: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column", alignItems: "center", padding: "0", overflow: "hidden" }}>
                    <Tabs
                        value={signupPath ? "signup" : "login"}
                        variant="fullWidth"
                        textColor="inherit"
                        sx={{
                            width: "100%",
                            background: theme => theme.palette.primary.main,
                            color: "rgb(255,255,255)",
                            "& .MuiTab-root": {
                                opacity: "1",
                                color: "rgba(255,255,255,0.8)",
                                background: "rgba(255,255,255,0.4)"
                            },
                            "& .Mui-selected": {
                                filter: "none",
                                opacity: 1,
                                background: theme => theme.palette.primary.main,
                                color: "rgba(255,255,255,1)"
                            },
                            "& .MuiTabs-indicator": {
                                display: "none"
                            },
                        }}
                    >
                        <Tab label={"Anmelden"} value="login" onClick={() => navigate("/login" + location.search)} />
                        <Tab label="Registrieren" value="signup" onClick={() => navigate("signup" + location.search)} />
                    </Tabs>
                    <Routes>
                        <Route path="signup" element={<SignupForm />} />
                        <Route path="verify" element={<VerifyForm />} />
                        <Route path="recover" element={<RecoverForm />} />
                        <Route path="*" element={<LoginForm />} />
                    </Routes>
                </StandardPaper>
                <StartFooter />
            </Box>
        </BackgroundFrame>
    </>
}
export default Login;