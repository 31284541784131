import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperContainer from "common/StandardPaperContainer";
import StandardPaperTitle from "common/StandardPaperTitle";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import { GoalType } from "graphql/API";
import { GoalDetailsReviewGoalStatus_goal$key } from "main/goals/goal_details/goal_details_review/__generated__/GoalDetailsReviewGoalStatus_goal.graphql";
import GoalStatusSelect from "main/goals/goal_edit/goal_completion_select/GoalStatusSelect";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsReviewGoalStatus = ({ goal, ...controlFormFieldReturn }: { goal: GoalDetailsReviewGoalStatus_goal$key } & ControlFormFieldReturn<number>) => {

    const data = useFragment(graphql`
        fragment GoalDetailsReviewGoalStatus_goal on Goal {
            type
        }
    `, goal)

    const completable = data.type === GoalType.completable;

    return <>
        <StandardPaper>
            <StandardPaperTitle>
                {completable ? <>Wie zuversichtlich seid ihr aktuell, das Ziel wie geplant zu erreichen?</> : <>Wie schätzt ihr die derzeitige Erfüllung des Working Agreements ein?</>}
            </StandardPaperTitle>
            <StandardPaperContainer>
                <Box sx={{ width: "100%", minHeight: "2rem", boxSizing: "border-box" }}>
                    <GoalStatusSelect {...controlFormFieldReturn} />
                </Box>
            </StandardPaperContainer>
        </StandardPaper>
    </>
}
export default GoalDetailsReviewGoalStatus;