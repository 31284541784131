/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalCardMenu_goal = {
    readonly id: string;
    readonly readableId: number;
    readonly " $fragmentRefs": FragmentRefs<"DeleteGoalMenuItem_goal" | "ArchiveGoalMenuItem_goal">;
    readonly " $refType": "GoalCardMenu_goal";
};
export type GoalCardMenu_goal$data = GoalCardMenu_goal;
export type GoalCardMenu_goal$key = {
    readonly " $data"?: GoalCardMenu_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalCardMenu_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalCardMenu_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readableId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteGoalMenuItem_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArchiveGoalMenuItem_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '8df3749974d5fbc2d2ec86ef52188f53';
export default node;
