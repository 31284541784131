import Imprint from "main/terms/Imprint";
import { Route, Routes } from "react-router";

const ImprintRoute = () => {

    return <Routes>
        <Route path="*" element={<></>} />
        <Route path="/imprint" element={<Imprint/>} />
    </Routes>
}
export default ImprintRoute;