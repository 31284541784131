/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalStatusField_review = {
    readonly status: number;
    readonly completion: number | null;
    readonly createdAt: string;
    readonly " $refType": "GoalStatusField_review";
};
export type GoalStatusField_review$data = GoalStatusField_review;
export type GoalStatusField_review$key = {
    readonly " $data"?: GoalStatusField_review$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalStatusField_review">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalStatusField_review",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Review",
  "abstractKey": null
};
(node as any).hash = '8f77d7468ee8add498a08717a3cef16e';
export default node;
