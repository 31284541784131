import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import useEventBus, { EventTypes } from "app/useEventBus";
import useTimeNow from "app/useTimeNow";
import useDateCalculations from "common/useDateCalculations";
import useFormHandlers from "forms/useFormHandlers";
import { DateTime, Duration } from "luxon";
import { cycleOptionDuration, getRepetitionLevelsByReviewCycleInput, typecastDurationObjectUnits } from "main/goals/common/cycleOptions";
import GoalDetailsReviews from "main/goals/goal_details/goal_details_overview/GoalDetailsReviews";
import GoalDetailsReviewForm, { ReviewFormData } from "main/goals/goal_details/goal_details_review/GoalDetailsReviewForm";
import GoalDetailsReviewStatus from "main/goals/goal_details/goal_details_review/GoalDetailsReviewStatus";
import { GoalDetailsReview_goal$key } from "main/goals/goal_details/goal_details_review/__generated__/GoalDetailsReview_goal.graphql";
import MainGrid from "main/MainGrid";
import useCreateReview from "main/review/__hooks/useCreateReview";
import { useEffect, useReducer, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { useLocation, useNavigate } from "react-router";
const graphql = require("babel-plugin-relay/macro");

export enum ReviewingStatus {
    DEFAULT = "default",
    REVIEWING = "reviewing",
    SUCCESS = "success",
    SUCCESS_LOADING = "success_loading"
}

export type ReviewDateCalculationResult = {
    completed?: boolean,
    statusChange?: "notchanged" | "better" | "worse",
    oldRepetitionLevel?: number | null
}

const GoalDetailsReview = ({ goal }: { goal: GoalDetailsReview_goal$key }) => {

    const data = useFragment(graphql`
    fragment GoalDetailsReview_goal on Goal {
        id
        type
        status
        completion
        spacedRepetitionLevel
        reviewMode {
            spacedRepetition
            cycleBegin {
                days
                months
            }
            cycleGoal {
                days
                months
            }
        }
        ...GoalDetailsReviewStatus_goal
        ...GoalDetailsReviewForm_goal
        ...GoalDetailsReviews_goal
    }
    `, goal)

    const reviewingStatusRef = useRef<ReviewingStatus | null>(null);
    const [reviewDateCalculationResult, setReviewDateCalculationResult] = useState<ReviewDateCalculationResult>({});

    const location = useLocation();
    const deriveReviewingStatusFromURL = () => {
        if (/review\/new/.test(location.pathname)) return ReviewingStatus.REVIEWING;
        return reviewingStatusRef.current ?? ReviewingStatus.DEFAULT;
    }
    const [reviewingStatus, dispatchReviewingStatus] = useReducer((state: ReviewingStatus, action: { type: string, value: ReviewingStatus }) => {
        if (action.type === "setValue" && action.value) return action.value;
        return deriveReviewingStatusFromURL()
    }, deriveReviewingStatusFromURL());

    useEffect(() => {
        dispatchReviewingStatus({ type: "update", value: ReviewingStatus.DEFAULT });
    }, [location]);

    const formHandlers = useFormHandlers<ReviewFormData>({
        initialFormData: {
            completion: data.completion,
            status: data.status,
            comment: ""
        },
        initialValidState: { completion: true, status: true, comment: true }
    });
    const { formData, isValidForm } = formHandlers;

    const [saving, setSaving] = useState(false);
    const [commit] = useCreateReview();
    const navigate = useNavigate();
    const now = useTimeNow();
    const { setDefaultHour } = useDateCalculations();

    const calculateNextReviewDate = (): { nextReviewAt: DateTime, spacedRepetitionLevel: number, result: ReviewDateCalculationResult } | null => {
        const statusChange = formData.status > data.status ? "better" : formData.status < data.status ? "worse" : "notchanged";
        if (!data.reviewMode.spacedRepetition) {
            return {
                result: { statusChange },
                spacedRepetitionLevel: 0,
                nextReviewAt: now.plus(Duration.fromObject(typecastDurationObjectUnits(data.reviewMode.cycleBegin)))
            };
        }

        if (formData.status < 0.9) {
            return {
                result: { statusChange, oldRepetitionLevel: data.spacedRepetitionLevel },
                spacedRepetitionLevel: 0,
                nextReviewAt: now.plus(Duration.fromObject(typecastDurationObjectUnits(data.reviewMode.cycleBegin)))
            };
        }

        if (!data.reviewMode.cycleGoal) return null;
        const repetitionLevels = getRepetitionLevelsByReviewCycleInput(data.reviewMode.cycleBegin, data.reviewMode.cycleGoal);
        if (!repetitionLevels) return null;
        const newRepetitionLevel = Math.min(repetitionLevels.length - 1, (data.spacedRepetitionLevel ?? 0) + 1);
        const newCycleOptionDuration = cycleOptionDuration(repetitionLevels[newRepetitionLevel]);
        if (!newCycleOptionDuration) return null;
        return {
            result: { statusChange, oldRepetitionLevel: data.spacedRepetitionLevel },
            spacedRepetitionLevel: newRepetitionLevel,
            nextReviewAt: now.plus(newCycleOptionDuration)
        };
    }

    const eventBus = useEventBus();

    const saveReview = () => {
        if (!isValidForm()) return;
        const calculationResult = calculateNextReviewDate();
        if (!calculationResult) return;
        if(data.type === "completable" && formData.completion === 1) {
            calculationResult.result.completed = true;
        }
        setSaving(true);
        commit({
            variables: {
                input: {
                    goalId: data.id,
                    completion: formData.completion ?? 0,
                    status: formData.status,
                    goalArchived: !!calculationResult.result.completed,
                    nextReviewAt: setDefaultHour(calculationResult.nextReviewAt).toISO(),
                    comment: formData.comment,
                    spacedRepetitionLevel: calculationResult.spacedRepetitionLevel
                }
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.REVIEW_CREATED))
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED_NEXT_REVIEW))
                if(calculationResult.result.completed) {
                    eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_ARCHIVED))
                }
                setSaving(false);
                reviewingStatusRef.current = ReviewingStatus.SUCCESS;
                setReviewDateCalculationResult(calculationResult.result);
                navigate("");
            },
            onError: error => {
                console.error(error)
                setSaving(false);
            }
        })
    }

    return <>
        <MainGrid>
            <Grid item md={1} lg={2} />

            <Grid item md={10} lg={8}>
                <GoalDetailsReviewStatus goal={data} status={reviewingStatus} reviewDateCalculationResult={reviewDateCalculationResult} />
                {reviewingStatus === ReviewingStatus.REVIEWING && <GoalDetailsReviewForm saving={saving} goal={data} saveReview={() => saveReview()} formHandlers={formHandlers} />}
                <Box sx={{ margin: "1rem 0 0 0" }}>
                    <GoalDetailsReviews goal={data} />
                </Box>
            </Grid>
            <Grid item md={1} lg={2} />
        </MainGrid>
    </>
}
export default GoalDetailsReview;