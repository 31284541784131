import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import useAccount, { GUEST_ACCOUNT_AFFIRMED_TERMS_LOCALSTORAGE_NAME } from "authentication/_hooks/useAccount";
import AppEmph from "common/AppEmph";
import AppLink from "common/AppLink";
import AppRouterLink from "common/AppRouterLink";
// import { Box } from "@mui/system";
import DialogContentApp from "common/DialogContentApp";
import DialogContentParagraph from "common/DialogContentParagraph";
import useMount from "common/useMount";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AffirmTermsDialog = ({ onClose }: { onClose: (affirmed_: boolean) => void }) => {

    const [showDialog, setShowDialog] = useState(true);
    const [affirmed, setAffirmed] = useState(false);

    const close = () => {
        setShowDialog(false);
    }

    const onExited = () => {
        setShowDialog(true);
        onClose(affirmed);
    }

    return <Dialog open={showDialog} TransitionProps={{ onExited: onExited }} data-cy="modal-affirmTerms">
        <DialogTitle>
            Willkommen bei vAlign Goals
        </DialogTitle>
        <DialogContentApp>
            <DialogContentParagraph>
                <AppEmph>Du nutzt vAlign Goals als Gast</AppEmph> (<AppLink data-cy="guestPrivacyLink" openInNewTab to="/privacy#guestaccount">mehr Informationen</AppLink>). So kannst du unkompliziert und ohne Registrierung starten.
            </DialogContentParagraph>
            <DialogContentParagraph>
                Deine Daten bleiben gespeichert, auch wenn du den Browser schließt. Allerdings behalten wir uns vor, alle Daten wieder zu löschen, wenn dein Gastkonto 3 Monate nicht genutzt wird.
            </DialogContentParagraph>
            <DialogContentParagraph>
                <AppEmph>Sobald du richtig mit vAlign arbeitest, solltest du ein <AppRouterLink to="/login/signup" onClick={close}>kostenloses Nutzerkonto erstellen</AppRouterLink>.</AppEmph> Das ist sicherer und bietet Funktionen wie den E-Mail-Reminder, die du als Gast nicht nutzen kannst.
            </DialogContentParagraph>
            <DialogContentParagraph>
                {/* <Box sx={{ fontSize: "0.9rem", lineHeight: "1.3rem" }}> */}
                Wenn du fortfährst, stimmst du damit den
                <> </><AppLink data-cy="agbLink" openInNewTab to="/documents/valign_goals_agb.pdf">Nutzungsbedingungen (AGB)</AppLink>,
                der <AppLink data-cy="privacyLink" openInNewTab to="/privacy">Datenschutzerklärung</AppLink><> </>
                und der <AppLink data-cy="avvLink" openInNewTab to="/documents/valign_goals_avv.pdf">Vereinbarung zur Auftragsverarbeitung</AppLink><> </>
                zu.
                {/* </Box> */}
            </DialogContentParagraph>
        </DialogContentApp>
        <DialogActions>
            <Button data-cy="agreeButton" onClick={() => {
                setAffirmed(true);
                close();
            }}>Zustimmen und fortfahren</Button>
        </DialogActions>
    </Dialog>
}

const AffirmTerms = ({ children }: { children: React.ReactNode }) => {
    const { mountComponent, unmount } = useMount();
    const [mounted, setMounted] = useState(false);
    const location = useLocation();
    const uncheckedPath = /^\/privacy|^\/imprint|^\/login/.test(location.pathname);
    const guestAlreadyAffirmed = !!window.localStorage.getItem(GUEST_ACCOUNT_AFFIRMED_TERMS_LOCALSTORAGE_NAME);
    const { authenticatedAccount, accountExists, getAndSetGuestAccountId } = useAccount();
    const alreadyAffirmed = guestAlreadyAffirmed || authenticatedAccount || accountExists;

    useEffect(() => {
        if (uncheckedPath || alreadyAffirmed) {
            if (!mounted) return;
            unmount();
            setMounted(false);
            return;
        }
        if (mounted) return;
        setMounted(true);
        mountComponent(<AffirmTermsDialog onClose={affirmed => {
            if (affirmed) {
                window.localStorage.setItem(GUEST_ACCOUNT_AFFIRMED_TERMS_LOCALSTORAGE_NAME, "1");
                getAndSetGuestAccountId();
            }
            unmount();
            setMounted(false);
        }} />)
    }, [mounted, setMounted, mountComponent, location, unmount, alreadyAffirmed, uncheckedPath, getAndSetGuestAccountId])

    return <>
        {children}
    </>;
}
export default AffirmTerms;