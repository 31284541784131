import { FormControl } from "@mui/material";
import AppTextField from "forms/AppTextField";
import { GoalDetailsDescription_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsDescription_goal.graphql";
import { GoalDescriptionEditorCommonProps } from "main/goals/goal_edit/GoalDescriptionEditor";
import { useFragment } from "react-relay";
import { useRef, useState } from "react";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import useEventBus, { EventTypes } from "app/useEventBus";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsDescription = ({ goal }: { goal: GoalDetailsDescription_goal$key }) => {

    const data = useFragment(graphql`
        fragment GoalDetailsDescription_goal on Goal {
            id
            description
        }
    `, goal)

    const [value, setValue] = useState<string | null>(data.description);
    const [saving, setSaving] = useState(false);
    const ref: React.RefObject<HTMLDivElement> = useRef(null);

    const [commit] = useUpdateGoal();
    const { dispatchFormFieldSavingEvent, dispatchFormFieldSavingSuccessEvent, dispatchFormFieldSavingErrorEvent } = useFormFieldSavingEvents(ref);
    
    const eventBus = useEventBus();
    const update = () => {
        if (value === data.description) return;
        setSaving(true);
        dispatchFormFieldSavingEvent();
        commit({
            variables: {
                input: {
                    id: data.id,
                    description: value
                }
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                dispatchFormFieldSavingSuccessEvent();
                setSaving(false);
            },
            onError: e => {
                console.error(e);
                dispatchFormFieldSavingErrorEvent();
                setSaving(false);
            }
        })
    }

    return <>
        <FormControl sx={{ width: "100%" }} component="div" ref={ref}>
            <AppTextField
                label="Beschreibung"
                readonlyStyle
                readonly={saving}
                eventSource={ref.current}
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={update}
                {...GoalDescriptionEditorCommonProps}
            />
        </FormControl>
    </>
}
export default GoalDetailsDescription;