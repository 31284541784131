import { useDeleteReviewMutation } from "main/review/__hooks/__generated__/useDeleteReviewMutation.graphql";
import { useMutation } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const useDeleteReview = () => {
    return useMutation<useDeleteReviewMutation>(graphql`
        mutation useDeleteReviewMutation(
            $input: DeleteReviewInput!
            $connections: [ID!]!
          ) {
            deleteReview(input: $input) {
              deletedId @deleteEdge(connections: $connections)
            }
        }
    `)
}
export default useDeleteReview;