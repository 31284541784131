import { Box, SxProps } from "@mui/system";
import React from "react";
import WarningIcon from '@mui/icons-material/WarningRounded';
import { useTheme } from "@mui/material";

export const WarningWithIconEmph = ({ children }: { children: React.ReactNode }) => <Box component="span" sx={{ fontWeight: 700 }}>{children}</Box>

const WarningWithIcon = ({ children, sx, title = <>Wichtiger Hinweis</> }: { children: React.ReactNode, sx?: SxProps, title?: React.ReactNode }) => {
    const theme = useTheme();
    return <Box sx={Object.assign({ display: "flex", alignItems: "flex-start" }, sx ?? {})}>
        <Box sx={{ margin: "0 0.5rem 0 0" }}>
            <WarningIcon sx={{ color: theme.palette.warning.main, fontSize: "1.5rem" }} />
        </Box>
        <Box sx={{ fontFamily: "Open Sans", fontStyle: "italic", padding: "0 1rem 0 0", boxSizing: "border-box" }}>
            {title && <Box sx={{
                fontWeight: 700,
                fontSize: "0.9rem",
                color: theme.palette.warning.main,
                height: "1.5rem",
                display: "flex",
                alignItems: "center"
            }}>{title}</Box>}
            <Box sx={{ fontWeight: 400, fontSize: "0.9rem", lineHeight: "1.25rem" }}>
                {children}
            </Box>
        </Box>
    </Box>
}
export default WarningWithIcon;