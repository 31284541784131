/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateGoalInput = {
  completed?: boolean | null,
  completion?: number | null,
  description?: string | null,
  dueDate?: string | null,
  nextReviewAt: string,
  reviewMode: ReviewModeInput,
  status: number,
  title: string,
  type: GoalType,
};

export type ReviewModeInput = {
  cycleBegin: ReviewCycleInput,
  cycleGoal?: ReviewCycleInput | null,
  spacedRepetition: boolean,
};

export type ReviewCycleInput = {
  days?: number | null,
  months?: number | null,
};

export enum GoalType {
  completable = "completable",
  continuous = "continuous",
}


export type CreateGoalResponse = {
  __typename: "CreateGoalResponse",
  goal: Goal,
};

export type Goal = {
  __typename: "Goal",
  archived?: boolean | null,
  completed?: boolean | null,
  completion?: number | null,
  createdAt: string,
  currentReviewAt: string,
  description?: string | null,
  dueDate?: string | null,
  id: string,
  nextReviewAt: string,
  readableId: number,
  reviewMode: ReviewMode,
  reviews?: ReviewConnection | null,
  spacedRepetitionLevel: number,
  status: number,
  title: string,
  type: GoalType,
  updatedAt: string,
};

export type Node = {
  __typename: "Node",
  id: string,
};

export type Review = {
  __typename: "Review",
  automatedReview?: boolean | null,
  comment?: string | null,
  completion?: number | null,
  createdAt: string,
  id: string,
  status: number,
  updatedAt: string,
};

export type ReviewMode = {
  __typename: "ReviewMode",
  cycleBegin: ReviewCycle,
  cycleGoal?: ReviewCycle | null,
  spacedRepetition: boolean,
};

export type ReviewCycle = {
  __typename: "ReviewCycle",
  days?: number | null,
  months?: number | null,
};

export type ReviewConnection = {
  __typename: "ReviewConnection",
  edges?:  Array<ReviewEdge | null > | null,
  pageInfo: PageInfo,
};

export type ReviewEdge = {
  __typename: "ReviewEdge",
  cursor: string,
  node?: Review | null,
};

export type PageInfo = {
  __typename: "PageInfo",
  endCursor: string,
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  startCursor: string,
};

export type CreateReviewInput = {
  comment?: string | null,
  completion?: number | null,
  goalArchived?: boolean | null,
  goalId: string,
  nextReviewAt: string,
  spacedRepetitionLevel?: number | null,
  status: number,
};

export type CreateReviewResponse = {
  __typename: "CreateReviewResponse",
  goal: Goal,
  review: Review,
};

export type DeleteAccountResponse = {
  __typename: "DeleteAccountResponse",
  deletedAccount: string,
};

export type DeleteGoalInput = {
  id: string,
};

export type DeleteGoalResponse = {
  __typename: "DeleteGoalResponse",
  deletedId: string,
};

export type DeleteReviewInput = {
  id: string,
};

export type DeleteReviewResponse = {
  __typename: "DeleteReviewResponse",
  deletedId: string,
};

export type UpdateGoalInput = {
  archived?: boolean | null,
  completion?: number | null,
  description?: string | null,
  dueDate?: string | null,
  id: string,
  nextReviewAt?: string | null,
  reviewMode?: ReviewModeInput | null,
  spacedRepetitionLevel?: number | null,
  status?: number | null,
  title?: string | null,
};

export type GetGlobalsReturn = {
  __typename: "GetGlobalsReturn",
  archiveCount: number,
  dueForReviewCount: number,
  goalsCount: number,
};

export type GetGoalInput = {
  id: string,
};

export type GetGoalByReadableIdInput = {
  readableId: number,
};

export type GoalConnection = {
  __typename: "GoalConnection",
  edges?:  Array<GoalEdge | null > | null,
  pageInfo: PageInfo,
};

export type GoalEdge = {
  __typename: "GoalEdge",
  cursor: string,
  node?: Goal | null,
};

export type CreateGoalMutationVariables = {
  input: CreateGoalInput,
};

export type CreateGoalMutation = {
  createGoal:  {
    __typename: "CreateGoalResponse",
    goal:  {
      __typename: "Goal",
      archived?: boolean | null,
      completed?: boolean | null,
      completion?: number | null,
      createdAt: string,
      currentReviewAt: string,
      description?: string | null,
      dueDate?: string | null,
      id: string,
      nextReviewAt: string,
      readableId: number,
      reviewMode:  {
        __typename: "ReviewMode",
        cycleBegin:  {
          __typename: "ReviewCycle",
          days?: number | null,
          months?: number | null,
        },
        cycleGoal?:  {
          __typename: "ReviewCycle",
          days?: number | null,
          months?: number | null,
        } | null,
        spacedRepetition: boolean,
      },
      reviews?:  {
        __typename: "ReviewConnection",
        edges?:  Array< {
          __typename: "ReviewEdge",
          cursor: string,
          node?:  {
            __typename: "Review",
            automatedReview?: boolean | null,
            comment?: string | null,
            completion?: number | null,
            createdAt: string,
            id: string,
            status: number,
            updatedAt: string,
          } | null,
        } | null > | null,
        pageInfo:  {
          __typename: "PageInfo",
          endCursor: string,
          hasNextPage: boolean,
          hasPreviousPage: boolean,
          startCursor: string,
        },
      } | null,
      spacedRepetitionLevel: number,
      status: number,
      title: string,
      type: GoalType,
      updatedAt: string,
    },
  },
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
};

export type CreateReviewMutation = {
  createReview:  {
    __typename: "CreateReviewResponse",
    goal:  {
      __typename: "Goal",
      archived?: boolean | null,
      completed?: boolean | null,
      completion?: number | null,
      createdAt: string,
      currentReviewAt: string,
      description?: string | null,
      dueDate?: string | null,
      id: string,
      nextReviewAt: string,
      readableId: number,
      reviewMode:  {
        __typename: "ReviewMode",
        cycleBegin:  {
          __typename: "ReviewCycle",
          days?: number | null,
          months?: number | null,
        },
        cycleGoal?:  {
          __typename: "ReviewCycle",
          days?: number | null,
          months?: number | null,
        } | null,
        spacedRepetition: boolean,
      },
      reviews?:  {
        __typename: "ReviewConnection",
        edges?:  Array< {
          __typename: "ReviewEdge",
          cursor: string,
          node?:  {
            __typename: "Review",
            automatedReview?: boolean | null,
            comment?: string | null,
            completion?: number | null,
            createdAt: string,
            id: string,
            status: number,
            updatedAt: string,
          } | null,
        } | null > | null,
        pageInfo:  {
          __typename: "PageInfo",
          endCursor: string,
          hasNextPage: boolean,
          hasPreviousPage: boolean,
          startCursor: string,
        },
      } | null,
      spacedRepetitionLevel: number,
      status: number,
      title: string,
      type: GoalType,
      updatedAt: string,
    },
    review:  {
      __typename: "Review",
      automatedReview?: boolean | null,
      comment?: string | null,
      completion?: number | null,
      createdAt: string,
      id: string,
      status: number,
      updatedAt: string,
    },
  },
};

export type DeleteAccountMutation = {
  deleteAccount:  {
    __typename: "DeleteAccountResponse",
    deletedAccount: string,
  },
};

export type DeleteGoalMutationVariables = {
  input: DeleteGoalInput,
};

export type DeleteGoalMutation = {
  deleteGoal:  {
    __typename: "DeleteGoalResponse",
    deletedId: string,
  },
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
};

export type DeleteReviewMutation = {
  deleteReview:  {
    __typename: "DeleteReviewResponse",
    deletedId: string,
  },
};

export type UpdateGoalMutationVariables = {
  input: UpdateGoalInput,
};

export type UpdateGoalMutation = {
  updateGoal:  {
    __typename: "Goal",
    archived?: boolean | null,
    completed?: boolean | null,
    completion?: number | null,
    createdAt: string,
    currentReviewAt: string,
    description?: string | null,
    dueDate?: string | null,
    id: string,
    nextReviewAt: string,
    readableId: number,
    reviewMode:  {
      __typename: "ReviewMode",
      cycleBegin:  {
        __typename: "ReviewCycle",
        days?: number | null,
        months?: number | null,
      },
      cycleGoal?:  {
        __typename: "ReviewCycle",
        days?: number | null,
        months?: number | null,
      } | null,
      spacedRepetition: boolean,
    },
    reviews?:  {
      __typename: "ReviewConnection",
      edges?:  Array< {
        __typename: "ReviewEdge",
        cursor: string,
        node?:  {
          __typename: "Review",
          automatedReview?: boolean | null,
          comment?: string | null,
          completion?: number | null,
          createdAt: string,
          id: string,
          status: number,
          updatedAt: string,
        } | null,
      } | null > | null,
      pageInfo:  {
        __typename: "PageInfo",
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      },
    } | null,
    spacedRepetitionLevel: number,
    status: number,
    title: string,
    type: GoalType,
    updatedAt: string,
  },
};

export type GetGlobalsQueryVariables = {
  reviewDueBefore?: string | null,
};

export type GetGlobalsQuery = {
  getGlobals?:  {
    __typename: "GetGlobalsReturn",
    archiveCount: number,
    dueForReviewCount: number,
    goalsCount: number,
  } | null,
};

export type GetGoalQueryVariables = {
  input: GetGoalInput,
};

export type GetGoalQuery = {
  getGoal?:  {
    __typename: "Goal",
    archived?: boolean | null,
    completed?: boolean | null,
    completion?: number | null,
    createdAt: string,
    currentReviewAt: string,
    description?: string | null,
    dueDate?: string | null,
    id: string,
    nextReviewAt: string,
    readableId: number,
    reviewMode:  {
      __typename: "ReviewMode",
      cycleBegin:  {
        __typename: "ReviewCycle",
        days?: number | null,
        months?: number | null,
      },
      cycleGoal?:  {
        __typename: "ReviewCycle",
        days?: number | null,
        months?: number | null,
      } | null,
      spacedRepetition: boolean,
    },
    reviews?:  {
      __typename: "ReviewConnection",
      edges?:  Array< {
        __typename: "ReviewEdge",
        cursor: string,
        node?:  {
          __typename: "Review",
          automatedReview?: boolean | null,
          comment?: string | null,
          completion?: number | null,
          createdAt: string,
          id: string,
          status: number,
          updatedAt: string,
        } | null,
      } | null > | null,
      pageInfo:  {
        __typename: "PageInfo",
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      },
    } | null,
    spacedRepetitionLevel: number,
    status: number,
    title: string,
    type: GoalType,
    updatedAt: string,
  } | null,
};

export type GetGoalByReadableIdQueryVariables = {
  input: GetGoalByReadableIdInput,
};

export type GetGoalByReadableIdQuery = {
  getGoalByReadableId?:  {
    __typename: "Goal",
    archived?: boolean | null,
    completed?: boolean | null,
    completion?: number | null,
    createdAt: string,
    currentReviewAt: string,
    description?: string | null,
    dueDate?: string | null,
    id: string,
    nextReviewAt: string,
    readableId: number,
    reviewMode:  {
      __typename: "ReviewMode",
      cycleBegin:  {
        __typename: "ReviewCycle",
        days?: number | null,
        months?: number | null,
      },
      cycleGoal?:  {
        __typename: "ReviewCycle",
        days?: number | null,
        months?: number | null,
      } | null,
      spacedRepetition: boolean,
    },
    reviews?:  {
      __typename: "ReviewConnection",
      edges?:  Array< {
        __typename: "ReviewEdge",
        cursor: string,
        node?:  {
          __typename: "Review",
          automatedReview?: boolean | null,
          comment?: string | null,
          completion?: number | null,
          createdAt: string,
          id: string,
          status: number,
          updatedAt: string,
        } | null,
      } | null > | null,
      pageInfo:  {
        __typename: "PageInfo",
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      },
    } | null,
    spacedRepetitionLevel: number,
    status: number,
    title: string,
    type: GoalType,
    updatedAt: string,
  } | null,
};

export type ListGoalsQueryVariables = {
  after?: string | null,
  archived?: boolean | null,
  before?: string | null,
  first?: number | null,
  last?: number | null,
  sortBefore?: string | null,
};

export type ListGoalsQuery = {
  listGoals?:  {
    __typename: "GoalConnection",
    edges?:  Array< {
      __typename: "GoalEdge",
      cursor: string,
      node?:  {
        __typename: "Goal",
        archived?: boolean | null,
        completed?: boolean | null,
        completion?: number | null,
        createdAt: string,
        currentReviewAt: string,
        description?: string | null,
        dueDate?: string | null,
        id: string,
        nextReviewAt: string,
        readableId: number,
        reviewMode:  {
          __typename: "ReviewMode",
          cycleBegin:  {
            __typename: "ReviewCycle",
            days?: number | null,
            months?: number | null,
          },
          cycleGoal?:  {
            __typename: "ReviewCycle",
            days?: number | null,
            months?: number | null,
          } | null,
          spacedRepetition: boolean,
        },
        reviews?:  {
          __typename: "ReviewConnection",
          edges?:  Array< {
            __typename: "ReviewEdge",
            cursor: string,
            node?:  {
              __typename: "Review",
              automatedReview?: boolean | null,
              comment?: string | null,
              completion?: number | null,
              createdAt: string,
              id: string,
              status: number,
              updatedAt: string,
            } | null,
          } | null > | null,
          pageInfo:  {
            __typename: "PageInfo",
            endCursor: string,
            hasNextPage: boolean,
            hasPreviousPage: boolean,
            startCursor: string,
          },
        } | null,
        spacedRepetitionLevel: number,
        status: number,
        title: string,
        type: GoalType,
        updatedAt: string,
      } | null,
    } | null > | null,
    pageInfo:  {
      __typename: "PageInfo",
      endCursor: string,
      hasNextPage: boolean,
      hasPreviousPage: boolean,
      startCursor: string,
    },
  } | null,
};

export type NodeQueryVariables = {
  id: string,
};

export type NodeQuery = {
  node: ( {
      __typename: "Goal",
      id: string,
      archived?: boolean | null,
      completed?: boolean | null,
      completion?: number | null,
      createdAt: string,
      currentReviewAt: string,
      description?: string | null,
      dueDate?: string | null,
      nextReviewAt: string,
      readableId: number,
      reviewMode:  {
        __typename: string,
        cycleBegin:  {
          __typename: string,
          days?: number | null,
          months?: number | null,
        },
        cycleGoal?:  {
          __typename: string,
          days?: number | null,
          months?: number | null,
        } | null,
        spacedRepetition: boolean,
      },
      reviews?:  {
        __typename: string,
        edges?:  Array< {
          __typename: string,
          cursor: string,
          node?:  {
            __typename: string,
            automatedReview?: boolean | null,
            comment?: string | null,
            completion?: number | null,
            createdAt: string,
            id: string,
            status: number,
            updatedAt: string,
          } | null,
        } | null > | null,
        pageInfo:  {
          __typename: string,
          endCursor: string,
          hasNextPage: boolean,
          hasPreviousPage: boolean,
          startCursor: string,
        },
      } | null,
      spacedRepetitionLevel: number,
      status: number,
      title: string,
      type: GoalType,
      updatedAt: string,
    } | {
      __typename: "Review",
      id: string,
      automatedReview?: boolean | null,
      comment?: string | null,
      completion?: number | null,
      createdAt: string,
      status: number,
      updatedAt: string,
    }
  ) | null,
};

export type TestStageLoginQueryVariables = {
  accessCode?: string | null,
};

export type TestStageLoginQuery = {
  testStageLogin: boolean,
};
