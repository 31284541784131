/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalDetailsReviewForm_goal = {
    readonly type: GoalType;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsReviewGoalStatus_goal">;
    readonly " $refType": "GoalDetailsReviewForm_goal";
};
export type GoalDetailsReviewForm_goal$data = GoalDetailsReviewForm_goal;
export type GoalDetailsReviewForm_goal$key = {
    readonly " $data"?: GoalDetailsReviewForm_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsReviewForm_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsReviewForm_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsReviewGoalStatus_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '20cae1a09f8451062715efb44e02d1e6';
export default node;
