import { Stack } from "@mui/material";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import { GoalDetailsReviews_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsReviews_goal.graphql";
import ReviewCard from "main/review/review_card/ReviewCard";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsReviews = ({ goal }: { goal: GoalDetailsReviews_goal$key }) => {

    const data = useFragment(graphql`
        fragment GoalDetailsReviews_goal on Goal {
            ...ReviewCard_goal
            nextReviewAt
            reviews(first: $first, after: $after, last: $last, before: $before) @connection(key: "GoalDetailsReviews_reviews") {
                edges {
                    node {
                        ...ReviewCard_review
                    }
                }
            }
        }
    `, goal)

    return <StandardPaper>
        <StandardPaperTitle>Bisherige Reviews</StandardPaperTitle>
        <Stack direction="column" spacing={"0.5rem"}>
            {data.reviews?.edges?.map((edge, i) => edge?.node && <ReviewCard review={edge.node} goal={data} key={i} />)}
        </Stack>
    </StandardPaper>
}
export default GoalDetailsReviews;