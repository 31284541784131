import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { GlobalsQueryProviderQuery } from "common/GlobalsQueryProvider";
import ReviewIcon from "common/_icons/ReviewIcon";
import { GlobalsQueryProviderQuery as GlobalsQueryProviderQueryType } from "common/__generated__/GlobalsQueryProviderQuery.graphql";
import MainTitle from "main/MainTitle"
import { ReviewMainTitleMain_getGlobalsReturn$key } from "main/review/__generated__/ReviewMainTitleMain_getGlobalsReturn.graphql";
import React from "react";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import useGlobalsQueryLoader from "_hooks/useGlobalsQueryLoader";
const graphql = require("babel-plugin-relay/macro");

const ReviewMainTitleMain = ({ queryRef }: { queryRef: PreloadedQuery<GlobalsQueryProviderQueryType> }) => {
    const queryData = usePreloadedQuery(GlobalsQueryProviderQuery, queryRef);
    const data = useFragment<ReviewMainTitleMain_getGlobalsReturn$key>(graphql`
        fragment ReviewMainTitleMain_getGlobalsReturn on GetGlobalsReturn {
            dueForReviewCount
        }
    `, queryData.getGlobals)
    return (() => {
        if (!data) return <></>;
        if (data.dueForReviewCount === 0) return <Box sx={{height: "2rem", display: "flex", alignItems: "center", margin: "0 0 0 0.5rem"}}>
            <ReviewIcon color="success" />
        </Box>
        return <>({data.dueForReviewCount} verbleibend)</>
    })()
}

const ReviewMainTitle = () => {
    const [queryRef] = useGlobalsQueryLoader();
    return <MainTitle>Review <React.Suspense fallback={<><CircularProgress color="primary" /></>}>
        {queryRef && <ReviewMainTitleMain queryRef={queryRef} />}
    </React.Suspense></MainTitle>
}
export default ReviewMainTitle;