import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 700;
    opacity: 0.8;
    letter-spacing: 0.2em;
    margin: 1.5em 0 0 0;
    padding: 1em 1.2em 1em 1.5em;
    border-bottom: 1px solid rgba(255,255,255,0.07);
    background: rgba(0,0,0,0.1);
`

const NavCategory = ({
    children
}: {
    children: React.ReactNode
}) => {
    return (
        <Container>
            {children}
        </Container>
    )
}
export default NavCategory;