import { Stack, Typography } from "@mui/material";
import { GoalType } from "graphql/API";
import { DateTime } from "luxon";
import GoalStatusField from "main/goals/common/fields/GoalStatusField";
import GoalDueDateField from "main/goals/common/fields/GoalDueDateField";
import GoalCardTitleFieldContainer from "main/goals/goal_card/GoalCardTitleFieldContainer";
import { GoalCardTitle_goal$key } from "main/goals/goal_card/__generated__/GoalCardTitle_goal.graphql";
import { useFragment } from "react-relay";
import GoalReviewField from "main/goals/common/fields/GoalReviewField";
const graphql = require("babel-plugin-relay/macro");

const GoalCardTitle = ({ goal, compactView, readableId, title, nextReviewAt, type, dueDate, completion }: { goal: GoalCardTitle_goal$key, compactView: boolean, readableId: string, title: string, nextReviewAt: DateTime, type: GoalType, dueDate?: DateTime, completion: number | null }) => {

    const data = useFragment(graphql`
        fragment GoalCardTitle_goal on Goal {
            readableId
            title
            description
            ...GoalStatusField_goal
        }
    `, goal)

    return <Stack direction="column" sx={{ padding: "0.9rem 2rem 1.25rem 0rem" }}>
        {!compactView && <>
            <Typography variant="body2" sx={{ fontSize: "1rem", lineHeight: "auto", fontWeight: "500"}}>
                {data.title}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.875rem", lineHeight: "auto", fontWeight: "300", margin: "0.2rem 0 0 0"}}>
                {data.description && data.description.length > 200 ? data.description?.substr(0, 200) + "..." : data.description}
            </Typography>
        </>}
        {compactView && <Stack direction="row" spacing={2} sx={{ margin: "0 0 0.25rem 0" }}>
            <Typography variant="body2" sx={{ fontSize: "1rem", lineHeight: "auto", fontWeight: "300", whiteSpace: "nowrap", margin: "0 0 0.25rem 0" }}>
                {data.readableId}
            </Typography>
            <Stack direction="column">
                <Typography variant="body2" sx={{ fontSize: "1rem", lineHeight: "auto", fontWeight: "500", padding: compactView ? "0 2rem 0 0" : undefined }}>
                    {data.title}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "0.875rem", lineHeight: "auto", fontWeight: "300", padding: compactView ? "0 2rem 0 0" : undefined }}>
                    {data.description && data.description.length > 200 ? data.description?.substr(0, 200) + "..." : data.description}
                </Typography>
            </Stack>
        </Stack>
        }
        {/* <Stack flexWrap="wrap" direction="row" sx={{ margin: "0.5rem 0 0rem 0" }} spacing={1} rowGap={0.5}>
            {Math.random() > 0.7 && <GoalCategory label="Daily Standup" color="rgba(200,20,100,1)" />}
            {Math.random() > 0.7 && <GoalCategory label="Retrospektive" />}
            {Math.random() > 0.7 && <GoalCategory label="Sprint Planning" color="rgba(30,150,150)" />}
        </Stack> */}
        {compactView && <Stack direction="row" sx={{ margin: "0.75rem 0 0.25rem 0" }}>
            <GoalCardTitleFieldContainer startContainer width="7rem">
                <GoalReviewField reviewAt={nextReviewAt} />
            </GoalCardTitleFieldContainer>
            <GoalCardTitleFieldContainer width="10rem">
                <GoalStatusField goal={data} />
            </GoalCardTitleFieldContainer>
            {dueDate && <GoalCardTitleFieldContainer width="8rem" sx={{ flexGrow: 1 }}>
                <GoalDueDateField dueDate={dueDate} />
            </GoalCardTitleFieldContainer>
            }
        </Stack>}
    </Stack>
}
export default GoalCardTitle;