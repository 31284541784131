import { Divider } from "@mui/material";
import { LOCAL_OR_TEST_ENVIRONMENT } from "common/common";
import DevelopmentToolsTime from "toolbar/development_tools/DevelopmentToolsTime";

const DevelopmentTools = () => {
    return LOCAL_OR_TEST_ENVIRONMENT ? <>
        <Divider />
        <DevelopmentToolsTime />
    </> : <></>
}
export default DevelopmentTools;