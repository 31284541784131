import { Box } from "@mui/system";

const GoalField = ({ children, width = "8rem" }: { children: React.ReactNode, width?: string }) => {
    return <Box sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        width: width,
        boxSizing: "border-box"
    }}>
        {children}
    </Box>
}
export default GoalField;