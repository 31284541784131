import { Box } from "@mui/material"

const MainContainer = ({ children }: { children: React.ReactNode }) => {
    return <Box sx={{
        px: "1.7rem",
        width: "100%",
        boxSizing: "border-box",
        position: "relative"
    }}>
        {children}
    </Box>
}
export default MainContainer;