import { Box } from "@mui/system";
import React from "react";

const GoalFieldTitle = ({ children }: { children: React.ReactNode }) => {
    return <Box sx={{
        display: "flex",
        alignItems: "flex-start",
        whiteSpace: "nowrap",
        height: "1.25rem",
        fontSize: "0.75rem",
        opacity: "0.8",
        fontWeight: "300"
    }}>
        {children}
    </Box>
}
export default GoalFieldTitle;