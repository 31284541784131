/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalDetailsCompletion_goal = {
    readonly " $fragmentRefs": FragmentRefs<"GoalStatusField_goal">;
    readonly " $refType": "GoalDetailsCompletion_goal";
};
export type GoalDetailsCompletion_goal$data = GoalDetailsCompletion_goal;
export type GoalDetailsCompletion_goal$key = {
    readonly " $data"?: GoalDetailsCompletion_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsCompletion_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsCompletion_goal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalStatusField_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = 'b95fbab66e5bcb3ad727cbf3d8881cf3';
export default node;
