/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GlobalsQueryProviderQueryVariables = {
    reviewDueBefore: string;
};
export type GlobalsQueryProviderQueryResponse = {
    readonly getGlobals: {
        readonly " $fragmentRefs": FragmentRefs<"ReviewNavItemBadge_getGlobalsReturn" | "ReviewButton_getGlobalsReturn" | "ReviewMainTitleMain_getGlobalsReturn" | "GoalsCountBadge_getGlobalsReturn" | "ArchiveCountBadge_getGlobalsReturn">;
    } | null;
};
export type GlobalsQueryProviderQuery = {
    readonly response: GlobalsQueryProviderQueryResponse;
    readonly variables: GlobalsQueryProviderQueryVariables;
};



/*
query GlobalsQueryProviderQuery(
  $reviewDueBefore: String!
) {
  getGlobals(reviewDueBefore: $reviewDueBefore) {
    ...ReviewNavItemBadge_getGlobalsReturn
    ...ReviewButton_getGlobalsReturn
    ...ReviewMainTitleMain_getGlobalsReturn
    ...GoalsCountBadge_getGlobalsReturn
    ...ArchiveCountBadge_getGlobalsReturn
  }
}

fragment ArchiveCountBadge_getGlobalsReturn on GetGlobalsReturn {
  archiveCount
}

fragment GoalsCountBadge_getGlobalsReturn on GetGlobalsReturn {
  goalsCount
}

fragment ReviewButton_getGlobalsReturn on GetGlobalsReturn {
  dueForReviewCount
}

fragment ReviewMainTitleMain_getGlobalsReturn on GetGlobalsReturn {
  dueForReviewCount
}

fragment ReviewNavItemBadge_getGlobalsReturn on GetGlobalsReturn {
  dueForReviewCount
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reviewDueBefore"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "reviewDueBefore",
    "variableName": "reviewDueBefore"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalsQueryProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetGlobalsReturn",
        "kind": "LinkedField",
        "name": "getGlobals",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReviewNavItemBadge_getGlobalsReturn"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReviewButton_getGlobalsReturn"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReviewMainTitleMain_getGlobalsReturn"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GoalsCountBadge_getGlobalsReturn"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArchiveCountBadge_getGlobalsReturn"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalsQueryProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetGlobalsReturn",
        "kind": "LinkedField",
        "name": "getGlobals",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dueForReviewCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "goalsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archiveCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd09e84c27b0e0c09358b8b44b78cb5c",
    "id": null,
    "metadata": {},
    "name": "GlobalsQueryProviderQuery",
    "operationKind": "query",
    "text": "query GlobalsQueryProviderQuery(\n  $reviewDueBefore: String!\n) {\n  getGlobals(reviewDueBefore: $reviewDueBefore) {\n    ...ReviewNavItemBadge_getGlobalsReturn\n    ...ReviewButton_getGlobalsReturn\n    ...ReviewMainTitleMain_getGlobalsReturn\n    ...GoalsCountBadge_getGlobalsReturn\n    ...ArchiveCountBadge_getGlobalsReturn\n  }\n}\n\nfragment ArchiveCountBadge_getGlobalsReturn on GetGlobalsReturn {\n  archiveCount\n}\n\nfragment GoalsCountBadge_getGlobalsReturn on GetGlobalsReturn {\n  goalsCount\n}\n\nfragment ReviewButton_getGlobalsReturn on GetGlobalsReturn {\n  dueForReviewCount\n}\n\nfragment ReviewMainTitleMain_getGlobalsReturn on GetGlobalsReturn {\n  dueForReviewCount\n}\n\nfragment ReviewNavItemBadge_getGlobalsReturn on GetGlobalsReturn {\n  dueForReviewCount\n}\n"
  }
};
})();
(node as any).hash = 'f0d0434db3ff27c926f4409a7d94346e';
export default node;
