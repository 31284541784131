import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Alert, Slide, Snackbar, SnackbarCloseReason } from "@mui/material";

type ShowSnackbarParameters = {
    message: string | React.ReactNode;
}
type ShowSnackbarType = (parameters: ShowSnackbarParameters) => void;

export const SnackbarContext = React.createContext<ShowSnackbarType>((a: ShowSnackbarParameters) => console.error("no snackbar context"));

const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {

    const [message, setMessage] = useState<string | React.ReactNode | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const handleClose = (e: any, reason?: SnackbarCloseReason) => {
        if (reason && reason === "clickaway") return;
        setOpen(false);
    }

    return <SnackbarContext.Provider value={({ message }: ShowSnackbarParameters) => {
        setMessage(message);
        setOpen(true);
    }}>
        <div>
            <Snackbar
                autoHideDuration={4000}
                TransitionComponent={Slide}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert variant="filled" onClose={handleClose} sx={{
                    background: `${theme.palette.primary.main}`
                }}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </div>
    </SnackbarContext.Provider>
}
export default SnackbarProvider;