import { Stack } from "@mui/material";
import GoalCard from "main/goals/goal_card/GoalCard";
import { GoalsList_goalConnection$key } from "main/goals/__generated__/GoalsList_goalConnection.graphql";
import React, { useLayoutEffect, useRef, useState } from "react";
import { usePaginationFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

export const GoalsListListGoalsQuery = graphql`
    query GoalsListListGoalsQuery($first: Int, $after: String, $last: Int, $before: String, $sortBefore: String, $archived: Boolean) {
        ...GoalsList_goalConnection
    }
`

export const GoalsListPaginationQuery = graphql`
    fragment GoalsList_goalConnection on Query 
    @refetchable(queryName: "GoalsListPaginationQuery") {
        listGoals(first: $first, after: $after, last: $last, before: $before, sortBefore: $sortBefore, archived: $archived) 
            @connection(key: "GoalsList_listGoals") {
                __id
                edges {
                    node {
                        ...GoalCard_goal
                    }
                }
            }
    }
`

const GoalsList = ({ queryData, emptyPlaceholder, showReviewButton }: {
    queryData: GoalsList_goalConnection$key,
    emptyPlaceholder: React.ReactNode,
    showReviewButton?: boolean
}) => {
    const [containerWidth, setContainerWidth] = useState(2000);
    const ref = useRef<HTMLDivElement>();
    const handleResize = () => {
        if (!ref.current) return;
        setContainerWidth(ref.current?.getBoundingClientRect().width);
    }
    useLayoutEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize)
    })
    const { data } = usePaginationFragment(GoalsListPaginationQuery, queryData);
    
    return <>
        <Stack direction="column" spacing={"0.5em"} ref={ref}>
            {data.listGoals?.edges?.map((edge, i: number) => edge?.node && <GoalCard goal={edge.node} key={i} connectionId={data.listGoals?.__id} compactView={containerWidth < 850} {...{showReviewButton}}/>)}
        </Stack>
        {data.listGoals?.edges?.length === 0 && emptyPlaceholder}
    </>
}

export default GoalsList;