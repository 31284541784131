import NavCategory from "./NavCategory";
import List from "@mui/material/List";

const NavBlock = ({
    categoryName,
    children
}: {
    categoryName: string,
    children?: React.ReactNode
}) => {
    return (<>
        <NavCategory>
            {categoryName}
        </NavCategory>
        <List disablePadding>
            {children}
        </List>
    </>)
}
export default NavBlock;