import { Button, CircularProgress, Stack } from "@mui/material";
import { Box } from "@mui/system";
import AppTextField from "forms/AppTextField";
import { useState } from "react";
import useWaitDuration from "common/useWaitDuration";
import LoginError from "authentication/LoginError";
import useRegexValidations from "common/useRegexValidations";
import useAccount from "authentication/_hooks/useAccount";
import AppRouterLink from "common/AppRouterLink";
import { useSearchParams } from "react-router-dom";

const LoginForm = () => {

    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [state, setState] = useState<"default" | "sending" | "error" | "notAuthorizedError">("default");

    const { validateEmail } = useRegexValidations();

    const validEmail = email !== null && validateEmail(email);
    const validPassword = password !== null && password.length >= 8;
    const validForm = validEmail && validPassword;
    const formInputsDisabled = state === "sending";
    const errorsExist = state === "error" || state === "notAuthorizedError";

    const waitDuration = useWaitDuration();
    const [searchParams] = useSearchParams();
    const redirectPath = searchParams.get("redirect");
    const { login, setLoggingIn } = useAccount();

    const submit = async () => {
        if (!validForm || state === "sending") return;
        const submitTime = Date.now();

        try {
            setState("sending");
            setLoggingIn(true);
            await login(email, password);
            await waitDuration(2000, submitTime);
            window.location.replace(redirectPath ? "/" + redirectPath : "/dashboard");
        } catch (e) {
            await waitDuration(2000, submitTime);
            setLoggingIn(false);
            console.error(e);
            if (e instanceof Error) {
                switch (e.name) {
                    case "NotAuthorizedException":
                        setState("notAuthorizedError");
                        break;
                    default:
                        setState("error")
                }
            } else {
                setState("error");
            }
        }
    }


    return <Stack direction="column" spacing="0.5rem" sx={{ margin: "1rem 0", padding: "1rem 1.5rem", width: "100%", boxSizing: "border-box" }}>
            <AppTextField
                label="E-Mail"
                type="text"
                onChange={event => setEmail(event.target.value)}
                onKeyDown={event => event.key === "Enter" && submit()}
                disabled={formInputsDisabled}
                error={false}
                autoFocus
                sx={{
                    width: "100%"
                }}
            />
            <AppTextField
                label="Kennwort"
                type="password"
                onChange={event => setPassword(event.target.value)}
                onKeyDown={event => event.key === "Enter" && submit()}
                disabled={formInputsDisabled}
                error={false}
            />
        <Box sx={{ padding: "0.5rem 0 0 0", boxSizing: "border-box", width: "100%" }}>
            <Button variant="contained" size="small" sx={{ width: "100%", height: "2rem", display: "flex", alignItems: "center", lineHeight: "1em" }} disabled={!validForm} disableRipple={formInputsDisabled} disableElevation onClick={submit}>
                {state !== "sending" && <>Anmelden</>}
                {state === "sending" && <CircularProgress size="1rem" sx={{ color: "rgba(255,255,255,1)" }} />}
            </Button>
        </Box>
        {errorsExist && <LoginError>
            {(() => {
                switch (state) {
                    case "notAuthorizedError":
                        return <>Die E-Mail-Adresse oder das Passwort ist fehlerhaft.</>
                    default:
                        return <>Während der Anmeldung ist ein unbekannter Fehler aufgetreten.</>
                }
            })()}

        </LoginError>}
        <Box sx={{ padding: "1rem 0 0 0", width: "100%", display: "flex", justifyContent: "center" }}>
            <Box sx={{ fontSize: "0.75rem", transition: "0.2s", opacity: 0.75, "&:hover": { opacity: 1 } }}>
                <AppRouterLink to="/login/recover">
                    Passwort vergessen?
                </AppRouterLink>
            </Box>
        </Box>
    </Stack>
}
export default LoginForm;