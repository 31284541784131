import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import useAccount from "authentication/_hooks/useAccount";
import DialogContentApp from "common/DialogContentApp";
import DialogContentParagraph from "common/DialogContentParagraph";
import LoginButton from "common/LoginButton";
import useMount from "common/useMount";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const GUEST_NOTE_AFFIRMED_SESSION_STORAGE_NAME = "guest_affirmed";

const GuestNoteDialog = ({ onClose }: { onClose: (unmounted: boolean) => void }) => {

    const navigate = useNavigate();
    const [originUnmounted, setOriginUnmounted] = useState(false);

    const close = () => {
        window.sessionStorage.setItem(GUEST_NOTE_AFFIRMED_SESSION_STORAGE_NAME, "1");
        setShowDialog(false);
    }
    const onExited = () => {
        setShowDialog(true);
        onClose(originUnmounted);
    }

    const [showDialog, setShowDialog] = useState(true);
    return <Dialog open={showDialog} TransitionProps={{ onExited: onExited }}>
        <DialogTitle>
            Du nutzt vAlign Goals als Gast
        </DialogTitle>
        <DialogContentApp>
            <DialogContentParagraph>
                Du nutzt vAlign Goals gerade mit deinem Gastkonto.
            </DialogContentParagraph>
            <DialogContentParagraph>
                Klicke unten auf OK, falls du mit dem Gastkonto arbeiten willst. Willst du stattdessen mit deinem Nutzerkonto arbeiten, klicke auf "Anmelden".
            </DialogContentParagraph>
            <Box sx={{margin: "1rem 0 0 0"}}>
                <LoginButton onClick={() => {
                    setOriginUnmounted(true);
                    setShowDialog(false);
                    navigate("/login");
                }} />
            </Box>
        </DialogContentApp>
        <DialogActions>
            <Button onClick={close}>OK, Gastkonto verwenden</Button>
        </DialogActions>
    </Dialog>
}

const GuestNote = ({ children }: { children: React.ReactNode }) => {
    const { mountComponent, unmount } = useMount();
    const [mounted, setMounted] = useState(false);
    const location = useLocation();
    const uncheckedPath = /^\/privacy|^\/imprint|^\/login/.test(location.pathname);
    const { authenticatedAccount, accountExists } = useAccount();
    const guestNoteAffirmed = window.sessionStorage.getItem(GUEST_NOTE_AFFIRMED_SESSION_STORAGE_NAME) === "1";

    useEffect(() => {
        if (uncheckedPath || authenticatedAccount || !accountExists || guestNoteAffirmed) {
            if (!mounted) return;
            unmount();
            setMounted(false);
            return;
        }
        if (mounted) return;
        setMounted(true);
        mountComponent(<GuestNoteDialog onClose={(unmounted: boolean) => {
            unmount();
            if(!unmounted) setMounted(false);
        }} />)
    }, [mounted, setMounted, mountComponent, location, unmount, authenticatedAccount, uncheckedPath, accountExists, guestNoteAffirmed])

    return <>
        {children}
    </>;
}
export default GuestNote;