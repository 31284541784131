import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import AppRadioButton from "forms/AppRadioButton";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import { GoalType } from "graphql/API";
import { GoalDetailsSettingsReviewCycleMode_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/__generated__/GoalDetailsSettingsReviewCycleMode_goal.graphql";
import GoalReviewCycleModeTooltipConst from "main/goals/goal_edit/GoalReviewCycleModeTooltipConst";
import GoalReviewCycleModeTooltipSpaced from "main/goals/goal_edit/GoalReviewCycleModeTooltipSpaced";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useEventBus, { EventTypes } from "app/useEventBus";
import { useState } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsReviewCycleMode = ({ goal, eventSource }: {
    goal: GoalDetailsSettingsReviewCycleMode_goal$key,
    eventSource: React.MutableRefObject<EventTarget>
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsReviewCycleMode_goal on Goal {
        id
        type
        reviewMode {
            spacedRepetition
            cycleBegin {
                days
                months
            }
            cycleGoal {
                days
                months
            }
        }
    }
    `, goal)

    const [value, setValue] = useState(!!data.reviewMode.spacedRepetition);

    const [commit] = useUpdateGoal();
    const eventBus = useEventBus();
    const formFieldSavingEvents = useFormFieldSavingEvents(eventSource);
    const save = (spacedRepetition: boolean) => {
        if(data.reviewMode.spacedRepetition === spacedRepetition) return;
        formFieldSavingEvents.dispatchFormFieldSavingEvent();
        commit({
            variables: {
                input: {
                    id: data.id,
                    reviewMode: Object.assign({}, data.reviewMode, { spacedRepetition })
                }
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                formFieldSavingEvents.dispatchFormFieldSavingSuccessEvent()
            },
            onError: () => formFieldSavingEvents.dispatchFormFieldSavingErrorEvent()
        })
    }

    const handleClick = (spacedRepetition: boolean) => {
        setValue(spacedRepetition);
        save(spacedRepetition);
    }

    return <Grid container spacing={"1rem"}>
        <Grid item md={6} sm={12}>
            <AppRadioButton
                value={"spacedRepetition"}
                selected={value}
                onClick={() => handleClick(true)}
                onChange={e => handleClick(true)}
                title={<><Box component="span" sx={{fontWeight: 500}}>Spaced Repetition</Box>{data.type === GoalType.continuous && <> (empfohlen)</>}</>}
                tooltip={<GoalReviewCycleModeTooltipSpaced />}
                small
            />
        </Grid>
        <Grid item md={6} sm={12}>
            <AppRadioButton
                value={"simple"}
                selected={!value}
                onClick={() => handleClick(false)}
                onChange={e => handleClick(false)}
                title={<><Box component="span" sx={{fontWeight: 500}}>Gleichbleibender Abstand</Box>{data.type === GoalType.completable && <> (empfohlen)</>}</>}
                tooltip={<GoalReviewCycleModeTooltipConst />}
                small
            />
        </Grid>
    </Grid>
}
export default GoalDetailsSettingsReviewCycleMode;