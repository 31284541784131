import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import { Stack } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import useRegexValidations from "common/useRegexValidations";

const ValidationIndicators = ({ password, sx = {} }: { password: string | null, sx?: SxProps }) => {
    const { hasMinPasswordLength, hasUpperCaseLetter, hasLowerCaseLetter, hasNumber, hasPasswordSpecialChar } = useRegexValidations();
    return <Stack direction="row" spacing="0.5rem" sx={Object.assign({ fontSize: "0.75rem" }, sx)}>
        {[{
            label: ">8 Zeichen",
            fulfilled: hasMinPasswordLength(password)
        }, {
            label: "Groß/Kleinbuchstaben",
            fulfilled: hasUpperCaseLetter(password) && hasLowerCaseLetter(password)
        }, {
            label: "Zahl",
            fulfilled: hasNumber(password)
        }, {
            label: "Sonderzeichen",
            fulfilled: hasPasswordSpecialChar(password)
        }].map((item, i) => <Stack direction="row" key={i} sx={{ whiteSpace: "nowrap", alignItems: "center", transition: "0.4s" }}>
            <CheckIcon sx={{ fontSize: "0.75rem", margin: "0 0.2rem 0 0", opacity: item.fulfilled ? 1 : 0.5, transition: "0.2s", color: theme => item.fulfilled ? theme.palette.success.main : "rgba(0,0,0,0.25)" }} />
            <Box component="span" sx={{ opacity: item.fulfilled ? 0.5 : 0.8, transition: "0.2s" }}>{item.label}</Box>
        </Stack>)}
    </Stack>
}
export default ValidationIndicators;