import StandardPaper from "common/StandardPaper";
import StandardPaperContainer from "common/StandardPaperContainer";
import StandardPaperTitle from "common/StandardPaperTitle";
import GoalStatusField from "main/goals/common/fields/GoalStatusField";
import { GoalDetailsCompletion_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsCompletion_goal.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsCompletion = ({ goal }: { goal: GoalDetailsCompletion_goal$key }) => {

    const data = useFragment(graphql`
        fragment GoalDetailsCompletion_goal on Goal {
            ...GoalStatusField_goal
        }
    `, goal)

    return <StandardPaper sx={{height: "100%"}}>
        <StandardPaperTitle>Aktueller Status</StandardPaperTitle>
        <StandardPaperContainer>
                <GoalStatusField goal={data} fieldStyle="emphasized" />
        </StandardPaperContainer>
    </StandardPaper>
}
export default GoalDetailsCompletion;