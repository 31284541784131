import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import GoalTypeIcon from "common/_icons/GoalTypeIcon";
import AppRadioButton from "forms/AppRadioButton";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import { GoalType } from "graphql/API";
import GoalTypeSelectTooltipCompletable from "main/goals/goal_edit/goal_type_select/GoalTypeSelectTooltipCompletable";
import GoalTypeSelectTooltipContinuous from "main/goals/goal_edit/goal_type_select/GoalTypeSelectTooltipContinuous";

const GoalTypeSelect = ({ value, updateValue, validateForm }: ControlFormFieldReturn<GoalType>) => {

    return <>
        <Grid container spacing={2} rowSpacing={1}>
            <Grid item md={6} sm={12}>
                <AppRadioButton
                    value={GoalType.continuous}
                    selected={value === GoalType.continuous}
                    onClick={() => {
                        updateValue(GoalType.continuous)
                        validateForm();
                    }}
                    onChange={e => {
                        updateValue(e.target.value as GoalType)
                        validateForm();
                    }}
                    icon={<GoalTypeIcon type={GoalType.continuous} size={"2rem"} />}
                    title={<><Box component="span" sx={{fontWeight: 500}}>Working Agreement</Box> (Zielzustand)</>}
                    tooltip={<GoalTypeSelectTooltipContinuous />}
                    small
                />
            </Grid>
            <Grid item md={6} sm={12}>
                <AppRadioButton
                    value={GoalType.completable}
                    selected={value === GoalType.completable}
                    onClick={() => {
                        updateValue(GoalType.completable)
                        validateForm();
                    }}
                    onChange={e => {
                        updateValue(e.target.value as GoalType)
                        validateForm();
                    }}
                    icon={<GoalTypeIcon type={GoalType.completable} size={"2rem"} />}
                    title={<><Box component="span" sx={{fontWeight: 500}}>Fertigstellbares Ziel</Box></>}
                    tooltip={<GoalTypeSelectTooltipCompletable />}
                    small
                />
            </Grid>

        </Grid>
    </>
}
export default GoalTypeSelect;