import { Divider, Stack } from "@mui/material";
import { Box } from "@mui/system";
import useTimeNow from "app/useTimeNow";
import GoalTypeLabel from "common/GoalTypeLabel";
import useDateCalculations from "common/useDateCalculations";
import ReviewIcon from "common/_icons/ReviewIcon";
import { DateTime } from "luxon";
import GoalStatusField from "main/goals/common/fields/GoalStatusField";
import ReviewCardMenu from "main/review/review_card/menu/ReviewCardMenu";
import { ReviewCard_goal$key } from "main/review/review_card/__generated__/ReviewCard_goal.graphql";
import { ReviewCard_review$key } from "main/review/review_card/__generated__/ReviewCard_review.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const ReviewCard = ({ review, goal }: { review: ReviewCard_review$key, goal: ReviewCard_goal$key }) => {
    const data = useFragment(graphql`
        fragment ReviewCard_review on Review {
            id
            completion
            comment
            automatedReview
            createdAt
            ...GoalStatusField_review
            ...ReviewCardMenu_review
        }
    `, review)
    const goalData = useFragment(graphql`
        fragment ReviewCard_goal on Goal {
            type
            ...GoalStatusField_goal
            ...ReviewCardMenu_goal
        }
    `, goal)

    const createdAt = DateTime.fromISO(data.createdAt);
    const { getLocaleDateString, getReadableDuration } = useDateCalculations();
    const now = useTimeNow();

    return <>
        <Stack direction="row" spacing={0} divider={<Divider orientation="vertical" flexItem sx={{ opacity: 0.5 }} />} sx={{
            display: "flex",
            borderRadius: "0.5rem",
            border: "1px solid rgba(0,0,0,0.075)",
            background: "rgba(0,0,0,0.0)",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <Box sx={{ padding: "0.7rem", display: "flex", boxSizing: "border-box", flexGrow: 1 }}>
                <Stack direction="row" spacing={"0.7rem"} sx={{ display: "flex" }}>
                    <ReviewIcon fontSize="small" color="primary" sx={{ mt: "0.1rem" }} />
                    <Box sx={{ fontSize: "0.875rem", boxSizing: "border-box", width: "100%", flexGrow: 1, display: "flex", flexDirection: "column" }}>
                        <Box sx={{ lineHeight: "1.4rem" }}>
                            <Box component="span" sx={{ fontWeight: "900" }}>
                                {getReadableDuration(createdAt, {diffDateTime: now})}
                            </Box><> </><Box component="span" sx={{ fontSize: "0.8rem", fontWeight: "300" }}>({getLocaleDateString(createdAt)})</Box><br />
                        </Box>
                        <Box component="span" sx={{ fontSize: "0.825rem", lineHeight: "1.3rem", fontStyle: "italic", fontFamily: "Open Sans", padding: "0rem 1rem 0 0" }}>
                            {data.automatedReview ? <>Dieser Review wurde automatisch erstellt, als das <GoalTypeLabel type={goalData.type} /> erstellt wurde.</> : (data.comment ? data.comment : <> - </>)}
                        </Box>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ minWidth: "10rem", width: "10rem", boxSizing: "border-box", padding: "0.9rem 1rem" }}>
                <GoalStatusField goal={goalData} review={data} />
            </Box>
            <Box sx={{ fontSize: "0.875rem", fontWeight: "400", display: "flex", padding: "0.5rem 0.25rem", alignItems: "flex-start" }}>
                <ReviewCardMenu review={data} goal={goalData} />
            </Box>
        </Stack>
    </>
}
export default ReviewCard;