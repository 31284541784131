import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperText from "common/StandardPaperText";
import StandardPaperTitle from "common/StandardPaperTitle";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainTitle from "main/MainTitle";
import React from "react";
import { styled } from "@mui/material";
import AppEmph from "common/AppEmph";

const CustomLink = ({ href, children }: { href: string, openInNewTab?: boolean, children: React.ReactNode }) => <a href={href} target="_blank" rel="noreferrer">{children}</a>

const List1 = styled('ol')({
    paddingInlineStart: "1rem"
})

const List2 = styled('ol')({
})

const Item1 = styled('li')({
    fontSize: "1.1rem",
    fontWeight: 500,
    margin: "2rem 0 1rem 0",
    padding: "0 0 0 1rem"
})

const Item2 = styled('li')({
    fontSize: "0.9rem",
    fontWeight: 500,
    margin: "1.5rem 0 0.5rem 0",
    padding: "0 0 0 0.5rem"
})

const SimpleText = styled('div')({
    fontWeight: 300,
    fontSize: "0.875rem",
    lineHeight: "1.4rem",
    margin: "0.5rem 0 0 0"
})

const Privacy = () => {
    return <>
        <MainBar>
            <MainTitle>Datenschutz</MainTitle>
        </MainBar>
        <MainContent>
            <MainContainer>
                <Stack direction="column" spacing="1rem">
                    <StandardPaper>
                        <StandardPaperTitle>
                            Auftragsverarbeitungs-Vereinbarung (AVV)
                        </StandardPaperTitle>
                        <StandardPaperText>
                            Hier finden Sie die aktuelle Auftragsverarbeitungs-Vereinbarung für die Software vAlign Goals:
                        </StandardPaperText>
                        <a href="/documents/valign_goals_avv.pdf" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                            <Button sx={{ margin: "1rem 0 0 0" }} variant="contained" color="primary">Download als PDF</Button>
                        </a>
                    </StandardPaper>
                    <StandardPaper>
                        <StandardPaperTitle>
                            Datenschutzerklärung
                        </StandardPaperTitle>
                        <StandardPaperText>

                            <div className="privacy__content__company">
                                Der Schutz Ihrer Daten, insbesondere Ihrer personenbezogenen Daten,
                                hat für uns höchste Priorität. Im Folgenden finden Sie
                                Informationen zum Thema Datenschutz hinsichtlich der Nutzung der
                                vAlign-Goals-Web-Anwendung (im Folgenden auch als Software bezeichnet).
                            </div>
                            <Box sx={{ padding: "0 2rem 0 1rem" }}>
                                <List1>
                                    <Item1>
                                        Name und Kontaktdaten des Verantwortlichen
                                        <SimpleText>
                                            Verantwortlich im Sinne der Datenschutz-Grundverordnung (im
                                            Folgenden: DS-GVO) ist die
                                        </SimpleText>
                                        <SimpleText>
                                            <AppEmph>evolutopia GmbH</AppEmph><br />
                                            Elektrastraße 13
                                            <br />
                                            81925 München
                                        </SimpleText>
                                        <SimpleText>
                                            Tel. +49 (0) 89 / 215 565 420
                                            <br />
                                            Fax + 49 (0) 89 / 215 565 421
                                            <br />
                                            Email: contact@valign.com
                                        </SimpleText>
                                    </Item1>

                                    <Item1>
                                        Datenverarbeitung bei der Nutzung der Software vAlign Goals
                                        <SimpleText>
                                            Die Verarbeitung Ihrer Daten erfolgt nur, soweit dies zur
                                            Bereitstellung der Software erforderlich ist, um die
                                            Eigenschaften und Qualität der Software optimal
                                            weiterzuentwickeln und um Sie über Neuigkeiten rundum die
                                            Software zu informieren. Sofern die Datenverarbeitung nicht
                                            bereits gesetzlich legitimiert ist, holen wir vorher Ihre
                                            Einwilligung ein.
                                        </SimpleText>
                                        <List2>
                                            <Item2>
                                                <div className="privacy__content__text__item--2__title">
                                                    Registrierung und Nutzerkonto
                                                </div>
                                                <SimpleText>
                                                    Zum Zweck der Registrierung und Erstellung eines Nutzerkontos speichern wir Ihre
                                                    E-Mail-Adresse. Ferner werden in Verbindung mit dem
                                                    Nutzerkonto weitere Kontoeinstellungen, Zustimmungen,
                                                    etc. gespeichert und verarbeitet. Die Verarbeitung dieser
                                                    personenbezogenen Daten ist erforderlich zur Erfüllung
                                                    Ihres Vertrages mit uns über die Nutzung von vAlign und
                                                    erfolgt auf Grundlage des Art. 6 Abs. 1 S. 1 lit. b
                                                    DS-GVO. Ihre Registrierungsdaten bleiben für die Dauer
                                                    der Softwarenutzung gespeichert. Danach werden sie
                                                    gelöscht, es sei denn gesetzliche Speicherfristen stehen
                                                    dem entgegen.
                                                </SimpleText>
                                                <SimpleText>
                                                    Eine Löschung Ihres Nutzerkontos ist ebenfalls über eine
                                                    Funktion in der Software möglich. Zur Wahrnehmung
                                                    bestimmter rechtlicher Verpflichtungen werden die Daten
                                                    des Nutzerkontos jedoch nicht sofort gelöscht, sondern
                                                    bleiben bspw. für die Dauer der Wahrnehmung steuer- oder
                                                    handelsrechtlicher Verpflichtungen (Art. 17 Abs. 3 lit.
                                                    b DS-GVO) oder zur Geltendmachung und Ausübung von
                                                    Rechtsansprüchen sowie zur Verteidigung von sowie gegen
                                                    Rechtsansprüche (Art. 17 Abs. 3 lit. e DS-GVO)
                                                    gespeichert. In diesen Fällen tritt an die Stelle der
                                                    Löschung die Sperrung der Daten. Nach Ablauf dieser
                                                    Speicherfristen werden auch diese Daten
                                                    unwiederbringlich gelöscht.
                                                </SimpleText>
                                            </Item2>
                                            <Item2>
                                                <div className="privacy__content__text__item--2__title">
                                                    Gastzugang und Gastkonto
                                                </div>
                                                <SimpleText>
                                                    Die Nutzung der Software ist auch ohne Registrierung über einen Gastzugang in Verbindung mit einem Gastkonto möglich.
                                                    Für die Erstellung eines Gastkontos werden keine persönlichen Daten erhoben.
                                                    Weitere Informationen zum Gastkonto und dessen Sicherheit finden Sie in Abschnitt 3.
                                                </SimpleText>
                                            </Item2>
                                            <Item2>
                                                <div className="privacy__content__text__item--2__title">
                                                    Inhaltsdaten und Kontolöschung
                                                </div>
                                                <SimpleText>
                                                    Die Verarbeitung Ihrer Inhaltsdaten, die Sie erstellen oder die sonst durch Ihre aktive
                                                    Nutzung von vAlign entstehen, erfolgt auf Grundlage von
                                                    Art. 6 Abs. S. 1 lit b DS-GVO, da sie der Erfüllung des
                                                    Vertrages über die Nutzung von vAlign Goals dient. Insoweit
                                                    wird ein Vertrag zur Auftragsverarbeitung gem. Art. 28
                                                    DS-GVO abgeschlossen.
                                                </SimpleText>
                                                <SimpleText>
                                                    Sie können Ihr Nutzerkonto und/oder Ihr Gastkonto über eine Funktion in der
                                                    Software eigenständig und unwiederbringlich löschen. Dabei werden auch alle mit dem jeweiligen Konto verknüpften Inhaltsdaten unwiederbringlich gelöscht.
                                                    Eine Wiederherstellung der Inhalte durch uns ist nicht
                                                    möglich.
                                                </SimpleText>
                                                <SimpleText>
                                                    Bitte beachten Sie, dass Ihr Nutzer- und Gastkonto (sofern vorhanden) jeweils separat und eigenständig von Ihnen gelöscht werden muss, da keine technische Verbindung zwischen Nutzerkonto und Gastkonto besteht.
                                                </SimpleText>
                                            </Item2>
                                            <Item2>
                                                <div className="privacy__content__text__item--2__title">
                                                    Feedback und Informationen zu Aktualisierungen
                                                </div>
                                                <SimpleText>
                                                    Um die Qualität unserer Software zu verbessern, die
                                                    Zufriedenheit unserer Benutzer sicherzustellen oder Sie
                                                    über neue Features und Veranstaltungen zu informieren,
                                                    kontaktieren wir Sie von Zeit zu Zeit via E-Mail (falls Sie ein Nutzerkonto registriert haben). Der
                                                    Nutzung Ihrer E- Mail-Adresse für diese Zwecke können
                                                    Sie jederzeit widersprechen, indem Sie uns eine formlose
                                                    E-Mail an privacy@valign.com schicken. Diese
                                                    Datenverarbeitung erfolgt auf Grundlage des Art. 6 Abs.
                                                    1 S. 1lit. f DS-GVO. Die Rechtsgrundlage für den Versand
                                                    der Informations-E-Mails infolge der Nutzung der
                                                    Software vAlign ist § 7 Abs. 3 UWG. Ihre E-Mail-Adresse
                                                    wird für diesen Zweck solange gespeichert, wie Ihre
                                                    Softwarenutzung aktiv ist bzw. solange Sie der
                                                    Versendung von Informationen nicht widersprechen.
                                                </SimpleText>
                                            </Item2>
                                            <Item2>
                                                <div className="privacy__content__text__item--2__title">
                                                    Messung der Nutzeraktivitäten
                                                </div>
                                                <SimpleText>
                                                    Um die Qualität und Funktionen unserer Software zu
                                                    verbessern und weiterzuentwickeln, erheben wir
                                                    pseudonymisierte Daten hinsichtlich Ihrer Aktivitäten
                                                    innerhalb der Software. Diese Daten umfassen
                                                    beispielsweise den Zeitpunkt und die Anzahl der
                                                    Anmeldevorgänge, die Dauer der Nutzung, die Anzahl
                                                    erstellter Elemente oder Informationen über die Nutzung
                                                    einzelner Funktionen.
                                                </SimpleText>
                                                <SimpleText>
                                                    Der Messung Ihrer Nutzungsaktivitäten für diese Zwecke
                                                    können Sie jederzeit widersprechen, indem Sie uns eine
                                                    formlose E-Mail an privacy@valign.com schicken.
                                                </SimpleText>
                                                <SimpleText>
                                                    Diese Datenverarbeitung erfolgt auf Grundlage des Art. 6
                                                    Abs. 1 S. 1lit. f DS-GVO.
                                                </SimpleText>
                                                <SimpleText>
                                                    Ihre Aktivitätsdaten werden für diesen Zweck solange
                                                    pseudonymisiert gespeichert, wie Ihre Softwarenutzung
                                                    aktiv ist. Sobald Sie Ihr Nutzerkonto löschen, werden
                                                    Ihre Aktivitätsdaten anonymisiert.
                                                </SimpleText>
                                            </Item2>
                                        </List2>
                                    </Item1>
                                    <Item1 id="guestaccount">
                                        Gastkonto und Sicherheit
                                        <SimpleText>
                                            Die Nutzung der Software ist auch ohne Registrierung über einen Gastzugang in Verbindung mit einem Gastkonto möglich.
                                            Dazu wird beim ersten Aufruf der Software ein Gastkonto erstellt, indem eine zufällige "Gast-ID" erstellt und unverschlüsselt in dem von Ihnen verwendeten Browser gespeichert wird.
                                        </SimpleText>
                                        <SimpleText>
                                            Die Gast-ID dient zur Authentifizierung Ihres Gastkontos. Das bedeutet, dass <AppEmph>jede Person, die die Gast-ID kennt, volle Kontrolle über alle mit dem Gastkonto verbundenen Daten (auch Inhaltsdaten) hat</AppEmph>.
                                        </SimpleText>
                                        <SimpleText>
                                            Verlieren Sie Ihre Gast-ID, haben wir keine Möglichkeit, Ihnen das zugehörige Gastkonto wieder zuzuordnen, da die Gast-ID die einzige Authentifizierung im Falle eines Gastkontos darstellt.
                                            <> </><AppEmph>Verlieren Sie Ihre Gast-ID, sind alle damit verbundenen Daten verloren</AppEmph>. Gehen Sie daher mit Ihrer Gast-ID bitte sorgsam um.
                                        </SimpleText>
                                        <SimpleText>
                                            Das Gastkonto ermöglicht die einfache und anonyme Nutzung der Software, genügt aus diesen Gründen aber auch nicht den Sicherheitsansprüchen eines passwortgeschützten Nutzerkontos (so wird die Gast-ID zum Beispiel unverschlüsselt im lokalen Speicher des Browsers abgelegt).
                                            <> </><AppEmph>Der Nutzer sollte daher über den Gastzugang nur Daten verarbeiten, die dieser Sicherheitseinstufung entsprechen!</AppEmph>
                                        </SimpleText>
                                    </Item1>
                                    <Item1>
                                        Hosting
                                        <SimpleText>
                                            Zur Bereitstellung der Software und der Website nutzen wir
                                            verschiedene Cloud Services von Amazon Web Services, Inc.
                                            (AWS), 410 Terry Avenue North, Seattle WA 98109 United
                                            States. Ein Angemessenheitsbebeschluss der Europäischen
                                            Kommission gem. Art. 45 DS-GVO zu Datenübermittlungen in die
                                            USA liegt aktuell nicht vor. Gleichwohl haben wir dafür
                                            Sorge getragen, dass die Server, auf denen Ihre Daten
                                            verarbeitet und gespeichert werden, sich in Rechenzentren
                                            innerhalb der Europäischen Union befinden. Weitere geeignete
                                            und angemessene Garantien zum Schutz Ihrer Daten gem. Art.
                                            46 DS-GVO haben wir durch Abschluss von
                                            Standarddatenschutzklauseln gem. Art. 46 Abs. 2 lit. c
                                            DS-GVO mit AWS Inc. vorgesehen. Eine Kopie dieser
                                            Vereinbarung können Sie jederzeit per E-Mail an
                                            support@valign.com anfordern.
                                        </SimpleText>
                                        <SimpleText>
                                            Weitere Informationen zum Schutz und Umgang mit Ihren Daten
                                            bei AWS können Sie auf diesen Seiten nachlesen:
                                        </SimpleText>
                                        <SimpleText>
                                            <CustomLink
                                                href="https://aws.amazon.com/de/privacy/"
                                                openInNewTab
                                            >
                                                AWS Datenschutzbestimmungen
                                            </CustomLink>
                                            <br />
                                            <CustomLink
                                                href="https://aws.amazon.com/de/compliance/eu-data-protection/"
                                                openInNewTab
                                            >
                                                AWS EU-Datenschutz
                                            </CustomLink>
                                        </SimpleText>
                                    </Item1>
                                    <Item1>
                                        Datenübertragung
                                        <SimpleText>
                                            Bei der Nutzung der Software und der Website wird die
                                            Verbindung zwischen Ihrem Webbrowser und dem Server über das
                                            Transport Layer Security (TLS) Protokoll abgesichert. Der
                                            Einsatz dieses Protokolls dient der sicheren Übertragung
                                            deiner Daten, wobei insbesondere die Vertraulichkeit, die
                                            Integrität und die Authentizität der übertragenen Daten im
                                            Vordergrund steht.
                                        </SimpleText>
                                        <SimpleText>
                                            Aus Sicherheitsgründen ist der Zugriff auf die Software nur
                                            mit einem aktuellen Webbrowser, der mindestens die Version
                                            TLS 1.2 unterstützt, möglich.
                                        </SimpleText>
                                        <SimpleText>
                                            Weitere Informationen zur Verwendung von TLS können beim
                                            Bundesamt für Sicherheit in der Informationstechnik (BSI)
                                            nachgelesen werden:
                                        </SimpleText>
                                        <SimpleText>
                                            <CustomLink
                                                href="https://www.bsi.bund.de/SharedDocs/Downloads/DE/BSI/Publikationen/TechnischeRichtlinien/TR02102/BSI-TR-02102-2.pdf"
                                                openInNewTab
                                            >
                                                BSI TR-02102-2
                                            </CustomLink>
                                        </SimpleText>
                                    </Item1>
                                    <Item1>
                                        Datenspeicherung der Software
                                        <SimpleText>
                                            Alle Daten, die wir auf den Servern von AWS speichern,
                                            werden in der Regel von AWS zusätzlich verschlüsselt, bevor
                                            sie dort abgelegt werden.
                                        </SimpleText>
                                        <SimpleText>
                                            Darüber hinaus treffen wir entsprechende technische
                                            Maßnahmen, um das Risiko von Datenverlust und -korruption zu
                                            minimieren.
                                        </SimpleText>
                                    </Item1>
                                    <Item1>
                                        Passwort-Sicherheit
                                        <SimpleText>
                                            Ihr Passwort dient zur Authentifizierung Ihres Nutzerkontos. Daher ist es wichtig, dass Sie ein sicheres und
                                            nicht leicht zu erratendes Passwort auswählen.
                                        </SimpleText>
                                        <SimpleText>
                                            Bei der Anmeldung und bestimmten Funktionen (z.B. Ändern
                                            Ihrer E-Mail- Adresse) werden Sie zur Eingabe Ihres
                                            Passworts aufgefordert. Zur Überprüfung Ihres Passworts
                                            nutzen wir das Secure Remote Password Protocol (SRP).
                                        </SimpleText>
                                        <SimpleText>
                                            Weitere Informationen zu SRP können Sie hier nachlesen:
                                        </SimpleText>
                                        <SimpleText>
                                            <CustomLink href="http://srp.stanford.edu/ndss.html" openInNewTab>
                                                The Secure Remote Password Protocol
                                            </CustomLink>
                                        </SimpleText>
                                    </Item1>
                                    <Item1>
                                        Ihre Rechte
                                        <SimpleText>
                                            Zusätzlich zu den oben genannten Rechten haben Sie uns
                                            gegenüber, folgende Rechte hinsichtlich der Sie betreffenden
                                            personenbezogenen Daten:
                                            <Box component="ul" sx={{ margin: "0.5rem 0 0 0" }}>
                                                <li>Recht auf Auskunft,</li>
                                                <li>Recht auf Berichtigung oder Löschung,</li>
                                                <li>Recht auf Einschränkung der Verarbeitung,</li>
                                                <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                                                <li>Recht auf Datenübertragbarkeit.</li>
                                            </Box>
                                        </SimpleText>
                                    </Item1>
                                    <Item1>
                                        Ansprechpartner
                                        <SimpleText>
                                            Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer
                                            persönlichen Daten haben, können Sie sich gerne an uns
                                            wenden. Hierzu können Sie uns unter privacy@valign.com
                                            kontaktieren.
                                        </SimpleText>
                                    </Item1>
                                    <Item1>
                                        Änderungen
                                        <SimpleText>
                                            Diese Datenschutzerklärung ist aktuell gültig und hat den
                                            Stand Januar 2022.
                                        </SimpleText>
                                        <SimpleText>
                                            Bedingt durch die Weiterentwicklung unserer Software und der
                                            Website werden wir auch unsere Datenschutzerklärung laufend
                                            anpassen. Änderungen werden wir auf dieser Seite rechtzeitig
                                            bekannt geben. Sie sollten sie daher regelmäßig aufrufen, um
                                            sich über den neuesten Stand der Datenschutzerklärung zu
                                            informieren.
                                        </SimpleText>
                                    </Item1>
                                </List1>
                                <Box sx={{ margin: "3rem 0 0 0" }}>evolutopia GmbH</Box>
                            </Box>

                        </StandardPaperText>
                    </StandardPaper>
                </Stack>
            </MainContainer>
        </MainContent>
    </>
}
export default Privacy;