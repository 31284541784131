import { Stack } from "@mui/material";
import StandardPaper from "common/StandardPaper";
import StandardPaperContainer from "common/StandardPaperContainer";
import StandardPaperTitle from "common/StandardPaperTitle";
import { DateTime } from "luxon";
import GoalReviewField from "main/goals/common/fields/GoalReviewField";
import { GoalDetailsCurrentReview_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsCurrentReview_goal.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsCurrentReview = ({ goal }: { goal: GoalDetailsCurrentReview_goal$key }) => {

    const data = useFragment(graphql`
        fragment GoalDetailsCurrentReview_goal on Goal {
            currentReviewAt
        }
    `, goal)

    return <StandardPaper>
        <StandardPaperTitle>Letzter Review</StandardPaperTitle>
        <StandardPaperContainer>
            <Stack sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} spacing={"1rem"}>
                <GoalReviewField
                    label={<>Letzter Review</>}
                    reviewAt={DateTime.fromISO(data.currentReviewAt)}
                    fieldStyle="emphasized"
                    dueStyle={false}
                    agoStyle={true}
                />
            </Stack>
        </StandardPaperContainer>
    </StandardPaper>
}
export default GoalDetailsCurrentReview;