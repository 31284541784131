import { cycleOptionsLabelMap, mapReviewCycleInputToCycleOption } from "main/goals/common/cycleOptions";
import AppEmph from "common/AppEmph";
import useDateCalculations from "common/useDateCalculations";
import { DateTime } from "luxon";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { Box } from "@mui/system";
import TooltipWithLargerText from "common/TooltipWithLargerText";
import GoalReviewCycleModeTooltipSpaced from "main/goals/goal_edit/GoalReviewCycleModeTooltipSpaced";
import GoalReviewCycleModeTooltipConst from "main/goals/goal_edit/GoalReviewCycleModeTooltipConst";
import { ReviewCycleInput } from "graphql/API";

const GoalReviewModeSummary = ({ spacedRepetition, reviewCycleBegin, reviewCycleGoal, reviewCycleStartDate }: {
    spacedRepetition: boolean,
    reviewCycleBegin: ReviewCycleInput,
    reviewCycleGoal: ReviewCycleInput,
    reviewCycleStartDate: DateTime
}) => {
    const { getLocaleDateString } = useDateCalculations();
    return <>
        <AppEmph>{spacedRepetition ? "Spaced Repetition" : "Gleichbleibender Abstand"}</AppEmph>
        <Box sx={{ display: "inline-flex", height: "0.9rem", width: "1.2rem", margin: "0 0 0 0.2rem" }}>
            <TooltipWithLargerText arrow title={spacedRepetition ? <GoalReviewCycleModeTooltipSpaced /> : <GoalReviewCycleModeTooltipConst />}>
                <HelpIcon sx={{ fontSize: "1.1rem", position: "absolute", top: 0, cursor: "pointer" }} />
            </TooltipWithLargerText>
        </Box>
        <> </>|
        <> </>{spacedRepetition ? "Start" : "Intervall"}: <AppEmph>{cycleOptionsLabelMap.get(mapReviewCycleInputToCycleOption(reviewCycleBegin))}</AppEmph>,
        {spacedRepetition && <><> </>Ziel: <AppEmph>{cycleOptionsLabelMap.get(mapReviewCycleInputToCycleOption(reviewCycleGoal))}</AppEmph>,</>}
        <> </>Erster Review: <AppEmph>{getLocaleDateString(reviewCycleStartDate)}</AppEmph>
    </>
}
export default GoalReviewModeSummary;