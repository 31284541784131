import MainContentList from "main/MainContentList";
import AccountSettingsChangePassword from "main/settings/account/AccountSettingsChangePassword";
import AccountSettingsDeleteAccount from "main/settings/account/AccountSettingsDeleteAccount";
import AccountSettingsDownloadData from "main/settings/account/AccountSettingsDownloadData";


const AccountSettingsAccount = () => {
    return <MainContentList>
        <AccountSettingsChangePassword />
        <AccountSettingsDownloadData />
        <AccountSettingsDeleteAccount />
    </MainContentList>
}
export default AccountSettingsAccount;