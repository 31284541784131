import { FormControl, FormHelperText } from "@mui/material";
import useDateCalculations from "common/useDateCalculations";
import AppDatePicker from "forms/AppDatePicker";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import useFormHandlers from "forms/useFormHandlers";
import { DateTime } from "luxon";
import { GoalDetailsSettingsReviewCycleNextReview_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/__generated__/GoalDetailsSettingsReviewCycleNextReview_goal.graphql";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useEventBus, { EventTypes } from "app/useEventBus";
import React, { useEffect, useRef, useState } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsReviewCycleNextReview = ({ goal, eventSource }: {
    goal: GoalDetailsSettingsReviewCycleNextReview_goal$key,
    readOnly?: boolean,
    eventSource: React.MutableRefObject<EventTarget>
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsReviewCycleNextReview_goal on Goal {
        id
        nextReviewAt
    }
    `, goal)

    const {formDataRef, isValidForm, controlFormField} = useFormHandlers({
        initialFormData: { nextReviewAt: DateTime.fromISO(data.nextReviewAt) },
        initialValidState: { nextReviewAt: true }
    })

    const { dateIsNotInThePast, setDefaultHour } = useDateCalculations();

    const { value, handleChange, errorsExist, setVisited, changed, errors } = controlFormField({
        fieldName: "nextReviewAt", validations: {
            required: () => (d: DateTime) => !!d,
            validDateTime: () => (d: DateTime) => d?.isValid,
            futureDate: () => (d: DateTime) => {
                if (!d || !d.isValid) return false;
                return dateIsNotInThePast(d, true)
            }
        }
    });

    const [commit] = useUpdateGoal();
    const eventBus = useEventBus();
    const formFieldSavingEvents = useFormFieldSavingEvents(eventSource);

    const [shouldSave, setShouldSave] = useState(false);
    const popupOpened = useRef(false);

    useEffect(() => {
        const save = () => {
            if (!isValidForm()) return;
            if (formDataRef.current.nextReviewAt.toISO() === data.nextReviewAt) return;
            formFieldSavingEvents.dispatchFormFieldSavingEvent();
            commit({
                variables: {
                    input: {
                        id: data.id,
                        nextReviewAt: setDefaultHour(formDataRef.current.nextReviewAt).toISO()
                    }
                },
                onCompleted: () => {
                    eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                    eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED_NEXT_REVIEW))
                    formFieldSavingEvents.dispatchFormFieldSavingSuccessEvent()
                },
                onError: () => formFieldSavingEvents.dispatchFormFieldSavingErrorEvent()
            })
        }
        if(shouldSave) {
            setShouldSave(false);
            save();
        }
    }, [eventBus, shouldSave, setShouldSave, isValidForm, formDataRef, formFieldSavingEvents, data, commit, setDefaultHour])

    return <>
        <FormControl error={changed && errorsExist()}>
            <AppDatePicker
                inputFormat="dd.LL.yyyy"
                label="Nächster Review-Termin"
                value={value}
                onChange={d => {
                    handleChange(d as DateTime);
                    if(popupOpened.current) {
                        popupOpened.current = false;
                        setShouldSave(true);
                    }
                }}
                renderInput={() => <></>}
                customRenderInput={{
                    onBlur: () => {
                        setVisited(true);
                        setShouldSave(true);
                    },
                    error: changed && errorsExist(),
                    sx: { width: "14em" },
                }}
                error={changed && errorsExist()}
                boldValue
                onOpen={() => {
                    popupOpened.current = true;
                }}
            />
            {
                changed && (errors?.required ? <FormHelperText >Bitte gib ein Datum für das erste Review an.</FormHelperText> :
                    errors?.validDateTime ? <FormHelperText >Bitte gib ein gültiges Datum im Format dd.mm.yyyy an.</FormHelperText> :
                        errors?.futureDate ? <FormHelperText >Bitte gib ein Datum an, das nicht in der Vergangenheit liegt.</FormHelperText> :
                            <></>
                )
            }
        </FormControl>
    </>
}
export default GoalDetailsSettingsReviewCycleNextReview;