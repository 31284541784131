import { GoalType } from "graphql/API";
import CompletableImgSrc from "./_assets/completable.png";
import ContinuousImgSrc from "./_assets/continuous.png";
import styled from "@emotion/styled";
import { Box, SxProps } from "@mui/system";

const Img = styled.img((props: {size: string}) => ({
    width: props.size,
    height: props.size
}))

const GoalTypeIcon = ({type, size, sx}: {type: GoalType, size: string, sx?: SxProps}) => {
    return <Box sx={Object.assign({
        display: "flex",
        alignItems: "center"
    }, sx)}><Img size={size} src={(() => {switch(type) {
        case GoalType.completable: return CompletableImgSrc;
        case GoalType.continuous: return ContinuousImgSrc;
        default: return CompletableImgSrc
    }})()}/></Box>
}
export default GoalTypeIcon;