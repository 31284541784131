import ArchiveIcon from "@mui/icons-material/ArchiveRounded";
import UnarchiveIcon from "@mui/icons-material/UnarchiveRounded";
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useEventBus, { EventTypes } from "app/useEventBus";
import DialogContentApp from "common/DialogContentApp";
import useDateCalculations from "common/useDateCalculations";
import useMount from "common/useMount";
import useReadableId from "common/useReadableId";
import useSnackbar from "common/useSnackbar";
import { DateTime } from "luxon";
import { ArchiveGoalMenuItem_goal$key } from "main/goals/goal_card/menu/__generated__/ArchiveGoalMenuItem_goal.graphql";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import { useUpdateGoalMutationVariables } from "main/goals/__hooks/__generated__/useUpdateGoalMutation.graphql";
import { useState } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const ArchiveGoalMenuItemDialog = ({ onClose, readableId, id, archived, nextReviewAt }: {
    onClose: () => void,
    readableId: number,
    id: string,
    archived: boolean,
    nextReviewAt: DateTime
}) => {

    const showSnackbar = useSnackbar();
    const [commit] = useUpdateGoal();
    const eventBus = useEventBus();
    const [status, setStatus] = useState<"ASK" | "ARCHIVING">("ASK");
    const { getReadableId } = useReadableId();
    const printableReadableId = getReadableId(readableId);
    const { setDefaultHour } = useDateCalculations();

    const archiveGoal = () => {
        setStatus("ARCHIVING");
        const variables: useUpdateGoalMutationVariables = {
            input: {
                id,
                archived: !archived
            }
        }
        if (archived && nextReviewAt.toMillis() < DateTime.now().toMillis()) {
            variables.input.nextReviewAt = setDefaultHour(DateTime.now()).toISO()
        }
        commit({
            variables,
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_ARCHIVED));
                showSnackbar({ message: `Ziel ${printableReadableId} wurde erfolgreich ${archived ? "reaktiviert" : "archiviert"}.` })
                setOpen(false);
            },
            onError: e => {
                console.error(e)
                setOpen(false);
            },
            updater: store => {
                store.invalidateStore();
            }
        })
    }

    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
        {(() => {
            switch (status) {
                case "ASK":
                    return <>
                        <DialogTitle>
                            Wirklich {archived ? "reaktivieren" : "archivieren"}?
                        </DialogTitle>
                        <DialogContentApp>
                            {archived && <>Willst du das Ziel mit {printableReadableId} wirklich reaktivieren? Du erhältst dann wieder Review-Erinnerungen für dieses Ziel.</>}
                            {!archived && <>Willst du das Ziel mit {printableReadableId} wirklich archivieren? Du erhältst dann keine Review-Erinnerungen mehr für dieses Ziel.</>}
                        </DialogContentApp>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
                            <Button onClick={archiveGoal}>Ja, {archived ? "reaktivieren" : "archivieren"}</Button>
                        </DialogActions>
                    </>
                case "ARCHIVING":
                    return <>
                        <DialogTitle>
                            <Box sx={{ fontWeight: "300" }}>Ziel <Typography sx={{ fontWeight: "500", fontSize: "1em", display: "inline-flex" }}>{printableReadableId}</Typography> wird {archived ? "reaktiviert" : "archiviert"}...</Box>
                        </DialogTitle>
                        <DialogContentApp>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}><CircularProgress sx={{ my: 1 }} /></Box>
                        </DialogContentApp>
                    </>
            }
        })()}

    </Dialog>
}

const ArchiveGoalMenuItem = ({ goal }: {
    goal: ArchiveGoalMenuItem_goal$key
}) => {
    const data = useFragment(graphql`
        fragment ArchiveGoalMenuItem_goal on Goal {
            id
            readableId
            archived
            nextReviewAt
        }
    `, goal)
    const nextReviewAt = DateTime.fromISO(data.nextReviewAt);
    const { mountComponent, unmount } = useMount();
    return <MenuItem onClick={() => mountComponent(<ArchiveGoalMenuItemDialog onClose={unmount} id={data.id} readableId={data.readableId} archived={!!data.archived} nextReviewAt={nextReviewAt} />)}>
        <ListItemIcon>{data.archived ? <UnarchiveIcon /> : <ArchiveIcon />}</ListItemIcon>
        <ListItemText sx={{ mr: "2rem" }}>{data.archived ? "Reaktivieren" : "Archivieren"}</ListItemText>
    </MenuItem>
}
export default ArchiveGoalMenuItem;