import React from "react";
import Box from '@mui/material/Box';
import { SxProps } from "@mui/system";
// import BackgroundSvg from "common/_assets/canvas_background.svg";

const MainContent = ({
    children,
    sx = {}
}: {
    children: React.ReactNode,
    sx?: SxProps
}) => {
    return <Box sx={Object.assign({
        padding: "1.7rem 0 10rem 0",
        width: "100%",
        minHeight: "100vh",
        boxSizing: "border-box",
        // backgroundImage: `
        //         linear-gradient(rgba(225,235,240,0.75), rgba(225,235,240,0.75)),
        //         url(${BackgroundSvg})
        //     `,
        // backgroundSize: "100vw auto",
        // backgroundRepeat: "no-repeat",
        // borderTop: "1px solid rgba(0,0,0,0.05)"
    }, sx)}>
        {children}
    </Box>
}
export default MainContent;