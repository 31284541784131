import { Button, Stack, useTheme } from "@mui/material";
import useDateCalculations from "common/useDateCalculations";
import { DateTime } from "luxon";
import GoalFieldRow from "main/goals/common/field/GoalFieldRow";
import GoalFieldText from "main/goals/common/field/GoalFieldText";
import GoalFieldTextMain, { GoalFieldTextMainStyle } from "main/goals/common/field/GoalFieldTextMain";
import GoalFieldTitle from "main/goals/common/field/GoalFieldTitle";
import React from "react";

const GoalReviewField = ({
    reviewAt,
    label = <>Review:</>,
    fieldStyle = "default",
    showTitle = true,
    showReviewButton = false,
    dueStyle = true,
    agoStyle = false
}: {
    reviewAt: DateTime,
    label?: React.ReactNode,
    fieldStyle?: GoalFieldTextMainStyle,
    showTitle?: boolean,
    showReviewButton?: boolean,
    dueStyle?: boolean,
    agoStyle?: boolean
}) => {
    const { getReadableDuration, getLocaleDateString, flatDue } = useDateCalculations();
    const theme = useTheme();
    const due = flatDue(reviewAt);
    return <>
        <Stack direction="column">
            {showTitle && <GoalFieldTitle>{label}</GoalFieldTitle>}
            <GoalFieldRow>
                <GoalFieldTextMain fieldStyle={fieldStyle} sx={{
                    color: (dueStyle && due) ? theme.palette.secondary.main : undefined,
                    fontStyle: (dueStyle && due) ? "italic" : undefined,
                    fontWeight: (dueStyle && due) ? "700" : "400"
                }}>
                    {getReadableDuration(reviewAt, { precision: "day", since: !agoStyle })}
                </GoalFieldTextMain>
            </GoalFieldRow>
            <GoalFieldRow>
                <GoalFieldText>{getLocaleDateString(reviewAt)}</GoalFieldText>
            </GoalFieldRow>
            {showReviewButton && <GoalFieldRow sx={{margin: "0.5rem 0 0 0"}}>
                <Button color="secondary" variant="contained" size="small">Review</Button>
            </GoalFieldRow>}
        </Stack>
    </>
}
export default GoalReviewField;