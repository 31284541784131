import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import DialogContentApp from "common/DialogContentApp";
import useMount from "common/useMount";
import useSnackbar from "common/useSnackbar";
import { ReviewCardMenuDeleteItem_goal$key } from "main/review/review_card/menu/__generated__/ReviewCardMenuDeleteItem_goal.graphql";
import { ReviewCardMenuDeleteItem_review$key } from "main/review/review_card/menu/__generated__/ReviewCardMenuDeleteItem_review.graphql";
import useDeleteReview from "main/review/__hooks/useDeleteReview";
import { useState } from "react";
import { useFragment } from "react-relay";
import { ConnectionHandler } from "relay-runtime";
const graphql = require("babel-plugin-relay/macro");

const DeleteReviewMenuItemDialog = ({ onClose, id, connectionId }: {
    onClose: () => void,
    id: string,
    connectionId: string
}) => {

    const showSnackbar = useSnackbar();
    const [commit] = useDeleteReview();
    const [status, setStatus] = useState<"ASK" | "DELETING">("ASK");

    const deleteReview = () => {
        setStatus("DELETING");
        commit({
            variables: {
                input: {
                    id: id,
                },
                connections: [connectionId]
            },
            onCompleted: () => {
                showSnackbar({ message: `Review wurde erfolgreich gelöscht.` })
                setOpen(false);
            },
            onError: e => {
                console.error(e)
                setOpen(false);
            }
        })
    }

    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
        {(() => {
            switch (status) {
                case "ASK":
                    return <>
                        <DialogTitle>
                            Wirklich löschen?
                        </DialogTitle>
                        <DialogContentApp>
                            Willst du den Review wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!
                        </DialogContentApp>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
                            <Button onClick={deleteReview}>Ja, löschen!</Button>
                        </DialogActions>
                    </>
                case "DELETING":
                    return <>
                        <DialogTitle>
                            <Box sx={{ fontWeight: "300" }}>Review wird gelöscht...</Box>
                        </DialogTitle>
                        <DialogContentApp>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}><CircularProgress sx={{ my: 1 }} /></Box>
                        </DialogContentApp>
                    </>
            }
        })()}

    </Dialog>
}

const ReviewCardMenuDeleteItem = ({ review, goal }: {
    review: ReviewCardMenuDeleteItem_review$key,
    goal: ReviewCardMenuDeleteItem_goal$key,
}) => {
    const data = useFragment(graphql`
        fragment ReviewCardMenuDeleteItem_review on Review {
            id
        }
    `, review)
    const goalData = useFragment(graphql`
        fragment ReviewCardMenuDeleteItem_goal on Goal {
            id
        }
    `, goal)
    const { mountComponent, unmount } = useMount();
    const connectionId = ConnectionHandler.getConnectionID(goalData.id, "GoalDetailsReviews_reviews");
    return <MenuItem onClick={() => mountComponent(<DeleteReviewMenuItemDialog onClose={unmount} id={data.id} connectionId={connectionId} />)}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText sx={{ mr: "2rem" }}>Löschen</ListItemText>
    </MenuItem>
}
export default ReviewCardMenuDeleteItem;