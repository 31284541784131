/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewCardMenu_goal = {
    readonly " $fragmentRefs": FragmentRefs<"ReviewCardMenuDeleteItem_goal">;
    readonly " $refType": "ReviewCardMenu_goal";
};
export type ReviewCardMenu_goal$data = ReviewCardMenu_goal;
export type ReviewCardMenu_goal$key = {
    readonly " $data"?: ReviewCardMenu_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewCardMenu_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCardMenu_goal",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewCardMenuDeleteItem_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = 'b9c6fd759f9da0b65d2fb27e29fd201c';
export default node;
