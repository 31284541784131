import { GoalDetailsSettingsRepetitionLevel_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/__generated__/GoalDetailsSettingsRepetitionLevel_goal.graphql";
import { CycleOption, cycleOptionsLabelMap, getRepetitionLevels, mapReviewCycleInputToCycleOption } from "main/goals/common/cycleOptions";
import { useFragment } from "react-relay";
import { Button, Dialog, DialogActions, DialogTitle, Link, MenuItem, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useReducer, useState } from "react";
import AppSelect from "forms/AppSelect";
import AppEmph from "common/AppEmph";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useEventBus, { EventTypes } from "app/useEventBus";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import DialogContentApp from "common/DialogContentApp";
import useMount from "common/useMount";
import DialogContentParagraph from "common/DialogContentParagraph";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsRepetitionLevel = ({ goal, eventSource }: {
    goal: GoalDetailsSettingsRepetitionLevel_goal$key,
    eventSource: React.MutableRefObject<EventTarget>
}) => {
    const data = useFragment(graphql`
    fragment GoalDetailsSettingsRepetitionLevel_goal on Goal {
        id
        type
        spacedRepetitionLevel
        reviewMode {
            spacedRepetition
            cycleBegin {
                days
                months
            }
            cycleGoal {
                days
                months
            }
        }
    }
    `, goal)

    const repetitionLevels = getRepetitionLevels(mapReviewCycleInputToCycleOption(data.reviewMode.cycleBegin), mapReviewCycleInputToCycleOption(data.reviewMode.cycleGoal))
    const levelCount = (repetitionLevels ?? []).length;
    const currentLevel = repetitionLevels && repetitionLevels.length > (data.spacedRepetitionLevel ?? 0) ? repetitionLevels[(data.spacedRepetitionLevel ?? 0)] : null;

    const [spacedRepetitionLevel, dispatchSpacedRepetitionLevel] = useReducer((state: number, action: { type: string, value?: number }) => {
        if (action.type === "setValue") return action?.value ?? 0;
        return data.spacedRepetitionLevel ?? 0;
    }, data.spacedRepetitionLevel ?? 0);
    useEffect(() => {
        dispatchSpacedRepetitionLevel({ type: "update" })
    }, [data.spacedRepetitionLevel])
    const [selectVisible, setSelectVisible] = useState(false);

    const [commit] = useUpdateGoal();
    const formFieldSavingEvents = useFormFieldSavingEvents(eventSource);
    const eventBus = useEventBus();
    const save = (level: number) => {
        if (data.spacedRepetitionLevel === level) return;
        formFieldSavingEvents.dispatchFormFieldSavingEvent();
        commit({
            variables: {
                input: {
                    id: data.id,
                    spacedRepetitionLevel: level
                }
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                formFieldSavingEvents.dispatchFormFieldSavingSuccessEvent()
            },
            onError: () => {
                dispatchSpacedRepetitionLevel({ type: "update" })
                formFieldSavingEvents.dispatchFormFieldSavingErrorEvent()
            }
        })
    }

    const { mountComponent, unmount } = useMount();
    const createMenuItems = (cycleSelection: CycleOption[]) => cycleSelection.map((cycleOption, i) => <MenuItem value={i} key={i}>Stufe {i + 1}: <AppEmph sx={{ margin: "0 0 0 0.25rem" }}>{cycleOptionsLabelMap.get(cycleOption)}</AppEmph></MenuItem>)
    return selectVisible && repetitionLevels ? <>
        <AppSelect
            labelText="Aktuelle Stufe"
            value={spacedRepetitionLevel}
            sx={{
                width: "14em"
            }}
            onChange={event => {
                dispatchSpacedRepetitionLevel({ type: "setValue", value: event.target.value as number });
                save(event.target.value as number);
            }}
        >
            {createMenuItems(repetitionLevels)}
        </AppSelect>
    </> : <Stack direction="column" sx={{ padding: "0 0.75rem" }}>
        <Box sx={{ fontSize: "0.8rem", fontWeight: 300, lineHeight: "2rem", color: "rgba(0,0,0,0.6)", display: "flex", justifyContent: "space-between" }}>
            Aktuelle Stufe <Link sx={{ cursor: "pointer" }} onClick={() => mountComponent(
                <GoalDetailsSettingsRepetitionLevelDialog onClose={unmount} onCancel={() => {}} onConfirm={() => setSelectVisible(true)} />
            )}>Stufe ändern</Link>
        </Box>
        <Box sx={{ fontSize: "1rem", fontWeight: 500, lineHeight: "1.1rem" }}>
            {currentLevel && <>Stufe {data.spacedRepetitionLevel + 1} <Box component="span" sx={{ fontWeight: 300 }}>von {levelCount} ({cycleOptionsLabelMap.get(currentLevel)})</Box></>}
        </Box>
    </Stack>

}
export default GoalDetailsSettingsRepetitionLevel;

const GoalDetailsSettingsRepetitionLevelDialog = ({ onClose, onConfirm, onCancel }: {
    onClose: () => void,
    onConfirm: () => void,
    onCancel: () => void,
}) => {
    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
        <DialogTitle>
            Fortgeschrittene Einstellung
        </DialogTitle>
        <DialogContentApp>
            Ihr seid dabei, die Repetitionsstufe zu ändern.
            <DialogContentParagraph>
                vAlign wählt eine passende Stufe, sobald ihr einen Review durchführt.
                <> </><AppEmph>Üblicherweise ist es daher unnötig, diese Einstellung manuell zu ändern.</AppEmph>
            </DialogContentParagraph>
        </DialogContentApp>
        <DialogActions>
            <Button onClick={() => { setOpen(false); onCancel(); }}>Abbrechen</Button>
            <Button onClick={() => { setOpen(false); onConfirm(); }}>Einstellung bearbeiten</Button>
        </DialogActions>
    </Dialog>
}