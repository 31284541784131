/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ArchiveCountBadge_getGlobalsReturn = {
    readonly archiveCount: number;
    readonly " $refType": "ArchiveCountBadge_getGlobalsReturn";
};
export type ArchiveCountBadge_getGlobalsReturn$data = ArchiveCountBadge_getGlobalsReturn;
export type ArchiveCountBadge_getGlobalsReturn$key = {
    readonly " $data"?: ArchiveCountBadge_getGlobalsReturn$data;
    readonly " $fragmentRefs": FragmentRefs<"ArchiveCountBadge_getGlobalsReturn">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveCountBadge_getGlobalsReturn",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archiveCount",
      "storageKey": null
    }
  ],
  "type": "GetGlobalsReturn",
  "abstractKey": null
};
(node as any).hash = '441f172483ccdf891c8fd219435b4590';
export default node;
