import { DateTime } from "luxon"

export enum EventTypes {
    GOAL_CREATED = "goalCreated",
    GOAL_UPDATED = "goalUpdated",
    GOAL_UPDATED_NEXT_REVIEW = "goalUpdatedNextReview",
    GOAL_DELETED = "goalDeleted",
    GOAL_ARCHIVED = "goalArchived",
    GOAL_REACTIVATED = "goalReactivated",
    REVIEW_CREATED = "reviewCreated",
    REVIEW_DELETED = "reviewDeleted",
    GOAL_SELECTED = "goalSelected",
    GUEST_ID_CHANGED = "guestIdChanged",
    TIME_SET = "timeSet"
}

export type CustomEventGoalSelectedPayload = {
    selectedGoalReadableId: number;
}
export const createCustomEventGoalSelected = (readableId: number) => new CustomEvent<CustomEventGoalSelectedPayload>(EventTypes.GOAL_SELECTED, {
    detail: {
        selectedGoalReadableId: readableId
    }
})

export type CustomEventTimeSetPayload = {
    now: DateTime;
}
export const createCustomEventTimeSet = (now: DateTime) => new CustomEvent<CustomEventTimeSetPayload>(EventTypes.TIME_SET, {
    detail: { now }
})

const useEventBus = () => ({
    addEventListener: <T>(eventType: EventTypes, listener: (event: CustomEvent<T>) => void) => {
        document.addEventListener(eventType, listener as EventListener);
    },
    dispatchEvent: <T>(event: CustomEvent<T>) => {
        document.dispatchEvent(event);
    },
    removeEventListener: (eventType: EventTypes, listener: (event: CustomEvent) => void) => {
        document.removeEventListener(eventType, listener as EventListener);
    }
})
export default useEventBus;