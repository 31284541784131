import { Box } from "@mui/system";
import AppEmph from "common/AppEmph";
import AppRouterLink from "common/AppRouterLink";
import ExplanationParagraph from "common/ExplanationParagraph";
import ExplanationTooltip from "common/ExplanationTooltip";
import GoalTypeLabel from "common/GoalTypeLabel";
import { cycleOptionsLabelMap, getRepetitionLevelsByReviewCycleInput, mapReviewCycleInputToCycleOption } from "main/goals/common/cycleOptions";
import { ReviewDateCalculationResult } from "main/goals/goal_details/goal_details_review/GoalDetailsReview";
import { GoalDetailsReviewCalculationOptions_goal$key } from "main/goals/goal_details/goal_details_review/__generated__/GoalDetailsReviewCalculationOptions_goal.graphql";
import GoalReviewCycleModeTooltipSpaced from "main/goals/goal_edit/GoalReviewCycleModeTooltipSpaced";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsReviewCalculationOptions = ({ goal, reviewDateCalculationResult }: {
    goal: GoalDetailsReviewCalculationOptions_goal$key,
    reviewDateCalculationResult: ReviewDateCalculationResult
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsReviewCalculationOptions_goal on Goal {
        id
        type
        spacedRepetitionLevel
        status
        reviewMode {
            spacedRepetition
            cycleBegin {
                days
                months
            }
            cycleGoal {
                days
                months
            }
        }
    }
    `, goal)

    const repetitionLevels = getRepetitionLevelsByReviewCycleInput(data.reviewMode.cycleBegin, data.reviewMode.cycleGoal ?? data.reviewMode.cycleBegin) ?? [];

    const level = data.spacedRepetitionLevel ?? 0;
    const levelEqualsGoalLevel = level === repetitionLevels.length - 1;
    const oldLevel = reviewDateCalculationResult.oldRepetitionLevel ?? 0;
    const levelDiff = level - oldLevel;

    const currentCycleOption = repetitionLevels[level];
    const currentCycleOptionReadable = cycleOptionsLabelMap.get(currentCycleOption);

    const cycleBeginOption = mapReviewCycleInputToCycleOption(data.reviewMode.cycleBegin);
    const cycleBeginReadable = cycleOptionsLabelMap.get(cycleBeginOption);

    const oldCycleOption = repetitionLevels[oldLevel];
    const oldCycleReadable = cycleOptionsLabelMap.get(oldCycleOption);

    const change = reviewDateCalculationResult.statusChange;

    const settingsLinkParagraph = <ExplanationParagraph>Ihr könnt die Review-Einstellungen jederzeit in den <AppRouterLink to="../settings">Einstellungen</AppRouterLink> des <GoalTypeLabel type={data.type} />s ändern.</ExplanationParagraph>;

    return <>
        {(() => {
            if (data.reviewMode.spacedRepetition) {
                if (levelDiff === 0 && levelEqualsGoalLevel) {
                    return <>
                        <AppEmph>{change === "better" ? <>Super, der Status des <GoalTypeLabel type={data.type} />s hat sich verbessert!</> : change === "worse" ? <>Super, der Zustand des <GoalTypeLabel type={data.type} />s hat sich zwar leicht verschlechtert, ist aber immer noch sehr gut!</> : <>Super, der Zustand des <GoalTypeLabel type={data.type} />s ist sehr gut!</>}</AppEmph><br />
                        <ExplanationParagraph>
                            <>Die aktuelle Repetition-Stufe bleibt bei <AppEmph>{level + 1} von {repetitionLevels.length} ({currentCycleOptionReadable})</AppEmph> und damit beim Ziel-Intervall </>
                            <ExplanationTooltip label={<Box component="span" sx={{ fontSize: "0.8rem" }}>(Erklärung)</Box>} tooltip={<>
                                Der Review-Modus für dieses <GoalTypeLabel type={data.type} /> ist <AppEmph>Spaced Repetition</AppEmph><> </><ExplanationTooltip label={<Box component="span" sx={{ fontSize: "0.8rem" }}>(Erklärung)</Box>} tooltip={<GoalReviewCycleModeTooltipSpaced />} />.
                                <ExplanationParagraph>Solange der in den Reviews gesetzte Status 9 oder 10 beträgt, bleibt vAlign beim Ziel-Intervall. 
                                Fällt der Status unter diese Marke, startet ihr wieder beim Start-Intervall.</ExplanationParagraph>
                                {settingsLinkParagraph}
                            </>} />.
                        </ExplanationParagraph>
                    </>
                }
                if (levelDiff <= 0) {
                    return <>
                        <AppEmph>{change === "better" ? <>Super, der Status des <GoalTypeLabel type={data.type} />s hat sich verbessert!</> : change === "worse" ? <>Das lief schon mal besser...</> : <>Der Zustand des <GoalTypeLabel type={data.type} />s hat sich nicht verändert.</>}</AppEmph><br />
                        Da es {change === "better" && "aber immer noch"} Verbesserungspotential gibt, wurde der nächste Review-Termin im Abstand des von euch gewählten Start-Intervalls (<AppEmph>{cycleBeginReadable}</AppEmph>) gesetzt.
                        <ExplanationParagraph>{levelDiff === 0 ?
                            <>Die aktuelle Repetition-Stufe bleibt bei <AppEmph>{level + 1} von {repetitionLevels.length} ({currentCycleOptionReadable})</AppEmph></> :
                            <>Die Repetition-Stufe wurde von <AppEmph>{oldLevel + 1} von {repetitionLevels.length} ({oldCycleReadable})</AppEmph> auf <AppEmph>{level + 1} von {repetitionLevels.length} ({currentCycleOptionReadable}) </AppEmph>herabgesetzt </>
                        }<> </>
                            <ExplanationTooltip label={<Box component="span" sx={{ fontSize: "0.8rem" }}>(Erklärung)</Box>} tooltip={<>
                                Der Review-Modus für dieses <GoalTypeLabel type={data.type} /> ist <AppEmph>Spaced Repetition</AppEmph><> </><ExplanationTooltip label={<Box component="span" sx={{ fontSize: "0.8rem" }}>(Erklärung)</Box>} tooltip={<GoalReviewCycleModeTooltipSpaced />} />.
                                <ExplanationParagraph>vAlign erinnert euch so lange im Abstand des Start-Intervalls (in diesem Fall: {cycleBeginReadable}) an das <GoalTypeLabel type={data.type} />, bis ihr einen Zustand von 9 oder 10 erreicht habt. In diesem Fall wird der Abstand vergrößert.</ExplanationParagraph>
                                {settingsLinkParagraph}
                            </>} />.
                        </ExplanationParagraph>
                    </>
                }
                if (levelDiff > 0) {
                    return <>
                        <AppEmph>Super, der Zustand des <GoalTypeLabel type={data.type} />s ist sehr gut!</AppEmph><br />
                        {levelDiff === 0 && <>Der Abstand zum nächsten Review bleibt damit unverändert gleich dem Ziel-Intervall.</>}
                        {levelDiff > 0 && <>Aufgrund des sehr guten Zustands des <GoalTypeLabel type={data.type} />s wurde der Termin für den nächsten Review in einem größeren Abstand als bisher gesetzt.</>}
                        {levelDiff > 0 && levelEqualsGoalLevel && <> Du hast damit das Ziel-Intervall erreicht.</>}
                        <ExplanationParagraph>Die Repetition-Stufe {levelDiff > 0 ? <>wurde von <AppEmph>{oldLevel + 1} von {repetitionLevels.length} ({oldCycleReadable})</AppEmph> auf</> : <>beträgt</>} <AppEmph>{level + 1} von {repetitionLevels.length} ({currentCycleOptionReadable}) </AppEmph>heraufgesetzt<> </>
                            <ExplanationTooltip label={<Box component="span" sx={{ fontSize: "0.8rem" }}>(Erklärung)</Box>} tooltip={<>
                                Der Review-Modus für dieses <GoalTypeLabel type={data.type} /> ist <AppEmph>Spaced Repetition</AppEmph><> </><ExplanationTooltip label={<Box component="span" sx={{ fontSize: "0.8rem" }}>(Erklärung)</Box>} tooltip={<GoalReviewCycleModeTooltipSpaced />} />.
                                <ExplanationParagraph>vAlign erhöht bei einem Zustand von 9 oder 10 die Abstände zwischen den Reviews so lange, bis ihr das Ziel-Intervall erreicht habt.</ExplanationParagraph>
                                {settingsLinkParagraph}
                            </>} />.
                        </ExplanationParagraph>
                    </>
                }
                return <></>
            } else {
                return <>Der Review-Modus für dieses <GoalTypeLabel type={data.type} /> ist so konfiguriert, dass es in gleichbleibendem Abstand <AppEmph>{cycleBeginReadable}</AppEmph> überprüft wird.
                    <> </>{settingsLinkParagraph}</>
            }
        })()}
    </>
}
export default GoalDetailsReviewCalculationOptions;