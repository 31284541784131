import { Box } from "@mui/system";
import AppMenu from "common/AppMenu";
import GoalCardDivider from "main/goals/goal_card/GoalCardDivider";
import GoalCardMenu from "main/goals/goal_card/menu/GoalCardMenu";
import { GoalCardMenuButton_goal$key } from "main/goals/goal_card/menu/__generated__/GoalCardMenuButton_goal.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalCardMenuButton = ({ goal, compactView = false, connectionId }: {
    goal: GoalCardMenuButton_goal$key,
    compactView?: boolean,
    connectionId?: string
}) => {
    const data = useFragment(graphql`
        fragment GoalCardMenuButton_goal on Goal {
            ...GoalCardMenu_goal
        }
    `, goal)

    return <Box sx={
        !compactView ?
            { display: "flex" } : {
                position: "absolute",
                top: "0",
                right: "0",
                display: "flex"
            }
    }>
        {!compactView && <GoalCardDivider />}
        <Box sx={{
            padding: "0.8rem 0.5rem 0.5rem 0.5rem",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "flex-start"
        }}>
            <AppMenu>
                <GoalCardMenu goal={data} connectionId={connectionId} />
            </AppMenu>
        </Box>
    </Box>
}
export default GoalCardMenuButton;