import { MenuItem } from "@mui/material";
import AppSelect from "forms/AppSelect";
import { FormHandlersReturn } from "forms/useFormHandlers";
import { ReviewCycleInput } from "graphql/API";
import { Duration, DurationObjectUnits } from "luxon";
import { cycleBeginSelection, CycleOption, cycleOptionsLabelMap, cycleOptionsMap } from "main/goals/common/cycleOptions";
import { GoalFormData } from "main/goals/goal_edit/GoalEditForm";
import { useState } from "react";

const GoalReviewCycleBeginSelect = ({ formHandlers: { controlFormField, formData, validateForm } }: { formHandlers: FormHandlersReturn<GoalFormData> }) => {

    const [selectValue, setSelectValue] = useState<CycleOption>(CycleOption.TWOWEEKS);

    const { handleChange, errorsExist } = controlFormField({
        fieldName: "reviewCycleBegin", validations: {
            lessThanCycleGoal: (validationFormData: GoalFormData) => (cycle: ReviewCycleInput) => {
                return !validationFormData.spacedRepetition || Duration.fromObject(cycle as DurationObjectUnits) < Duration.fromObject(validationFormData.reviewCycleGoal as DurationObjectUnits)
            }
        }
    });
    const createMenuItems = (cycleSelection: CycleOption[]) => cycleSelection.map((cycleOption, i) => <MenuItem value={cycleOption} key={i}>{cycleOptionsLabelMap.get(cycleOption)}</MenuItem>)
    return <>
        <AppSelect
            labelText={formData.spacedRepetition ? "Start-Intervall" : "Intervall"}
            labelId="reviewCycleLabelId"
            label={formData.spacedRepetition ? "Start-Intervall" : "Intervall"}
            value={selectValue}
            error={errorsExist()}
            onChange={e => {
                const cycleOption = e.target.value as CycleOption;
                let reviewCycle: ReviewCycleInput | null = cycleOptionsMap.get(cycleOption) ?? null;
                if (!reviewCycle) return;
                setSelectValue(cycleOption);
                handleChange(reviewCycle)
                validateForm();
            }}
            required
            boldValue
            sx={{
                width: "12em"
            }}
        >
            {createMenuItems(cycleBeginSelection)}
        </AppSelect>
    </>
}
export default GoalReviewCycleBeginSelect;