/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalDetailsReviewCalculationOptions_goal = {
    readonly id: string;
    readonly type: GoalType;
    readonly spacedRepetitionLevel: number;
    readonly status: number;
    readonly reviewMode: {
        readonly spacedRepetition: boolean;
        readonly cycleBegin: {
            readonly days: number | null;
            readonly months: number | null;
        };
        readonly cycleGoal: {
            readonly days: number | null;
            readonly months: number | null;
        } | null;
    };
    readonly " $refType": "GoalDetailsReviewCalculationOptions_goal";
};
export type GoalDetailsReviewCalculationOptions_goal$data = GoalDetailsReviewCalculationOptions_goal;
export type GoalDetailsReviewCalculationOptions_goal$key = {
    readonly " $data"?: GoalDetailsReviewCalculationOptions_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsReviewCalculationOptions_goal">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "days",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "months",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsReviewCalculationOptions_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spacedRepetitionLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReviewMode",
      "kind": "LinkedField",
      "name": "reviewMode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spacedRepetition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ReviewCycle",
          "kind": "LinkedField",
          "name": "cycleBegin",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ReviewCycle",
          "kind": "LinkedField",
          "name": "cycleGoal",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
})();
(node as any).hash = 'f58dfb2a839a92639837bac0f117f229';
export default node;
