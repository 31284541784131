import { Box, SxProps } from "@mui/system";
import React from "react";

const GoalFieldRow = ({ children, sx }: { children: React.ReactNode, sx?: SxProps }) => {
    return <Box sx={Object.assign({
        display: "flex",
        alignItems: "center",
        minHeight: "1.3rem",
        width: "100%"
    }, sx)}>
        {children}
    </Box>
}
export default GoalFieldRow;