import { PRODUCTION_ENVIRONMENT, TEST_ENVIRONMENT } from "common/common";

export enum LOG_LEVEL {
    "ALL" = 0,
    "ALL_CONDITIONAL" = 1,
    "TEST" = 2,
    "TEST_CONDITIONAL" = 3,
    "PRODUCTION" = 4,
    "PRODUCTION_CONDITIONAL" = 5
}

let logLevel = LOG_LEVEL.ALL;
const consoleLogSet = window.sessionStorage.getItem("consoleLog") === "1" || window.localStorage.getItem("consoleLog") === "1";
if (consoleLogSet) logLevel = LOG_LEVEL.ALL_CONDITIONAL;
if (TEST_ENVIRONMENT) logLevel = LOG_LEVEL.TEST;
if (TEST_ENVIRONMENT && consoleLogSet) logLevel = LOG_LEVEL.TEST_CONDITIONAL;
if (PRODUCTION_ENVIRONMENT) logLevel = LOG_LEVEL.PRODUCTION;
if (PRODUCTION_ENVIRONMENT && consoleLogSet) logLevel = LOG_LEVEL.PRODUCTION_CONDITIONAL;

const AppConsole = (component: string, { consoleLogSetOverride }: { consoleLogSetOverride?: boolean } = {}) => ({
    log: (m: any, level: number = LOG_LEVEL.ALL, options: {
        object?: boolean
    } = {}) => {
        if (logLevel > level) return;
        if (level === 1 || level === 3 || level === 5) {
            if (!consoleLogSet && !consoleLogSetOverride) return;
        }
        if (options.object) {
            console.log(`_______${component}_______`);
            console.log(m);
            return;
        }
        console.log(`${component}: ${m}`)
    }
})
AppConsole("AppConsole").log(`logLevel: ${logLevel}`, 1);
export default AppConsole;