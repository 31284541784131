import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import FormFieldSavingIndicator from "forms/FormFieldSavingIndicator";
import { GoalType } from "graphql/API";
import GoalDetailsSettingsDueDateSelect from "main/goals/goal_details/goal_details_settings/goal_details_settings_due_date/GoalDetailsSettingsDueDateSelect";
import { GoalDetailsSettingsDueDate_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_due_date/__generated__/GoalDetailsSettingsDueDate_goal.graphql";
import { useRef } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsDueDate = ({ goal }: { goal: GoalDetailsSettingsDueDate_goal$key }) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsDueDate_goal on Goal {
        type
        ...GoalDetailsSettingsDueDateSelect_goal
    }
    `, goal)

    const eventSource = useRef(new EventTarget());

    return data.type === GoalType.completable ? <Grid item xs={12}>
        <StandardPaper>
            <StandardPaperTitle noOpacityReduction>
                <Box sx={{ opacity: 0.8 }}>Zieldatum</Box>
                <Box sx={{ margin: "0 0 0 0.5rem" }}>
                    <FormFieldSavingIndicator eventSource={eventSource.current} />
                </Box>
            </StandardPaperTitle>
            <GoalDetailsSettingsDueDateSelect goal={data} eventSource={eventSource} />
        </StandardPaper>
    </Grid> : <></>
}
export default GoalDetailsSettingsDueDate;