import { FormControl, FormHelperText, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import GoalTypeLabel from "common/GoalTypeLabel";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import FormFieldSavingIndicator from "forms/FormFieldSavingIndicator";
import useFormHandlers from "forms/useFormHandlers";
import { ReviewCycleInput } from "graphql/API";
import GoalDetailsSettingsInactiveText from "main/goals/goal_details/goal_details_settings/GoalDetailsSettingsInactiveText";
import GoalDetailsSettingsRepetitionLevel from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/GoalDetailsSettingsRepetitionLevel";
import GoalDetailsSettingsReviewCycleBegin from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/GoalDetailsSettingsReviewCycleBegin";
import GoalDetailsSettingsReviewCycleGoal from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/GoalDetailsSettingsReviewCycleGoal";
import GoalDetailsSettingsReviewCycleMode from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/GoalDetailsSettingsReviewCycleMode";
import { GoalDetailsSettingsReviewMode_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/__generated__/GoalDetailsSettingsReviewMode_goal.graphql";
import { useRef } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsReviewMode = ({ goal }: { goal: GoalDetailsSettingsReviewMode_goal$key }) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsReviewMode_goal on Goal {
        archived
        type
        reviewMode {
            spacedRepetition
            cycleBegin {
                days
                months
            }
            cycleGoal {
                days
                months
            }
        }
        ...GoalDetailsSettingsReviewCycleMode_goal
        ...GoalDetailsSettingsReviewCycleBegin_goal
        ...GoalDetailsSettingsReviewCycleGoal_goal
        ...GoalDetailsSettingsReviewCycleNextReview_goal
        ...GoalDetailsSettingsRepetitionLevel_goal
    }
    `, goal)

    const cycleBeginFormHandlers = useFormHandlers<{ reviewCycleBegin: ReviewCycleInput | null }>({
        initialFormData: { reviewCycleBegin: data.reviewMode.cycleBegin },
        initialValidState: { reviewCycleBegin: true }
    })
    const { errors: cycleBeginErrors, errorsExist: cycleBeginErrorsExist } = cycleBeginFormHandlers;

    const cycleGoalFormHandlers = useFormHandlers<{ reviewCycleGoal: ReviewCycleInput | null }>({
        initialFormData: { reviewCycleGoal: data.reviewMode.cycleGoal },
        initialValidState: { reviewCycleGoal: true }
    })
    const { errors: cycleGoalErrors, errorsExist: cycleGoalErrorsExist } = cycleGoalFormHandlers;

    const eventSource = useRef(new EventTarget());

    return <Grid item xs={12}>
        <Stack direction="column" spacing={"0.25rem"}>
            <StandardPaper>
                <StandardPaperTitle noOpacityReduction>
                    <Box sx={{ opacity: 0.8 }}>Review-Modus</Box>
                    <Box sx={{ margin: "0 0 0 0.5rem" }}>
                        <FormFieldSavingIndicator eventSource={eventSource.current} />
                    </Box>
                </StandardPaperTitle>
                {data.archived ? <>
                    <GoalDetailsSettingsInactiveText>
                        Der Review-Modus kann bei einem archivierten <GoalTypeLabel type={data.type} casus="accusative" uppercase={false} /> nicht bearbeitet werden.
                    </GoalDetailsSettingsInactiveText>
                </> : <>
                    <FormControl error={true} sx={{ width: "100%" }}>
                        <Stack direction="column" spacing={"1rem"}>
                            <GoalDetailsSettingsReviewCycleMode goal={data} eventSource={eventSource} />
                            <Stack direction="row" spacing={"1rem"}>
                                <Stack direction="column">
                                    <Stack direction="row" spacing={"1rem"}>
                                        <GoalDetailsSettingsReviewCycleBegin goal={data} formHandlers={cycleBeginFormHandlers} readOnly={cycleGoalErrorsExist()} eventSource={eventSource} />
                                        {data.reviewMode.spacedRepetition && <>
                                            <GoalDetailsSettingsRepetitionLevel goal={data} eventSource={eventSource} />
                                            <GoalDetailsSettingsReviewCycleGoal goal={data} formHandlers={cycleGoalFormHandlers} readOnly={cycleBeginErrorsExist()} eventSource={eventSource} />
                                        </>}
                                    </Stack>
                                    <Box sx={{ maxWidth: "24rem" }}>
                                        {cycleBeginErrors?.reviewCycleBegin?.lessThanCycleGoal ? <FormHelperText>Bitte gib ein Intervall an, das kleiner als das Ziel-Intervall ist.</FormHelperText> :
                                            data.reviewMode.spacedRepetition && cycleGoalErrors?.reviewCycleGoal?.exceedsCycleBegin ? <FormHelperText>Bitte gib ein Intervall an, das mindestens so groß wie das Start-Intervall ist.</FormHelperText> :
                                                <></>}
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </FormControl>
                </>
                }
            </StandardPaper>
            {/* <GoalDetailsSettingsReviewCycles goal={data} /> */}
        </Stack>
    </Grid>
}
export default GoalDetailsSettingsReviewMode;