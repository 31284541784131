/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewCardMenuDeleteItem_goal = {
    readonly id: string;
    readonly " $refType": "ReviewCardMenuDeleteItem_goal";
};
export type ReviewCardMenuDeleteItem_goal$data = ReviewCardMenuDeleteItem_goal;
export type ReviewCardMenuDeleteItem_goal$key = {
    readonly " $data"?: ReviewCardMenuDeleteItem_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewCardMenuDeleteItem_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCardMenuDeleteItem_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = 'bfb8a83e4c00b23705e44577b86822f0';
export default node;
