import MuiAppBar from '@mui/material/AppBar';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import NewGoalButton from "toolbar/NewGoalButton";
import ReviewButton from "toolbar/ReviewButton";
import Divider from '@mui/material/Divider';
import AccountAvatar from "toolbar/AccountAvatar";
import LoginSignupButton from "toolbar/LoginSignupButton";
import useAccount from "authentication/_hooks/useAccount";

const AppBar = styled(MuiAppBar)`
  background: rgb(255,255,255);
`

const AppToolbar = () => {
    const { authenticatedAccount } = useAccount();

    return <AppBar
        position="fixed"
        sx={{
            width: `calc(100vw - ${useTheme().app.navigationWidth}px)`,
            height: useTheme().app.toolbarHeight,
            ml: `${useTheme().app.navigationWidth}px`,
            color: useTheme().palette.text.primary,
            boxShadow: 0
        }}
    >
        <Toolbar sx={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Stack direction="row" spacing={2}>
                        {/* <Search /> */}
                        <NewGoalButton />
                        <ReviewButton />
                    </Stack>
                </Box>
                <Box sx={{ justifyContent: "flex-end" }}>
                    <Stack
                        direction="row" spacing={1} sx={{ alignItems: "center" }}
                        divider={<Divider orientation="vertical" flexItem />}
                    >
                        <AccountAvatar />
                        {!authenticatedAccount && <LoginSignupButton />}
                        {/* <MailTool/>
                        <LogoutButton/> */}
                    </Stack>
                </Box>
            </Box>
        </Toolbar>
    </AppBar>
}
export default AppToolbar;