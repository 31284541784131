/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalDetailsTitle_goal = {
    readonly id: string;
    readonly title: string;
    readonly " $refType": "GoalDetailsTitle_goal";
};
export type GoalDetailsTitle_goal$data = GoalDetailsTitle_goal;
export type GoalDetailsTitle_goal$key = {
    readonly " $data"?: GoalDetailsTitle_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsTitle_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsTitle_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '705da82b931c19584295bafa782e7083';
export default node;
