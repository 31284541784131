import { Badge, CircularProgress } from "@mui/material";
import { GlobalsQueryProviderQuery } from "common/GlobalsQueryProvider";
import { ReviewNavItemBadge_getGlobalsReturn$key } from "navigation/__generated__/ReviewNavItemBadge_getGlobalsReturn.graphql";
import React, { useEffect, useReducer, useRef } from "react";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import useGlobalsQueryLoader from "_hooks/useGlobalsQueryLoader";
import { GlobalsQueryProviderQuery as GlobalsQueryProviderQueryType } from "common/__generated__/GlobalsQueryProviderQuery.graphql";
const graphql = require("babel-plugin-relay/macro");

const ReviewNavItemBadge = () => {
    const [queryRef] = useGlobalsQueryLoader();
    return queryRef ? <React.Suspense fallback={<CircularProgress size="1rem" />}><ReviewNavItemBadgeMain queryRef={queryRef} /></React.Suspense> : <></>
}

const ReviewNavItemBadgeMain = ({ queryRef }: { queryRef: PreloadedQuery<GlobalsQueryProviderQueryType> }) => {
    
    const query = usePreloadedQuery(GlobalsQueryProviderQuery, queryRef);
    const data = useFragment<ReviewNavItemBadge_getGlobalsReturn$key>(graphql`
        fragment ReviewNavItemBadge_getGlobalsReturn on GetGlobalsReturn {
            dueForReviewCount
        }
    `, query.getGlobals);

    const [visible, setVisible] = useReducer(() => true, false);
    const alreadyVisible = useRef(false);
    useEffect(() => {
        if(alreadyVisible.current) return;
        alreadyVisible.current = true;
        const timeout = setTimeout(() => setVisible(), 500);
        return () => clearTimeout(timeout);
    }, [setVisible, alreadyVisible])

    return <Badge invisible={!visible || data?.dueForReviewCount === 0} badgeContent={data?.dueForReviewCount} color="secondary" anchorOrigin={{horizontal: "left", vertical: "top"}} sx={{transform: "translate(-0.5rem, 0)"}} />
}
export default ReviewNavItemBadge;