import { MenuItem } from "@mui/material";
import AppSelect from "forms/AppSelect";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import { FormHandlersReturn } from "forms/useFormHandlers";
import { ReviewCycleInput } from "graphql/API";
import { Duration, DurationObjectUnits } from "luxon";
import { GoalDetailsSettingsReviewCycleGoal_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/__generated__/GoalDetailsSettingsReviewCycleGoal_goal.graphql";
import { cycleGoalSelection, CycleOption, cycleOptionsLabelMap, cycleOptionsMap, mapReviewCycleInputToCycleOption } from "main/goals/common/cycleOptions";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useEventBus, { EventTypes } from "app/useEventBus";
import { useEffect, useRef, useState } from "react";
import { useFragment } from "react-relay";
import useCalculateRepetitionLevel from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/_hooks/useCalculateRepetitionLevel";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsReviewCycleGoal = ({ goal, formHandlers, readOnly, eventSource }: {
    goal: GoalDetailsSettingsReviewCycleGoal_goal$key,
    formHandlers: FormHandlersReturn<{reviewCycleGoal: ReviewCycleInput | null}>,
    readOnly?: boolean,
    eventSource: React.MutableRefObject<EventTarget>
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsReviewCycleGoal_goal on Goal {
        id
        spacedRepetitionLevel
        reviewMode {
            spacedRepetition
            cycleBegin {
                days
                months
            }
            cycleGoal {
                days
                months
            }
        }
    }
    `, goal)

    const [selectValue, setSelectValue] = useState<CycleOption>(() => data?.reviewMode?.cycleGoal ? (mapReviewCycleInputToCycleOption(data.reviewMode.cycleGoal) ?? CycleOption.HALFYEARLY) : CycleOption.HALFYEARLY);

    const { isValidForm, formDataRef } = formHandlers;
    const { handleChange, errorsExist } = formHandlers.controlFormField({
        fieldName: "reviewCycleGoal", validations: {
            exceedsCycleBegin: () => (cycle: ReviewCycleInput) => {
                return Duration.fromObject(cycle as DurationObjectUnits) >= Duration.fromObject(data.reviewMode.cycleBegin as DurationObjectUnits)
            }
        }
    });

    const mounted = useRef(false);
    useEffect(() => {
        if(!mounted.current) {
            mounted.current = true;
            if(data.reviewMode.cycleGoal) handleChange(data.reviewMode.cycleGoal)
        }
    })

    const [commit] = useUpdateGoal();
    const eventBus = useEventBus();
    const formFieldSavingEvents = useFormFieldSavingEvents(eventSource);
    const calculateRepetitionLevel = useCalculateRepetitionLevel();
    const save = () => {
        if (!isValidForm()) return;
        if (!data.reviewMode.cycleBegin || !data.reviewMode.cycleGoal || !formDataRef.current.reviewCycleGoal || data.spacedRepetitionLevel === null) throw new Error("error while updating goal")
        formFieldSavingEvents.dispatchFormFieldSavingEvent();
        const newRepetitionLevel = calculateRepetitionLevel(data.reviewMode.cycleBegin, data.reviewMode.cycleGoal, data.reviewMode.cycleBegin, formDataRef.current.reviewCycleGoal, data.spacedRepetitionLevel);
        commit({
            variables: {
                input: {
                    id: data.id,
                    spacedRepetitionLevel: newRepetitionLevel,
                    reviewMode: Object.assign({}, data.reviewMode, { cycleGoal: formDataRef.current.reviewCycleGoal })
                }
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                formFieldSavingEvents.dispatchFormFieldSavingSuccessEvent()
            },
            onError: () => formFieldSavingEvents.dispatchFormFieldSavingErrorEvent()
        })
    }
    
    const createMenuItems = (cycleSelection: CycleOption[]) => cycleSelection.map((cycleOption, i) => <MenuItem value={cycleOption} key={i}>{cycleOptionsLabelMap.get(cycleOption)}</MenuItem>)
    return <>
        <AppSelect
            labelText={"Ziel-Intervall"}
            labelId="reviewCycleGoalLabelId"
            label={"Ziel-Intervall"}
            value={selectValue}
            error={errorsExist()}
            onChange={e => {
                const cycleOption = e.target.value as CycleOption;
                let reviewCycle: ReviewCycleInput | null = cycleOptionsMap.get(cycleOption) ?? null;
                if (!reviewCycle) return;
                setSelectValue(cycleOption);
                handleChange(reviewCycle);
                save();
            }}
            boldValue
            sx={{
                width: "12em"
            }}
            readOnly={readOnly}
        >
            {createMenuItems(cycleGoalSelection)}
        </AppSelect>
    </>
}
export default GoalDetailsSettingsReviewCycleGoal;