import { Box } from "@mui/system";
import GoalTypeLabel from "common/GoalTypeLabel";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import GoalTypeIcon from "common/_icons/GoalTypeIcon";
import { GoalType } from "graphql/API";
import GoalDetailsDescription from "main/goals/goal_details/goal_details_overview/GoalDetailsDescription";
import GoalDetailsTitle from "main/goals/goal_details/goal_details_overview/GoalDetailsTitle";
import { GoalDetailsTitleDescription_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsTitleDescription_goal.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsTitleDescription = ({ goal }: { goal: GoalDetailsTitleDescription_goal$key }) => {

    const data = useFragment(graphql`
            fragment GoalDetailsTitleDescription_goal on Goal {
                type
                archived
                ...GoalDetailsTitle_goal
                ...GoalDetailsDescription_goal
            }
        `, goal)

    return <>
        <StandardPaper boxShadow>
            <StandardPaperTitle noOpacityReduction>
                <GoalTypeIcon type={data.type as GoalType} size={"1.2rem"} sx={{ mr: "0.5rem" }} />
                <Box sx={{ opacity: 1 }}>
                    <GoalTypeLabel type={data.type as GoalType} fullName />
                    {data.archived && <> (ARCHIVIERT)</>}
                </Box>
            </StandardPaperTitle>
            <Box sx={{
                display: "flex",
                flexDirection: "column"
            }}>
                <GoalDetailsTitle goal={data} />
                <GoalDetailsDescription goal={data} />
            </Box>
        </StandardPaper>

    </>
}
export default GoalDetailsTitleDescription;