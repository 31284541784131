import { Box } from "@mui/system";
import React from "react";

const MainTitle = ({
    children
}: {
    children: React.ReactNode
}) => {
    return <Box sx={{
        display: "flex",
        fontSize: "1.5rem",
        lineHeight: "2rem",
        minHeight: "2.5rem",
        fontWeight: "300",
        boxSizing: "border-box",
        padding: "0.4rem 0 0 0",
        position: "relative",
        top: "-0.15rem"
    }}>{children}</Box>
}
export default MainTitle;