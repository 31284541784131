import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        intermediate: true
    }
    interface Theme {
        app: {
            navigationWidth: number,
            toolbarHeight: number,
            backgroundColor: string,
            modalTitleBarBackground: string,
            modalBackground: string,
            gradientColor1: string,
            gradientColor2: string,
            formFieldBackgroundHovered: string,
            defaultHoverBackground: string,
            hoverFormFieldBackground: string
        }
    }
    interface ThemeOptions {
        app?: {
            navigationWidth?: number
            toolbarHeight?: number,
            backgroundColor?: string,
            modalTitleBarBackground?: string,
            modalBackground?: string,
            gradientColor1?: string,
            gradientColor2?: string,
            formFieldBackgroundHovered?: string,
            defaultHoverBackground?: string,
            hoverFormFieldBackground?: string
        }
    }
}

export const defaultTheme = createTheme({
    breakpoints: {
        keys: ["xs", "sm", "intermediate", "md", "lg", "xl"],
        values: {
            xs: 0,
            sm: 600,
            intermediate: 750,
            md: 900,
            lg: 1200,
            xl: 1500
        }
    },
    palette: {
        mode: 'light',
        text: {
            primary: 'rgba(0,0,0,0.8)'
        },
        primary: {
            main: "rgb(0,115,200)"
        },
        secondary: {
            main: "rgb(160,0,155)",
            contrastText: "rgb(255,255,255)"
        },
        action: {
            hover: "rgba(240,247,252,1)",
            focus: "rgba(240,247,252,1)",
        },
        success: {
            main: "rgb(60,190,75)",
            contrastText: "rgb(255,255,255)"
        },
        error: {
            main: "rgb(160,0,155)"
        }
    },
    typography: {
        button: {
            fontWeight: 500
        },
        h1: {
            fontSize: 32,
            color: 'rgba(0,0,0,0.7)'
        },
        h2: {
            fontSize: 24,
            lineHeight: 1.5,
            color: 'rgba(0,0,0,0.7)'
        },
        h3: {
            fontSize: 20,
            color: 'rgba(0,0,0,0.7)'
        },
        h4: {
            fontSize: 16,
            color: 'rgba(0,0,0,0.9)'
        }
    },
    app: {
        // backgroundColor: "rgba(240,235,225,1)",
        // backgroundColor: "rgba(225,235,240,1)",
        backgroundColor: "rgba(235,230,240,1)",
        // modalTitleBarBackground: "rgba(230,235,240,1)",
        modalTitleBarBackground: "rgba(245,245,245,1)",
        // modalBackground: "rgba(240,247,252,1)",
        modalBackground: "rgba(253,251,248,1)",
        // modalBackground: "rgba(255,255,255,1)",
        // gradientColor1: "rgba(24,118,209,1)",
        gradientColor1: "rgba(0,200,135,1)",
        // gradientColor2: "rgba(0,200,200,1)",
        gradientColor2: "rgba(0,160,200,1)",
        formFieldBackgroundHovered: "rgba(240,247,252,1)",
        toolbarHeight: 64,
        navigationWidth: 256,
        defaultHoverBackground: "rgba(245,250,255)",
        hoverFormFieldBackground: "rgba(245,245,245,1)"
    }
});

export const darkThemeBackground = 'rgb(10,40,55)';
export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: darkThemeBackground,
            paper: darkThemeBackground,
        }
    }
})
