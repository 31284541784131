import { Button, Stack } from "@mui/material";
import useTimeNow from "app/useTimeNow";
import StandardPaper from "common/StandardPaper";
import StandardPaperContainer from "common/StandardPaperContainer";
import StandardPaperTitle from "common/StandardPaperTitle";
import { DateTime } from "luxon";
import GoalReviewField from "main/goals/common/fields/GoalReviewField";
import { GoalDetailsNextReview_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsNextReview_goal.graphql";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsNextReview = ({ goal }: { goal: GoalDetailsNextReview_goal$key }) => {

    const data = useFragment(graphql`
        fragment GoalDetailsNextReview_goal on Goal {
            nextReviewAt
        }
    `, goal)

    const now = useTimeNow();
    const nextReviewAt = DateTime.fromISO(data.nextReviewAt);
    const due = now >= nextReviewAt;
    const navigate = useNavigate();

    return <StandardPaper>
        <StandardPaperTitle>{due ? "It's Review Time!" : "Nächster Review"}</StandardPaperTitle>
        <StandardPaperContainer>
            <Stack sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} spacing={"1rem"}>
                <GoalReviewField fieldStyle="emphasized" reviewAt={DateTime.fromISO(data.nextReviewAt)} label={<>Nächster Review fällig</>} />
                {due && <Button variant="contained" color="secondary" onClick={() => navigate("review/new")}>
                    Review
                </Button>}
            </Stack>
        </StandardPaperContainer>
    </StandardPaper>
}
export default GoalDetailsNextReview;