import { ReviewCycleInput } from "graphql/API"
import { Duration, DurationObjectUnits } from "luxon"

export enum CycleOption {
    DAILY = "1-day",
    WEEKLY = "7-days",
    TWOWEEKS = "14-days",
    THREEWEEKS = "21-days",
    FOURWEEKS = "28-days",
    MONTHLY = "1-month",
    TWOMONTHS = "2-months",
    QUARTERLY = "3-months",
    FOURMONTHS = "4-months",
    HALFYEARLY = "6-months",
    YEARLY = "1-year",
    TWOYEARS = "2-year",
}

export const cycleOptionsDurationLabelMap: Map<CycleOption | null, string> = new Map([
    [CycleOption.DAILY, "1 Tag"],
    [CycleOption.WEEKLY, "1 Woche"],
    [CycleOption.TWOWEEKS, "2 Wochen"],
    [CycleOption.THREEWEEKS, "3 Wochen"],
    [CycleOption.FOURWEEKS, "4 Wochen"],
    [CycleOption.MONTHLY, "1 Monat"],
    [CycleOption.TWOMONTHS, "2 Monate"],
    [CycleOption.QUARTERLY, "3 Monate"],
    [CycleOption.FOURMONTHS, "4 Monate"],
    [CycleOption.HALFYEARLY, "6 Monate"],
    [CycleOption.YEARLY, "1 Jahr"],
    [CycleOption.TWOYEARS, "2 Jahre"]
])

export const cycleOptionsLabelMap: Map<CycleOption | null, string> = new Map([
    [CycleOption.DAILY, "täglich"],
    [CycleOption.WEEKLY, "wöchentlich"],
    [CycleOption.TWOWEEKS, "alle 2 Wochen"],
    [CycleOption.THREEWEEKS, "alle 3 Wochen"],
    [CycleOption.FOURWEEKS, "alle 4 Wochen"],
    [CycleOption.MONTHLY, "monatlich"],
    [CycleOption.TWOMONTHS, "alle 2 Monate"],
    [CycleOption.QUARTERLY, "alle 3 Monate"],
    [CycleOption.FOURMONTHS, "alle 4 Monate"],
    [CycleOption.HALFYEARLY, "halbjährlich"],
    [CycleOption.YEARLY, "jährlich"],
    [CycleOption.TWOYEARS, "alle 2 Jahre"]
])

export const cycleOptionsMap: Map<CycleOption, ReviewCycleInput> = new Map([
    [CycleOption.DAILY, { days: 1 }],
    [CycleOption.WEEKLY, { days: 7 }],
    [CycleOption.TWOWEEKS, { days: 14 }],
    [CycleOption.THREEWEEKS, { days: 21 }],
    [CycleOption.FOURWEEKS, { days: 28 }],
    [CycleOption.MONTHLY, { months: 1 }],
    [CycleOption.TWOMONTHS, { months: 2 }],
    [CycleOption.QUARTERLY, { months: 3 }],
    [CycleOption.FOURMONTHS, { months: 4 }],
    [CycleOption.HALFYEARLY, { months: 6 }],
    [CycleOption.YEARLY, { months: 12 }],
    [CycleOption.TWOYEARS, { months: 24 }]
])

export const beginToGoalCycleOptionsMap: Map<CycleOption, CycleOption> = new Map([
    [CycleOption.DAILY, CycleOption.HALFYEARLY],
    [CycleOption.WEEKLY, CycleOption.HALFYEARLY],
    [CycleOption.TWOWEEKS, CycleOption.HALFYEARLY],
    [CycleOption.THREEWEEKS, CycleOption.HALFYEARLY],
    [CycleOption.FOURWEEKS, CycleOption.HALFYEARLY],
    [CycleOption.MONTHLY, CycleOption.HALFYEARLY],
    [CycleOption.TWOMONTHS, CycleOption.HALFYEARLY],
    [CycleOption.QUARTERLY, CycleOption.YEARLY],
    [CycleOption.FOURMONTHS, CycleOption.YEARLY],
    [CycleOption.HALFYEARLY, CycleOption.YEARLY],
    [CycleOption.YEARLY, CycleOption.TWOYEARS],
    [CycleOption.TWOYEARS, CycleOption.TWOYEARS]
])

export const increaseRepetitionLevelMap: Map<CycleOption, CycleOption> = new Map([
    [CycleOption.DAILY, CycleOption.WEEKLY],
    [CycleOption.WEEKLY, CycleOption.TWOWEEKS],
    [CycleOption.TWOWEEKS, CycleOption.MONTHLY],
    [CycleOption.THREEWEEKS, CycleOption.TWOMONTHS],
    [CycleOption.FOURWEEKS, CycleOption.TWOMONTHS],
    [CycleOption.MONTHLY, CycleOption.TWOMONTHS],
    [CycleOption.TWOMONTHS, CycleOption.FOURMONTHS],
    [CycleOption.QUARTERLY, CycleOption.HALFYEARLY],
    [CycleOption.FOURMONTHS, CycleOption.YEARLY],
    [CycleOption.HALFYEARLY, CycleOption.YEARLY],
    [CycleOption.YEARLY, CycleOption.TWOYEARS],
    [CycleOption.TWOYEARS, CycleOption.TWOYEARS]
])

export const mapReviewCycleInputToCycleOption = (cycle: ReviewCycleInput | null): CycleOption | null => {
    if(!cycle) return null;
    return allCycleOptions.reduce((a, c) => {
        if (a !== null) return a;
        const b = cycleOptionsMap.get(c);
        if (!b) return a;
        if (b.days === (cycle.days ?? undefined) && b.months === (cycle.months ?? undefined)) return c;
        return a;
    }, null as CycleOption | null)
}

export const allCycleOptions = [
    CycleOption.DAILY,
    CycleOption.WEEKLY,
    CycleOption.TWOWEEKS,
    CycleOption.THREEWEEKS,
    CycleOption.FOURWEEKS,
    CycleOption.MONTHLY,
    CycleOption.TWOMONTHS,
    CycleOption.QUARTERLY,
    CycleOption.FOURMONTHS,
    CycleOption.HALFYEARLY,
    CycleOption.YEARLY,
    CycleOption.TWOYEARS
]
export const cycleBeginSelection = allCycleOptions.slice();

const [, ...cycleGoalSelection_] = cycleBeginSelection;
export const cycleGoalSelection = cycleGoalSelection_;

export const typecastDurationObjectUnits = (a: ReviewCycleInput): DurationObjectUnits => ({days: a.days ?? undefined, months: a.months ?? undefined });

export const cycleOptionDuration = (c: CycleOption): Duration | null => {
    const a = cycleOptionsMap.get(c);
    if (!a) return null;
    return Duration.fromObject(typecastDurationObjectUnits(a));
}

export const getRepetitionLevels = (a: CycleOption | null, b: CycleOption | null): CycleOption[] | null => {
    if(!a || !b) return null;
    const aDuration = cycleOptionDuration(a);
    const bDuration = cycleOptionDuration(b);
    if (!aDuration || !bDuration) return null;
    if (aDuration > bDuration) return [b];
    if(a === CycleOption.FOURWEEKS && b === CycleOption.MONTHLY) return [b];
    const result: CycleOption[] = [a];
    if (+aDuration === +bDuration) return result;
    const addIncreasedCycle = (c: CycleOption | null) => {
        if (!c) return;
        const cDuration = cycleOptionDuration(c);
        if (!cDuration) return;
        if (cDuration >= bDuration) return;
        if (c === CycleOption.FOURWEEKS && b === CycleOption.MONTHLY) return;
        if (c === CycleOption.MONTHLY && b === CycleOption.FOURWEEKS) return;
        result.push(c);
        addIncreasedCycle(increaseRepetitionLevelMap.get(c) ?? null)
    }
    addIncreasedCycle(increaseRepetitionLevelMap.get(a) ?? null);
    result.push(b);
    return result;
}

export const getRepetitionLevelEquivalent = (cycleOption: CycleOption, repetitionLevels: CycleOption[]): number | null => {
    return repetitionLevels.reduce((a, c, i, array) => {
        if (a !== null) return a;
        const durationC = cycleOptionDuration(c);
        const durationCycleOption = cycleOptionDuration(cycleOption);
        if (!durationC || !durationCycleOption) return null;
        if (durationC >= durationCycleOption) return i;
        if (c === CycleOption.MONTHLY && cycleOption === CycleOption.FOURWEEKS) return i;
        if (cycleOption === CycleOption.MONTHLY && c === CycleOption.FOURWEEKS) return i;
        if (i === array.length - 1) return i;
        return null;
    }, null as number | null)
}

export const getRepetitionLevelsByReviewCycleInput = (cycleBegin: ReviewCycleInput, cycleGoal: ReviewCycleInput) => getRepetitionLevels(mapReviewCycleInputToCycleOption(cycleBegin), mapReviewCycleInputToCycleOption(cycleGoal));