import { Box, CircularProgress, Grid } from "@mui/material";
import useEventBus, { EventTypes } from "app/useEventBus";
import useTimeNow from "app/useTimeNow";
import AppButton from "common/AppButton";
import useDateCalculations from "common/useDateCalculations";
import useSnackbar from "common/useSnackbar";
import useFormHandlers from "forms/useFormHandlers";
import { GoalType } from "graphql/API";
import GoalEditForm, { GoalFormData } from "main/goals/goal_edit/GoalEditForm";
import useCreateGoal from "main/goals/__hooks/useCreateGoal";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainGrid from "main/MainGrid";
import MainNavigateBackButton from "main/MainNavigateBackButton";
import MainTitle from "main/MainTitle";
import { useNavigate } from "react-router";

const NewGoal = () => {

    const now = useTimeNow();
    const formHandlers = useFormHandlers<GoalFormData>({
        initialFormData: {
            title: "",
            description: "",
            goalType: GoalType.continuous,
            dueDate: null,
            reviewCycleBegin: { days: 14 },
            reviewCycleGoal: { months: 6 },
            reviewCycleStartDate: now.plus({ days: 14 }),
            spacedRepetition: true,
            completion: 0,
            status: 1
        },
        initialValidState: {
            title: false,
            description: true,
            goalType: true,
            dueDate: false,
            reviewCycleBegin: true,
            reviewCycleGoal: true,
            reviewCycleStartDate: true,
            spacedRepetition: true,
            completion: true,
            status: true
        },
        initiallyExcludeFromValidation: {
            dueDate: true
        }
    })
    const { formData, isValidForm, validateForm, setAllVisited } = formHandlers;

    const showSnackbar = useSnackbar();

    const navigate = useNavigate();
    const { setDefaultHour } = useDateCalculations();

    const [commit, mutationInProgress] = useCreateGoal();
    const { dispatchEvent } = useEventBus();
    const createGoal = () => {
        commit({
            variables: {
                input: {
                    type: formData.goalType,
                    title: formData.title,
                    description: formData.description,
                    reviewMode: {
                        spacedRepetition: formData.spacedRepetition,
                        cycleBegin: formData.reviewCycleBegin,
                        cycleGoal: formData.reviewCycleGoal
                    },
                    nextReviewAt: setDefaultHour(formData.reviewCycleStartDate).toISO(),
                    dueDate: formData.dueDate ? setDefaultHour(formData.dueDate).toISO() : undefined,
                    status: formData.status,
                    completion: formData.goalType === GoalType.continuous ? Math.round(formData.completion * 10) / 10 : Math.round(formData.completion * 100) / 100
                }
            },
            onCompleted: data => {
                showSnackbar({
                    message: <>Ziel <b>ID-{data?.createGoal?.goal?.readableId}</b> wurde erfolgreich erstellt!</>
                })
                dispatchEvent(new CustomEvent(EventTypes.GOAL_CREATED));
                navigate("/goals");
            },
            onError: error => console.error(error)
        })
    }

    return <>
        <MainBar>
            <MainNavigateBackButton onClick={() => {
                navigate("/goals")
            }
            } />
            <MainTitle>
                Neues Ziel erstellen
            </MainTitle>
        </MainBar>
        <MainContent>
            <MainContainer>
                <MainGrid>
                    <Grid item xs={12}>
                        <GoalEditForm formHandlers={formHandlers} />
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "2rem 0 0 0" }}>
                            <div onClick={() => {
                                validateForm();
                                setAllVisited();
                                if (isValidForm()) createGoal()
                            }}>
                                {mutationInProgress && <CircularProgress />}
                                {!mutationInProgress && <AppButton
                                    disabled={!isValidForm()}
                                >
                                    Neues Ziel erstellen
                                </AppButton>}
                            </div>
                        </Box>
                    </Grid>
                </MainGrid>
            </MainContainer>
        </MainContent>
    </>
}
export default NewGoal;