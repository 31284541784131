import { Button, Dialog, DialogActions, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import BuildIcon from '@mui/icons-material/BuildRounded';
import AppTextField from "forms/AppTextField";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setDevelopmentNow } from "app/TimeSlice";
import { DateTime } from "luxon";
import DialogContentApp from "common/DialogContentApp";
import useMount from "common/useMount";
import DialogTitleWithIcon from "common/DialogTitleWithIcon";
import useEventBus, { createCustomEventTimeSet } from "app/useEventBus";

const DevelopmentToolsTimeDialog = ({ onClose }: { onClose: () => void }) => {

    const now = useRef<string>()
    const dispatch = useDispatch();
    const { dispatchEvent } = useEventBus();

    const setTime = () => {
        if (now.current === undefined) now.current = "";
        const nowDateTime = DateTime.fromISO(now.current);
        if (!nowDateTime.isValid) {
            console.warn("No valid input. Resetting time to now.");
            dispatch(setDevelopmentNow(null));
            setOpen(false);
            dispatchEvent(createCustomEventTimeSet(DateTime.now()));
            return;
        }
        dispatch(setDevelopmentNow(nowDateTime.toISO()))
        setOpen(false);
        dispatchEvent(createCustomEventTimeSet(nowDateTime));
    }

    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
        <DialogTitleWithIcon icon={<BuildIcon color="warning" />}>
            DEV: Zeitpunkt-Simulation
        </DialogTitleWithIcon>
        <DialogContentApp>
            <AppTextField
                autoFocus
                label="Zeitpunkt"
                onChange={e => now.current = e.target.value}
                onKeyDown={e => {
                    if(e.key === 'Enter') {
                        e.stopPropagation();
                        e.preventDefault();
                        setTime();
                    }
                }}
            />
        </DialogContentApp>
        <DialogActions>
            <Button onClick={setTime}>Zeitpunkt setzen</Button>
        </DialogActions>
    </Dialog>
}

const DevelopmentToolsTime = () => {

    const {mountComponent, unmount} = useMount();

    return <>
        <MenuItem onClick={() => mountComponent(<DevelopmentToolsTimeDialog onClose={unmount} />)}>
            <ListItemIcon><BuildIcon color="warning" /></ListItemIcon>
            <ListItemText>DEV: Zeitpunkt-Simulation</ListItemText>
        </MenuItem>
    </>
}
export default DevelopmentToolsTime;