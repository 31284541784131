import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import AppTextField from "forms/AppTextField";
import { ControlFormFieldReturn } from "forms/useFormHandlers";

const GoalDetailsReviewComment = ({handleChange, value}: ControlFormFieldReturn<string>) => {

    return <>
        <StandardPaper>
            <StandardPaperTitle>
                Kommentar (optional)
            </StandardPaperTitle>
                <AppTextField
                    minRows={3}
                    onBlur={(e) => {
                        handleChange(e.target.value);
                    }}
                    multiline
                    label="Kommentar zum Review"
                    sx={{
                        width: "100%"
                    }}
                    inputProps={{
                        sx: {
                            fontSize: "1rem",
                            pt: "0.75rem",
                            pb: "1rem",
                            fontFamily: "Open Sans",
                            fontStyle: "italic"
                        }
                    }}
                />
        </StandardPaper>
    </>
}
export default GoalDetailsReviewComment;