import { Tooltip } from "@mui/material";
import AppButton from "common/AppButton";
import { useNavigate } from "react-router";

const NewGoalButton = () => {
    const navigate = useNavigate();
    return <>
        <Tooltip title="Erstelle ein neues Ziel oder ein Working Agreement" arrow>
            <AppButton
                onClick={() => navigate("/goals/new")}
                data-cy="newGoalButton"
            >
                Neues Ziel
            </AppButton>
        </Tooltip>
    </>
}
export default NewGoalButton;