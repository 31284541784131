import useTimeNow from "app/useTimeNow";
import { ReviewCycleInput } from "graphql/API";
import { DateTime, Interval } from "luxon";

const useDateCalculations = () => {

    const now = useTimeNow();

    const setDefaultHour = (d: DateTime): DateTime => d.set({hour: 12, minute: 0, second: 0});

    const flatDateTime = (d: DateTime): DateTime => {
        try {
            return DateTime.fromObject({ day: d.day, month: d.month, year: d.year, hour: 0, minute: 0, second: 0, millisecond: 0 })
        } catch (e) {
            console.error(e);
            return d;
        }
    }

    const getNextReviewDate = (begin: DateTime, cycle: ReviewCycleInput): DateTime => {
        const flatBegin = flatDateTime(begin);
        if (cycle.days) return flatBegin.plus({ days: cycle.days });
        if (cycle.months) {
            const sum = flatBegin.month + cycle.months;
            const additionalYears = Math.ceil(sum / 12) - 1;
            return DateTime.fromObject({
                day: flatBegin.day,
                month: additionalYears > 0 ? sum % 12 : sum,
                year: flatBegin.year + additionalYears
            })
        }
        return flatBegin;
    }

    const dateIsNotInThePast = (d: DateTime, allowToday: boolean = true): boolean => {
        const flatDate = flatDateTime(d);
        const flatToday = flatDateTime(now);
        return allowToday ? flatDate >= flatToday : flatDate > flatToday;
    }

    const getLocaleDateString = (d: DateTime) => d.toLocaleString({ day: "2-digit", month: "2-digit", year: "numeric" })
    const getLocaleTimeString = (d: DateTime) => d.toLocaleString({ hour: "2-digit", minute: "2-digit" })

    const equalDates = (d1: DateTime, d2: DateTime) => d1.diff(d2).milliseconds === 0;

    const getReadableDuration = (dateTime: DateTime, options?: { precision?: "default" | "day", diffDateTime?: DateTime, since?: boolean }) => {
        const precision: "default" | "day" = options?.precision ?? "default";
        const a: DateTime = dateTime;
        const b: DateTime = options?.diffDateTime ?? now;
        const pastDuration = a < b;
        let interval: Interval = pastDuration ? Interval.fromDateTimes(a, b) : Interval.fromDateTimes(b, a);
        const d = interval.toDuration("hours")
        if (d.as("hours") >= 24 || precision === "day") {
            interval = pastDuration ? Interval.fromDateTimes(flatDateTime(a), flatDateTime(b)) : Interval.fromDateTimes(flatDateTime(b), flatDateTime(a));
        }

        const ago_ = options?.since ? "seit " : "";
        const ago = options?.since ? "seit" : "vor";

        const diffDays = interval.toDuration("days")

        if (diffDays.days < 1) {
            if (precision === "day") {
                if (a.day === b.day && a.month === b.month && a.year === b.year) return ago_ + "heute"
            } else {
                if (Math.round(diffDays.as("minutes")) < 60) {
                    if (diffDays.as("minutes") < 1) {
                        return ago_ + "gerade eben";
                    }
                    return `${!pastDuration ? "in" : ago} ${Math.round(diffDays.as("minutes"))} Minute${Math.round(diffDays.as("minutes")) > 1 ? "n" : ""}`;
                }
                if (d.as("hours") < 8 || a.day === b.day) {
                    return `${!pastDuration ? "in" : ago} ${Math.round(diffDays.as("hours"))} Stunde${Math.round(diffDays.as("hours")) > 1 ? "n" : ""}`;
                }
            }
        }

        if (diffDays.as("days") <= 1) {
            return !pastDuration ? "morgen" : ago_ + "gestern";
        }

        if (diffDays.as("days") <= 2) {
            return !pastDuration ? "übermorgen" : ago_ + "vorgestern";
        }

        if (Math.round(diffDays.as("days")) === 7) {
            return `${!pastDuration ? "in" : ago} 1 Woche`;
        }

        if (diffDays.as("weeks") < 2) {
            return `${!pastDuration ? "in" : ago} ${Math.round(diffDays.as("days"))} Tag${Math.round(diffDays.as("days")) > 1 ? "en" : ""}`;
        }

        if (Math.round(diffDays.as("weeks")) < 8) {
            return `${!pastDuration ? "in" : ago} ${Math.round(diffDays.as("weeks"))} Woche${Math.round(diffDays.as("weeks")) > 1 ? "n" : ""}`;
        }

        if (Math.round(diffDays.as("weeks")) === 8) {
            return `${!pastDuration ? "in" : ago} 2 Monaten`;
        }

        const diffYearsMonthsWeeks = interval.toDuration(["years", "months", "weeks"]);
        let years = Math.round(diffYearsMonthsWeeks.years);
        let months = Math.round(diffYearsMonthsWeeks.months);
        const weeks = Math.round(diffYearsMonthsWeeks.weeks);

        if (diffYearsMonthsWeeks.years < 1) {
            if (months === 11 && weeks === 4) return "in 1 Jahr";
            if (weeks === 1 || weeks === 2 || weeks === 3) {
                return `${!pastDuration ? "in" : ago} ${months} Monat${months > 1 ? "en" : ""}`
                    + ` und ${weeks} Woche${weeks > 1 ? "n" : ""}`;
            } else {
                const m = months + (weeks === 4 ? 1 : 0);
                return `${!pastDuration ? "in" : ago} ${m} Monat${m > 1 ? "en" : ""}`;
            }
        }

        const diffYearsMonths = interval.toDuration(["years", "months"]);
        years = Math.round(diffYearsMonths.years);
        months = Math.round(diffYearsMonths.months);

        if (months !== 0 && months !== 12) {
            return `${!pastDuration ? "in" : ago} ${years} Jahr${years > 1 ? "en" : ""}`
                + ` und ${months} Monat${months > 1 ? "en" : ""}`;
        } else {
            const y = years + (months === 12 ? 1 : 0);
            return `${!pastDuration ? "in" : ago} ${y} Jahr${y > 1 ? "en" : ""}`;
        }

    }

    const flatDue = (d: DateTime) => flatDateTime(now) >= flatDateTime(d);

    const getFlatISOString = (d: DateTime) => d.toISO().split("T")[0];

    const getFlatISORequestString = (d: DateTime) => getFlatISOString(d) + "T23:59:59";

    return {
        setDefaultHour,
        flatDateTime,
        flatDue,
        getFlatISOString,
        getFlatISORequestString,
        getNextReviewDate,
        dateIsNotInThePast,
        getLocaleDateString,
        getLocaleTimeString,
        equalDates,
        getReadableDuration
    }
}
export default useDateCalculations;