import Goal from "main/goals/goal_details/Goal";
import { Route, Routes } from "react-router";

const GoalRoute = () => {

    return <Routes>
        <Route path="*" element={<></>} />
        <Route path="/goal/:readableId/*" element={<Goal key="1"/>} />
        <Route path="/review/goal/:readableId/*" element={<Goal key="2"/>} />
        <Route path="/archive/goal/:readableId/*" element={<Goal key="3"/>} />
    </Routes>
}
export default GoalRoute;