import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import AppRadioButton from "forms/AppRadioButton";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import { GoalType } from "graphql/API";
import { GoalFormData } from "main/goals/goal_edit/GoalEditForm";
import GoalReviewCycleModeTooltipConst from "main/goals/goal_edit/GoalReviewCycleModeTooltipConst";
import GoalReviewCycleModeTooltipSpaced from "main/goals/goal_edit/GoalReviewCycleModeTooltipSpaced";
import { useEffect, useState } from "react";

const GoalReviewModeSelect = ({ value, handleChange, formData, visited, setVisited }: ControlFormFieldReturn<boolean> & { formData: GoalFormData }) => {

    const [goalType, setGoalType] = useState<GoalType>();
    useEffect(() => {
        if (goalType === formData.goalType) return;
        setGoalType(formData.goalType);
        if (visited) return;
        if (formData.goalType === GoalType.continuous) {
            handleChange(true);
        } else {
            handleChange(false);
        }
    }, [formData.goalType, visited, goalType, setGoalType, handleChange])

    const handleClick = (value: boolean) => {
        setVisited(true);
        handleChange(value);
    }

    return <>
        <Grid item md={6} sm={12}>
            <AppRadioButton
                value={"spacedRepetition"}
                selected={value}
                onClick={() => handleClick(true)}
                onChange={e => handleClick(true)}
                // icon={<GoalTypeIcon type={GoalType.continuous} size={"2.5em"} />}
                title={<><Box component="span" sx={{fontWeight: 500}}>Spaced Repetition</Box>{formData.goalType === GoalType.continuous && <> (empfohlen)</>}</>}
                tooltip={<GoalReviewCycleModeTooltipSpaced />}
                small
            />
        </Grid>
        <Grid item md={6} sm={12}>
            <AppRadioButton
                value={"simple"}
                selected={!value}
                onClick={() => handleClick(false)}
                onChange={e => handleClick(false)}
                // icon={<GoalTypeIcon type={GoalType.continuous} size={"2.5em"} />}
                title={<><Box component="span" sx={{fontWeight: 500}}>Gleichbleibender Abstand</Box>{formData.goalType === GoalType.completable && <> (empfohlen)</>}</>}
                tooltip={<GoalReviewCycleModeTooltipConst />}
                small
            />
        </Grid>
    </>
}
export default GoalReviewModeSelect;