/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteGoalMenuItem_goal = {
    readonly id: string;
    readonly readableId: number;
    readonly " $refType": "DeleteGoalMenuItem_goal";
};
export type DeleteGoalMenuItem_goal$data = DeleteGoalMenuItem_goal;
export type DeleteGoalMenuItem_goal$key = {
    readonly " $data"?: DeleteGoalMenuItem_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"DeleteGoalMenuItem_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteGoalMenuItem_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readableId",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '2e18993027aa754e47c344806817b9eb';
export default node;
