import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GoalType } from "graphql/API";

const GoalCardId = ({ type, id }: { type: GoalType, id: string }) => {
    return <Box sx={{ minWidth: "4.25rem", display: "flex", justifyContent: "flex-start", padding: "0.9rem 0.6rem", boxSizing: "border-box" }}>
        <Typography variant="body2" sx={{ fontSize: "1rem", lineHeight: "auto", fontWeight: "300", whiteSpace: "nowrap" }}>
            {id}
        </Typography>
    </Box>
}
export default GoalCardId;