import { Stack } from "@mui/material"
import StandardPaper from "common/StandardPaper"
import StandardPaperContainer from "common/StandardPaperContainer"
import StandardPaperTitle from "common/StandardPaperTitle"
import StandardPaperVMargin from "common/StandardPaperVMargin"
import { FormHandlersReturn } from "forms/useFormHandlers"
import { GoalType } from "graphql/API"
import { GoalFormData } from "main/goals/goal_edit/GoalEditForm"
import GoalCompletionSelect from "main/goals/goal_edit/goal_completion_select/GoalCompletionSelect"
import GoalStatusSelect from "main/goals/goal_edit/goal_completion_select/GoalStatusSelect"

const GoalStatusCompletionSelect = ({ formHandlers }: { formHandlers: FormHandlersReturn<GoalFormData> }) => {
    const { controlFormField, formData } = formHandlers;
    const completable = formData.goalType === GoalType.completable;
    return <Stack direction="column" spacing={"0.25rem"}>
        {completable && <StandardPaper>
            <StandardPaperTitle>Wie schätzt ihr die derzeitige Zielerreichung ein?</StandardPaperTitle>
            <StandardPaperVMargin>
                <StandardPaperContainer>
                    <GoalCompletionSelect
                        {...controlFormField<number>({ fieldName: "completion" })}
                    />
                </StandardPaperContainer>
            </StandardPaperVMargin>
        </StandardPaper>}
        <StandardPaper>
            <StandardPaperTitle>
                {completable ? <>Wie zuversichtlich seid ihr, das Ziel wie geplant zu erreichen?</> : <>Wie schätzt ihr die derzeitige Erfüllung des Working Agreements ein?</>}
            </StandardPaperTitle>
            <StandardPaperVMargin>
                <StandardPaperContainer>
                    <GoalStatusSelect
                        {...controlFormField<number>({ fieldName: "status" })}
                    />
                </StandardPaperContainer>
            </StandardPaperVMargin>
        </StandardPaper>
    </Stack>
}
export default GoalStatusCompletionSelect;