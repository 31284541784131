import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material"
import { styled } from "@mui/material/styles";

const TooltipWithLargerText = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "40rem",
        fontSize: "0.875rem",
        lineHeight: "1.6em",
        fontWeight: "normal",
        padding: "1rem",
        background: "rgba(255,255,255,1)",
        border: "1px solid rgba(0,0,0,0.15)",
        color: "rgba(0,0,0,0.8)",
        boxShadow: "0 0.1rem 0.5rem 0.5rem rgba(0,0,0,0.05)"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "rgba(255,255,255,1)"
    },
}))

export default TooltipWithLargerText;