import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import NavBlock from "./NavBlock";
import DashboardIcon from '@mui/icons-material/DashboardRounded';
import GoalsIcon from '@mui/icons-material/FormatListBulletedRounded';
import NavItem from "navigation/NavItem";
import SettingsIcon from '@mui/icons-material/Settings';
import ReviewNavItem from "navigation/ReviewNavItem";
import ImprintAndTerms from "navigation/ImprintAndTerms";
import AppVersion from "navigation/AppVersion";
import NavSubItem from "navigation/NavSubItem";
import GoalsCountBadge from "navigation/GoalsCountBadge";
import ArchiveCountBadge from "navigation/ArchiveCountBadge";

const NavContent = () => {
    return (<>
        <Box sx={{ height: `calc(100vh - ${useTheme().app.toolbarHeight}px)`, display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1 }}>
                <NavBlock
                    categoryName="Dashboard"
                >
                    <NavItem
                        icon={<DashboardIcon fontSize="inherit" />}
                        label="Übersicht"
                        path="/dashboard"
                        activePattern={/^\/(dashboard)*$/}
                    />
                </NavBlock>
                <NavBlock
                    categoryName="Ziele"
                >
                    <NavItem
                        icon={<GoalsIcon fontSize="inherit" />}
                        label="Ziele"
                        path="/goals"
                        activePattern={/^\/(goal|archive)/}
                    >
                        <NavSubItem
                            path="/goals"
                            activePattern={/^\/goal/}
                            badge={<GoalsCountBadge />}
                        >
                            aktuelle Ziele
                        </NavSubItem>
                        <NavSubItem
                            path="/archive"
                            activePattern={/^\/archive/}
                            badge={<ArchiveCountBadge />}
                        >
                            Archiv
                        </NavSubItem>
                    </NavItem>
                    <ReviewNavItem />
                </NavBlock>
            </Box>
            <Box sx={{ paddingBottom: "1rem" }}>
                <NavItem
                    icon={<SettingsIcon fontSize="inherit" />}
                    label="Einstellungen"
                    path="/settings"
                />
                <ImprintAndTerms />
                <AppVersion />
            </Box>
        </Box>
    </>)
}
export default NavContent;