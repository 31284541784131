import useAppConsole from "app/useAppConsole";
import useAccount from "authentication/_hooks/useAccount";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const AccountAuthentication = ({ children }: { children: React.ReactNode }) => {

    const [mounted, setMounted] = useState(false);

    const dispatch = useDispatch();
    const { getAndSetGuestAccountId, updateStores } = useAccount();
    const appConsole = useAppConsole("AccountAuthentication");

    useEffect(() => {
        if (mounted) return;
        setMounted(true);

        getAndSetGuestAccountId();

        (async () => {
            try {
                await updateStores();
            } catch(e) {
                appConsole.log("no authenticated user... defaulting to guest account", 1)
            }
        })()

    }, [mounted, setMounted, dispatch, getAndSetGuestAccountId, appConsole, updateStores])

    return <>{children}</>
}
export default AccountAuthentication;