import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ThemeProvider } from '@mui/material/styles';
import { Box, styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { defaultTheme } from "themes";

const MainMenuListItemIcon = styled(ListItemIcon)`
    min-width: 2.5em;
    opacity: 0.7;
    display: flex;
    align-items: center;
`

const MainMenuListItemButton = styled(ListItemButton)`
    padding: 12px 1.5rem 12px 1rem;
    align-items: center;
`

const MainMenuListItemText = styled(ListItemText)(() => ({
    opacity: 0.9,
    display: "flex",
    margin: "0",
    alignItems: "center",
    "& .MuiTypography-root": {
        lineHeight: "unset"
    }
}))


const NavItem = ({
    icon,
    label,
    children,
    badge = <></>,
    chipColor,
    path,
    activePattern = new RegExp(`^${path}`)
}: {
    icon: React.ReactNode,
    label: string,
    children?: React.ReactNode,
    badge?: React.ReactNode,
    chipColor?: any,
    path: string,
    activePattern?: RegExp
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const selected = activePattern.test(location.pathname);
    return <Box sx={{
        background: selected ? "rgba(15,50,70,1)" : undefined,
        transition: "0.2s"
    }}>
        <MainMenuListItemButton
            onClick={() => navigate(path)}
        // selected={selected}
        >
            <MainMenuListItemIcon>
                <Box sx={{ fontSize: "1.5rem", display: "flex" }}>
                    {icon}
                </Box>
            </MainMenuListItemIcon>
            <MainMenuListItemText>
                {label}
            </MainMenuListItemText>
            <ThemeProvider theme={defaultTheme}>
                {badge}
            </ThemeProvider>
            {/* {children && <ExpandLessIcon />} */}
        </MainMenuListItemButton>
        {children && <List sx={{ padding: 0, margin: "0 0 1em 0" }} disablePadding>
            {children}
        </List>}
    </Box>
}
export default NavItem;