import { styled } from "@mui/material";
import { SxProps } from "@mui/system";
import { Link, LinkProps } from "react-router-dom";


const AppLink = ({ children, openInNewTab, sx = {}, target, rel, ...props }: LinkProps & {
    openInNewTab?: boolean,
    sx?: SxProps
}) => {
    const CustomLink = styled(Link, {
        shouldForwardProp: () => true
    })(({ theme }) => Object.assign({
        cursor: "pointer",
        color: theme.palette.primary.main
    }, sx as any))
    return <CustomLink
        target={openInNewTab ? "_blank" : target}
        rel={openInNewTab ? "noreferrer" : rel}
        {...props}
    >
        {children}
    </CustomLink>
}
export default AppLink;