import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import MainContainer from "main/MainContainer";
import React, { useEffect, useReducer } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";

export type MainTabParameters = {
    label: React.ReactNode,
    value: string,
    pathnameRegExp?: string
}

const MainTabs = ({ tabs, children,
    deriveSelectedTabFromURL = (location: Location) => tabs.filter(tab => new RegExp(tab.pathnameRegExp ?? `${tab.value}$`).test(location.pathname)).map(tab => tab.value).pop() ?? tabs[0].value
}: {
    tabs: MainTabParameters[],
    children: React.ReactNode,
    deriveSelectedTabFromURL?: (location: Location) => string
}) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedTab, selectedTabDispatch] = useReducer(() => deriveSelectedTabFromURL(location), deriveSelectedTabFromURL(location))

    useEffect(() => {
        selectedTabDispatch();
    }, [location]);

    return <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ background: "rgba(255,255,255,0.7)", width: "100%", padding: "0.5rem 0 0 0", mt: "1px", boxSizing: "border-box" }}>
            <MainContainer>
                <Tabs value={selectedTab} onChange={(e, v) => {
                    navigate(`${(v as string).substring(1)}`)
                }} >
                    {tabs.map((tab, i) => <Tab key={i} label={tab.label} value={tab.value} id={tab.value} />)}
                </Tabs>
            </MainContainer>
        </Box>
        {children}
    </Box>
}
export default MainTabs;