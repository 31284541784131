import GuestAccountIcon from '@mui/icons-material/PersonOutlineRounded';
import { Button, Collapse, Dialog, DialogActions, Link, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import useAccount from "authentication/_hooks/useAccount";
import AnimationWrapper from "common/AnimationWrapper";
import DialogContentApp from "common/DialogContentApp";
import DialogTitleWithIcon from "common/DialogTitleWithIcon";
import TextFeature from "common/TextFeature";
import useMount from "common/useMount";
import WarningWithIcon, { WarningWithIconEmph } from "common/WarningWithIcon";
import { useState } from "react";

export const GuestToolsShowIdDialog = ({ onClose }: { onClose: () => void }) => {
    const guestAccountId = useAccount().getGuestAccountId();
    const [showExplanation, setShowExplanation] = useState(false);
    const [showId, setShowId] = useState(false);
    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
        <DialogTitleWithIcon icon={<GuestAccountIcon />}>
            Gast-ID
        </DialogTitleWithIcon>
        <DialogContentApp>
            <WarningWithIcon sx={{margin: "0 0 1rem 0"}}>
            Wenn du deine Gast-ID mit anderen Personen teilst, haben diese <WarningWithIconEmph>vollen Zugriff auf deinen vAlign-Workspace!</WarningWithIconEmph>
            </WarningWithIcon>
            Die aktuell verwendete Gast-ID lautet:
            <Box component="p" sx={{ fontWeight: 900, fontSize: "1.1rem" }}>
                {!showId && <Link sx={{fontWeight: 400, cursor: "pointer"}} onClick={() => setShowId(true)} role="button">Gast-ID einblenden</Link>}
                {showId && guestAccountId}
            </Box>
            <p>
                Teile diese Gast-ID mit anderen Personen, um ihnen zu erlauben, auf deinen vAlign-Workspace zuzugreifen.
            </p>
            {!showExplanation && <Box><Link sx={{ cursor: "pointer", fontSize: "0.875rem" }} onClick={() => setShowExplanation(true)}>
                Weitere Informationen zur Gast-ID einblenden
            </Link></Box>}
            <Collapse in={showExplanation}>
                <AnimationWrapper>
                    <Box sx={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}>
                        Die Gast-ID wird in deinem Browser gespeichert und bleibt gespeichert, auch wenn du deinen Browser schließt.
                        <p>
                            Rufst du einen Link auf, den ein anderen Nutzer mit dir über die <TextFeature>"Link teilen"</TextFeature>-Funktion
                            geteilt hat, wird die im Link enthaltene Gast-ID für die Dauer deiner Browser-Session verwendet. Schließe und öffne deinen Browser erneut, um wieder mit deiner
                            ursprünglichen Gast-ID zu arbeiten.
                        </p>
                        <p>
                            Nutze die <TextFeature>"Gast-ID setzen"</TextFeature>-Funktion, um dauerhaft mit einer anderen Gast-ID zu arbeiten.
                        </p>
                    </Box>
                </AnimationWrapper>
            </Collapse>
        </DialogContentApp>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>OK, verstanden</Button>
        </DialogActions>
    </Dialog>
}

const GuestToolsShowId = () => {

    const { mountComponent, unmount } = useMount();

    return <>
        <MenuItem onClick={() => mountComponent(<GuestToolsShowIdDialog onClose={unmount} />)}>
            <ListItemIcon><GuestAccountIcon /></ListItemIcon>
            <ListItemText>Gast-ID einblenden</ListItemText>
        </MenuItem>
    </>
}
export default GuestToolsShowId;