import { selectAuthenticatedUser, selectLoggingIn, setAuthenticatedUser, setLoggingIn, unsetAuthenticatedUser } from "authentication/_store/AccountSlice";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import useDeleteAccount from "main/settings/account/__hooks/useDeleteAccount";
import { GUEST_NOTE_AFFIRMED_SESSION_STORAGE_NAME } from "GuestNote";

export const ACCOUNT_EXISTS_LOCAL_STORAGE_NAME = "valign_account_exists";
export const ACCOUNT_LOGGED_IN_SESSION_STORAGE_NAME = "valign_account";
export const GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME = "valign_guest_account_id";
export const GUEST_ACCOUNT_AFFIRMED_TERMS_LOCALSTORAGE_NAME = "valign_guest_affirmed_terms";

export const getGuestAccountId = () => window.sessionStorage.getItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME) ?? window.localStorage.getItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME) ?? null;

const useAccount = () => {
    const dispatch = useDispatch();
    const [commitDeleteAccountMutation] = useDeleteAccount();
    const authenticatedAccount = useSelector(selectAuthenticatedUser);

    const account = {
        authenticatedAccount,
        currentAuthenticatedUser: async () => await Auth.currentAuthenticatedUser(),
        signup: async (email: string, password: string) => {
            return await Auth.signUp({
                username: email,
                password
            });
        },
        login: async (email: string, password: string, updateStores?: boolean) => {
            await Auth.signIn(email, password);
            if(updateStores) await account.updateStores();
        },
        updateStores: async () => {
            const user = await account.currentAuthenticatedUser();
            window.sessionStorage.setItem(ACCOUNT_LOGGED_IN_SESSION_STORAGE_NAME, "1");
            window.sessionStorage.removeItem(GUEST_NOTE_AFFIRMED_SESSION_STORAGE_NAME);
            window.localStorage.setItem(ACCOUNT_EXISTS_LOCAL_STORAGE_NAME, "1");
            dispatch(setAuthenticatedUser({
                email: user.attributes.email
            }))
        },
        setLoggingIn: (b: boolean) => dispatch(setLoggingIn(b)),
        loggingIn: useSelector(selectLoggingIn),
        accountExists: window.localStorage.getItem(ACCOUNT_EXISTS_LOCAL_STORAGE_NAME) === "1",
        logout: async (unsetUserInStore: boolean = false) => {
            try {
                await Auth.signOut();
                window.sessionStorage.removeItem(ACCOUNT_LOGGED_IN_SESSION_STORAGE_NAME);
                if (unsetUserInStore) dispatch(unsetAuthenticatedUser());
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        changePassword: async (currentPassword: string, newPassword: string) => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, currentPassword, newPassword);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        forgotPassword: async (email: string) => {
            try {
                await Auth.forgotPassword(email);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        forgotPasswordSubmit: async (email: string, code: string, password: string) => {
            try {
                await Auth.forgotPasswordSubmit(email, code, password);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        deleteAuthenticatedAccount: (): Promise<void> => new Promise((resolve, reject) => {
            commitDeleteAccountMutation({
                variables: {},
                onCompleted: () => {
                    (async () => {
                        try {
                            const user = await Auth.currentAuthenticatedUser();
                            user.deleteUser((e: Error, result: string) => {
                                if (e) {
                                    reject(e);
                                    throw e;
                                } else {
                                    window.localStorage.removeItem(ACCOUNT_EXISTS_LOCAL_STORAGE_NAME);
                                    resolve();
                                }
                            })
                        } catch (e) {
                            reject(e);
                        }
                    })()
                },
                onError: e => reject(e),
                updater: store => {
                    store.invalidateStore();
                }
            })
        }),
        deleteGuestAccount: (): Promise<void> => new Promise((resolve, reject) => {
            commitDeleteAccountMutation({
                variables: {},
                onCompleted: () => {
                    window.localStorage.removeItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME);
                    window.sessionStorage.removeItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME);
                    window.localStorage.removeItem(GUEST_ACCOUNT_AFFIRMED_TERMS_LOCALSTORAGE_NAME);
                    resolve();
                },
                onError: e => reject(e),
                updater: store => {
                    store.invalidateStore();
                }
            })
        }),
        setGuestAccountId: (guestAccountId: string) => {
            window.sessionStorage.removeItem("valign_guest_account_id");
            window.localStorage.setItem("valign_guest_account_id", guestAccountId);
        },
        getAndSetGuestAccountId: () => {
            let guestAccountId = null;
            const searchParamsGuestAccountId = new URLSearchParams(window.location.search).get("guest");
            const localStorageGuestAccountId = window.localStorage.getItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME);
            const sessionStorageGuestAccountId = window.sessionStorage.getItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME);

            const conditionallySetLocalStorage = (guestAccountId: string) => {
                if (!!window.localStorage.getItem(GUEST_ACCOUNT_AFFIRMED_TERMS_LOCALSTORAGE_NAME)) {
                    if (!localStorageGuestAccountId) {
                        window.localStorage.setItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME, guestAccountId)
                    }
                }
            }

            if (searchParamsGuestAccountId) {
                guestAccountId = searchParamsGuestAccountId;
                window.sessionStorage.setItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME, guestAccountId);
                conditionallySetLocalStorage(guestAccountId);
            } else if (sessionStorageGuestAccountId) {
                guestAccountId = sessionStorageGuestAccountId;
                conditionallySetLocalStorage(guestAccountId);
            } else if (localStorageGuestAccountId) {
                guestAccountId = localStorageGuestAccountId;
            } else {
                guestAccountId = uuidv4();
                window.sessionStorage.setItem(GUEST_ACCOUNT_ID_LOCALSTORAGE_NAME, guestAccountId)
                conditionallySetLocalStorage(guestAccountId);
            }

            return guestAccountId
        },
        getGuestAccountId
    }
    return account;
}
export default useAccount;