import useAppConsole from "app/useAppConsole";
import AppTimeoutModal from "AppTimeoutModal";
import { FetchGraphQLFunction } from "fetchGraphQL";
import React, { useEffect, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import RelayEnvironment from "RelayEnvironment";

const AppRelayEnvironmentProvider = ({ children, customFetchGraphQL }: { children: React.ReactNode, customFetchGraphQL?: FetchGraphQLFunction }) => {
    const environment = new RelayEnvironment(customFetchGraphQL);
    const [timeoutModalVisible, setTimeoutModalVisible] = useState(false);
    const appConsole = useAppConsole("AppRelayEnvironmentProvider");
    
    useEffect(() => {
        environment.setHandleLogout(() => {
            setTimeoutModalVisible(true)
        });
        appConsole.log("relay environment provider updated", 1);
    });
    return <RelayEnvironmentProvider environment={environment.environment}>
        {timeoutModalVisible && <AppTimeoutModal />}
        {children}
    </RelayEnvironmentProvider>
}
export default AppRelayEnvironmentProvider;