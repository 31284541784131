const InputStyles = ({error, readonly}: {error?: boolean, readonly?: boolean}) => ({
    "& .MuiFilledInput-root": {
        border: readonly ? "1px solid rgba(0,0,0,0)" : "1px solid rgba(0,0,0,0.075)",
        background: error ? "rgba(160,0,155,0.03)" : "rgba(255,255,255,1)",
        borderRadius: "0.25em",
    },
    "& .MuiFilledInput-root:hover": {
        background: "rgba(245,245,245,1)"
    },
    "& .MuiFilledInput-root:focus": {
        background: "rgba(240,247,252,1)",
    },
    "& .MuiFilledInput-root:before": {
        borderBottom: "1px solid rgba(0,0,0,0)"
    },
    "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid rgba(0,0,0,0)"
    },
    "& .MuiFilledInput-root.Mui-focused": {
        background: "rgba(240,247,252,1)"
    },
    "& div.MuiFilledInput-root.Mui-disabled": {
        background: "rgba(0,0,0,0.05)"
    },
    "& .MuiFilledInput-input:focus": {
        background: "none"
    }
})
export default InputStyles;