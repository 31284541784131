/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewCardMenuDeleteItem_review = {
    readonly id: string;
    readonly " $refType": "ReviewCardMenuDeleteItem_review";
};
export type ReviewCardMenuDeleteItem_review$data = ReviewCardMenuDeleteItem_review;
export type ReviewCardMenuDeleteItem_review$key = {
    readonly " $data"?: ReviewCardMenuDeleteItem_review$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewCardMenuDeleteItem_review">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCardMenuDeleteItem_review",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Review",
  "abstractKey": null
};
(node as any).hash = '2ccda2613fd11fc15f020ce09ba9fc7c';
export default node;
