import { Grid } from "@mui/material";
import React from "react";

const MainGrid = ({
    children
}: {
    children?: React.ReactNode
}) => {
    return <Grid container spacing={{xs: 1, md: 2, lg: 3}} rowSpacing={{xs: 1, md: 2}}>{children}</Grid>
}
export default MainGrid;