import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperContainer from "common/StandardPaperContainer";
import StandardPaperTitle from "common/StandardPaperTitle";
import { ControlFormFieldReturn } from "forms/useFormHandlers";
import GoalCompletionCompletableSelect from "main/goals/goal_edit/goal_completion_select/GoalCompletionCompletableSelect";
const GoalDetailsReviewCompletion = ({ handleChange, value }: ControlFormFieldReturn<number>) => {

    return <>
        <StandardPaper>
            <StandardPaperTitle>
            Wie schätzt ihr die derzeitige Zielerreichung ein?
            </StandardPaperTitle>
            <StandardPaperContainer>
                <Box sx={{ width: "100%", boxSizing: "border-box" }}>
                                <GoalCompletionCompletableSelect value={value} handleChange={handleChange} />
                </Box>
            </StandardPaperContainer>
        </StandardPaper>
    </>
}
export default GoalDetailsReviewCompletion;