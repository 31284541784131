import { CircularProgress } from "@mui/material";
import useEventBus, { EventTypes } from "app/useEventBus";
import GoalDetails from "main/goals/goal_details/GoalDetails";
import GoalDetailsMainBarTitle from "main/goals/goal_details/GoalDetailsMainBarTitle";
import { GoalGetGoalByReadableIdQuery as GoalGetGoalByReadableIdQueryType } from "main/goals/goal_details/__generated__/GoalGetGoalByReadableIdQuery.graphql";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainErrorBoundary from "main/MainErrorBoundary";
import { Suspense, useCallback, useEffect, useRef } from "react";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router";
const graphql = require("babel-plugin-relay/macro");

export const GoalGetGoalByReadableIdQuery = graphql`
    query GoalGetGoalByReadableIdQuery($input: GetGoalByReadableIdInput!, $first: Int, $after: String, $last: Int, $before: String) {
        getGoalByReadableId(input: $input) {
            ...GoalDetails_goal
            ...GoalDetailsMainBarTitle_goal
        }
    }
`

const Goal = () => {
    const params = useParams();
    
    const fetchedReadableId = useRef<number | null>(null);
    const [getGoalQueryRef, loadGetGoalQuery] = useQueryLoader<GoalGetGoalByReadableIdQueryType>(GoalGetGoalByReadableIdQuery);
    const eventBus = useEventBus();
    
    const fetchGoalByReadableId = useCallback(({readableId, refresh = false}: {readableId: number, refresh?: boolean}) => {
        if(isNaN(readableId)) throw new Error("error fetching goal")
        loadGetGoalQuery({
            input: {
                readableId: readableId
            }
        }, { fetchPolicy: refresh ? "network-only" : undefined })
    }, [loadGetGoalQuery])

    useEffect(() => {
        const refetchListener = () => {
            if(!fetchedReadableId.current) return;
            fetchGoalByReadableId({readableId: fetchedReadableId.current, refresh: true })
          }
        eventBus.addEventListener(EventTypes.GUEST_ID_CHANGED, refetchListener)
        return () => {
            eventBus.removeEventListener(EventTypes.GUEST_ID_CHANGED, refetchListener)
        }
    })
    
    useEffect(() => {
        const e = new Error("error while fetching goal");
        if(!params.readableId) throw e;
        if(isNaN(+params.readableId)) throw e;
        if(+params.readableId === fetchedReadableId.current) return;
        fetchedReadableId.current = +params.readableId;
        fetchGoalByReadableId({readableId: +params.readableId});
    }, [params.readableId, fetchGoalByReadableId])

    return <>
            <MainErrorBoundary errorMessage={<>
                <MainContent>
                    <MainContainer>
                        <b>Das hat nicht geklappt...</b>
                        <p>
                            Es gab einen Fehler beim Laden der Daten.
                        </p>
                    </MainContainer>
                </MainContent>
            </>}>
            <MainBar>
                    <GoalDetailsMainBarTitle queryRef={getGoalQueryRef} />
            </MainBar>
                <Suspense fallback={<>
                    <MainContainer>
                        <MainContent>
                            <CircularProgress color="primary" />
                        </MainContent>
                    </MainContainer>
                </>}>
                    {!!getGoalQueryRef && <GoalDetails queryRef={getGoalQueryRef} />}
                </Suspense>
            </MainErrorBoundary>
        </>
}
export default Goal;