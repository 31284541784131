import MainTabs from "main/common/MainTabs";
import { GoalGetGoalByReadableIdQuery } from "main/goals/goal_details/Goal";
import GoalDetailsReviewTab from "main/goals/goal_details/GoalDetailsReviewTab";
import GoalDetailsOverview from "main/goals/goal_details/goal_details_overview/GoalDetailsOverview";
import GoalDetailsReview from "main/goals/goal_details/goal_details_review/GoalDetailsReview";
import GoalDetailsSettings from "main/goals/goal_details/goal_details_settings/GoalDetailsSettings";
import { GoalDetails_goal$key } from "main/goals/goal_details/__generated__/GoalDetails_goal.graphql";
import { GoalGetGoalByReadableIdQuery as GoalGetGoalByReadableIdQueryType } from "main/goals/goal_details/__generated__/GoalGetGoalByReadableIdQuery.graphql";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import { Route, Routes } from "react-router-dom";
const graphql = require("babel-plugin-relay/macro");

const GoalDetails = ({ queryRef }: { queryRef: PreloadedQuery<GoalGetGoalByReadableIdQueryType> }) => {

    const queryData = usePreloadedQuery<GoalGetGoalByReadableIdQueryType>(GoalGetGoalByReadableIdQuery, queryRef)

    const data = useFragment<GoalDetails_goal$key>(graphql`
            fragment GoalDetails_goal on Goal {
                readableId
                archived
                ...GoalDetailsOverview_goal
                ...GoalDetailsReviewTab_goal
                ...GoalDetailsReview_goal
                ...GoalDetailsSettings_goal
            }
        `, queryData.getGoalByReadableId)
    

    return <>
        {data &&
            <MainTabs
                tabs={[
                    { label: "Übersicht", value: "/" },
                    { label: data ? <GoalDetailsReviewTab goal={data} /> : "Review", value: "/review", pathnameRegExp: "review/*(new)*$" },
                    { label: "Einstellungen", value: "/settings" }
                ]}
            >
                <MainContent>
                    <MainContainer>
                        <Routes>
                            <Route path="*" element={<GoalDetailsOverview goal={data} />} />
                            <Route path="review/*" element={<GoalDetailsReview goal={data} />} />
                            {/* {data.archived && <Route path="review/*" element={<Navigate to=".." />} />} */}
                            <Route path="settings" element={<GoalDetailsSettings goal={data} />} />
                        </Routes>
                    </MainContainer>
                </MainContent>
            </MainTabs>
        }
    </>
}
export default GoalDetails;