import NavigateBeforeIcon from "@mui/icons-material/ArrowBackRounded";
import { IconButton, IconButtonProps } from "@mui/material";

const MainNavigateBackButton = ({ sx, ...iconButtonProps }: IconButtonProps) => {
    return <IconButton sx={{
        position: "relative",
        left: "-0.25rem"
    }}
        {...iconButtonProps}
    >
        <NavigateBeforeIcon />
    </IconButton>
}
export default MainNavigateBackButton;