const useWaitDuration = () => (duration: number, start: number, checkInterval: number = 500) => new Promise((resolve) => {
    const checkTime = () => {
        if(Date.now() - start >= duration) {
            resolve(true);
        } else {
            setTimeout(() => checkTime(), checkInterval)
        }
    }
    checkTime();
});
export default useWaitDuration;