import Privacy from "main/terms/Privacy";
import { Route, Routes } from "react-router";

const PrivacyRoute = () => {

    return <Routes>
        <Route path="*" element={<></>} />
        <Route path="/privacy" element={<Privacy/>} />
    </Routes>
}
export default PrivacyRoute;