import { Button, Checkbox, Stack, Collapse, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AppLink from "common/AppLink";
import AppTextField from "forms/AppTextField";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import AnimationWrapper from "common/AnimationWrapper";
import AppEmph from "common/AppEmph";
import AppParagraph from "common/AppParagraph";
import useWaitDuration from "common/useWaitDuration";
import LoginError from "authentication/LoginError";
import useRegexValidations from "common/useRegexValidations";
import useAppConsole from "app/useAppConsole";
import ValidationIndicators from "authentication/ValidationIndicators";
import useAccount from "authentication/_hooks/useAccount";

const SignupForm = () => {

    const appConsole = useAppConsole("SignupForm");

    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [enteredPasswordField, setEnteredPasswordField] = useState<boolean>(false);
    const [affirmedTerms, setAffirmedTerms] = useState<boolean>(false);
    const [state, setState] = useState<"default" | "sending" | "success" | "error" | "usernameExistsError">("default");

    const { validateEmail, validatePassword } = useRegexValidations();

    const validEmail = email !== null && validateEmail(email);
    const validPassword = password !== null && validatePassword(password);
    const validForm = validEmail && validPassword && affirmedTerms;
    const formInputsDisabled = state === "sending";
    const errorsExist = state === "error" || state === "usernameExistsError";

    const waitDuration = useWaitDuration();
    const { signup } = useAccount();

    const submit = async () => {
        if (!validForm || state === "sending") return;
        const submitTime = Date.now();

        try {
            setState("sending");
            const { user } = await signup(email, password);
            await waitDuration(2000, submitTime);
            appConsole.log(user, 3, { object: true });
            setState("success");
        } catch (e) {
            await waitDuration(2000, submitTime);
            console.error(e);
            if (e instanceof Error) {
                switch (e.name) {
                    case "UsernameExistsException":
                        setState("usernameExistsError");
                        break;
                    default:
                        setState("error")
                }
            } else {
                setState("error")
            }
        }
    }

    return (() => {
        switch (state) {
            case "success":
                return <Box sx={{ padding: "2rem 1.5rem" }}>
                    <Stack direction="row" spacing="0.5rem" sx={{ margin: "0 0 1rem 0", display: "flex", alignItems: "center" }}>
                        <CheckIcon sx={{ color: theme => theme.palette.success.main, fontSize: "1.5rem" }} />
                        <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                            Registrierung erfolgreich
                        </Typography>
                    </Stack>
                    <Typography sx={{ fontSize: "0.875rem" }} component="div">
                        Dein Nutzerkonto wurde erfolgreich erstellt. Bitte bestätige nun noch deine E-Mail-Adresse.
                        <AppParagraph>
                            Dazu wurde eine Mail mit einem Bestätigungslink an die Adresse <AppEmph>{email}</AppEmph> geschickt.
                        </AppParagraph>
                    </Typography>
                </Box>
            default:
                return <Stack direction="column" spacing="0.5rem" sx={{ margin: "1rem 0 1rem 0", padding: "1rem 1.5rem", width: "100%", boxSizing: "border-box" }}>
                    <AppTextField
                        label="E-Mail"
                        id="emailInput"
                        type="text"
                        onChange={event => setEmail(event.target.value)}
                        onKeyDown={event => event.key === "Enter" && submit()}
                        disabled={formInputsDisabled}
                        error={false}
                        autoFocus
                        sx={{
                            width: "100%"
                        }}
                    />
                    <AppTextField
                        label="Kennwort"
                        id="passwordInput"
                        type="password"
                        onFocus={() => setEnteredPasswordField(true)}
                        onChange={event => setPassword(event.target.value)}
                        onKeyDown={event => event.key === "Enter" && submit()}
                        disabled={formInputsDisabled}
                        error={false}
                    />
                    <Collapse in={enteredPasswordField}>
                        <AnimationWrapper>
                            <ValidationIndicators password={password} sx={{ opacity: enteredPasswordField ? 1 : 0, transition: "0.4s" }} />
                        </AnimationWrapper>
                    </Collapse>
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                        <Checkbox id="termsCheckbox" disabled={formInputsDisabled} checked={affirmedTerms} onChange={event => setAffirmedTerms(event.target.checked)} sx={{
                            color: "rgba(0,0,0,0.25)"
                        }} />
                        <Box sx={{
                            fontSize: "0.825rem",
                            padding: "0.75rem 0 0 0",
                            lineHeight: "1.2rem"
                        }}>
                            Ich stimme den
                            <> </><AppLink openInNewTab to="/documents/valign_goals_agb.pdf">Nutzungsbedingungen (AGB)</AppLink>,
                            der <AppLink openInNewTab to="/privacy">Datenschutzerklärung</AppLink><> </>
                            und der <AppLink openInNewTab to="/documents/valign_goals_avv.pdf">Vereinbarung zur Auftragsverarbeitung</AppLink><> </>
                            zu.
                        </Box>
                    </Box>
                    <Box sx={{ padding: "1rem 0 0 0", boxSizing: "border-box", width: "100%" }}>
                        <Button id="submitButton" variant="contained" size="small" sx={{ width: "100%", height: "2rem", display: "flex", alignItems: "center", lineHeight: "1em" }} disabled={!validForm} disableRipple={formInputsDisabled} disableElevation onClick={submit}>
                            {state !== "sending" && <>Kostenlos registrieren</>}
                            {state === "sending" && <CircularProgress size="1rem" sx={{ color: "rgba(255,255,255,1)" }} />}
                        </Button>
                    </Box>
                    {errorsExist && <LoginError>
                        {(() => {
                            switch (state) {
                                case "error":
                                    return <>Während der Erstellung des Benutzerkontos ist ein unbekannter Fehler aufgetreten.</>
                                case "usernameExistsError":
                                    return <>Für die angegebene E-Mail-Adresse existiert bereits ein Benutzerkonto.</>
                            }
                        })()}

                    </LoginError>}
                </Stack>
        }
    })()
}
export default SignupForm;