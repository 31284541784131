import { Box } from "@mui/system";
import { GoalType } from "graphql/API";
import GoalTypeIcon from "common/_icons/GoalTypeIcon";
import GoalCardDivider from "main/goals/goal_card/GoalCardDivider";

const GoalCardType = ({type}: {type: GoalType}) => {
    return <Box sx={{ display: "flex" }}>
        <Box sx={{
            padding: "0.8rem 0.5rem 0.5rem 0.5rem",
            boxSizing: "border-box",
            display: "flex",
            filter: "grayscale(1)",
            background: "rgba(0,0,0,0.02)",
            alignItems: "flex-start"
        }}>
            <GoalTypeIcon type={type} size={"1.5em"} />
        </Box>
        <GoalCardDivider/>
    </Box>
}
export default GoalCardType;