import { TextField, TextFieldProps } from "@mui/material";
import { Box } from "@mui/system";
import FormFieldSavingIndicator from "forms/FormFieldSavingIndicator";
import InputStyles from "forms/InputStyles";

const AppTextField = ({ sx, variant, size, InputLabelProps, error, readonly, readonlyStyle, label, eventSource, inputProps, ...other }: TextFieldProps & {
    error?: boolean,
    readonly?: boolean,
    readonlyStyle?: boolean,
    eventSource?: EventTarget | null
}) => {
    
    return <Box sx={{position: "relative"}}>
        <Box sx={{position: "absolute", top: "0.2rem", left: "0.75rem", zIndex: 1, display: "flex", alignItems: "center", height: "1.25rem", fontSize: "0.8rem", fontWeight: 300, letterSpacing: "0.01rem"}}>
            <Box sx={{margin: "0 0.5rem 0 0"}}>{label}</Box>
            <FormFieldSavingIndicator eventSource={eventSource} />
        </Box>
        <TextField
            sx={Object.assign({
                width: "100%"
            }, InputStyles({ error, readonly: readonly || readonlyStyle }), sx)}
            FormHelperTextProps={{
                sx: {
                    margin: "0.25em 0 0.5em 0"
                }
            }}
            inputProps={inputProps ?? {
                sx: {
                    pt: "1.75rem",
                    pb: "1rem"
                }
            }}
            InputLabelProps={(({ shrink, ...otherInputLabelProps }: any) => Object.assign({
                shrink: true,
                ...otherInputLabelProps
            }))(InputLabelProps ? InputLabelProps : {})}
            variant="filled"
            size="small"
            error={error}
            {...other}
        />
    </Box>
}
export default AppTextField;