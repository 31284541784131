import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "app/store";
import { DateTime } from "luxon";

export const TIME_REFRESH_INTERVAL = 30000;

interface TimeState {
    now: string;
    developmentNow: boolean;
}

const initialState: TimeState = {
    now: DateTime.now().toISO(),
    developmentNow: false
}

export const TimeSlice = createSlice({
    name: 'time',
    initialState,
    reducers: {
        updateNow: (state) => {
            if(state.developmentNow) {
                state.developmentNow = true;
                state.now = DateTime.fromISO(state.now).plus({milliseconds: TIME_REFRESH_INTERVAL}).toISO();
            } else {
                state.now = DateTime.now().toISO()
            }
        },
        setDevelopmentNow: (state, action: PayloadAction<string | null>) => {
            if(action.payload) {
                state.developmentNow = true;
                state.now = action.payload;
            } else {
                state.developmentNow = false;
                state.now = DateTime.now().toISO()
            }
        }
    }
})

export const { updateNow, setDevelopmentNow } = TimeSlice.actions;

export const selectNow = (state: RootState) => state.time.now;

export default TimeSlice.reducer;