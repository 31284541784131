import { FormControl, FormHelperText } from "@mui/material";
import AppDatePicker from "forms/AppDatePicker";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import useFormHandlers from "forms/useFormHandlers";
import { DateTime } from "luxon";
import { GoalDetailsSettingsDueDateSelect_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_due_date/__generated__/GoalDetailsSettingsDueDateSelect_goal.graphql";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useEventBus, { EventTypes } from "app/useEventBus";
import React, { useEffect, useRef, useState } from "react";
import { useFragment } from "react-relay";
import useDateCalculations from "common/useDateCalculations";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsDueDateSelect = ({ goal, readOnly, eventSource }: {
    goal: GoalDetailsSettingsDueDateSelect_goal$key,
    readOnly?: boolean,
    eventSource: React.MutableRefObject<EventTarget>
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsDueDateSelect_goal on Goal {
        id
        type
        dueDate
    }
    `, goal)

    const {formDataRef, isValidForm, controlFormField} = useFormHandlers({
        initialFormData: { dueDate: DateTime.fromISO(data.dueDate ?? "") },
        initialValidState: { dueDate: true }
    })

    const { value, handleChange, errorsExist, setVisited, changed, errors } = controlFormField({
        fieldName: "dueDate", validations: {
            required: () => (d: DateTime) => !!d,
            validDateTime: () => (d: DateTime) => d?.isValid
        }
    });

    const [commit] = useUpdateGoal();
    const formFieldSavingEvents = useFormFieldSavingEvents(eventSource);
    const {setDefaultHour} = useDateCalculations();

    const [shouldSave, setShouldSave] = useState(false);
    const popupOpened = useRef(false);
    const eventBus = useEventBus();
    useEffect(() => {
        const save = () => {
            if (!isValidForm()) return;
            if (formDataRef.current.dueDate.toISO() === data.dueDate) return;
            formFieldSavingEvents.dispatchFormFieldSavingEvent();
            commit({
                variables: {
                    input: {
                        id: data.id,
                        dueDate: setDefaultHour(formDataRef.current.dueDate).toISO()
                    }
                },
                onCompleted: () => {
                    eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                    formFieldSavingEvents.dispatchFormFieldSavingSuccessEvent()
                },
                onError: () => formFieldSavingEvents.dispatchFormFieldSavingErrorEvent()
            })
        }
        if(shouldSave) {
            setShouldSave(false);
            save();
        }
    }, [eventBus, shouldSave, setShouldSave, isValidForm, formDataRef, formFieldSavingEvents, data, commit, setDefaultHour])

    return <>
        <FormControl error={changed && errorsExist()}>
            <AppDatePicker
                inputFormat="dd.LL.yyyy"
                label="Zieldatum"
                value={value}
                onChange={d => {
                    handleChange(d as DateTime);
                    if(popupOpened.current) {
                        popupOpened.current = false;
                        setShouldSave(true);
                    }
                }}
                renderInput={() => <></>}
                customRenderInput={{
                    onBlur: () => {
                        setVisited(true);
                        setShouldSave(true);
                    },
                    error: changed && errorsExist(),
                    sx: { width: "12em" },
                }}
                error={changed && errorsExist()}
                boldValue
                onOpen={() => {
                    popupOpened.current = true;
                }}
            />
            {
                changed && (errors?.required ? <FormHelperText >Bitte gib ein Zieldatum an.</FormHelperText> :
                    errors?.validDateTime ? <FormHelperText >Bitte gib ein gültiges Datum im Format dd.mm.yyyy an.</FormHelperText> :
                        errors?.futureDate ? <FormHelperText >Bitte gib ein Datum an, das nicht in der Vergangenheit liegt.</FormHelperText> :
                            <></>
                )
            }
        </FormControl>
    </>
}
export default GoalDetailsSettingsDueDateSelect;