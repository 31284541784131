import Login from "authentication/Login";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const LoginRoute = ({ children }: { children: React.ReactNode }) => {

    return <Routes>
        <Route path="/login/*" element={<Login />} />
        <Route path="/signup" element={<Navigate to="/login/signup" />} />
        <Route path="/logout" element={<Navigate to="/login" />} />
        <Route path="*" element={<>{children}</>} />
    </Routes>
}
export default LoginRoute;