import NavItem from "navigation/NavItem";
import ReviewIcon from "common/_icons/ReviewIcon";
import ReviewNavItemBadge from "navigation/ReviewNavItemBadge";

const ReviewNavItem = () => {
    
    return <NavItem
        icon={<ReviewIcon fontSize="inherit" />}
        label="Review"
        path="/review"
        badge={<ReviewNavItemBadge />}
    />
}
export default ReviewNavItem;