import ProgressIcon from "@mui/icons-material/Circle";
import EmptyProgressIcon from "@mui/icons-material/CircleOutlined";
import { LinearProgress, Rating, Stack, Typography } from "@mui/material";
import { GoalType } from "graphql/API";
import { valueLabelMap } from "main/goals/goal_edit/goal_completion_select/GoalCompletionContinuousSelect";
import GoalFieldRow from "main/goals/common/field/GoalFieldRow";
import GoalFieldTitle from "main/goals/common/field/GoalFieldTitle";
import GoalFieldTextMain, { GoalFieldTextMainStyle } from "main/goals/common/field/GoalFieldTextMain";
import { useFragment } from "react-relay";
import { GoalStatusField_goal$key } from "main/goals/common/fields/__generated__/GoalStatusField_goal.graphql";
import { DateTime } from "luxon";
import useDateCalculations from "common/useDateCalculations";
import { GoalStatusField_review$key } from "main/goals/common/fields/__generated__/GoalStatusField_review.graphql";
import useStatusCalculations from "common/useStatusCalculations";
const graphql = require("babel-plugin-relay/macro");

const GoalStatusField = ({ goal, review = null, fieldStyle = "default", showTitle = true }: {
    goal: GoalStatusField_goal$key, review?: GoalStatusField_review$key | null, fieldStyle?: GoalFieldTextMainStyle, showTitle?: boolean
}) => {

    const data = useFragment(graphql`
            fragment GoalStatusField_goal on Goal {
                type
                status
                completion
                currentReviewAt
            }
        `, goal)

    const reviewData = useFragment(graphql`
            fragment GoalStatusField_review on Review {
                status
                completion
                createdAt
            }
        `, review)

    const currentReviewAt = DateTime.fromISO(reviewData ? reviewData.createdAt : data.currentReviewAt);
    const completion = reviewData ? reviewData.completion : data.completion;
    const status = reviewData ? reviewData.status : data.status;
    const { getLocaleDateString } = useDateCalculations();
    const completable = data.type === GoalType.completable;
    const { getStatusColor } = useStatusCalculations();
    const calculatedStatusColor = getStatusColor(reviewData?.status ?? data.status);
    const emphasized = fieldStyle === "emphasized" || fieldStyle === "emphasizedNumber";

    return <Stack direction="column">
        {showTitle && <GoalFieldTitle>
            {completable ? "Zielerreichung" : "Zustand"}
            {emphasized ? ` (am ${getLocaleDateString(currentReviewAt)})` : ""}:</GoalFieldTitle>}
        <GoalFieldRow>
            <GoalFieldTextMain fieldStyle={fieldStyle} additional={completable ? undefined : `(${Math.round(status * 10)})`}>
                {completable && completion !== null ? `${Math.round(completion * 100)} %` : valueLabelMap[Math.round(status * 10)]}
                {/* {completable && <CircleIcon sx={{ fontSize: "1rem", color: calculatedStatusColor, margin: "0 0 0 0.25rem" }} />} */}
            </GoalFieldTextMain>
        </GoalFieldRow>
        {completable && completion !== null &&
            <GoalFieldRow>
                <LinearProgress
                    value={completion * 100}
                    variant="determinate"
                    color={completion >= 8 ? "success" : "primary"}
                    sx={{
                        borderRadius: "1em",
                        height: "0.5rem",
                        width: "100%",
                        "&.MuiLinearProgress-root": {
                            backgroundColor: "rgba(24,118,209,0.2)",
                        }
                    }}
                />
            </GoalFieldRow>}
        <GoalFieldRow sx={{margin: emphasized && completable ? "0.5rem 0 0 0" : undefined}}>
            {emphasized && completable && <Typography sx={{fontSize: "0.8rem", fontWeight: 300, opacity: 0.8, margin: "0 0.5rem 0 0"}}>Zustand:</Typography>}
            <Rating
                readOnly
                value={status * 10}
                max={10}
                icon={<ProgressIcon sx={{color: calculatedStatusColor}} />}
                emptyIcon={<EmptyProgressIcon color="primary" sx={{ opacity: 0.4 }} />}
                sx={{
                    "& .MuiSvgIcon-root": {
                        width: completable || !emphasized ? "0.75rem" : "1rem",
                        height: completable || !emphasized ? "0.75rem" : "1rem"
                    }
                }}
            />
        </GoalFieldRow>

    </Stack>
}
export default GoalStatusField;