import { Box, FormControl, FormHelperText } from "@mui/material";
import useTimeNow from "app/useTimeNow";
import useDateCalculations from "common/useDateCalculations";
import AppDatePicker from "forms/AppDatePicker";
import { FormHandlersReturn } from "forms/useFormHandlers";
import { ReviewCycleInput } from "graphql/API";
import { DateTime, Duration, DurationObjectUnits } from "luxon";
import { GoalFormData } from "main/goals/goal_edit/GoalEditForm";
import { useEffect, useRef } from "react";

const GoalReviewCycleStartDateSelect = ({ formHandlers, formData }: {
    formHandlers: FormHandlersReturn<GoalFormData>,
    formData: GoalFormData
}) => {

    const { dateIsNotInThePast } = useDateCalculations();
    const now = useTimeNow();

    const { value, handleChange, errorsExist, setVisited, visited, errors } = formHandlers.controlFormField({
        fieldName: "reviewCycleStartDate", validations: {
            required: () => (d: DateTime) => !!d,
            validDateTime: () => (d: DateTime) => d?.isValid,
            futureDate: () => (d: DateTime) => {
                if (!d || !d.isValid) return false;
                return dateIsNotInThePast(d, true)
            }
        }
    });

    const changed = useRef(false)
    const handledCycleBegin = useRef<ReviewCycleInput | null>(null)
    useEffect(() => {
        if (handledCycleBegin.current === formData.reviewCycleBegin || changed.current) return;
        handleChange(now.plus(Duration.fromObject(formData.reviewCycleBegin as DurationObjectUnits)));
        handledCycleBegin.current = formData.reviewCycleBegin;
    })

    return <Box sx={{ justifyContent: "flex-start" }}>
        <FormControl error={errorsExist()}>
            <AppDatePicker
                inputFormat="dd.LL.yyyy"
                label="Erster Review-Termin"
                value={value}
                onChange={d => {
                    changed.current = true;
                    handleChange(d as DateTime);
                }}
                renderInput={() => <></>}
                customRenderInput={{
                    onBlur: () => {
                        setVisited(true);
                    },
                    error: errorsExist(),
                    required: true,
                    sx: { width: "12em" },
                }}
                error={visited && errorsExist()}
                boldValue
                minDate={now}
            />
            {
                visited && (errors?.required ? <FormHelperText >Bitte gib ein Datum für das erste Review an.</FormHelperText> :
                    errors?.validDateTime ? <FormHelperText >Bitte gib ein gültiges Datum im Format dd.mm.yyyy an.</FormHelperText> :
                        errors?.futureDate ? <FormHelperText >Bitte gib ein Datum an, das nicht in der Vergangenheit liegt.</FormHelperText> :
                            <></>
                )
            }
        </FormControl>
    </Box>
}
export default GoalReviewCycleStartDateSelect;