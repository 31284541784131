import { DialogContent, DialogContentProps } from "@mui/material"

const DialogContentApp = ({children, sx, ...otherDialogContentProps}: DialogContentProps) => {
    return <DialogContent
        sx={Object.assign({
            lineHeight: "1.5rem"
        }, sx ?? {})}
        {...otherDialogContentProps}
    >
        {children}
    </DialogContent>
}
export default DialogContentApp;