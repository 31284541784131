/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalDetailsOverview_goal = {
    readonly type: GoalType;
    readonly archived: boolean | null;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsNextReview_goal" | "GoalDetailsCurrentReview_goal" | "GoalDetailsCompletion_goal" | "GoalDetailsReviews_goal" | "GoalDetailsTitleDescription_goal" | "GoalDetailsDueDate_goal">;
    readonly " $refType": "GoalDetailsOverview_goal";
};
export type GoalDetailsOverview_goal$data = GoalDetailsOverview_goal;
export type GoalDetailsOverview_goal$key = {
    readonly " $data"?: GoalDetailsOverview_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsOverview_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetailsOverview_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsNextReview_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsCurrentReview_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsCompletion_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsReviews_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsTitleDescription_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsDueDate_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '544422ab35a69b146b378abd2614f6d5';
export default node;
