import { FormControl, InputLabel, Select, SelectProps } from "@mui/material";
import { SxProps } from "@mui/system";
import InputStyles from "forms/InputStyles";
import React from "react";

const AppSelect = ({ children, sx, selectStyle = {}, variant, labelId, labelText, required, inputProps, boldValue = false, error, errorMessages, ...other }: SelectProps & {
    selectStyle?: SxProps,
    labelText?: string,
    boldValue?: boolean,
    error?: boolean,
    errorMessages?: React.ReactNode
}) => {
    return <FormControl
        error={error}
        variant="filled"
        sx={Object.assign({}, InputStyles({error: error}), sx)}
    >
        {labelText && <InputLabel required={required} id={labelId} sx={{fontWeight: 300, fontSize: "1.05rem"}}>{labelText}</InputLabel>}
        <Select
            error={error}
            labelId={labelId}
            sx={selectStyle}
            inputProps={(({ sx, ...otherInputProps }: any) => Object.assign({
                sx: Object.assign({
                    fontWeight: boldValue ? "500" : undefined,
                    pt: "1.75rem"
                }, sx),
                ...otherInputProps
            }))(inputProps ? inputProps : {sx: {}})}
            {...other}
        >
            {children}
        </Select>
        {errorMessages}
    </FormControl>
}
export default AppSelect;