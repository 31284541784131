import { MountContext } from "common/MountProvider";
import React, { useContext } from "react";

const useMount = () => {
    const mountContext = useContext(MountContext);
    if (!mountContext) {
        console.error("no mount context");
        return {
            mountComponent: () => { },
            unmount: () => { }
        }
    }
    return {
        mountComponent: (component: React.ReactNode) => mountContext.mountComponent(component),
        unmount: () => mountContext.unmount()
    };
}
export default useMount;