import { selectAuthenticatedUser } from "authentication/_store/AccountSlice";
import { useSelector } from "react-redux";
import UserToolsLogout from "toolbar/user_tools/UserToolsLogout";

const UserTools = () => {

    const authenticatedUser = useSelector(selectAuthenticatedUser);

    return authenticatedUser ? <>
        <UserToolsLogout />
    </> : <></>
}
export default UserTools;