/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type AccountSettingsDownloadDataGenerator_goalConnection = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly readableId: number;
            readonly title: string;
            readonly description: string | null;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly type: GoalType;
            readonly nextReviewAt: string;
            readonly dueDate: string | null;
            readonly reviewMode: {
                readonly cycleBegin: {
                    readonly days: number | null;
                    readonly months: number | null;
                };
                readonly cycleGoal: {
                    readonly days: number | null;
                    readonly months: number | null;
                } | null;
                readonly spacedRepetition: boolean;
            };
            readonly spacedRepetitionLevel: number;
            readonly status: number;
            readonly reviews: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly createdAt: string;
                        readonly updatedAt: string;
                        readonly comment: string | null;
                        readonly completion: number | null;
                        readonly status: number;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    } | null> | null;
    readonly " $refType": "AccountSettingsDownloadDataGenerator_goalConnection";
};
export type AccountSettingsDownloadDataGenerator_goalConnection$data = AccountSettingsDownloadDataGenerator_goalConnection;
export type AccountSettingsDownloadDataGenerator_goalConnection$key = {
    readonly " $data"?: AccountSettingsDownloadDataGenerator_goalConnection$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountSettingsDownloadDataGenerator_goalConnection">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "days",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "months",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountSettingsDownloadDataGenerator_goalConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GoalEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Goal",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "readableId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nextReviewAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dueDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReviewMode",
              "kind": "LinkedField",
              "name": "reviewMode",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReviewCycle",
                  "kind": "LinkedField",
                  "name": "cycleBegin",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReviewCycle",
                  "kind": "LinkedField",
                  "name": "cycleGoal",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "spacedRepetition",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spacedRepetitionLevel",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ReviewConnection",
              "kind": "LinkedField",
              "name": "reviews",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReviewEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Review",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "comment",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "completion",
                          "storageKey": null
                        },
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GoalConnection",
  "abstractKey": null
};
})();
(node as any).hash = '05db624c60d4404fcc7bfc9987036b66';
export default node;
