import GoalDetailsSettingsDueDate from "main/goals/goal_details/goal_details_settings/goal_details_settings_due_date/GoalDetailsSettingsDueDate";
import GoalDetailsSettingsNextReview from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/GoalDetailsSettingsNextReview";
import GoalDetailsSettingsReviewMode from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/GoalDetailsSettingsReviewMode";
import { GoalDetailsSettings_goal$key } from "main/goals/goal_details/goal_details_settings/__generated__/GoalDetailsSettings_goal.graphql";
import MainGrid from "main/MainGrid";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettings = ({ goal }: { goal: GoalDetailsSettings_goal$key }) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettings_goal on Goal {
        ...GoalDetailsSettingsReviewMode_goal
        ...GoalDetailsSettingsNextReview_goal
        ...GoalDetailsSettingsDueDate_goal
    }
    `, goal)

    return <MainGrid>
        <GoalDetailsSettingsReviewMode goal={data} />
        <GoalDetailsSettingsNextReview goal={data} />
        <GoalDetailsSettingsDueDate goal={data} />
    </MainGrid>
}
export default GoalDetailsSettings;