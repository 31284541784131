import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import AppEmph from "common/AppEmph";
import StandardPaper from "common/StandardPaper";
import StandardPaperText, { StandardPaperTextParagraph } from "common/StandardPaperText";
import StandardPaperTitle from "common/StandardPaperTitle";
import useAccountTerm from "common/useAccountTerm";
import MainErrorBoundary from "main/MainErrorBoundary";
import AccountSettingsDownloadDataGenerator from "main/settings/account/AccountSettingsDownloadDataGenerator";
import { AccountSettingsDownloadDataQuery as AccountSettingsDownloadDataQueryType } from "main/settings/account/__generated__/AccountSettingsDownloadDataQuery.graphql";
import { Suspense } from "react";
import { useQueryLoader } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

export const AccountSettingsDownloadDataQuery = graphql`
    query AccountSettingsDownloadDataQuery {
        listGoals {
            ...AccountSettingsDownloadDataGenerator_goalConnection
        }
    }
`;

const AccountSettingsDownloadData = () => {

    const [queryRef, loadQuery] = useQueryLoader<AccountSettingsDownloadDataQueryType>(AccountSettingsDownloadDataQuery)

    const handleClick = () => {
        loadQuery({});
    }

    const accountTerm = useAccountTerm();

    return <StandardPaper>
        <StandardPaperTitle>Inhaltsdaten-Backup</StandardPaperTitle>
        <StandardPaperText>
            Mithilfe dieser Funktion kannst du ein Backup all deiner mit diesem {accountTerm} verknüpften Inhaltsdaten erstellen.
        </StandardPaperText>
        <StandardPaperTextParagraph>
            Der Download der Daten erfolgt im JSON-Format. Das ist ein weit verbreitetes maschinenlesbares Format, in dem deine Daten strukturiert enthalten sind.
        </StandardPaperTextParagraph>
        <Box sx={{ margin: "1rem 0 0 0" }}>
            {
                queryRef ? <MainErrorBoundary errorMessage={<AppEmph sx={{color: "error.main"}}>Bei der Bereitstellung der Daten ist ein Fehler aufgetreten!</AppEmph>}>
                    <Suspense fallback={<CircularProgress size="1rem" />}>
                        <AccountSettingsDownloadDataGenerator goals={queryRef} />
                    </Suspense>
                </MainErrorBoundary>
                    :
                    <Button onClick={handleClick} variant="contained" size="small" color="primary">Daten-Backup im JSON-Format erstellen</Button>
            }
        </Box>
    </StandardPaper>
}
export default AccountSettingsDownloadData;