import { Box } from "@mui/system";
import AppEmph from "common/AppEmph";
import { LOCAL_OR_TEST_ENVIRONMENT } from "common/common";

const AppVersion = () => LOCAL_OR_TEST_ENVIRONMENT ? <Box sx={{
    opacity: 0.25,
    display: "inline-block",
    position: "absolute",
    bottom: "0.4rem",
    left: "1rem",
    fontSize: "0.6rem"
}}>
    Version <AppEmph>{process.env.REACT_APP_VERSION}</AppEmph>
</Box> : <></>;
export default AppVersion;