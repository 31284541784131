import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { teal } from '@mui/material/colors';
import Menu from "@mui/material/Menu";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { selectAuthenticatedUser } from "authentication/_store/AccountSlice";
import React from 'react';
import { useSelector } from "react-redux";
import DevelopmentTools from "toolbar/development_tools/DevelopmentTools";
import GuestTools from "toolbar/guest_tools/GuestTools";
import UserTools from "toolbar/user_tools/UserTools";

const AccountAvatar = () => {

    const authenticatedUser = useSelector(selectAuthenticatedUser);
    const userName = authenticatedUser ? authenticatedUser.email : "Gast";

    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<HTMLAnchorElement | null>(null);
    const accountMenuOpen = !!accountMenuAnchor;
    const openAccountMenu = (event: any) => {
        setAccountMenuAnchor(event.currentTarget as HTMLAnchorElement);
    }
    const closeAccountMenu = () => {
        setAccountMenuAnchor(null);
    }

    return <>
        <Button sx={{ textTransform: "none" }} color="inherit"
            onClick={openAccountMenu}
        >
            <Stack direction="row" sx={{ alignItems: "center" }}>
                <Avatar sx={{ background: teal[400], width: 32, height: 32, fontSize: 16, mr: 1 }}>{userName.charAt(0).toUpperCase()}</Avatar>
                <Typography variant="body2">
                    {userName}
                </Typography>
                <ArrowDropDownIcon />
            </Stack>
        </Button>
        <Menu
            anchorEl={accountMenuAnchor}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={accountMenuOpen}
            onClose={closeAccountMenu}
            onClick={closeAccountMenu}
        >
            <UserTools />
            <GuestTools/>
            {/* <Divider/>
            <MenuItem sx={{alignItems: "flex-start"}}>
                <ListItemIcon><AccountIcon /></ListItemIcon>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography component="div">Nutzer-Account anlegen</Typography>
                    <Typography component="div" sx={{fontSize: "0.8em", lineHeight: "1.4em", maxWidth: "20em", boxSizing: "border-box", whiteSpace: "initial"}}>
                        <b>Die Registrierung dauert nur 2 Minuten!</b><br/>
                        Deine bisherigen Daten werden in den neuen Account übernommen.
                    </Typography>
                </Box>
                
            </MenuItem>
            <Divider/>
            <MenuItem>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText>Abmelden</ListItemText>
            </MenuItem> */}
            <DevelopmentTools/>
        </Menu>
    </>
}
export default AccountAvatar;