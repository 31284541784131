import styled from "@emotion/styled";
import { Radio } from "@mui/material";
import React from "react";
import TooltipWithLargerText from "common/TooltipWithLargerText";
import HelpIcon from "@mui/icons-material/HelpOutline";

const IconImg = styled<any>("div")`
    width: 2rem;
    height: 2rem;
    opacity: ${(props: any) => props.selected ? 1 : 0.6};
    transition: 0.2s;
`;

const IconContainer = styled.div`
    padding: 0.5em 1em;
    border-right: 1px solid rgba(0,0,0,0.05);
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 0em 0 0;
    background: rgba(255,255,255,0.75);
`

const ButtonContainer = styled("div")((props: any) => ({
    borderRadius: "0.5em",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    height: "100%",
    cursor: "pointer",
    transition: "0.2s",
    overflow: "hidden",
    background: props.selected ? "rgba(240,247,252,0.5)" : "rgb(255,255,255)",
    border: props.selected ? "1px solid rgba(24,118,209,0.5)" : "1px solid rgba(0,0,0,0.1)",
    opacity: props.selected ? "1" : "0.7",
    filter: props.selected ? "none" : "grayscale(1)",
    "&:hover": {
        background: props.selected ? "rgba(240,247,252,1)" : "rgba(245,245,245,1)",
        opacity: 1
    }
}))

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 1em 0.5em 0;
    flex-grow: 1;
`

const Title = styled.div((props: any) => ({
    fontWeight: props.selected ? 400 : 400,
    fontSize: props.small ? "1rem" : "1.2rem"
}))

const AppRadioButton = ({ selected, icon, value, onChange, onClick, title, tooltip, small }: {
    selected: boolean,
    value: string,
    icon?: React.ReactNode,
    onClick: () => void,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    title: React.ReactNode,
    tooltip?: React.ReactNode,
    small?: boolean
}) => {
    return <ButtonContainer selected={selected} onClick={onClick}>
        {icon && <IconContainer>
            <IconImg selected={selected}>{icon}</IconImg>
        </IconContainer>}
        <Radio size="small" sx={{ mx: 1 }} checked={selected} value={value} onChange={onChange} />
        <Label>
            <Title small={small} selected={selected}>{title}</Title>
            {tooltip && <TooltipWithLargerText arrow title={tooltip}>
                <HelpIcon fontSize="small" sx={{ ml: 1 }} />
            </TooltipWithLargerText>}
        </Label>
    </ButtonContainer>
}
export default AppRadioButton;