import { Button } from "@mui/material";
import StandardPaper from "common/StandardPaper";
import StandardPaperText from "common/StandardPaperText";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainTitle from "main/MainTitle";

const Terms = () => {
    return <>
        <MainBar>
            <MainTitle>Allgemeine Geschäftsbedingungen (AGB)</MainTitle>
        </MainBar>
        <MainContent>
            <MainContainer>
                <StandardPaper>
                    <StandardPaperText>
                        Hier finden Sie die aktuellen allgemeinen Geschäftsbedingungen zur unentgeltlichen Nutzung der Software vAlign Goals:
                    </StandardPaperText>
                    <a href="/documents/valign_goals_agb.pdf" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
                        <Button sx={{ margin: "1rem 0 0 0" }} variant="contained" color="primary">Download als PDF</Button>
                    </a>
                </StandardPaper>
            </MainContainer>
        </MainContent>
    </>
}
export default Terms;