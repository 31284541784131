import Box from '@mui/material/Box';
import MainContainer from "main/MainContainer";
import React from "react";

const MainBar = ({
    children
}: {
    children: React.ReactNode
}) => {
    return <Box sx={{
        background: "rgb(255,255,255)",
        padding: "1rem 0",
        boxSizing: "border-box",
        minHeight: "4.5rem",
        display: "flex",
        alignItems: "flex-start"
    }}>
        <MainContainer>
            <Box sx={{
                display: "flex",
                alignItems: "flex-start"
            }}>
                {children}
            </Box>
        </MainContainer>
    </Box>
}
export default MainBar;