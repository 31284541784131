import React from "react";

class MainErrorBoundary extends React.Component<{errorMessage: React.ReactNode, children: React.ReactNode}> {

    state = { hasError: false }
    
    constructor(props: {errorMessage: React.ReactNode, children: React.ReactNode}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // TODO
    }

    render() {
        if(this.state.hasError) {
            return <>
                    {this.props.errorMessage}
            </>
        }
        return this.props.children;
    }
}
export default MainErrorBoundary;