import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import AppEmphBolder from "common/AppEmphBolder";
import ExplanationParagraph from "common/ExplanationParagraph";
import SpacedRepetitionImgSrc from "main/goals/_assets/spaced_repetition.png";

const Img = styled("img")({
    width: "100%"
})

const GoalReviewCycleModeTooltipSpaced = () => <>
    <Stack direction="row" spacing={"2rem"} sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box sx={{ minWidth: "12rem", width: "12rem" }}>
            <Img src={SpacedRepetitionImgSrc} />
        </Box>
        <Stack direction="column" spacing={"1rem"}>

            <Box sx={{ fontSize: "0.875rem", lineHeight: "1.2rem", maxWidth: "25rem" }}>
                <Box sx={{ fontSize: "0.95rem", fontWeight: 500, margin: "0 0 0.5rem 0" }}>Spaced Repetition</Box>
                <Box>
                vAlign erinnert euch an eure Working Agreements &ndash; in immer größer werdenden Abständen, solange ihr mit dem Zustand des Working Agreements zufrieden seid.
                    <ExplanationParagraph>Läuft es nicht so gut, erinnert euch vAlign wieder in kürzeren Abständen an das Working Agreement.</ExplanationParagraph>
                    <ExplanationParagraph><AppEmphBolder>Dies ist der empfohlene Modus für Working Agreements.</AppEmphBolder></ExplanationParagraph>
                </Box>
            </Box>
        </Stack>
    </Stack>
</>
export default GoalReviewCycleModeTooltipSpaced;