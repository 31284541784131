import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Dashboard from "main/dashboard/Dashboard";
import GoalsRoute from "main/goals/GoalsRoute";
import GoalRoute from "main/goals/goal_details/GoalRoute";
import NewGoalRoute from "main/goals/NewGoalRoute";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainErrorBoundary from "main/MainErrorBoundary";
import Review from "main/review/Review";
import AppSettingsRoute from "main/settings/AppSettingsRoute";
import ImprintRoute from "main/terms/ImprintRoute";
import PrivacyRoute from "main/terms/PrivacyRoute";
import TermsRoute from "main/terms/TermsRoute";

const MainCanvas = () => {

    return <Box sx={{
        height: `calc(100vh - ${useTheme().app.toolbarHeight}px)`,
        mt: `${useTheme().app.toolbarHeight}px`,
        width: "100%",
        padding: "0",
        overflowY: "scroll",
        boxSizing: "border-box"
    }}>
        <MainErrorBoundary errorMessage={<>
            <MainContent>
                <MainContainer>
                    Das hat nicht geklappt... Es ist ein Fehler aufgetreten.
                </MainContainer>
            </MainContent>
        </>}>
            <Dashboard />
            <GoalsRoute />
            <NewGoalRoute />
            <GoalRoute />
            <Review />
            <AppSettingsRoute />
            <ImprintRoute />
            <TermsRoute />
            <PrivacyRoute />
        </MainErrorBoundary>
    </Box>
}
export default MainCanvas;