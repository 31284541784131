/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewMainTitleMain_getGlobalsReturn = {
    readonly dueForReviewCount: number;
    readonly " $refType": "ReviewMainTitleMain_getGlobalsReturn";
};
export type ReviewMainTitleMain_getGlobalsReturn$data = ReviewMainTitleMain_getGlobalsReturn;
export type ReviewMainTitleMain_getGlobalsReturn$key = {
    readonly " $data"?: ReviewMainTitleMain_getGlobalsReturn$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewMainTitleMain_getGlobalsReturn">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewMainTitleMain_getGlobalsReturn",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueForReviewCount",
      "storageKey": null
    }
  ],
  "type": "GetGlobalsReturn",
  "abstractKey": null
};
(node as any).hash = '6622e0418140161f3a723cffb27d4eab';
export default node;
