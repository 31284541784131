import Goals from "main/goals/Goals";
import { Route, Routes } from "react-router-dom";

const GoalsRoute = () => {
  return <>
    <Routes>
      <Route path="*" element={<></>} />
      <Route path="/goals/new" element={<></>} />
    </Routes>
    <Goals pathname="/goals" key="goals" />
    <Goals pathname="/archive" archive key="archive" />
  </>
}
export default GoalsRoute;