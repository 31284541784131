import { Box, SxProps } from "@mui/system";
import GoalFieldText from "main/goals/common/field/GoalFieldText";

export type GoalFieldTextMainStyle = "default" | "emphasized" | "emphasizedNumber";

const GoalFieldTextMain = ({ children, fieldStyle = "default", sx, additional }: { children: React.ReactNode, fieldStyle?: GoalFieldTextMainStyle, sx?: SxProps, additional?: string }) => {
    return <GoalFieldText sx={Object.assign({
        fontSize: fieldStyle === "default" ? "1.1rem" : fieldStyle==="emphasizedNumber" ? "2rem" : "1.4rem",
        lineHeight: fieldStyle === "default" ? "1.5rem" : fieldStyle==="emphasizedNumber" ? "2.9rem" :  "1.9rem",
        minHeight: fieldStyle === "default" ? "1.7rem" : "2rem",
        padding: fieldStyle === "default" ? "0" : "0.3rem 0",
        fontWeight: 400,
        display: "flex",
        alignItems: "center"
    }, sx ?? {})}>
        {children}
        {additional && <><>&nbsp;</><Box component="span" sx={{fontWeight: 100}}>{additional}</Box></>}
    </GoalFieldText>
}
export default GoalFieldTextMain;