import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AppTextField from "forms/AppTextField";
import { useEffect, useState } from "react";
import useWaitDuration from "common/useWaitDuration";
import LoginError from "authentication/LoginError";
import useRegexValidations from "common/useRegexValidations";
import useAccount from "authentication/_hooks/useAccount";
import ValidationIndicators from "authentication/ValidationIndicators";
import { useLocation, useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import AppParagraph from "common/AppParagraph";
import AppEmph from "common/AppEmph";

const RecoverForm = () => {

    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [searchParamEmail, setSearchParamEmail] = useState<string>("");
    const [state, setState] = useState<"default" | "sendingCode" | "codeSentSuccess" | "codeReceived" | "error" | "sendingNewPassword" | "setNewPasswordError" | "setNewPasswordSuccess">("default");

    const { validateEmail, validatePassword } = useRegexValidations();
    const waitDuration = useWaitDuration();
    const errorsExist = state === "error";
    const setNewPasswordErrorsExist = state === "setNewPasswordError";
    const formInputsDisabled = state === "sendingCode" || state === "sendingNewPassword";

    const validEmailForm = validateEmail(email);
    const passwordConfirmed = password !== "" && password === passwordConfirm;
    const validPasswordForm = validatePassword(password) && passwordConfirmed;

    const { forgotPassword, forgotPasswordSubmit } = useAccount();
    const navigate = useNavigate();

    const unsetFormInputs = () => {
        setEmail("");
        setPassword("");
        setPasswordConfirm("");
    }

    const setNewPassword = async () => {
        if (state !== "codeReceived" && state !== "setNewPasswordError") return;
        const t = Date.now();
        setState("sendingNewPassword");
        const searchParams = new URLSearchParams(location.search);
        const emailAddress = searchParams.get("email");
        const code = searchParams.get("code");
        if (!emailAddress || !code) {
            await waitDuration(2000, t);
            setState("setNewPasswordError");
            unsetFormInputs();
            return;
        }
        try {
            await forgotPasswordSubmit(emailAddress, code, password);
            await waitDuration(2000, t);
            unsetFormInputs();
            setState("setNewPasswordSuccess");
        } catch (e) {
            await waitDuration(2000, t);
            unsetFormInputs();
            setState("setNewPasswordError")
        }
    }

    const sendCode = async () => {
        if (state !== "default" && state !== "error") return;
        const t = Date.now();
        setState("sendingCode");
        try {
            await forgotPassword(email);
            await waitDuration(2000, t);
            setState("codeSentSuccess");
        } catch (e) {
            await waitDuration(2000, t);
            setState("error")
        }
    }

    const [mounted, setMounted] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (mounted) return;
        setMounted(true);

        const searchParams = new URLSearchParams(location.search);
        const emailAddress = searchParams.get("email");
        const code = searchParams.get("code");
        if (!emailAddress || !code) return;
        setState("codeReceived");
        setSearchParamEmail(emailAddress);
    }, [setMounted, setState, setSearchParamEmail, location.search, mounted])


    return <>
        <Box sx={{ padding: "1.5rem 1.5rem 2rem 1.5rem", width: "100%", boxSizing: "border-box" }}>
            {(() => {
                switch (state) {


                    case "default":
                    case "sendingCode":
                    case "error":
                        return <>
                            <Typography sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                                Du hast dein Passwort vergessen?
                            </Typography>
                            <Typography sx={{ fontSize: "0.875rem", margin: "0.5rem 0 0 0" }}>
                                Dann gib hier deine E-Mail-Adresse ein und wir schicken dir eine Mail mit einem Link, über den du dein Passwort neu setzen kannst.
                            </Typography>
                            <Stack direction="column" spacing="0.5rem" sx={{ margin: "1rem 0 0 0", width: "100%", boxSizing: "border-box" }}>
                                <AppTextField
                                    label="E-Mail-Adresse"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    disabled={formInputsDisabled}
                                />
                                <Box sx={{ padding: "0.5rem 0 0 0", boxSizing: "border-box", width: "100%" }}>
                                    <Button variant="contained" size="small" sx={{ width: "100%", height: "2rem", display: "flex", alignItems: "center", lineHeight: "1em" }} disabled={!validEmailForm} disableRipple={formInputsDisabled} disableElevation onClick={sendCode}>
                                        {(state === "default" || state === "error") && <>Passwort zurücksetzen</>}
                                        {state === "sendingCode" && <CircularProgress size="1rem" sx={{ color: "rgba(255,255,255,1)" }} />}
                                    </Button>
                                </Box>
                                {errorsExist && <LoginError>
                                    {(() => {
                                        switch (state) {
                                            default:
                                                return <>Während des Zurücksetzen des Passworts ist ein Fehler aufgetreten.</>
                                        }
                                    })()}

                                </LoginError>}
                            </Stack>
                        </>
                    case "codeSentSuccess":
                        return <>
                            <Stack direction="row" spacing="0.5rem" sx={{ margin: "0 0 1rem 0", display: "flex", alignItems: "center" }}>
                                <CheckIcon sx={{ color: theme => theme.palette.success.main, fontSize: "1.5rem" }} />
                                <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                                    Passwort erfolgreich zurückgesetzt
                                </Typography>
                            </Stack>
                            <Typography sx={{ fontSize: "0.875rem" }} component="div">
                                Dein Passwort wurde erfolgreich zurückgesetzt.
                                <AppParagraph>
                                    Wir haben eine Mail an die Adresse <AppEmph>{email}</AppEmph> geschickt. Darin findest du einen Link, über den du ein neues Passwort setzen kannst.
                                </AppParagraph>
                            </Typography>
                        </>
                    case "codeReceived":
                    case "sendingNewPassword":
                    case "setNewPasswordError":
                        return <>
                            <Typography sx={{ fontSize: "0.875rem", }}>
                                Setze hier ein neues Passwort für dein Nutzerkonto mit der E-Mail-Adresse <AppEmph>{searchParamEmail}</AppEmph>:
                            </Typography>
                            <Stack direction="column" spacing="0.5rem" sx={{ margin: "1rem 0 0 0", width: "100%", boxSizing: "border-box" }}>
                                <AppTextField
                                    label="Neues Passwort"
                                    type="password"
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    disabled={formInputsDisabled}
                                />
                                <ValidationIndicators password={password} />
                                <AppTextField
                                    label="Neues Passwort wiederholen"
                                    type="password"
                                    value={passwordConfirm}
                                    onChange={event => setPasswordConfirm(event.target.value)}
                                    disabled={formInputsDisabled}
                                />
                                <Stack direction="row" spacing="0.5rem" sx={{ fontSize: "0.75rem" }}>
                                    {[{
                                        label: "Passwörter stimmen überein",
                                        fulfilled: passwordConfirmed
                                    }].map((item, i) => <Stack direction="row" key={i} sx={{ whiteSpace: "nowrap", alignItems: "center", transition: "0.4s" }}>
                                        <CheckIcon sx={{ fontSize: "0.75rem", margin: "0 0.2rem 0 0", opacity: item.fulfilled ? 1 : 0.5, transition: "0.2s", color: theme => item.fulfilled ? theme.palette.success.main : "rgba(0,0,0,0.25)" }} />
                                        <Box component="span" sx={{ opacity: item.fulfilled ? 0.5 : 0.8, transition: "0.2s" }}>{item.label}</Box>
                                    </Stack>)}
                                </Stack>
                                <Box sx={{ padding: "0.5rem 0 0 0", boxSizing: "border-box", width: "100%" }}>
                                    <Button variant="contained" size="small" sx={{ width: "100%", height: "2rem", display: "flex", alignItems: "center", lineHeight: "1em" }} disabled={!validPasswordForm} disableRipple={formInputsDisabled} disableElevation onClick={setNewPassword}>
                                        {(state === "codeReceived" || state === "setNewPasswordError") && <>Neues Passwort setzen</>}
                                        {state === "sendingNewPassword" && <CircularProgress size="1rem" sx={{ color: "rgba(255,255,255,1)" }} />}
                                    </Button>
                                </Box>
                                {setNewPasswordErrorsExist && <LoginError>
                                    {(() => {
                                        switch (state) {
                                            default:
                                                return <>Während des Setzen des neuen Passworts ist ein Fehler aufgetreten.</>
                                        }
                                    })()}

                                </LoginError>}
                            </Stack>
                        </>
                    case "setNewPasswordSuccess":
                        return <>
                            <Stack direction="row" spacing="0.5rem" sx={{ margin: "0 0 1rem 0", display: "flex", alignItems: "center" }}>
                                <CheckIcon sx={{ color: theme => theme.palette.success.main, fontSize: "1.5rem" }} />
                                <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                                    Neues Passwort erfolgreich gesetzt
                                </Typography>
                            </Stack>
                            <Typography sx={{ fontSize: "0.875rem" }} component="div">
                                Dein neues Passwort wurde erfolgreich gesetzt.
                                <AppParagraph>
                                    Du kannst dich jetzt mit deiner E-Mail-Adresse und deinem neuen Passwort anmelden.
                                </AppParagraph>
                                <Button variant="contained" color="primary" onClick={() => navigate("/login")} sx={{
                                    margin: "2rem 0 0 0"
                                }}>
                                    Zur Anmeldung
                                </Button>
                            </Typography>
                        </>
                    default:
                        return <></>
                }
            })()}
        </Box>
    </>


}
export default RecoverForm;