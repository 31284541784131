/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteReviewInput = {
    id: string;
};
export type useDeleteReviewMutationVariables = {
    input: DeleteReviewInput;
    connections: Array<string>;
};
export type useDeleteReviewMutationResponse = {
    readonly deleteReview: {
        readonly deletedId: string;
    };
};
export type useDeleteReviewMutation = {
    readonly response: useDeleteReviewMutationResponse;
    readonly variables: useDeleteReviewMutationVariables;
};



/*
mutation useDeleteReviewMutation(
  $input: DeleteReviewInput!
) {
  deleteReview(input: $input) {
    deletedId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteReviewResponse",
        "kind": "LinkedField",
        "name": "deleteReview",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useDeleteReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteReviewResponse",
        "kind": "LinkedField",
        "name": "deleteReview",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d7e774b7b6deb3780a73c0d83e81a09c",
    "id": null,
    "metadata": {},
    "name": "useDeleteReviewMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteReviewMutation(\n  $input: DeleteReviewInput!\n) {\n  deleteReview(input: $input) {\n    deletedId\n  }\n}\n"
  }
};
})();
(node as any).hash = '5b950cfa3e0be81f79b5e9ffa752de10';
export default node;
