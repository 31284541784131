import { useRef, useState } from "react";

type ControlFormFieldProps<V, T> = {
    fieldName: string,
    validations?: { [validationKey: string]: (validationFormData: T) => (v: V) => boolean },
    onChange?: (v: V) => void
}

export type ControlFormFieldReturn<V> = {
    value: V,
    updateValue: (v: V) => void,
    handleChange: (v: V, dontValidate?: boolean) => void,
    visited: boolean,
    changed: boolean,
    setVisited: (b: boolean) => void,
    errors: { [errorKey: string]: boolean },
    updateErrors: () => void,
    errorsExist: () => boolean,
    validate: () => void,
    validateForm: () => void
}

export type FormHandlersReturn<T extends { [key: string]: any }> = {
    formData: T,
    formDataRef: React.MutableRefObject<T>,
    errors: { [key: string]: { [errorKey: string]: boolean } | undefined },
    errorsExist: () => boolean,
    errorsForFieldExist: (fieldName: string) => boolean,
    resetFormData: (data?: T) => void,
    setFormFieldValue: ({ fieldName, value }: { fieldName: string, value: any }) => void,
    controlFormField: <V>(props: ControlFormFieldProps<V, T>) => ControlFormFieldReturn<V>,
    isValidForm: () => boolean,
    validateForm: () => void,
    setAllVisited: () => void,
    excludeFromValidation: (s: string) => void,
    includeForValidation: (s: string) => void,
}

const useFormHandlers = <T extends { [key: string]: any }>({
    initialFormData,
    initialValidState,
    initiallyExcludeFromValidation = {}
}: {
    initialFormData: T,
    initialValidState: { [Property in keyof T]: boolean },
    initiallyExcludeFromValidation?: { [Property in keyof T]?: boolean }
}): FormHandlersReturn<T> => {
    const validState = useRef<{ [key: string]: boolean }>({ ...initialValidState });
    const [formData, setFormData] = useState<T>({ ...initialFormData });
    const formDataRef = useRef<T>({ ...initialFormData });
    const validationFormData = useRef<{ [key: string]: any }>({ ...initialFormData });
    const excludeFromValidationList = useRef<{ [key: string]: boolean | undefined }>({ ...initiallyExcludeFromValidation })

    const [visitedState, setVisitedState] = useState<{ [key: string]: boolean | undefined }>({})
    const [changedState, setChangedState] = useState<{ [key: string]: boolean | undefined }>({})
    const validationsMap = useRef<{ [key: string]: { [validationKey: string]: (validationFormData: T) => (v: any) => boolean } | undefined }>({});
    const [errors, setErrors] = useState<{ [key: string]: { [errorKey: string]: boolean } | undefined }>({});

    const resetFormData = (data?: T) => {
        setFormData({ ...(data ?? initialFormData) });
        formDataRef.current = { ...(data ?? initialFormData) };
        validState.current = { ...initialValidState };
        validationFormData.current = { ...(data ?? initialFormData) };
        excludeFromValidationList.current = { ...initiallyExcludeFromValidation };
        setVisitedState({});
        validationsMap.current = {};
        setErrors({});
    }

    const isValidForm = () => {
        return !!validState.current &&
            Object.entries(validState.current).reduce((a, [key, value]) => a && !!(value || excludeFromValidationList.current[key]), true)
    };

    const errorsExist = () => Object.keys(formData).map(fieldName => errorsForFieldExist(fieldName)).reduce((a, c) => a || c, false);

    const errorsForFieldExist = (fieldName: string) => Object.keys(errors[fieldName] ?? {}).length > 0 && Object.values(errors[fieldName] ?? {}).reduce((a, c) => a || c, false);

    const calculateErrors = (fieldName: string, value: any) => {
        const errors: { [errorKey: string]: boolean } = {};
        Object.entries(validationsMap.current[fieldName] ?? {}).forEach(([key, validation]) => {
            errors[key] = !validation(validationFormData.current as T)(value)
        })
        return errors;
    }

    const updateErrorsOfField = (fieldName: string) => {
        const calculatedErrors = calculateErrors(fieldName, validationFormData.current[fieldName]);
        setErrors(formerErrors => {
            formerErrors[fieldName] = calculatedErrors;
            return formerErrors;
        });
    }

    const validateField = (fieldName: string, calculatedErrors?: { [errorKey: string]: boolean }) => {
        const recalculatedErrors = calculatedErrors ?? calculateErrors(fieldName, validationFormData.current[fieldName]);
        validState.current[fieldName] = Object.values(
            recalculatedErrors
        ).reduce((a, c) => a && !c, true)
        setErrors(formerErrors => {
            const newErrors = { ...formerErrors }
            newErrors[fieldName] = recalculatedErrors;
            return newErrors;
        });
    }

    const validateForm = (dontSetErrors = false) => {
        const calculatedErrors: { [errorKey: string]: { [errorKey: string]: boolean } } = {};
        Object.keys(formData).forEach(fieldName => {
            calculatedErrors[fieldName] = calculateErrors(fieldName, validationFormData.current[fieldName]);
            validateField(fieldName, calculatedErrors[fieldName]);
        })
        if (!dontSetErrors) {
            setErrors(calculatedErrors);
        }
    }

    const setAllVisited = () => {
        const newVisitedState: { [key: string]: boolean } = {}
        Object.keys(formData).forEach(fieldName => {
            newVisitedState[fieldName] = true;
        })
        setVisitedState(newVisitedState);
    }

    const updateExcludeFromValidationList = (s: string, b: boolean) => {
        excludeFromValidationList.current[s] = b;
        validateForm(true);
    }

    const setFormFieldValue = ({ fieldName, value }: { fieldName: string, value: any }) => {
        setFormData(Object.assign({}, formData, { [fieldName]: value }));
        formDataRef.current = Object.assign({}, formData, { [fieldName]: value });
    }

    const excludeFromValidation = (s: string) => updateExcludeFromValidationList(s, true);
    const includeForValidation = (s: string) => updateExcludeFromValidationList(s, false);

    const controlFormField = <V>({ fieldName, validations, onChange }: ControlFormFieldProps<V, T>): ControlFormFieldReturn<V> => {
        validationsMap.current[fieldName] = validations ?? {};
        const updateValue = (v: V) => {
            setFormData(state => Object.assign({}, state, { [fieldName]: v }));
            formDataRef.current = Object.assign({}, formData, { [fieldName]: v });
            validationFormData.current[fieldName] = v;
            setChangedState(state => Object.assign({}, state, { [fieldName]: true }));
            onChange && onChange(v);
        }
        const setVisited = (b: boolean) => setVisitedState(state => Object.assign({}, state, { [fieldName]: b }));
        const updateErrors = () => updateErrorsOfField(fieldName);
        const errorsExist = () => errorsForFieldExist(fieldName);
        const validate = () => validateField(fieldName);
        const handleChange = (v: V, dontValidate: boolean = false) => {
            updateValue(v);
            if (!dontValidate) {
                updateErrors();
                validate();
            }
        }

        return {
            value: formData[fieldName],
            updateValue,
            handleChange,
            visited: !!visitedState[fieldName],
            changed: !!changedState[fieldName],
            setVisited,
            errors: errors[fieldName] ?? { "couldNotFind": true },
            updateErrors,
            errorsExist,
            validate,
            validateForm
        }
    }

    return {
        formData,
        formDataRef,
        errors,
        errorsExist,
        errorsForFieldExist,
        resetFormData,
        setFormFieldValue,
        controlFormField,
        isValidForm,
        validateForm,
        setAllVisited,
        excludeFromValidation,
        includeForValidation,
    }
}
export default useFormHandlers;
