import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperTitle from "common/StandardPaperTitle";
import FormFieldSavingIndicator from "forms/FormFieldSavingIndicator";
import ExpandIcon from "@mui/icons-material/ExpandMoreRounded";
import React, { useState } from "react";
import { Collapse, Divider, IconButton, Stack } from "@mui/material";
import AnimationWrapper from "common/AnimationWrapper";

const StandardPaperExpandable = ({ children, title, eventSource, summary, embedded, open, expandIconLabel = "ausklappen", subtitle }: {
    children: React.ReactNode,
    title: React.ReactNode,
    eventSource?: React.MutableRefObject<EventTarget>,
    summary?: React.ReactNode,
    expandIconLabel?: React.ReactNode | null,
    embedded?: boolean,
    subtitle?: boolean,
    open?: boolean
}) => {
    const [expanded, setExpanded] = useState(!!open);
    const toggleExpanded = () => {
        setExpanded(state => !state);
    }
    return ((main: React.ReactNode) => embedded ? <>{main}</> : <StandardPaper>{main}</StandardPaper>)(<>
        <StandardPaperTitle noOpacityReduction compact embedded={embedded} subtitle={subtitle}>
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Box sx={{ opacity: 0.8, minWidth: "max-content" }}>{title}</Box>
                {eventSource && <Box sx={{ margin: "0 0 0 0.5rem" }}>
                    <FormFieldSavingIndicator eventSource={eventSource.current} />
                </Box>}
                {summary && <Box sx={{ fontSize: "0.9rem", fontWeight: "300", margin: "0 0 0 1rem", boxSizing: "border-box", padding: "0 3rem 0 0", transform: embedded || subtitle ? undefined : "translate(0, 0.05rem)", opacity: expanded ? 0 : 1, transition: "0.4s" }}>
                    {summary}
                </Box>}
            </Box>
            <Box sx={{ justifySelf: "flex-end", height: "100%", position: "relative" }}>
                <Box sx={{ position: "absolute", top: 0, right: 0, height: "100%", width: "max-content", display: "flex", alignItems: "center", }}>
                    <div onClick={toggleExpanded}>
                        <Stack direction="row" spacing={"0.25rem"} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                            {expandIconLabel && <Box sx={{ fontSize: "0.825rem", opacity: { xs: 0, lg: expanded ? 0 : 1 }, transition: "0.4s" }}>{expandIconLabel}</Box>}
                            <IconButton size="small" sx={{ transform: expanded ? "rotate(180deg)" : undefined, transition: "0.4s" }}><ExpandIcon /></IconButton>
                        </Stack>
                    </div>
                </Box>
            </Box>
        </StandardPaperTitle>
        <Collapse in={expanded}>
            <AnimationWrapper>
                <Divider sx={{ margin: "0.5rem 0 1rem 0", opacity: 0.8 }} />
                {children}
            </AnimationWrapper>
        </Collapse>
    </>)
}
export default StandardPaperExpandable;