/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalDetails_goal = {
    readonly readableId: number;
    readonly archived: boolean | null;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetailsOverview_goal" | "GoalDetailsReviewTab_goal" | "GoalDetailsReview_goal" | "GoalDetailsSettings_goal">;
    readonly " $refType": "GoalDetails_goal";
};
export type GoalDetails_goal$data = GoalDetails_goal;
export type GoalDetails_goal$key = {
    readonly " $data"?: GoalDetails_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalDetails_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalDetails_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readableId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsOverview_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsReviewTab_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsReview_goal"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalDetailsSettings_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '75dcf9ff4c3cbc0f3602e24be2b96636';
export default node;
