const useRegexValidations = () => {
    /* eslint-disable */

    const hasPasswordSpecialChar = (s: string | null) => s !== null && /[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;\|_~`\+=]/.test(s);
    const hasNumber = (s: string | null) => s !== null && /[0-9]/.test(s);
    const hasUpperCaseLetter = (s: string | null) => s !== null && /[A-Z]/.test(s);
    const hasLowerCaseLetter = (s: string | null) => s !== null && /[a-z]/.test(s);
    const hasMinPasswordLength = (s: string | null) => s !== null && s.length >= 8;

    return {
        validateEmail: (email: string) => /^[0-9a-zA-Z-_][0-9a-zA-Z-\._]*@[0-9a-zA-Z-\._]+\.[a-zA-Z-]{2,3}$/.test(email),
        hasPasswordSpecialChar,
        hasNumber,
        hasUpperCaseLetter,
        hasLowerCaseLetter,
        hasMinPasswordLength,
        validatePassword: (password: string) => hasPasswordSpecialChar(password) && hasNumber(password) && hasUpperCaseLetter(password) && hasLowerCaseLetter(password) && hasMinPasswordLength(password)
    }
    /* eslint-disable */
}
export default useRegexValidations;