import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import useEventBus, { createCustomEventGoalSelected } from "app/useEventBus";
import useReadableId from "common/useReadableId";
import { GoalType } from "graphql/API";
import { DateTime } from "luxon";
import GoalStatusField from "main/goals/common/fields/GoalStatusField";
import GoalDueDateField from "main/goals/common/fields/GoalDueDateField";
import { setSelectedGoal } from "main/goals/GoalSlice";
import GoalCardFieldContainer from "main/goals/goal_card/GoalCardFieldContainer";
import GoalCardId from "main/goals/goal_card/GoalCardId";
import GoalCardTitle from "main/goals/goal_card/GoalCardTitle";
import GoalCardType from "main/goals/goal_card/GoalCardType";
import GoalCardMenuButton from "main/goals/goal_card/menu/GoalCardMenuButton";
import { GoalCard_goal$key } from "main/goals/goal_card/__generated__/GoalCard_goal.graphql";
import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router";
import useReviewContext from "common/useReviewContext";
import useArchiveContext from "common/useArchiveContext";
import GoalReviewField from "main/goals/common/fields/GoalReviewField";
const graphql = require("babel-plugin-relay/macro");

const GoalCard = ({ goal, compactView = false, connectionId, showReviewButton }: {
    goal: GoalCard_goal$key,
    compactView?: boolean,
    connectionId?: string,
    showReviewButton?: boolean
}) => {

    const data = useFragment(graphql`
        fragment GoalCard_goal on Goal {
            id
            type
            readableId
            title
            nextReviewAt
            currentReviewAt
            dueDate
            completion
            ...GoalCardTitle_goal
            ...GoalStatusField_goal
            ...GoalCardMenuButton_goal
        }
    `, goal)

    const { getReadableId } = useReadableId();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const eventBus = useEventBus();
    const reviewContext = useReviewContext();
    const archiveContext = useArchiveContext();
    let pathnameBegin = "";
    if (reviewContext) {
        pathnameBegin = "/review";
    } else if (archiveContext) {
        pathnameBegin = "/archive"
    }
    const handleClick: MouseEventHandler<HTMLDivElement> = e => {
        dispatch(setSelectedGoal({ id: data.id, readableId: data.readableId, type: data.type as GoalType, title: data.title }));
        eventBus.dispatchEvent(createCustomEventGoalSelected(data.readableId));
        navigate(`${pathnameBegin}/goal/${data.readableId}${reviewContext ? "/review" : ""}`);
    }

    return <Paper onClick={handleClick} elevation={0} sx={{
        display: "grid",
        position: "relative",
        gridTemplateColumns: `min-content min-content 1fr min-content min-content`,
        alignItems: "stretch",
        overflow: "hidden",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.08), 0px 1px 1px 0px rgba(0,0,0,0.05), 0px 1px 3px 0px rgba(0,0,0,0.04)",
        cursor: "pointer",
        transition: "0.1s",
        ":hover": {
            background: theme => theme.app.defaultHoverBackground
        }
    }}>
        <GoalCardType type={data.type as GoalType} />
        {!compactView ? <GoalCardId type={data.type as GoalType} id={getReadableId(data.readableId)} /> : <Box sx={{ width: "1rem" }} />}
        <GoalCardTitle
            goal={data}
            readableId={getReadableId(data.readableId)}
            title={data.title}
            nextReviewAt={DateTime.fromISO(data.nextReviewAt as string)}
            type={data.type as GoalType}
            dueDate={data.dueDate ? DateTime.fromISO(data.dueDate as string) : undefined}
            completion={data.completion}
            compactView={compactView}
        />
        {!compactView && <Box sx={{ display: "flex" }}>
            {data.dueDate && <GoalCardFieldContainer>
                <GoalDueDateField dueDate={DateTime.fromISO(data.dueDate as string)} />
            </GoalCardFieldContainer>
            }
            <GoalCardFieldContainer width="10rem">
                <GoalStatusField goal={data} />
            </GoalCardFieldContainer>
            <GoalCardFieldContainer>
                <GoalReviewField
                    label={archiveContext ? "letzter Review" : undefined}
                    dueStyle={archiveContext ? false : true}
                    agoStyle={archiveContext ? true : false}
                    reviewAt={DateTime.fromISO(archiveContext ? data.currentReviewAt as string : data.nextReviewAt as string)}
                    {...{ showReviewButton }}
                />
            </GoalCardFieldContainer>
        </Box>}
        <GoalCardMenuButton compactView={compactView} goal={data} connectionId={connectionId} />
    </Paper>
}
export default GoalCard;