/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalType = "completable" | "continuous" | "%future added value";
export type GoalStatusField_goal = {
    readonly type: GoalType;
    readonly status: number;
    readonly completion: number | null;
    readonly currentReviewAt: string;
    readonly " $refType": "GoalStatusField_goal";
};
export type GoalStatusField_goal$data = GoalStatusField_goal;
export type GoalStatusField_goal$key = {
    readonly " $data"?: GoalStatusField_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalStatusField_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalStatusField_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentReviewAt",
      "storageKey": null
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = '09a03432039ebda21b1b800557bcce01';
export default node;
