import { Box, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StartFooter = () => {
    const navigate = useNavigate();

    return <Stack direction="row" spacing="0.5rem" divider={<Divider orientation="vertical" flexItem />} sx={{ opacity: 0.4, margin: "2rem 0 0 0", fontSize: "0.75rem", display: "flex", alignItems: "center" }}>
        <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
            vAlign Goals
            2022
        </Stack>
        <Box>
            <div onClick={() => navigate("/imprint")} data-cy="imprintLink">
                <Box sx={{ color: "rgba(0,0,0,0.8)", textDecoration: "underline", cursor: "pointer" }}>Impressum</Box>
            </div>
        </Box>
    </Stack>
}

export default StartFooter;