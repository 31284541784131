import ShareIcon from '@mui/icons-material/ShareRounded';
import { Button, Dialog, DialogActions, Link, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import useAccount from "authentication/_hooks/useAccount";
import DialogContentApp from "common/DialogContentApp";
import DialogTitleWithIcon from "common/DialogTitleWithIcon";
import useMount from "common/useMount";
import WarningWithIcon, { WarningWithIconEmph } from "common/WarningWithIcon";
import { useState } from "react";

export const GuestToolsShareURLDialog = ({ onClose }: { onClose: () => void }) => {
    const guestAccountId = useAccount().getGuestAccountId();
    const [open, setOpen] = useState(true);
    const [showURL, setShowURL] = useState(false);

    const getURL = () => {
        let url = window.location.origin;
        url += window.location.pathname;
        url += "?guest=" + guestAccountId;
        return url;
    }
    
    return <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
        <DialogTitleWithIcon icon={<ShareIcon />}>
            Aktuelle Seite teilen
        </DialogTitleWithIcon>
        <DialogContentApp>
            Teile die folgende URL mit anderen Personen, um ihnen zu erlauben, auf die aktuelle Seite und deinen vAlign-Gast-Workspace zuzugreifen.
            <Box component="p" sx={{ fontWeight: 900, fontSize: "1rem", margin: "1.5rem 0" }}>
            {!showURL && <Link sx={{fontWeight: 400, cursor: "pointer"}} onClick={() => setShowURL(true)} role="button">URL einblenden</Link>}
                {showURL && getURL()}
            </Box>
            <WarningWithIcon>
                    Wenn du diese URL mit anderen Personen teilst, haben diese <WarningWithIconEmph>vollen Zugriff auf deinen gesamten vAlign-Workspace!</WarningWithIconEmph>
            </WarningWithIcon>
        </DialogContentApp>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>OK, verstanden</Button>
        </DialogActions>
    </Dialog>
}

const GuestToolsShareURL = () => {

    const { mountComponent, unmount } = useMount();

    return <>
        <MenuItem onClick={() => mountComponent(<GuestToolsShareURLDialog onClose={unmount} />)}>
            <ListItemIcon><ShareIcon /></ListItemIcon>
            <ListItemText>Aktuelle Seite teilen</ListItemText>
        </MenuItem>
    </>
}
export default GuestToolsShareURL;