import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useEventBus, { EventTypes } from "app/useEventBus";
import DialogContentApp from "common/DialogContentApp";
import useMount from "common/useMount";
import useReadableId from "common/useReadableId";
import useSnackbar from "common/useSnackbar";
import { DeleteGoalMenuItem_goal$key } from "main/goals/goal_card/menu/__generated__/DeleteGoalMenuItem_goal.graphql";
import useDeleteGoal from "main/goals/__hooks/useDeleteGoal";
import { useState } from "react";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const DeleteGoalMenuItemDialog = ({ onClose, readableId, id, connectionId }: {
    onClose: () => void,
    readableId: number,
    id: string,
    connectionId?: string
}) => {

    const showSnackbar = useSnackbar();
    const [commit] = useDeleteGoal();
    const eventBus = useEventBus();
    const [status, setStatus] = useState<"ASK" | "DELETING">("ASK");
    const {getReadableId} = useReadableId();
    const printableReadableId = getReadableId(readableId);

    const deleteGoal = () => {
        setStatus("DELETING");
        commit({
            variables: {
                input: {
                    id: id,
                },
                connections: [connectionId ?? ""]
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_DELETED));
                showSnackbar({ message: `Ziel ${printableReadableId} wurde erfolgreich gelöscht.` })
                setOpen(false);
            },
            onError: e => {
                console.error(e)
                setOpen(false);
            }
        })
    }

    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
        {(() => {
            switch (status) {
                case "ASK":
                    return <>
                        <DialogTitle>
                            Wirklich löschen?
                        </DialogTitle>
                        <DialogContentApp>
                            Willst du das Ziel mit {printableReadableId} inklusive aller zugehörigen Reviews wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!
                        </DialogContentApp>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
                            <Button onClick={deleteGoal}>Ja, löschen</Button>
                        </DialogActions>
                    </>
                case "DELETING":
                    return <>
                        <DialogTitle>
                            <Box sx={{ fontWeight: "300" }}>Ziel <Typography sx={{ fontWeight: "500", fontSize: "1em", display: "inline-flex" }}>{printableReadableId}</Typography> wird gelöscht...</Box>
                        </DialogTitle>
                        <DialogContentApp>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}><CircularProgress sx={{ my: 1 }} /></Box>
                        </DialogContentApp>
                    </>
            }
        })()}

    </Dialog>
}

const DeleteGoalMenuItem = ({goal, connectionId}: {
    goal: DeleteGoalMenuItem_goal$key,
    connectionId?: string
}) => {
    const data = useFragment(graphql`
        fragment DeleteGoalMenuItem_goal on Goal {
            id
            readableId
        }
    `, goal)
    const {mountComponent, unmount} = useMount();
    return <MenuItem onClick={() => mountComponent(<DeleteGoalMenuItemDialog onClose={unmount} id={data.id} readableId={data.readableId} connectionId={connectionId} />)}>
        <ListItemIcon><DeleteIcon /></ListItemIcon>
        <ListItemText sx={{ mr: "2rem" }}>Löschen</ListItemText>
    </MenuItem>
}
export default DeleteGoalMenuItem;