import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "app/store";
import { GoalType } from "graphql/API";

type SelectedGoalDigest = {
    id: string,
    readableId: number,
    type: GoalType,
    title: string
}

interface GoalState {
    selectedGoal: SelectedGoalDigest | null;
}

const initialState: GoalState = {
    selectedGoal: null
}

export const GoalSlice = createSlice({
    name: 'goal',
    initialState,
    reducers: {
        setSelectedGoal: (state, action: PayloadAction<SelectedGoalDigest>) => {
            state.selectedGoal = action.payload;
        }
    }
})

export const { setSelectedGoal } = GoalSlice.actions;

export const selectSelectedGoal = (state: RootState) => state.goal.selectedGoal;

export default GoalSlice.reducer;