import {
    Environment,
    Network,
    RecordSource,
    Store
} from "relay-runtime";
import { ACCOUNT_LOGGED_IN_SESSION_STORAGE_NAME, getGuestAccountId } from "authentication/_hooks/useAccount";
import { Auth } from "aws-amplify";
import AppConsole from "app/AppConsole";
import fetchGraphQL, { FetchGraphQLFunction } from "fetchGraphQL";

const appConsole = AppConsole("RelayEnvironment");

class AppRelayEnvironment {
    public environment: Environment;
    private handleLogout: () => void = () => { };
    private customFetchGraphQL: FetchGraphQLFunction;

    constructor(customFetchGraphQL: FetchGraphQLFunction = fetchGraphQL) {
        this.environment = new Environment({
            network: Network.create(this.fetchRelay),
            store: new Store(new RecordSource())
        })
        this.customFetchGraphQL = customFetchGraphQL;
        appConsole.log("creating new relay environment...", 1);
    }

    setHandleLogout = (handleLogout: () => void) => {
        this.handleLogout = handleLogout;
    }

    fetchRelay = async (params: any, variables: any) => {

        appConsole.log(`fetching graphql query ${params.name}...`, 5)
        appConsole.log(`variables: ${JSON.stringify(variables)}`, 5)

        let user = null;
        try {
            user = await Auth.currentAuthenticatedUser();
            appConsole.log("authenticated fetch...", 5);
        } catch (e) {
            appConsole.log("unauthenticated fetch...", 5);
        }

        if (!user && window.sessionStorage.getItem(ACCOUNT_LOGGED_IN_SESSION_STORAGE_NAME) === "1") {
            this.handleLogout();
            throw new Error("LogoutException");
        }

        const guestAccountId = getGuestAccountId();
        const h: { [key: string]: string } = {};
        if (guestAccountId && !user) {
            h["valign-guest-account-id"] = guestAccountId;
        }

        const promise = this.customFetchGraphQL({
            query: params.text ? params.text : "",
            variables: variables,
            authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY"
        }, h);
        promise
            .then(data => {
                appConsole.log("successfully fetched result:", 5);
                appConsole.log(`variables: ${JSON.stringify(variables)}`, 5)
                appConsole.log(data, 5, { object: true });
            })
            .catch(e => console.error(e))

        return promise;
    }

}

export default AppRelayEnvironment;