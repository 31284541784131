import { DatePicker, DatePickerProps } from "@mui/lab";
import { TextField, TextFieldProps, useTheme } from "@mui/material";
import InputStyles from "forms/InputStyles";

const AppDatePicker = ({ customRenderInput, renderInput, boldValue, error, disableInputErrorStyle, ...other }: DatePickerProps & {
    customRenderInput?: any, boldValue?: boolean, error?: boolean, disableInputErrorStyle?: boolean
}) => {
    const theme = useTheme();
    return <DatePicker
        renderInput={(({ sx, InputLabelProps, ...otherCustomRenderInputProps }) => (renderInputProps: TextFieldProps) => {
            return <TextField
                variant="filled"
                InputLabelProps={Object.assign({sx:{
                    fontWeight: 300,
                    fontSize: "1.05rem"
                }, shrink: true }, InputLabelProps ?? {})}
                sx={Object.assign({}, InputStyles({error: error}), {
                    "& .MuiFilledInput-input": {
                        fontWeight: boldValue ? "500" : undefined,
                        pt: "1.75rem"
                    },
                    "& .Mui-error": disableInputErrorStyle ? {
                        color: "currentColor"
                    } : undefined,
                    "& .Mui-error:after": disableInputErrorStyle ? {
                        borderBottomColor: "rgba(0,0,0,0)",
                        transform: "scaleX(0)"
                    } : undefined,
                    "& .Mui-focused:after": disableInputErrorStyle ? {
                        borderBottomColor: theme.palette.primary.main,
                        transform: "scaleX(1)"
                    } : undefined
                }, sx)}
                {...otherCustomRenderInputProps}
                {...renderInputProps}
            />
        })(customRenderInput)}
        {...other}
    />
}
export default AppDatePicker;