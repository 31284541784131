/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteGoalInput = {
    id: string;
};
export type useDeleteGoalMutationVariables = {
    input: DeleteGoalInput;
    connections: Array<string>;
};
export type useDeleteGoalMutationResponse = {
    readonly deleteGoal: {
        readonly deletedId: string;
    };
};
export type useDeleteGoalMutation = {
    readonly response: useDeleteGoalMutationResponse;
    readonly variables: useDeleteGoalMutationVariables;
};



/*
mutation useDeleteGoalMutation(
  $input: DeleteGoalInput!
) {
  deleteGoal(input: $input) {
    deletedId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteGoalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteGoalResponse",
        "kind": "LinkedField",
        "name": "deleteGoal",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useDeleteGoalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteGoalResponse",
        "kind": "LinkedField",
        "name": "deleteGoal",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "578a026513a463efbe2864dcd5f1b372",
    "id": null,
    "metadata": {},
    "name": "useDeleteGoalMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteGoalMutation(\n  $input: DeleteGoalInput!\n) {\n  deleteGoal(input: $input) {\n    deletedId\n  }\n}\n"
  }
};
})();
(node as any).hash = '57598de30eb0ff028e691e7d6154bfd2';
export default node;
