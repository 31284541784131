/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewCard_review = {
    readonly id: string;
    readonly completion: number | null;
    readonly comment: string | null;
    readonly automatedReview: boolean | null;
    readonly createdAt: string;
    readonly " $fragmentRefs": FragmentRefs<"GoalStatusField_review" | "ReviewCardMenu_review">;
    readonly " $refType": "ReviewCard_review";
};
export type ReviewCard_review$data = ReviewCard_review;
export type ReviewCard_review$key = {
    readonly " $data"?: ReviewCard_review$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewCard_review">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCard_review",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automatedReview",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalStatusField_review"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewCardMenu_review"
    }
  ],
  "type": "Review",
  "abstractKey": null
};
(node as any).hash = '7786ac6bfa797dfd728adbfa7d5aefc7';
export default node;
