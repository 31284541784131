import MenuIcon from "@mui/icons-material/MoreVertRounded";
import { IconButton, Menu, SvgIconProps } from "@mui/material";
import React, { MouseEventHandler, useState } from "react";

const AppMenu = ({ children: menuItems, menuIconProps = {} }: { children?: React.ReactNode, menuIconProps?: SvgIconProps }) => {

    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
    const menuOpen = !!menuAnchor;
    const openMenu: MouseEventHandler<HTMLButtonElement> = event => {
        event.stopPropagation();
        setMenuAnchor(event.currentTarget);
    }
    const closeMenu = () => {
        setMenuAnchor(null);
    }

    const handleClick: MouseEventHandler<HTMLDivElement> = e => {
        e.stopPropagation();
        closeMenu();
    }

    return <>
        <IconButton onClick={openMenu}>
            <MenuIcon {...menuIconProps} />
        </IconButton>
        <Menu
            anchorEl={menuAnchor} open={menuOpen}
            onClose={handleClick}
            onClick={handleClick}
        >
            {menuItems}
        </Menu>
    </>
}
export default AppMenu;