import { Button, Card, CardActions, CardContent, CardMedia, Grid, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import successIcon from './_assets/success.png';
import goalIcon from './_assets/goal.png';
import reviewIcon from './_assets/review.png';
import { useNavigate } from "react-router";
import { styled } from "@mui/material";

const Introduction = ({ imgSize = "10rem", disableAppLinks, embedded }: { imgSize?: string, disableAppLinks?: boolean, embedded?: boolean }) => {

    const navigate = useNavigate();

    const IconImg = styled("img")({
        width: imgSize
    })
    const columnView = useMediaQuery(useTheme().breakpoints.up(embedded ? "md" : "intermediate"));

    return <Grid container spacing={{xs: 1, md: 2, lg: 3}} rowSpacing={{xs: 2}}>
        {[
            {
                title: "Ziele",
                subtitle: "Ergebnisse aus Lessons Learned und Retrospektiven",
                text: [
                    <><b>Erstellt neue Working Agreements und Ziele.</b></>,
                    <><b>Beispiel</b>: <Box component="span" sx={{ fontFamily: "Open Sans", fontStyle: "italic" }}>"Ab sofort führen wir jeden Morgen eine kurze Besprechung durch."</Box></>,
                    <>Dabei legt ihr außerdem fest, in welchem Modus ihr ein Ziel erneut überprüfen wollt.</>,
                    // <>Am besten ihr legt gleich los und erstellt euer erstes Ziel:</>
                ],
                action: "Neues Ziel erstellen",
                actionLink: "/goals/new",
                emphasizeAction: false,
                background: useTheme().app.modalBackground,
                icon: goalIcon
            },
            {
                title: "Reviews",
                subtitle: "Spaced Repetition Reviews",
                text: [
                    <>vAlign erinnert euch, sobald ihr ein Working Agreement oder Ziel wieder überprüfen solltet - <b>in immer größer werdenden Abständen, wenn alles gut läuft und in kurzen Abständen, wenn es noch Verbesserungs&shy;bedarf gibt.</b></>,
                    <>So investiert ihr eure wertvolle Zeit nur da, wo tatsächlich Handlungsbedarf besteht.</>,
                ],
                background: useTheme().app.modalBackground,
                icon: reviewIcon
            },
            {
                title: "Erfolge",
                subtitle: "Zeit sparen und Erfolge feiern",
                text: [
                    <>vAlign ist wie ein <b>persönlicher Trainer, der euch in passenden Abständen an eure Ziele und Working Agreements erinnert.</b></>,
                    <>Das smarte Erinnerungsmanagement hilft euch, aufwändig erarbeitete Working Agreements und Ziele nicht aus dem Blick zu verlieren und erfolgreich umzusetzen.</>
                    // <>So hilft euch vAlign, eure Ziele und Working Agreements nicht zu vergessen und letzlich erfolgreich umzusetzen.</>
                ],
                background: useTheme().app.modalBackground,
                icon: successIcon
            }
        ].map((i, j) => <Grid item xs={columnView ? 4 : 12} sx={{ alignSelf: "stretch" }} key={j}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: "column", borderRadius: "1rem" }} elevation={0}>
                <CardMedia component="div" sx={{ position: "relative", background: "rgba(255,255,255,1)", px: 2, py: 1, display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
                    <IconImg src={i.icon} />
                    <Box sx={{ position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "flex-end", pl: 2.5, pb: 1, boxSizing: "border-box" }}>
                        <Typography sx={{ color: "rgba(0,0,0, 0.7)", fontWeight: "900", fontSize: "2.5rem", fontFamily: "Dancing Script" }}>{j + 1}</Typography>
                    </Box>
                </CardMedia>
                <CardContent sx={{ flexGrow: 1, padding: "1rem 2rem" }}>
                    <Typography sx={{ fontWeight: "400", fontSize: "1.25rem", color: "primary.main", margin: "0.5rem 0 0.75rem 0", minHeight: "3em" }}>{i.subtitle}</Typography>
                    {i.text.map((t, k) => <Typography gutterBottom variant="body2" sx={{ lineHeight: "1.4rem" }} key={k}>
                        {t}
                    </Typography>
                    )}
                </CardContent>
                {i.action !== undefined && !disableAppLinks && <>
                    <CardActions sx={{padding: "1rem 1.75rem"}}>
                        <Button size="small" variant={i.emphasizeAction ? "contained" : "text"} onClick={() => i.actionLink && navigate(i.actionLink)}>{i.action}</Button>
                    </CardActions>
                </>}
            </Card>
        </Grid>
        )}
    </Grid>
}
export default Introduction;