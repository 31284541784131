import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import AppEmphBolder from "common/AppEmphBolder";
import GoalTypeIcon from "common/_icons/GoalTypeIcon";
import { GoalType } from "graphql/API";

const ExplanationParagraph = styled("p")({
    margin: "0.5rem 0"
})

const GoalTypeSelectTooltipContinuous = () => <>
    <Stack direction="row" spacing={"2rem"} sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box sx={{ minWidth: "7rem", width: "7rem" }}>
            <GoalTypeIcon type={GoalType.continuous} size={"7rem"}/>
        </Box>
        <Stack direction="column" spacing={"1rem"}>

            <Box sx={{ fontSize: "0.875rem", lineHeight: "1.2rem", maxWidth: "25rem" }}>
                <Box sx={{ fontSize: "0.95rem", fontWeight: 500, margin: "0 0 0.5rem 0" }}>Working Agreement</Box>
                <Box>
                Ein Working Agreement ist ein Zustand, den ihr erreichen und/oder aufrecht erhalten wollt.
                    <ExplanationParagraph>Zum Beispiel:</ExplanationParagraph>
                    <ExplanationParagraph><AppEmphBolder>"JEDEN Mittwoch führen wir ein Meeting durch."</AppEmphBolder></ExplanationParagraph>
                </Box>
            </Box>
        </Stack>
    </Stack>
</>
export default GoalTypeSelectTooltipContinuous;