import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { CircularProgress, Stack, Typography } from "@mui/material";
import LoginError from "authentication/LoginError";
import useWaitDuration from "common/useWaitDuration";
import { Auth } from "aws-amplify";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import LoginForm from "authentication/LoginForm";

const VerifyForm = () => {

    const [state, setState] = useState<"default" | "success" | "error">("default");

    const location = useLocation();
    const waitDuration = useWaitDuration();

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (mounted) return;
        setMounted(true);
        const mountTime = Date.now();

        (async () => {
            const searchParams = new URLSearchParams(location.search);
            const email = searchParams.get("email");
            const code = searchParams.get("code");
            if (!email || !code) {
                await waitDuration(2000, mountTime);
                setState("error");
                return;
            }
            try {
                await Auth.confirmSignUp(email, code);
                await waitDuration(2000, mountTime);
                setState("success");
            } catch (e) {
                await waitDuration(2000, mountTime);
                setState("error");
            }
        })();

    }, [setState, location, mounted, setMounted, waitDuration])


    return <>
        <Box sx={{ padding: "2rem 2.25rem 0 2.25rem", flexDirection: "column", display: "flex", alignItems: "flex-start", width: "100%", boxSizing: "border-box" }}>
            {(state === "default" || state === "error") && <Box sx={{textAlign: "center", padding: "0 0 2rem 0", width: "100%"}}>
                <Typography sx={{ fontSize: "1rem", margin: "0 0 1rem 0" }}>
                    E-Mail-Adresse bestätigen...
                </Typography>
                {state === "default" && <CircularProgress size="1.5rem" />}
                {state === "error" && <LoginError>
                    Beim Bestätigen der E-Mail-Adresse ist ein Fehler aufgetreten.
                </LoginError>}
            </Box>}
            {state === "success" && <>
                <Stack direction="row" spacing="0.5rem" sx={{ margin: "0 0 1rem 0", display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ color: theme => theme.palette.success.main, fontSize: "1.5rem" }} />
                    <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                        E-Mail-Adresse erfolgreich bestätigt
                    </Typography>
                </Stack>
                <Typography sx={{ fontSize: "0.875rem" }}>
                    Du kannst dich jetzt anmelden:
                </Typography>

            </>}
        </Box>
        {state === "success" && <LoginForm />}
    </>
}
export default VerifyForm;