import TooltipWithLargerText from "common/TooltipWithLargerText";
import React from "react";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { Box } from "@mui/system";

const ExplanationTooltip = ({ tooltip, label }: { tooltip: React.ReactNode, label?: React.ReactNode }) => <TooltipWithLargerText arrow title={<>
    <Box sx={{ fontSize: "0.875rem", lineHeight: "1.2rem" }}>
        {tooltip}
    </Box>
</>}>
    {label ? <Box component="div" sx={{display: "inline-block", cursor: "pointer", color: "primary.main", textDecoration: "underline"}}>{label}</Box> :
        <HelpIcon sx={{ ml: "0.1rem", fontSize: "1rem", transform: "translate(0, 0.2em)" }} />
    }
</TooltipWithLargerText>

export default ExplanationTooltip;