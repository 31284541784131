import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl } from "@mui/material";
import { Box } from "@mui/system";
import AppEmph from "common/AppEmph";
import DialogContentApp from "common/DialogContentApp";
import DialogContentParagraph from "common/DialogContentParagraph";
import StandardPaper from "common/StandardPaper";
import StandardPaperText from "common/StandardPaperText";
import StandardPaperTitle from "common/StandardPaperTitle";
import useAccountTerm from "common/useAccountTerm";
import useMount from "common/useMount";
import WarningWithIcon, { WarningWithIconEmph } from "common/WarningWithIcon";
import AppTextField from "forms/AppTextField";
import { useState } from "react";
import useAccount from "authentication/_hooks/useAccount";

const DeleteAccountDialog = ({ onClose }: {
    onClose: () => void,
}) => {

    const { authenticatedAccount, deleteAuthenticatedAccount, deleteGuestAccount } = useAccount();

    const [status, setStatus] = useState<"ASK" | "DELETING" | "SUCCESS" | "SUCCESS_GUEST">("ASK");

    const [textInput, setTextInput] = useState("");
    const buttonActive = textInput === "löschen";

    const deleteAccount = async () => {
        setStatus("DELETING");
        const handleError = (e: Error | unknown) => {
            console.error(e)
            setOpen(false);
        }
        if(authenticatedAccount) {
            try {
                await deleteAuthenticatedAccount();
                setStatus("SUCCESS");
            } catch(e) {
                handleError(e);
            }
        } else {
            try {
                await deleteGuestAccount();
                setStatus("SUCCESS_GUEST");
            } catch(e) {
                handleError(e);
            }
        }
    }

    const handleClose = () => {
        if (status === "ASK") {
            setOpen(false);
            return;
        }
    }

    const accountTerm = useAccountTerm();

    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }}>
        {(() => {
            switch (status) {
                case "ASK":
                    return <>
                        <DialogTitle>
                            {accountTerm} löschen
                        </DialogTitle>
                        <DialogContentApp>
                            Willst du dein {accountTerm} wirklich löschen?
                            <DialogContentParagraph>
                                <WarningWithIcon>
                                    Fährst du fort, <WarningWithIconEmph>werden alle mit dem {accountTerm} verbundenen Daten unwiederbringlich gelöscht!</WarningWithIconEmph>
                                </WarningWithIcon>
                            </DialogContentParagraph>
                            <DialogContentParagraph>

                            </DialogContentParagraph>
                            <Box sx={{ margin: "2rem 0 0 0" }}>
                                <FormControl sx={{ width: "100%" }}>
                                    <AppTextField
                                        label={`Bitte gib zur Bestätigung das Wort "löschen" in das Textfeld ein`}
                                        autoFocus
                                        value={textInput}
                                        onChange={(e) => {
                                            setTextInput(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </DialogContentApp>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Abbrechen</Button>
                            <Button onClick={deleteAccount} disabled={!buttonActive}>{accountTerm} löschen</Button>
                        </DialogActions>
                    </>
                case "DELETING":
                    return <>
                        <DialogTitle>
                            <Box sx={{ fontWeight: "300" }}>{accountTerm} wird gelöscht...</Box>
                        </DialogTitle>
                        <DialogContentApp>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}><CircularProgress sx={{ my: 1 }} /></Box>
                        </DialogContentApp>
                    </>
                case "SUCCESS":
                case "SUCCESS_GUEST":
                    return <>
                        <DialogTitle>
                            <Box sx={{ fontWeight: "300" }}>{accountTerm} erfolgreich gelöscht</Box>
                        </DialogTitle>
                        <DialogContentApp>
                            Dein {accountTerm} und alle damit verbundenen Daten wurden erfolgreich gelöscht.
                            {status === "SUCCESS" && <DialogContentParagraph>
                                Falls du mit diesem Browser die Gast-Funktion von vAlign genutzt hast, hast du noch ein Gastkonto. Die mit dem Gastkonto verknüpften Daten wurden durch diesen Vorgang nicht gelöscht.
                            </DialogContentParagraph>}
                            <DialogContentParagraph>
                                Um die gegebenenfalls noch im Browser gespeicherten Daten zu löschen, klicke auf "OK", um zur Login-Seite weitergeleitet zu werden oder verlasse diese Browserseite.
                            </DialogContentParagraph>
                        </DialogContentApp>
                        <DialogActions>
                            <Button onClick={() => window.location.assign("/login")}>OK, verstanden</Button>
                        </DialogActions>
                    </>
            }
        })()}

    </Dialog>
}

const AccountSettingsDeleteAccount = () => {
    const { mountComponent, unmount } = useMount();
    const accountTerm = useAccountTerm();
    return <StandardPaper>
        <StandardPaperTitle>{accountTerm} löschen</StandardPaperTitle>
        <StandardPaperText>
            Mithilfe dieser Funktion kannst du dein {accountTerm} und alle damit verbundenen Daten löschen.
            <> </><AppEmph>Diese Aktion kann nicht rückgängig gemacht werden!</AppEmph>
        </StandardPaperText>
        <Button variant="contained" size="small" color="primary" sx={{ margin: "1rem 0 0 0" }} onClick={() => mountComponent(<DeleteAccountDialog onClose={unmount} />)}>{accountTerm} löschen</Button>
    </StandardPaper>
}
export default AccountSettingsDeleteAccount;