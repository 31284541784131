/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReviewNavItemBadge_getGlobalsReturn = {
    readonly dueForReviewCount: number;
    readonly " $refType": "ReviewNavItemBadge_getGlobalsReturn";
};
export type ReviewNavItemBadge_getGlobalsReturn$data = ReviewNavItemBadge_getGlobalsReturn;
export type ReviewNavItemBadge_getGlobalsReturn$key = {
    readonly " $data"?: ReviewNavItemBadge_getGlobalsReturn$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewNavItemBadge_getGlobalsReturn">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewNavItemBadge_getGlobalsReturn",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueForReviewCount",
      "storageKey": null
    }
  ],
  "type": "GetGlobalsReturn",
  "abstractKey": null
};
(node as any).hash = 'dd6fab9cd3a298b4f89071ab03c68770';
export default node;
