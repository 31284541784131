import { useEffect } from 'react';
import { Box } from "@mui/system";
import MainCanvas from "main/MainCanvas";
import MainNav from "navigation/MainNav";
import AppToolbar from "toolbar/AppToolbar";
import { useDispatch } from "react-redux";
import { TIME_REFRESH_INTERVAL, updateNow } from "app/TimeSlice";

const AppMain = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const t = setInterval(() => {
            dispatch(updateNow())
        }, TIME_REFRESH_INTERVAL)
        return () => clearTimeout(t);
    })

    return <Box sx={{ display: 'flex' }} data-version={process.env.REACT_APP_VERSION}>
        <AppToolbar />
        <MainNav />
        <MainCanvas />
    </Box>
}
export default AppMain;