import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperContainer from "common/StandardPaperContainer";
import StandardPaperTitle from "common/StandardPaperTitle";
import FormFieldSavingIndicator from "forms/FormFieldSavingIndicator";
import { DateTime } from "luxon";
import GoalDueDateField from "main/goals/common/fields/GoalDueDateField";
import { GoalDetailsDueDate_goal$key } from "main/goals/goal_details/goal_details_overview/__generated__/GoalDetailsDueDate_goal.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsDueDate = ({ goal }: { goal: GoalDetailsDueDate_goal$key }) => {

    const data = useFragment(graphql`
        fragment GoalDetailsDueDate_goal on Goal {
            id
            dueDate
        }
    `, goal)

    const dueDate = data.dueDate ? DateTime.fromISO(data.dueDate) : DateTime.now();

    return <>
        <StandardPaper sx={{height: "100%"}}>
            <StandardPaperTitle noOpacityReduction>
                <Box sx={{ opacity: 0.8 }}>Zieldatum</Box>
                <Box sx={{ margin: "0 0 0 0.5rem" }}>
                    <FormFieldSavingIndicator />
                </Box>
            </StandardPaperTitle>
            <Box sx={{
                display: "flex",
                flexDirection: "column"
            }}>
                <StandardPaperContainer>
                    <Stack direction="row" spacing={"2rem"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Box>
                            <GoalDueDateField showTitle={false} fieldStyle="emphasized" dueDate={dueDate} />
                        </Box>
                    </Stack>
                </StandardPaperContainer>
            </Box>
        </StandardPaper>

    </>
}
export default GoalDetailsDueDate;