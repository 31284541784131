import { useDeleteAccountMutation } from "main/settings/account/__hooks/__generated__/useDeleteAccountMutation.graphql";
import { useMutation } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

const useDeleteAccount = () => {
    return useMutation<useDeleteAccountMutation>(graphql`
        mutation useDeleteAccountMutation {
            deleteAccount {
              deletedAccount
            }
        }
    `)
}
export default useDeleteAccount;