import useAccount from "authentication/_hooks/useAccount";
import AppButton from "common/AppButton";
import { useNavigate } from "react-router-dom";

const LoginSignupButton = () => {
    const { authenticatedAccount, accountExists } = useAccount();
    const navigate = useNavigate();

    return (() => {
        if (authenticatedAccount) {
            return <></>
        } else if (accountExists) {
            return <AppButton color="success" onClick={() => navigate("/login")}>
                Anmelden
            </AppButton>
        }
        return <AppButton color="success" onClick={() => navigate("/login/signup")}>
            Kostenlos registrieren
        </AppButton>
    })();
}
export default LoginSignupButton;