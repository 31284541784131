import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FormHandlersReturn } from "forms/useFormHandlers";
import { GoalType } from "graphql/API";
import GoalDetailsReviewComment from "main/goals/goal_details/goal_details_review/GoalDetailsReviewComment";
import GoalDetailsReviewCompletion from "main/goals/goal_details/goal_details_review/GoalDetailsReviewCompletion";
import GoalDetailsReviewGoalStatus from "main/goals/goal_details/goal_details_review/GoalDetailsReviewGoalStatus";
import { GoalDetailsReviewForm_goal$key } from "main/goals/goal_details/goal_details_review/__generated__/GoalDetailsReviewForm_goal.graphql";
import { useFragment } from "react-relay";
const graphql = require("babel-plugin-relay/macro");

export type ReviewFormData = {
    completion: number | null,
    status: number,
    comment?: string
}

const GoalDetailsReviewForm = ({ saveReview, goal, saving = false, formHandlers }: {
    saveReview: () => void,
    goal: GoalDetailsReviewForm_goal$key,
    saving: boolean,
    formHandlers: FormHandlersReturn<ReviewFormData>
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsReviewForm_goal on Goal {
        type
        ...GoalDetailsReviewGoalStatus_goal
    }
    `, goal)

    const { controlFormField } = formHandlers;
    const completable = data.type === GoalType.completable;

    return <>
        <Box sx={{ width: "100%", margin: "0 0 4rem 0" }}>
            <Stack direction="column" spacing={"1rem"}>
                <Stack direction="column" spacing={"0.25rem"}>
                    {completable && <GoalDetailsReviewCompletion {...controlFormField<number>({ fieldName: "completion" })} />}
                    <GoalDetailsReviewGoalStatus goal={data} {...controlFormField<number>({ fieldName: "status" })} />
                </Stack>
                <GoalDetailsReviewComment {...controlFormField<string>({ fieldName: "comment" })} />
            </Stack>
            <Box sx={{ margin: "2rem 0 0 0" }}>
                {saving ? <>
                    <Stack direction="row" spacing={"1rem"} sx={{ display: "flex", alignItems: "center" }}>
                        <CircularProgress size={"2rem"} />
                        <Typography variant="body2">Review speichern...</Typography>
                    </Stack>
                </> :
                    <Button variant="contained"
                        onClick={saveReview}
                    >
                        Review speichern
                    </Button>}
            </Box>
        </Box>
    </>
}
export default GoalDetailsReviewForm;