import { Button, CircularProgress, Collapse, Dialog, DialogTitle, Stack } from "@mui/material";
import useAccount from "authentication/_hooks/useAccount";
import AnimationWrapper from "common/AnimationWrapper";
import StandardPaper from "common/StandardPaper";
import StandardPaperText from "common/StandardPaperText";
import StandardPaperTitle from "common/StandardPaperTitle";
import useRegexValidations from "common/useRegexValidations";
import AppTextField from "forms/AppTextField";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import { Box } from "@mui/system";
import DialogContentApp from "common/DialogContentApp";
import useMount from "common/useMount";
import DialogTitleWithIcon from "common/DialogTitleWithIcon";

const ChangePasswordDialog = ({ actionPromise, onClose }: { actionPromise: Promise<void>, onClose: () => void }) => {
    const [status, setStatus] = useState<"pending" | "success" | "error">("pending");
    const handleClose = () => {
        if (status === "success" || status === "error") {
            setOpen(false);
            return;
        }
    }

    useEffect(() => {
        actionPromise
            .then(() => setStatus("success"))
            .catch(() => setStatus("error"))
    }, [actionPromise])

    const [open, setOpen] = useState(true);
    return <Dialog open={open} onClose={handleClose} TransitionProps={{ onExited: onClose }}>
        {(() => {
            switch (status) {
                case "pending":
                    return <>
                        <DialogTitle>
                            <Box sx={{ fontWeight: "300" }}>Neues Passwort setzen...</Box>
                        </DialogTitle>
                        <DialogContentApp>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}><CircularProgress sx={{ my: 1 }} /></Box>
                        </DialogContentApp>
                    </>
                case "success":
                    return <>
                        <DialogTitleWithIcon icon={<CheckIcon color="success" />}>
                            Neues Passwort erfolgreich gesetzt
                        </DialogTitleWithIcon>
                        <DialogContentApp>
                            Das Passwort wurde erfolgreich geändert.
                        </DialogContentApp>
                    </>
                case "error":
                default:
                    return <>
                        <DialogTitleWithIcon icon={<ErrorIcon color="error" />}>
                            FEHLER
                        </DialogTitleWithIcon>
                        <DialogContentApp>
                            Beim Setzen des neuen Passworts ist ein Fehler aufgetreten
                        </DialogContentApp>
                    </>
            }
        })()}
    </Dialog>
}

const AccountSettingsChangePassword = () => {

    const { authenticatedAccount, changePassword } = useAccount();
    const [formVisible, setFormVisible] = useState(false);
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
    const { validatePassword, hasMinPasswordLength, hasUpperCaseLetter, hasLowerCaseLetter, hasNumber, hasPasswordSpecialChar } = useRegexValidations();

    const newPasswordConfirmed = newPassword !== "" && newPassword === newPasswordConfirm;
    const newPasswordValid = newPassword && validatePassword(newPassword);
    const formValid = newPasswordValid && newPasswordConfirmed && newPassword !== currentPassword;

    const unsetFormInputs = () => {
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
    }

    const changePasswordPromise = async () => {
        try {
            await changePassword(currentPassword, newPassword);
            unsetFormInputs();
            setFormVisible(false);
        } catch(e) {
            unsetFormInputs();
            throw e;
        }
    }

    const { mountComponent, unmount } = useMount();

    return authenticatedAccount ? <StandardPaper>
        <StandardPaperTitle>Passwort ändern</StandardPaperTitle>
        <StandardPaperText>
            Mithilfe dieser Funktion kannst du das Passwort für dein Nutzerkonto ändern.
        </StandardPaperText>
        <Collapse in={!formVisible}>
            <AnimationWrapper>
                <Button variant="contained" size="small" color="primary" disabled={formVisible} sx={{ margin: "1rem 0 0 0", opacity: formVisible ? 0 : 1, transition: "0.5s" }} onClick={() => {
                    setFormVisible(true)
                }}>
                    Passwort ändern...
                </Button>
            </AnimationWrapper>
        </Collapse>
        <Collapse in={formVisible}>
            <AnimationWrapper>
                <Stack direction="column" spacing="0.5rem" sx={{ margin: "1rem 0 0 0" }}>
                    <AppTextField
                        value={currentPassword}
                        label="Aktuelles Passwort"
                        type="password"
                        sx={{ width: "25rem" }}
                        onChange={event => setCurrentPassword(event.target.value)}
                    />
                    <AppTextField
                        value={newPassword}
                        label="Neues Passwort"
                        type="password"
                        sx={{ width: "25rem" }}
                        onChange={event => setNewPassword(event.target.value)}
                    />
                    <Stack direction="row" spacing="0.5rem" sx={{ fontSize: "0.75rem" }}>
                        {[{
                            label: ">8 Zeichen",
                            fulfilled: hasMinPasswordLength(newPassword)
                        }, {
                            label: "Groß/Kleinbuchstaben",
                            fulfilled: hasUpperCaseLetter(newPassword) && hasLowerCaseLetter(newPassword)
                        }, {
                            label: "Zahl",
                            fulfilled: hasNumber(newPassword)
                        }, {
                            label: "Sonderzeichen",
                            fulfilled: hasPasswordSpecialChar(newPassword)
                        }].map((item, i) => <Stack direction="row" key={i} sx={{ whiteSpace: "nowrap", alignItems: "center", transition: "0.4s" }}>
                            <CheckIcon sx={{ fontSize: "0.75rem", margin: "0 0.2rem 0 0", opacity: item.fulfilled ? 1 : 0.5, transition: "0.2s", color: theme => item.fulfilled ? theme.palette.success.main : "rgba(0,0,0,0.25)" }} />
                            <Box component="span" sx={{ opacity: item.fulfilled ? 0.5 : 0.8, transition: "0.2s" }}>{item.label}</Box>
                        </Stack>)}
                    </Stack>
                    <AppTextField
                        value={newPasswordConfirm}
                        label="Neues Passwort erneut eingeben"
                        type="password"
                        sx={{ width: "25rem" }}
                        onChange={event => setNewPasswordConfirm(event.target.value)}
                    />
                    <Stack direction="row" spacing="0.5rem" sx={{ fontSize: "0.75rem" }}>
                        {[{
                            label: "Passwörter stimmen überein",
                            fulfilled: newPasswordConfirmed
                        }].map((item, i) => <Stack direction="row" key={i} sx={{ whiteSpace: "nowrap", alignItems: "center", transition: "0.4s" }}>
                            <CheckIcon sx={{ fontSize: "0.75rem", margin: "0 0.2rem 0 0", opacity: item.fulfilled ? 1 : 0.5, transition: "0.2s", color: theme => item.fulfilled ? theme.palette.success.main : "rgba(0,0,0,0.25)" }} />
                            <Box component="span" sx={{ opacity: item.fulfilled ? 0.5 : 0.8, transition: "0.2s" }}>{item.label}</Box>
                        </Stack>)}
                    </Stack>
                </Stack>
                <Stack direction="row" spacing="1rem" sx={{ margin: "1rem 0 0 0" }}>

                    <Button variant="contained" size="small" color="primary" onClick={() => {
                        setFormVisible(false);
                        unsetFormInputs();
                    }}>
                        Abbrechen
                    </Button>
                    <Button variant="contained" size="small" color="primary" disabled={!formValid} onClick={() => {
                        mountComponent(<ChangePasswordDialog actionPromise={changePasswordPromise()} onClose={unmount} />)
                    }}>
                        Neues Passwort setzen
                    </Button>
                </Stack>
            </AnimationWrapper>
        </Collapse>
    </StandardPaper> : <></>
}
export default AccountSettingsChangePassword;