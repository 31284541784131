import React, { createContext, useState } from "react"

export const MountContext = createContext<{
    mountComponent: (title: React.ReactNode) => void,
    unmount: () => void
} | null>(null);

const MountProvider = ({children}: {children: React.ReactNode}) => {
    const [component, mountComponent] = useState<React.ReactNode | null>(null);
    const unmount = () => {
        mountComponent(null);
    }
    return <MountContext.Provider value={{mountComponent, unmount}}>
        {component ?? <></>}
        {children}
    </MountContext.Provider>
}
export default MountProvider;