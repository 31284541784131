import { Stack } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import GoalCardDivider from "main/goals/goal_card/GoalCardDivider";
import React from "react";

const GoalCardTitleFieldContainer = ({ children, width = "8rem", startContainer = false, sx }: { children: React.ReactNode, width?: string, startContainer?: boolean, sx?: SxProps }) => {
    return <Stack direction="row" sx={Object.assign({
        width: width,
        margin: "0",
    }, sx ?? {})}>
        {!startContainer && <GoalCardDivider />}
        <Box sx={{
            margin: startContainer ? "0 1rem 0 0" : "0 1rem",
            display: "flex",
            flexDirection: "column",
            width: "100%"
        }}>
            {children}
        </Box>
    </Stack>
}
export default GoalCardTitleFieldContainer;