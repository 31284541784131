import { MenuItem } from "@mui/material";
import AppSelect from "forms/AppSelect";
import useFormFieldSavingEvents from "forms/useFormFieldsSavingEvents";
import { FormHandlersReturn } from "forms/useFormHandlers";
import { Duration, DurationObjectUnits } from "luxon";
import { GoalDetailsSettingsReviewCycleBegin_goal$key } from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/__generated__/GoalDetailsSettingsReviewCycleBegin_goal.graphql";
import { cycleBeginSelection, CycleOption, cycleOptionsLabelMap, cycleOptionsMap, mapReviewCycleInputToCycleOption, beginToGoalCycleOptionsMap } from "main/goals/common/cycleOptions";
import useUpdateGoal from "main/goals/__hooks/useUpdateGoal";
import useEventBus, { EventTypes } from "app/useEventBus";
import React, { useState } from "react";
import { useFragment } from "react-relay";
import { ReviewCycleInput } from "graphql/API";
import useCalculateRepetitionLevel from "main/goals/goal_details/goal_details_settings/goal_details_settings_review_mode/_hooks/useCalculateRepetitionLevel";
const graphql = require("babel-plugin-relay/macro");

const GoalDetailsSettingsReviewCycleBegin = ({ goal, formHandlers, readOnly, eventSource }: {
    goal: GoalDetailsSettingsReviewCycleBegin_goal$key,
    formHandlers: FormHandlersReturn<{ reviewCycleBegin: ReviewCycleInput | null }>,
    readOnly?: boolean,
    eventSource: React.MutableRefObject<EventTarget>
}) => {

    const data = useFragment(graphql`
    fragment GoalDetailsSettingsReviewCycleBegin_goal on Goal {
        id
        spacedRepetitionLevel
        reviewMode {
            spacedRepetition
            cycleBegin {
                days
                months
            }
            cycleGoal {
                days
                months
            }
        }
    }
    `, goal)

    const [selectValue, setSelectValue] = useState<CycleOption>(() => data?.reviewMode?.cycleBegin ? (mapReviewCycleInputToCycleOption(data.reviewMode.cycleBegin) ?? CycleOption.TWOWEEKS) : CycleOption.TWOWEEKS);

    const { isValidForm, formDataRef } = formHandlers;
    const { handleChange, errorsExist } = formHandlers.controlFormField({
        fieldName: "reviewCycleBegin", validations: {
            lessThanCycleGoal: () => (cycle: ReviewCycleInput) => {
                return !data.reviewMode.spacedRepetition || Duration.fromObject(cycle as DurationObjectUnits) < Duration.fromObject(data.reviewMode.cycleGoal as DurationObjectUnits)
            }
        }
    });

    const [commit] = useUpdateGoal();
    const eventBus = useEventBus();
    const formFieldSavingEvents = useFormFieldSavingEvents(eventSource);
    const calculateRepetitionLevel = useCalculateRepetitionLevel();
    const save = () => {
        if (!isValidForm()) return;
        if (!data.reviewMode.cycleBegin || !data.reviewMode.cycleGoal || !formDataRef.current.reviewCycleBegin || data.spacedRepetitionLevel === null) throw new Error("error while updating goal")
        formFieldSavingEvents.dispatchFormFieldSavingEvent();
        const calculatedCycleGoal = !data.reviewMode.spacedRepetition ? [formDataRef.current.reviewCycleBegin]
            .map(reviewCycleBegin => mapReviewCycleInputToCycleOption(reviewCycleBegin))
            .map(cycleOptionBegin => cycleOptionBegin && beginToGoalCycleOptionsMap.get(cycleOptionBegin))
            .map(cycleOptionGoal => cycleOptionGoal && cycleOptionsMap.get(cycleOptionGoal))
            .pop() ?? null : null;
        const newRepetitionLevel = data.reviewMode.spacedRepetition ? calculateRepetitionLevel(data.reviewMode.cycleBegin, data.reviewMode.cycleGoal, formDataRef.current.reviewCycleBegin, data.reviewMode.cycleGoal, data.spacedRepetitionLevel) : 0;
        commit({
            variables: {
                input: {
                    id: data.id,
                    spacedRepetitionLevel: newRepetitionLevel,
                    reviewMode: Object.assign({}, data.reviewMode, { cycleBegin: formDataRef.current.reviewCycleBegin },
                        calculatedCycleGoal ? { cycleGoal: calculatedCycleGoal } : {}
                    )
                }
            },
            onCompleted: () => {
                eventBus.dispatchEvent(new CustomEvent(EventTypes.GOAL_UPDATED))
                formFieldSavingEvents.dispatchFormFieldSavingSuccessEvent()
            },
            onError: () => formFieldSavingEvents.dispatchFormFieldSavingErrorEvent()
        })
    }

    const createMenuItems = (cycleSelection: CycleOption[]) => cycleSelection.map((cycleOption, i) => <MenuItem value={cycleOption} key={i}>{cycleOptionsLabelMap.get(cycleOption)}</MenuItem>)
    return <>
        <AppSelect
            labelText={data.reviewMode.spacedRepetition ? "Start-Intervall" : "Intervall"}
            labelId="reviewCycleLabelId"
            label={data.reviewMode.spacedRepetition ? "Start-Intervall" : "Intervall"}
            value={selectValue}
            error={errorsExist()}
            onChange={e => {
                const cycleOption = e.target.value as CycleOption;
                let reviewCycle: ReviewCycleInput | null = cycleOptionsMap.get(cycleOption) ?? null;
                if (!reviewCycle) return;
                setSelectValue(cycleOption);
                handleChange(reviewCycle);
                save();
            }}
            boldValue
            sx={{
                width: "12em"
            }}
            readOnly={readOnly}
        >
            {createMenuItems(cycleBeginSelection)}
        </AppSelect>
    </>
}
export default GoalDetailsSettingsReviewCycleBegin;