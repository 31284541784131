/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GoalCardTitle_goal = {
    readonly readableId: number;
    readonly title: string;
    readonly description: string | null;
    readonly " $fragmentRefs": FragmentRefs<"GoalStatusField_goal">;
    readonly " $refType": "GoalCardTitle_goal";
};
export type GoalCardTitle_goal$data = GoalCardTitle_goal;
export type GoalCardTitle_goal$key = {
    readonly " $data"?: GoalCardTitle_goal$data;
    readonly " $fragmentRefs": FragmentRefs<"GoalCardTitle_goal">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GoalCardTitle_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readableId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoalStatusField_goal"
    }
  ],
  "type": "Goal",
  "abstractKey": null
};
(node as any).hash = 'b2e5616325c851e0320a5760d8de2e3f';
export default node;
