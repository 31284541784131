import { styled } from "@mui/material";
import { Box } from "@mui/system";

const LoginError = styled(Box)(({theme}) => ({
    padding: "1rem 0 0 0",
    color: theme.palette.error.main,
    fontSize: "0.875rem",
    lineHeight: "1.2rem",
    fontWeight: 500
}))
export default LoginError;