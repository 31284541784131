import { GoalGetGoalByReadableIdQuery } from "main/goals/goal_details/Goal";
import { GoalDetailsMainBarTitle_goal$key } from "main/goals/goal_details/__generated__/GoalDetailsMainBarTitle_goal.graphql";
import { GoalGetGoalByReadableIdQuery as GoalGetGoalByReadableIdQueryType } from "main/goals/goal_details/__generated__/GoalGetGoalByReadableIdQuery.graphql";
import { Suspense } from "react";
import { PreloadedQuery, useFragment, usePreloadedQuery } from "react-relay";
import MainBarField from "main/MainBarField";
import MainNavigateBackButton from "main/MainNavigateBackButton";
import GoalTypeIcon from "common/_icons/GoalTypeIcon";
import useReadableId from "common/useReadableId";
import { useSelector } from "react-redux";
import { selectSelectedGoal } from "main/goals/GoalSlice";
import MainTitle from "main/MainTitle";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import { GoalType } from "graphql/API";
import useReviewContext from "common/useReviewContext";
import useArchiveContext from "common/useArchiveContext";
const graphql = require("babel-plugin-relay/macro");

const LoadedTitle = ({ queryRef }: { queryRef: PreloadedQuery<GoalGetGoalByReadableIdQueryType> }) => {

    const { getReadableId } = useReadableId();
    const navigate = useNavigate();

    const queryData = usePreloadedQuery<GoalGetGoalByReadableIdQueryType>(GoalGetGoalByReadableIdQuery, queryRef)

    const data = useFragment<GoalDetailsMainBarTitle_goal$key>(graphql`
        fragment GoalDetailsMainBarTitle_goal on Goal {
            readableId
            type
            title
        }
    `, queryData.getGoalByReadableId)

    const reviewContext = useReviewContext();
    const archiveContext = useArchiveContext();

    return data?.readableId ? <>
        <MainBarField>
            <MainNavigateBackButton onClick={() => navigate(reviewContext ? "/review" : archiveContext ? "/archive" : "/goals")} />
            {data.type && <GoalTypeIcon type={data.type as GoalType} size="1.75rem" sx={{ mr: "0.75em", filter: "grayscale(0)" }} />}
            <Box sx={{ fontWeight: 500, fontSize: "1.5rem", mr: "1rem", whiteSpace: "nowrap" }}>{getReadableId(data.readableId ?? 0)}</Box>
        </MainBarField>
        <MainTitle>
            {data.title}
        </MainTitle>
    </> : <></>
}

const GoalDetailsMainBarTitle = ({ queryRef }: {
    queryRef?: PreloadedQuery<GoalGetGoalByReadableIdQueryType> | null
}) => {
    const selectedGoalDigest = useSelector(selectSelectedGoal);
    const navigate = useNavigate();
    const { getReadableId } = useReadableId();
    const reviewContext = useReviewContext();

    return <Suspense fallback={<>{selectedGoalDigest?.readableId && <>
        <MainBarField>
            <MainNavigateBackButton onClick={() => navigate(reviewContext ? "/review" : "/goals")} />
            {selectedGoalDigest?.type && <GoalTypeIcon type={selectedGoalDigest?.type} size="1.75rem" sx={{ mr: "0.75em", filter: "grayscale(0)" }} />}
            <Box sx={{ fontWeight: 500, fontSize: "1.5rem", mr: "1rem", whiteSpace: "nowrap" }}>{getReadableId(selectedGoalDigest?.readableId ?? 0)}</Box>
        </MainBarField>
        <MainTitle>
            {selectedGoalDigest?.title}
        </MainTitle>
    </>}</>}>
        {queryRef && <LoadedTitle queryRef={queryRef} />}
    </Suspense>
}
export default GoalDetailsMainBarTitle;