import ListItemButton from "@mui/material/ListItemButton";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "themes";
import React from "react";

const NavSubItem = ({
    children,
    path,
    activePattern = new RegExp(`^${path}`),
    badge = <></>
}: {
    children: string,
    path: string,
    activePattern?: RegExp,
    badge?: React.ReactNode
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const selected = activePattern.test(location.pathname);
    return <>
        <ListItemButton selected={selected} sx={{
            padding: "0.3em 1em 0.3em 3.5em",
        }} onClick={() => navigate(path)}>
            <Stack direction="row" sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                <Typography sx={{
                    fontSize: "0.8em",
                    opacity: selected ? "1" : "0.8"
                }}>
                    {children}
                </Typography>
                <ThemeProvider theme={defaultTheme}>
                    {badge}
                </ThemeProvider>
            </Stack>
        </ListItemButton>
    </>
}
export default NavSubItem;