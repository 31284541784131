import { Box } from "@mui/system";
import StandardPaper from "common/StandardPaper";
import StandardPaperText from "common/StandardPaperText";
import MainBar from "main/MainBar";
import MainContainer from "main/MainContainer";
import MainContent from "main/MainContent";
import MainTitle from "main/MainTitle";

const Imprint = () => {
    return <>
        <MainBar>
            <MainTitle>Impressum</MainTitle>
        </MainBar>
        <MainContent>
            <MainContainer>
                <StandardPaper>
                    <StandardPaperText>
                        <Box sx={{fontSize: "1rem", fontWeight: 500, margin: "0 0 0.5rem 0"}}>evolutopia GmbH</Box>
                        Elektrastraße 13
                        <br />
                        81925 München <br />
                        <br />
                        Tel. +49 (0) 89 / 215 565 420
                        <br />
                        Fax + 49 (0) 89 / 215 565 421
                        <br />
                        Email: contact@valign.com
                        <br />
                        <br />
                        Geschäftsführer: Danny Winter, Stefan Birnkammerer
                        <br />
                        Umsatzsteuer-Identifikationsnummer: DE310638610
                        <br />
                        Amtsgericht München, HRB 231047
                    </StandardPaperText>
                </StandardPaper>
            </MainContainer>
        </MainContent>
    </>
}
export default Imprint;