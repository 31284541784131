import SuccessIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import { CircularProgress, Fade } from "@mui/material";
import { Box } from "@mui/system";
import AnimationWrapper from "common/AnimationWrapper";
import { FormFieldSavingCustomEventPayload, FormFieldSavingEventType, FormFieldSavingStatus } from "forms/types";
import { useEffect, useRef, useState } from "react";

const FormFieldSavingIndicator = ({ eventSource }: { eventSource?: EventTarget | null }) => {

    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successVisible, setSuccessVisible] = useState(true);
    const [error, setError] = useState(false);
    const [errorVisible, setErrorVisible] = useState(true);

    const mounted = useRef(true);
    const timeout = useRef<number | null>(null);

    useEffect(() => {
        mounted.current = true;
        if (eventSource) {
            const listener = (event: CustomEvent<FormFieldSavingCustomEventPayload>) => {
                switch (event.detail.status) {
                    case FormFieldSavingStatus.SAVING:
                        setSaving(true);
                        setSuccess(false);
                        setSuccessVisible(true);
                        setError(false);
                        setErrorVisible(true);
                        break;
                    case FormFieldSavingStatus.SUCCESS:
                        setSaving(false);
                        setSuccess(true);
                        timeout.current = window.setTimeout(() => {
                            mounted.current && setSuccessVisible(false);
                        }, 2000)
                        break;
                    case FormFieldSavingStatus.ERROR:
                        setSaving(false);
                        setError(true);
                        timeout.current = window.setTimeout(() => {
                            mounted.current && setErrorVisible(false);
                        }, 2000)
                        break;
                    default:
                        setSaving(false);
                        setSuccess(false);
                        setSuccessVisible(false)
                        break;
                }
            }
            eventSource.addEventListener(FormFieldSavingEventType, listener as EventListener)
            return () => {
                eventSource.removeEventListener(FormFieldSavingEventType, listener as EventListener)
                mounted.current = false;
            }
        }
    })
    return <Box sx={{ position: "relative", height: "100%" }}>
        <Box sx={{ position: "absolute", height: "100%", display: "flex", alignItems: "center" }}>
            {saving && <>
                <CircularProgress size="1rem" />
            </>}
        </Box>
        {success && <>
            <Box sx={{ position: "absolute", height: "100%", minWidth: "max-content", display: "flex", alignItems: "center", left: "-0.1rem" }}>
                <Fade in={successVisible} appear={true} timeout={{ appear: 400, enter: 400, exit: 3000 }}>
                    <AnimationWrapper>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <SuccessIcon fontSize="small" color="success" sx={{ position: "relative", zIndex: 2 }} />
                            <Box sx={{ fontSize: "0.7rem", fontWeight: "900", margin: "0 0 0 0.25rem" }}>gespeichert</Box>
                        </Box>
                    </AnimationWrapper>
                </Fade>
            </Box>
        </>}
        {error && <>
            <Box sx={{ position: "absolute", height: "100%", minWidth: "max-content", display: "flex", alignItems: "center", left: "-0.1rem" }}>
                <Fade in={errorVisible} appear={true} timeout={{ appear: 400, enter: 400, exit: 3000 }}>
                    <AnimationWrapper>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ErrorIcon fontSize="small" color="error" sx={{ position: "relative", zIndex: 2 }} />
                            <Box sx={{ fontSize: "0.7rem", fontWeight: "900", margin: "0 0 0 0.25rem" }}>Fehler beim Speichern!</Box>
                        </Box>
                    </AnimationWrapper>
                </Fade>
            </Box>
        </>}
    </Box>
}
export default FormFieldSavingIndicator;